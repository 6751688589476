import { Button } from "@mui/material";
import Spinner from "Components/Spinner/Spinner";
import { useTrendingKeywords } from "Utils/queries";
import { useEffect, useState } from "react";
import RenderListKeywords from "./RenderListKeyword";

const TrendingKeywords = () => {
  const [allKeywords, setAllKeywords] = useState([]);

  // queries
  const { isLoading, data, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useTrendingKeywords();

  useEffect(() => {
    if (data) {
      const { pages } = data;
      let arr = [];
      pages.forEach((page) => {
        page.keyword.forEach((keyword) => {
          arr.push(keyword);
        });
      });
      setAllKeywords(arr);
    }
  }, [data]);

  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div>
        {allKeywords.map((keyword, idx) => (
          <RenderListKeywords
            idx={idx}
            keyword={keyword}
            key={idx + keyword.uuid}
          />
        ))}
      </div>
      {hasNextPage && (
        <div className="center">
          <Button
            onClick={fetchNextPage}
            size="small"
            disabled={isFetchingNextPage}
          >
            {isFetchingNextPage ? "Loading" : "Load More"}
          </Button>
        </div>
      )}
    </>
  );
};

export default TrendingKeywords;
