import {
  Add,
  ArrowDropDown,
  Delete,
  EditOutlined,
  Share,
} from "@mui/icons-material";
import {
  Avatar,
  Button,
  ButtonGroup,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
} from "@mui/material";
import { lazy, useEffect, useState } from "react";

import DummyImage1 from "assets/images/logo.svg";
import ListComments from "Components/ListComments/ListComments";
import ListTags from "Components/ListTags/ListTags";
import ProfileKeywordsTags from "Components/ProfileKeywordsTags/ProfileKeywordsTags";
import RelativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import { red } from "@mui/material/colors";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { useReduxUserProfile } from "Utils/hooks";
import Advertisement from "Components/Advertisement/Advertisement";
import ShareList from "Components/ShareList/ShareList";

dayjs.extend(RelativeTime);

const TAGS_ARRAY = [
  "keywordsCount",
  "questionsCount",
  "notesCount",
  "linksCount",
  "imagesCount",
  "videosCount",
];

// components
const PlayGame = lazy(() => import("Components/PlayGame/PlayGame"));
const DeleteKewordalList = lazy(() =>
  import("../DeleteKewordalList/DeleteKewordalList")
);
const EditKewordalList = lazy(() =>
  import("../EditKewordalList/EditKewordalList")
);
const AddKeyword = lazy(() => import("../EditKeyword/EditKeyword"));
// Components
const CloneKewordalListModal = lazy(() =>
  import("Components/CloneKeywordList/CloneKeywordList")
);

const NewListCard = ({
  list,
  selectedSubject,
  refetchListData,
  userProfile,
  idx,
}) => {
  // props
  const {
    uuid,
    name,
    href: listHref,
    userUuid,
    username,
    keywords,
    userAvatarURL,
    mainKeywordImg,
    createdAt,
    likes,
    reportedBy,
    dislikes,
    subject,
    subjectHref,
  } = list;

  const filteredKeywords = keywords.filter((keyword) => !!keyword.uuid);

  // state
  const [playGame, setPlayGame] = useState(false);
  const [postMenu, setPostMenu] = useState(null);
  const [openDeleteModal, setDeleteListModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [addKeywordModal, setAddKeywordModal] = useState(false);
  const [renderAdvertisement, setRenderAdvertisement] = useState(false);
  const [cloneDialog, setCloneDialog] = useState(false);
  const [shareList, setShareList] = useState(false);
  const [disableGameButton, setDisableGameButton] = useState(true);

  // redux
  const userRedux = useReduxUserProfile();

  // observer
  const { ref: commentsRef, inView: commentsInView } = useInView({});

  // router
  const navigate = useNavigate();

  useEffect(() => {
    if (idx && idx % 2 === 0) {
      setRenderAdvertisement(true);
    }
  }, [idx]);

  useEffect(() => {
    if (keywords) {
      const count = keywords.filter((i) => i.question).length;
      if (count >= 4) {
        setDisableGameButton(false);
      } else {
        setDisableGameButton(true);
      }
    }
  }, [keywords]);

  const handleCloseMenu = () => {
    setPostMenu(null);
  };

  const handleViewList = () => {
    navigate(`/view-list/${subjectHref}/${listHref}`);
  };

  const handleViewUser = () => {
    navigate(`/my-network/${username}`);
  };

  const handlePlayGame = () => {
    setPlayGame(true);
  };

  const handleDeleteList = () => {
    handleCloseMenu();
    setDeleteListModal(true);
  };

  const handleOpenEditModal = () => {
    handleCloseMenu();
    setOpenEditModal(true);
  };

  const handleOpenAddKeyword = () => {
    handleCloseMenu();
    setAddKeywordModal(true);
  };

  const handleCloneList = () => {
    handleCloseMenu();
    setCloneDialog(true);
  };

  const handleShareList = () => {
    setShareList(true);
  };

  return (
    <>
      <Paper elevation={2} className="new-list-card">
        <div>
          <p className="subject">{subject}</p>
        </div>
        <div className="footer-div">
          <span onClick={handleViewList} className="footer-title">
            {name}
          </span>
          <div className="d-flex gap-2">
            <span>
              <Button
                disabled={disableGameButton}
                onClick={handlePlayGame}
                disableElevation
                variant="contained"
                className={`custom-btn ${disableGameButton ? "" : "green-btn"}`}
                size="small"
              >
                Game
              </Button>
            </span>

            <ButtonGroup variant="contained" disableElevation size="small">
              <Button
                onClick={handleViewList}
                disableElevation
                variant="contained"
                className="custom-btn "
                size="small"
              >
                View
              </Button>
              <Button
                size="small"
                onClick={(e) => setPostMenu(e.currentTarget)}
              >
                <ArrowDropDown />
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <i className="text-muted from">From</i>
        <div className="title-div mt-2">
          <div onClick={handleViewUser} className="d-flex gap-2 cursor-pointer">
            <Avatar src={userAvatarURL} alt={username} />
            <div className="d-flex flex-column">
              <span className="fw-500">{username}</span>
              <span className="text-muted" style={{ fontSize: "11px" }}>
                {dayjs(createdAt).fromNow()}
              </span>
            </div>
          </div>
        </div>
        {userProfile ? (
          filteredKeywords.length > 0 && (
            <ProfileKeywordsTags
              keywords={filteredKeywords}
              refetchListData={refetchListData}
            />
          )
        ) : (
          <div className="list-tags-container">
            {TAGS_ARRAY.map((tag) => (
              <ListTags key={tag + uuid} title={tag} value={list[tag]} />
            ))}
          </div>
        )}
        {mainKeywordImg && (
          <div
            onClick={handleViewList}
            style={{ backgroundImage: `url(${mainKeywordImg || DummyImage1})` }}
            className="image-div"
          />
        )}

        <div ref={commentsRef}>
          <ListComments
            listID={uuid}
            commentsInView={commentsInView}
            likes={likes}
            reportedBy={reportedBy}
            refetchListData={refetchListData}
            dislikes={dislikes}
          />
        </div>
      </Paper>
      {renderAdvertisement && (
        <div className="mt-3">
          <Advertisement fullView />
        </div>
      )}
      <Menu
        open={Boolean(postMenu)}
        anchorEl={postMenu}
        onClose={handleCloseMenu}
        PaperProps={{
          sx: {
            minWidth: "150px",
          },
        }}
      >
        <MenuList dense>
          {userRedux.uuid === userUuid && (
            <>
              <MenuItem onClick={handleOpenEditModal}>
                <ListItemIcon>
                  <EditOutlined fontSize="small" />
                </ListItemIcon>
                <ListItemText>Edit List</ListItemText>
              </MenuItem>
              <MenuItem onClick={handleOpenAddKeyword}>
                <ListItemIcon>
                  <Add fontSize="small" />
                </ListItemIcon>
                <ListItemText>Add keyword</ListItemText>
              </MenuItem>
              {/* <MenuItem onClick={handleShareList}>
                <ListItemIcon>
                  <Share fontSize="small" />
                </ListItemIcon>
                <ListItemText>Share List</ListItemText>
              </MenuItem> */}
              <MenuItem
                sx={{
                  "&:hover": {
                    backgroundColor: red[700],
                    color: "white",
                  },
                }}
                onClick={handleDeleteList}
              >
                <ListItemIcon>
                  <Delete fontSize="small" />
                </ListItemIcon>
                <ListItemText>Delete list</ListItemText>
              </MenuItem>
            </>
          )}
          {userRedux.uuid !== userUuid && (
            <MenuItem onClick={handleCloneList}>
              <ListItemIcon>
                <Add fontSize="small" />
              </ListItemIcon>
              <ListItemText>Clone list</ListItemText>
            </MenuItem>
          )}
        </MenuList>
      </Menu>
      {playGame && (
        <PlayGame open={playGame} close={setPlayGame} listHref={listHref} />
      )}
      {openDeleteModal && (
        <DeleteKewordalList
          open={openDeleteModal}
          setDeleteListModal={setDeleteListModal}
          id={uuid}
          refresh={refetchListData}
          totalKeywords={
            Array.isArray(keywords) &&
            keywords.filter((item) => item.uuid).length
          }
          name={name}
        />
      )}
      {shareList && (
        <ShareList listID={uuid} open={shareList} toggle={setShareList} />
      )}
      {openEditModal && (
        <EditKewordalList
          open={openEditModal}
          id={uuid}
          listName={name}
          setOpenEditModal={setOpenEditModal}
          subject={selectedSubject}
          refresh={refetchListData}
        />
      )}
      {addKeywordModal && (
        <AddKeyword
          open={addKeywordModal}
          close={setAddKeywordModal}
          subjectHref={subjectHref}
          listName={name}
          listHref={listHref}
          refresh={refetchListData}
          addKeyword
        />
      )}
      {subject && (
        <CloneKewordalListModal
          uuid={uuid}
          term={name}
          open={cloneDialog}
          setCloneModal={setCloneDialog}
          refetchListData={refetchListData}
        />
      )}
    </>
  );
};

export default NewListCard;
