import { LOGIN, LOGOUT, GET_PROFILE, UPDATE_PROFILE } from "./ActionTypes";

const initialState = {
  user: null,
  profile: null,
};

export const User = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case GET_PROFILE: {
      return {
        ...state,
        profile: action.payload,
      };
    }
    case UPDATE_PROFILE: {
      return {
        ...state,
        profile: action.payload,
      };
    }
    case LOGOUT: {
      return {
        ...state,
        user: null,
        profile: null,
      };
    }
    default: {
      return state;
    }
  }
};
