import { useInfiniteQuery, useQuery } from "react-query";

import axios from "axios";
import { getToken } from "../../Shared/baseURL";

const fetchListsGroupedBySubjects = async (userID) => {
  const token = await getToken();
  return axios
    .get(`/users/${userID}/lists-by-subjects.json`, {
      headers: {
        Authorization: token,
      },
      params: {
        sort: "pinnedTime.DESC",
      },
    })
    .then((res) => res.data);
};

export const useListsGroupedBySubjectsData = (userID) => {
  return useQuery(
    ["get-lists-grouped-by-subjects", userID],
    () => fetchListsGroupedBySubjects(userID),
    {
      enabled: !!userID,
      refetchOnWindowFocus: false,
    }
  );
};

const fetchListsOfSubjectsByUser = async (
  page,
  userID,
  subjectHref,
  allListSubject
) => {
  const token = await getToken();

  let url = "";
  if (allListSubject) {
    url = `/subjects/all/lists.json`;
  } else {
    url = `/subjects/[href=${subjectHref}]/lists.json`;
  }

  return axios
    .get(url, {
      headers: {
        Authorization: token,
      },
      params: {
        userUuid: userID,
        page,
        size: 10,
      },
    })
    .then((res) => res.data);
};

export const useListsOfSubjectsByUser = (
  subjectHref,
  userID,
  allListSubject
) => {
  return useInfiniteQuery(
    ["get-lists-of-subjects-by-user", userID, subjectHref, allListSubject],
    ({ pageParam = 1 }) =>
      fetchListsOfSubjectsByUser(
        pageParam,
        userID,
        subjectHref,
        allListSubject
      ),
    {
      enabled: (!!userID && !!subjectHref) || (!!userID && !!allListSubject),
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => {
        const { pagination } = lastPage;
        if (pagination.page < pagination.totalPages) {
          return pagination.page + 1;
        } else {
          return null;
        }
      },
    }
  );
};
