import "./styles.scss";

import { AnimatePresence, motion } from "framer-motion";
import { Alert, Box, Button, Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";

import AboutMe from "Components/AboutMe/AboutMe";
import BannerImage from "assets/images/banner.png";
import { FaEdit } from "react-icons/fa";
import MyPosts from "../MyPosts/MyPosts";
import Network from "Pages/Network/Network";
import NewDashboard from "Components/NewDashboard/NewDashboard";
import Spinner from "Components/Spinner/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { useReduxUserProfile } from "Utils/hooks";
import Advertisement from "Components/Advertisement/Advertisement";
import SponsoredBy from "Components/SponsoredBy/SponsoredBy";

// import AllLists from "Pages/AllLists/AllLists";

const FULL_GRID = ["lists", "all-lists", "my-network"];

const TABS = [
  { label: "Posts", value: "posts" },
  { label: "Lists", value: "lists" },
  { label: "My Network", value: "my-network" },
  // { label: "Challenges", value: "challenge-content" },
  { label: "About Me", value: "about-me" },
];

const animation = {
  initial: { opacity: 0, x: 50 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 50 },
};

const NewUserProfile = () => {
  // state
  const [activeTab, setActiveTab] = useState("posts");
  const [userBannerPreview] = useState(BannerImage);
  const [userProfilePicPreview, setUserProfilePicPreview] = useState(null);
  const [user, setUser] = useState(null);

  // redux
  const userRedux = useReduxUserProfile();

  // router
  const navigate = useNavigate();

  useEffect(() => {
    if (userRedux) {
      setUser(userRedux);
    }
    if (user) {
      setUserProfilePicPreview(user?.avatarURL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userRedux]);

  if (!user) {
    return <Spinner />;
  }

  const handleTabValue = (val) => {
    setActiveTab(val);
  };

  const navigateToEditProfile = () => {
    navigate("/edit-profile/user-details");
  };

  return (
    <div className="new-profile-page">
      <Container>
        <div className="image-container">
          <Box
            className={`cover-photo-box`}
            sx={
              userBannerPreview
                ? {
                    backgroundImage: `url(${userBannerPreview})`,
                  }
                : {}
            }
          />
          <div className="profile-pic-container">
            <Box
              sx={
                userProfilePicPreview
                  ? {
                      backgroundImage: `url(${userProfilePicPreview})`,
                    }
                  : {}
              }
              className={`profile-pic`}
            />
            <div className="user-info">
              <div>
                <span className="name">
                  {user.firstName} {user.lastName}
                </span>
                <Button
                  onClick={navigateToEditProfile}
                  variant="contained"
                  disableElevation
                  startIcon={<FaEdit size="18px" />}
                  className="pill-btn"
                >
                  Edit Profile
                </Button>
              </div>
              <span>@{user.username}</span>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "8rem", marginBottom: "2rem" }}>
          {userRedux.unsubscribed && (
            <Alert variant="filled" severity="warning">
              You are currently not subscribed and can always resubscribe from{" "}
              <Link
                to="/edit-profile/notifications"
                style={{
                  color: "white",
                  textDecoration: "none",
                  fontWeight: 600,
                }}
              >
                "Notifications"
              </Link>
            </Alert>
          )}
        </div>
        <div className="d-flex justify-content-center mb-4">
          <SponsoredBy
            name={user?.sponsoredBy?.name}
            image={user?.sponsoredBy?.logo}
            bgColor="white"
          />
        </div>
        <div className="tabs-container">
          {TABS.map((t, idx) => (
            <span
              key={`tab-${idx}-${t.value}`}
              onClick={() => handleTabValue(t.value)}
              className={`tabs  ${activeTab === t.value && "active"}`}
            >
              {t.label}
            </span>
          ))}
        </div>
        <Grid container columns={24} spacing={2}>
          <Grid item xs={24} md={FULL_GRID.indexOf(activeTab) === -1 ? 16 : 24}>
            <div className="content-container">
              <AnimatePresence mode="wait">
                {activeTab === "posts" && (
                  <motion.div
                    key="posts"
                    initial={animation.initial}
                    animate={animation.animate}
                    exit={animation.exit}
                  >
                    <MyPosts user={user} />
                  </motion.div>
                )}
                {activeTab === "lists" && (
                  <motion.div
                    key="lists"
                    initial={animation.initial}
                    animate={animation.animate}
                    exit={animation.exit}
                  >
                    <NewDashboard showMine showAddList userProfile />
                  </motion.div>
                )}
                {activeTab === "my-network" && (
                  <motion.div>
                    <Network />
                  </motion.div>
                )}
                {activeTab === "about-me" && (
                  <motion.div
                    key="about-me"
                    initial={animation.initial}
                    animate={animation.animate}
                    exit={animation.exit}
                  >
                    <AboutMe user={user} />
                  </motion.div>
                )}
                {/* {activeTab === "all-lists" && <AllLists />} */}
              </AnimatePresence>
            </div>
          </Grid>
          {!FULL_GRID.includes(activeTab) && (
            <Grid item xs={24} md={8}>
              <Advertisement />
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default NewUserProfile;
