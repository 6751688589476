import styles from "./profile-info.module.scss";
import { Avatar, Box, Grid, IconButton, Table } from "@mui/material";
import { memo, useState } from "react";
import CustomTableRow from "./CustomTableRow";
import { Report } from "@mui/icons-material";
import ReportUser from "Components/ReportUser/ReportUser";

const ProfileInfo = ({ user, isLoggedUser, children, differentUser }) => {
  // props
  const {
    avatarURL,
    studiesAt,
    department,
    aboutMe,
    highestQualification,
    uuid,
    related,
    displayName,
  } = user;

  // state
  const [reportUser, setReportUser] = useState(false);

  const handleReportUser = () => {
    setReportUser(true);
  };

  if (user) {
    return (
      <div className={styles["edit-off-profile"]}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            md="auto"
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" },
            }}
          >
            <div className={styles["image-box"]}>
              <Avatar
                src={avatarURL}
                alt={displayName}
                variant="rounded"
                sx={{ height: "200px", width: "200px" }}
              />
            </div>
          </Grid>
          <Grid item xs={12} md>
            <div className={styles["info-box"]}>
              <Box
                className={styles["user-name"]}
                sx={{ textAlign: { xs: "center", md: "start" } }}
              >
                <div className="d-flex justify-content-center">
                  {displayName}
                  {related && <small className="small-text">({related})</small>}
                </div>
                {differentUser && (
                  <IconButton
                    onClick={handleReportUser}
                    color="error"
                    size="small"
                    title="Report user"
                  >
                    <Report />
                  </IconButton>
                )}
              </Box>
              <Table size="small">
                <CustomTableRow rowName="Studies At" rowValue={studiesAt} />
                <CustomTableRow
                  rowName=" Highest Qualification"
                  rowValue={highestQualification}
                />
                <CustomTableRow rowName="Department" rowValue={department} />
                <CustomTableRow rowName="Bio" rowValue={aboutMe} />
              </Table>
            </div>
            {children}
          </Grid>
        </Grid>
        {reportUser && (
          <ReportUser open={reportUser} toggle={setReportUser} userID={uuid} />
        )}
      </div>
    );
  }
};

export default memo(ProfileInfo);
