import ReactPlayer from "react-player";

const PostVideo = ({ video }) => {
  return (
    video &&
    video?.url && (
      <div className="col-md-10 w-100 mt-1">
        <ReactPlayer url={video.url} width="100%" controls />
      </div>
    )
  );
};

export default PostVideo;
