import { PersonOff, PersonRemove, Report } from "@mui/icons-material";
import { Avatar, Button, IconButton, Paper } from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SchoolIcon from "@mui/icons-material/School";
import { useState } from "react";
import DisconnectUser from "Components/DisconnectDialog/DisconnectDialog";
import UnfollowUser from "../UnfollowDialog/UnfollowDialog";
import ReportUser from "Components/ReportUser/ReportUser";
import { useNavigate } from "react-router-dom";

const UserCard = ({ item, activeTab, refetchUserData, data }) => {
  // state
  const [disconnectDialog, setDisconnectDialog] = useState(false);
  const [unfollowDialog, setUnfollowDialog] = useState(false);
  const [reportUser, setReportUser] = useState(false);

  // router
  const navigate = useNavigate();

  const handleDisconnect = () => {
    setDisconnectDialog(true);
  };

  const handleUnFollow = () => {
    setUnfollowDialog(true);
  };

  const handleReportUser = () => {
    setReportUser(true);
  };

  const handleSeeProfile = () => {
    navigate(`/my-network/${item?.username}`);
  };

  return (
    <Paper sx={{ borderRadius: 4, marginBottom: 1.5 }}>
      <div className="network-card p-3">
        <div className="title-div">
          <div className="d-flex gap-2">
            <div onClick={handleSeeProfile} style={{ cursor: "pointer" }}>
              <Avatar
                src={item?.avatarURL}
                alt={item?.username}
                className="avatar"
              />
            </div>
            <div>
              <h5 className="name-heading">
                <span onClick={handleSeeProfile} style={{ cursor: "pointer" }}>
                  {item?.username}
                </span>
                <IconButton
                  color="error"
                  size="small"
                  title="Report user"
                  disableRipple
                  onClick={handleReportUser}
                >
                  <Report />
                </IconButton>
              </h5>
              <small className="sub-text">{item?.aboutMe}</small>
            </div>
          </div>
          <div>
            {activeTab === "connections" && (
              <Button
                onClick={handleDisconnect}
                startIcon={<PersonOff />}
                size="small"
                className="disconnect-btn"
              >
                Disconnect
              </Button>
            )}
            {activeTab === "following" && (
              <Button
                onClick={handleUnFollow}
                startIcon={<PersonRemove />}
                size="small"
                color="error"
                className="error-btn"
              >
                Unfollow
              </Button>
            )}
          </div>
        </div>
        <div className="mt-2">
          <div className="d-flex align-items-center gap-2">
            <AccountBalanceIcon fontSize="small" />
            <div>
              <small className="text">Studies at</small>
              <br />
              <small className="sub-text">{item?.studiesAt ?? "N/A"}</small>
            </div>
          </div>
          <br />
          <div className="d-flex align-items-center gap-2">
            <SchoolIcon fontSize="small" />
            <div>
              <small className="text">Education Level</small>
              <br />
              <small className="sub-text">{item?.degree ?? "N/A"}</small>
            </div>
          </div>
        </div>
      </div>
      {disconnectDialog && (
        <DisconnectUser
          currentUserID={data.uuid}
          open={disconnectDialog}
          setDisconnectDialog={setDisconnectDialog}
          disconnectWithID={item.uuid}
          refetch={refetchUserData}
        />
      )}
      {unfollowDialog && (
        <UnfollowUser
          currentUserID={data.uuid}
          open={unfollowDialog}
          setUnfollowDialog={setUnfollowDialog}
          unfollowUserID={item.uuid}
          refetch={refetchUserData}
        />
      )}
      {reportUser && (
        <ReportUser
          open={reportUser}
          toggle={setReportUser}
          userID={item.uuid}
        />
      )}
    </Paper>
  );
};

export default UserCard;
