import "./styles.scss";

const ListTags = ({ title = "", value = 0, list }) => {
  title = title.replace("Count", "");

  return (
    <div className="list-tags">
      <span className="title">{title}</span>
      <span className="value">{value}</span>
    </div>
  );
};

export default ListTags;
