import * as yup from "yup";
import { Autocomplete, TextField as FormikTextField } from "formik-mui";
import {
  CircularProgress,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  TextField,
  Tooltip,
} from "@mui/material";
import { Close, Done, Info } from "@mui/icons-material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { PostNewList, useSubjectsData } from "./Api";
import AddSubjectCard from "Components/AddSubjectCard/AddSubjectCard";
import { useSearchParams } from "react-router-dom";
import { useMyListData } from "../../Utils/queries";
import { useNotify } from "Utils/hooks";
import CreateListSubMessage from "Components/SubscriptionMessages/List/CreateListSubMessage";

const SubmitSchema = yup.object().shape({
  subject: yup.object().nullable(true).required("Required"),
  name: yup.string().required("Required"),
});

const AddList = ({ open, close, refresh, selectedSubject }) => {
  // state
  const notify = useNotify();
  const [searchName, setSearchName] = useState("");
  const [subjectList, setSubjectList] = useState([]);

  // queries
  const { refetch: refetchMyList } = useMyListData();
  const { data, isLoading } = useSubjectsData(searchName);

  // router
  const [searchParams, setSearchParams] = useSearchParams();

  const handleClose = () => {
    // setSubjectList([]);
    setSearchParams({});
    close(false);
  };

  useEffect(() => {
    if (data) {
      setSubjectList(data.subjects);
    }
  }, [data]);

  const handleInputChange = (value) => {
    setSearchName(value);
  };

  const handleSubmit = async (values, setSubmitting) => {
    try {
      let data = {
        subject: values.subject.href,
        name: values.name,
      };
      const res = await PostNewList(data);
      if (res.message) {
        throw new Error(res.message);
      }
      notify("List created.");
      handleClose();
      refresh();
      refetchMyList();
    } catch (error) {
      if (error.message === "LIST_CANNOT_BE_NAMED_AS SUBJECT") {
        notify(
          "A list name cannot have the same name as a subject. Please give your list a different title.",
          { variant: "error" }
        );
      } else if (error.message === "LIST_ALREADY_EXISTS_IN_THIS_SUBJECT") {
        notify("List with this name already exists in this subject.", {
          variant: "error",
        });
      } else {
        notify(error.message, { variant: "error" });
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="add-list"
        fullWidth
      >
        <DialogTitle>Add New List</DialogTitle>
        <DialogContent>
          <DialogContentText>Select subject</DialogContentText>
          <Box sx={{ py: 2 }}>
            <Formik
              initialValues={{ subject: selectedSubject ?? null, name: "" }}
              validationSchema={SubmitSchema}
              onSubmit={(values, { setSubmitting }) =>
                handleSubmit(values, setSubmitting)
              }
            >
              {({ isSubmitting, submitForm }) => (
                <Form>
                  <Box>
                    <Field
                      name="subject"
                      component={Autocomplete}
                      options={subjectList}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(opt, val) => opt.uuid === val.uuid}
                      filterSelectedOptions
                      loading={isLoading}
                      onInputChange={(e, value) => {
                        handleInputChange(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="subject"
                          label="Type to search subject..."
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}

                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                    <ErrorMessage
                      name="subject"
                      render={(msg) => (
                        <small
                          style={{
                            fontSize: "0.75rem",
                            color: "#d32f2f",
                            marginLeft: "14px",
                          }}
                        >
                          {msg}
                        </small>
                      )}
                    />
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Field
                      component={FormikTextField}
                      name="name"
                      label="Name your Kewordal List"
                      variant="outlined"
                      fullWidth
                      autocomplete="off"
                    />
                  </Box>
                  <Box mt={2}>
                    <CreateListSubMessage>
                      <Box
                        sx={{
                          mt: 2,
                          display: "flex",
                          justifyContent: "center",
                          gap: "20px",
                        }}
                      >
                        <Button
                          disabled={isSubmitting}
                          onClick={handleClose}
                          variant="outlined"
                          color="secondary"
                          startIcon={<Close />}
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={isSubmitting}
                          onClick={submitForm}
                          variant="contained"
                          disableElevation
                          startIcon={<Done />}
                        >
                          Create
                        </Button>
                      </Box>
                    </CreateListSubMessage>
                  </Box>

                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
                  >
                    <AddSubjectCard>
                      <Button
                        // onClick={() => setOptionOpen(false)}
                        color="secondary"
                        endIcon={
                          <Tooltip
                            arrow
                            title='If you cannot find your subject under any of the existing subjects on our platform you can put in a request to Kewordal for us to add it.  To do this, click on the "Add Subject" button here.'
                          >
                            <Info />
                          </Tooltip>
                        }
                      >
                        Add Subject
                      </Button>
                    </AddSubjectCard>
                  </Box>
                  {isSubmitting && <LinearProgress sx={{ my: 2 }} />}
                </Form>
              )}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AddList;
