import "./styles.scss";

import { Paper } from "@mui/material";
import React from "react";
import image from "assets/images/cloud-search-icon.png";

const EmptySearch = ({
  searchQuery,
  tabName,
  posts,
  kewords,
  lists,
  people,
  setActiveTab,
}) => {
  return (
    <div className=" d-flex justify-content-center flex-column align-items-center p-3 empty-search mt-3">
      <img src={image} alt="No Data"></img>
      <h4>Oops!</h4>
      <span>
        No results found for "{searchQuery}" in {tabName}.
      </span>
      <p>
        {tabName === "posts"
          ? "Try a different keyword or see the results below in Keywords, Lists and People"
          : tabName === "keywords"
          ? "Try a different keyword or see the results below in Posts, Lists and People"
          : tabName === "lists"
          ? "Try a different keyword or see the results below in Posts, Keywords and People"
          : "Try a different keyword or see the results below in Posts, Keywords and Lists"}
      </p>
      {tabName !== "posts" && (
        <Paper
          elevation={0}
          className="py-2 px-3  rounded-4 mt-4 d-flex justify-content-between align-items-center w-50 paper"
        >
          <span className="cursor-pointer" onClick={() => setActiveTab(0)}>
            Posts
          </span>
          <small>{posts} results found</small>
        </Paper>
      )}
      {tabName !== "keywords" && (
        <Paper
          elevation={0}
          className="p-2 px-3 rounded-4 mt-3 d-flex justify-content-between align-items-center w-50 paper"
        >
          <span className="cursor-pointer" onClick={() => setActiveTab(1)}>
            Keywords
          </span>
          <small>{kewords} results found</small>
        </Paper>
      )}
      {tabName !== "lists" && (
        <Paper
          elevation={0}
          className="p-2 px-3 rounded-4 mt-3 d-flex justify-content-between align-items-center w-50 paper"
        >
          <span className="cursor-pointer" onClick={() => setActiveTab(2)}>
            Lists
          </span>
          <small>{lists} results found</small>
        </Paper>
      )}
      {tabName !== "people" && (
        <Paper
          elevation={0}
          className="p-2 px-3 rounded-4 mt-3 d-flex justify-content-between align-items-center w-50 paper"
        >
          <span className="cursor-pointer" onClick={() => setActiveTab(3)}>
            People
          </span>
          <small>{people} results found</small>
        </Paper>
      )}
    </div>
  );
};

export default EmptySearch;
