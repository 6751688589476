import axios from "axios";
import { getToken } from "../../Shared/baseURL";
import { useQuery } from "react-query";

const getSubscriptionProducts = async () => {
  const token = await getToken();
  return axios
    .get("/stripe/products.json", {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data);
};

export const useSubscriptionProducts = () => {
  return useQuery({
    queryKey: ["get-subscription-products"],
    queryFn: getSubscriptionProducts,
    refetchOnWindowFocus: false,
  });
};

export const getActiveSubscription = async () => {
  const token = await getToken();
  return axios
    .get("/stripe/subscriptions.json", {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data);
};

export const useActiveSubscriptions = () => {
  return useQuery({
    queryKey: ["get-active-subscription"],
    queryFn: getActiveSubscription,
    refetchOnWindowFocus: false,
  });
};

export const handleSubscription = async (priceID) => {
  const token = await getToken();
  const data = { priceId: priceID };
  return axios
    .post("/stripe/subscriptions", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data);
};

export const handleChangeSubscription = async (priceID) => {
  const token = await getToken();
  const data = { priceId: priceID };
  return axios
    .post("/stripe/subscriptions", data, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data);
};

export const handleCompleteSubscription = async () => {
  const token = await getToken();
  return await axios
    .get("/stripe/subscriptions", {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data);
};

export const cancelSubscription = async (subscriptionID, reason = "") => {
  const token = await getToken();
  const data = {};
  if (reason) data.reason = reason;
  return await axios
    .delete(`/stripe/subscriptions/${subscriptionID}`, {
      data,
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data);
};

export const cancelCancellation = async (subscriptionID) => {
  const token = await getToken();
  return await axios
    .post(
      `/stripe/subscriptions/${subscriptionID}/continue`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data);
};
