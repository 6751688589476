import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import {
  CircularProgress,
  createFilterOptions,
  LinearProgress,
  TextField,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Autocomplete } from "formik-mui";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import axios from "axios";
import { getToken } from "Shared/baseURL";

const SubmitSchema = yup.object().shape({
  users: yup.array().min(1, "Select at least 1 user.").required("Required"),
});

const filter = createFilterOptions();

const ShareList = ({ toggle, open: modalOpen, listID }) => {
  // state
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar: notify } = useSnackbar();

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpen(false);
    setLoading(false);
    toggle(false);
  };

  const handleSubmit = async (values, setSubmitting) => {
    const emails = values.users.map((item) => item.email);
    const data = { emails };
    try {
      const token = await getToken();
      await axios.post(`/lists/${listID}/shared`, data, {
        headers: {
          Authorization: token,
        },
      });
      notify("List share link sent");
      handleClose();
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      open={modalOpen}
      onClose={handleClose}
      fullWidth
      aria-labelledby="share-list"
    >
      <DialogTitle>Share list</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Type and press <strong>"Enter"</strong> to add emails.
        </DialogContentText>
        <Box mt={2}>
          <Formik
            initialValues={{ users: [] }}
            validationSchema={SubmitSchema}
            onSubmit={(values, { setSubmitting }) =>
              handleSubmit(values, setSubmitting)
            }
          >
            {({ values, isSubmitting, submitForm }) => (
              <Form autoComplete="off">
                <Box>
                  <Field
                    name="users"
                    multiple
                    component={Autocomplete}
                    options={[]}
                    getOptionLabel={(option) => option.email}
                    filterSelectedOptions
                    loading={loading}
                    open={open}
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    autoHighlight
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      const isExisting = [...values.users].some(
                        (item) => item.email === inputValue
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          email: inputValue,
                        });
                      }
                      return filtered;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="users"
                        label="Select Users"
                        variant="outlined"
                        placeholder="Type..."
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                  <ErrorMessage
                    name="users"
                    render={(msg) => (
                      <small className="text-danger">{msg}</small>
                    )}
                  />
                </Box>
                <Box mt={2} className="d-flex justify-content-end">
                  <Button
                    disabled={isSubmitting}
                    onClick={handleClose}
                    color="error"
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    onClick={submitForm}
                    variant="contained"
                    disableElevation
                    color="primary"
                  >
                    Send
                  </Button>
                </Box>
                {isSubmitting && <LinearProgress sx={{ my: 1 }} />}
              </Form>
            )}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ShareList;
