import axios from "axios";
import { getClientToken } from "../../Shared/baseURL";

export const getUserByID = async (id) => {
  try {
    const token = await getClientToken();
    const res = await axios.get(`/users/${id}.json`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return { data: res.data, status: res.status };
  } catch (error) {
    if (error.response) {
      const { data, status, statusText } = error.response;
      return {
        message: data.message ?? data.error_description ?? statusText,
        status,
      };
    }
  }
};

export const UpdatePassword = async (id, password) => {
  try {
    const token = await getClientToken();
    const res = await axios.patch(
      `/users/${id}/reset-password`,
      { password },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return { data: res.data, status: res.status };
  } catch (error) {
    if (error.response) {
      const { data, status, statusText } = error.response;
      return {
        message: data.message ?? data.error_description ?? statusText,
        status,
      };
    }
  }
};
