import "./styles.scss";

import { useMyListData } from "Utils/queries";
import { useNavigate } from "react-router-dom";
import ShowHints from "Components/ShowHints/ShowHints";
import {
  Button,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Add, LocalOfferOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import Spinner from "Components/Spinner/Spinner";
import { getToken } from "Shared/baseURL";
import axios from "axios";
import { useNotify } from "Utils/hooks";
import CreateListSubMessage from "Components/SubscriptionMessages/List/CreateListSubMessage";

const LIST_ENUMS = {
  KEYWORDS: "keywords",
  LISTS: "lists",
};

const NewMyList = ({ showHintButton }) => {
  // state
  const [totalLists, setTotalLists] = useState(0);
  const [totalKeywords, setTotalKeywords] = useState(0);
  const [allKeywords, setAllKeywords] = useState([]);
  const [allList, setAllList] = useState([]);
  const [view, setView] = useState(LIST_ENUMS.LISTS);

  // router
  const navigate = useNavigate();

  // queries
  const { data: myListData, isLoading: myListLoading } = useMyListData();

  useEffect(() => {
    if (myListData) {
      const { pagination, lists } = myListData;
      let arr = [];
      lists.forEach((list) => {
        const { keywords } = list;
        keywords.forEach((keyword) => {
          arr.push(keyword);
        });
      });
      setAllKeywords(arr.slice(0, 15));
      setTotalKeywords(arr.length);
      setTotalLists(pagination.total);
      setAllList(lists);
    }
  }, [myListData]);

  const handleClickMyList = (item) => {
    const { href, subjectHref } = item;
    navigate(`/view-list/${subjectHref}/${href}`);
  };

  const handleAddList = () => {
    navigate(`/lists?addList=1`);
  };

  const handleView = (e, val) => {
    setView(val);
  };

  return (
    <div className="my-new-lists custom-scroll">
      <div>
        <div className="title-div">
          <span style={{ textTransform: "capitalize" }} className="list-title">
            My Kewordal ({view === LIST_ENUMS.KEYWORDS && totalKeywords}
            {view === LIST_ENUMS.LISTS && totalLists})
          </span>
          {showHintButton && <ShowHints />}
        </div>
        <div className="mb-4">
          <ToggleButtonGroup
            color="primary"
            value={view}
            exclusive
            onChange={handleView}
            size="small"
            fullWidth
          >
            <ToggleButton value={LIST_ENUMS.LISTS}>Lists</ToggleButton>
            <ToggleButton value={LIST_ENUMS.KEYWORDS}>Keywords</ToggleButton>
          </ToggleButtonGroup>
        </div>
        {myListLoading && <Spinner />}
        {!myListLoading && view === LIST_ENUMS.KEYWORDS && (
          <RenderKeyword allKeywords={allKeywords} />
        )}
        {!myListLoading && view === LIST_ENUMS.LISTS && (
          <RenderList allList={allList} handleClickMyList={handleClickMyList} />
        )}
      </div>
      {view === LIST_ENUMS.LISTS && (
        <div className="footer-div">
          <div className="my-2">
            <CreateListSubMessage>
              <Button
                onClick={handleAddList}
                variant="contained"
                size="small"
                startIcon={<Add />}
                disableElevation
              >
                Add New List
              </Button>
            </CreateListSubMessage>
          </div>
        </div>
      )}
    </div>
  );
};

const RenderKeyword = ({ allKeywords }) => {
  // hooks
  const notify = useNotify();
  const handlePinKeyword = async (id) => {
    try {
      const token = await getToken();
      const res = await axios.post(
        `/keywords/${id}/pin`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        notify("Keyword pinned.");
      } else {
        throw new Error("Error pinning the keyword.");
      }
    } catch (error) {
      notify(error.message, { variant: "error" });
    }
  };

  return allKeywords.map((item, idx) => (
    <div className="keyword-item" key={`${item.uuid}-${idx}`}>
      <div className="d-flex gap-2">
        <span>{++idx}.</span>
        <span className="list-name">{item.term}</span>
      </div>
      <IconButton
        title="Pin keyword"
        size="small"
        onClick={() => handlePinKeyword(item.uuid)}
      >
        <LocalOfferOutlined fontSize="small" />
      </IconButton>
    </div>
  ));
};
const RenderList = ({ allList, handleClickMyList }) => {
  return allList.map((item, idx) => (
    <div
      className="list-item"
      key={`${item.uuid}-${idx}`}
      onClick={() => handleClickMyList(item)}
    >
      <span>{++idx}.</span>
      <span className="list-name">{item.name}</span>
    </div>
  ));
};

export default NewMyList;
