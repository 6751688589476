import Activate from "./Pages/Activate/Activate";
import AllLists from "Pages/AllLists/AllLists";
import BlogPost from "Pages/BlogPost/BlogPost";
import ChangePassword from "Components/ChangePassword/ChangePassword";
import Configure from "Components/Configure/Configure";
import ContactUs from "./Pages/ContactUs/ContactUs";
import Dashboard from "./Pages/Dashboard/Dashboard";
import DeleteUserAccount from "Components/DeleteUserAccount/DeleteUserAccount";
import DeletedHistory from "Pages/DeletedHistory/DeletedHistory";
import EditProfile from "Pages/EditProfile/EditProfile";
import EditUserDetails from "Components/EditUserDetails/EditUserDetails";
import Home from "./Pages/Home/Home";
import Login from "./Pages/Login/Login";
import MyNetwork from "Pages/MyNetwork/MyNetwork";
import NewMyListsPage from "Components/NewDashboard/NewDashboard";
import NewUserProfile from "Components/NewUserProfile/NewUserProfile";
import OtherLists from "./Pages/OtherLists/OtherLists";
import OthersNetwork from "Pages/Networks/OthersNetwork";
import Register from "./Pages/Register/Register";
import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import SearchResults from "./Pages/SearchResults/SearchResults";
import SharePost from "Pages/SharePost/SharePost";
import StaticPageRender from "./Pages/StaticPages/StaticPages";
import ViewList from "./Pages/ViewList/ViewList";
import ConformConnection from "Pages/ConfirmConnection/ConfirmConnection";
import SubscribeActivate from "Pages/SubscribeActivate/SubscribeActivate";
import Blogs from "Pages/Blogs/Blogs";
import AcceptShare from "Pages/AcceptShare/AcceptShare";
import UnsubscribeWeeklyUpdates from "Pages/UnsubscribeWeeklyUpdates/UnsubscribeWeeklyUpdates";
import UnsubscribeNewsletter from "Pages/UnsubscribeNewsletter/UnsubscribeNewsletter";
import BusinessProfile from "Pages/BusinessProfile/BusinessProfile";
import BlogsXML from "Pages/BlogsXML/BlogsXML";
import PostsXML from "Pages/PostsXML/PostsXML";
import SitemapXML from "Pages/SitemapXML/SitemapXML";
import ManageSubscriptions from "Components/ManageSubscriptions/ManageSubscriptions";

// import Profile from "./Pages/Profile/Profile";

export const protectedRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: <Dashboard />,
    sidebar: true,
  },
  {
    path: "/lists",
    name: "Lists",
    component: <NewMyListsPage />,
    sidebar: true,
    subRoutes: [
      {
        path: ":username",
        component: <OtherLists />,
        name: "User List",
      },
    ],
  },
  {
    path: "/all-lists",
    name: "All Lists",
    component: <AllLists />,
    sidebar: true,
  },
  {
    path: "/edit-profile",
    name: "Edit Profile",
    component: <EditProfile />,
    sidebar: false,
    subRoutes: [
      {
        path: "change-password",
        component: <ChangePassword />,
        name: "Change Password",
      },
      {
        path: "user-details",
        component: <EditUserDetails />,
        name: "User Details",
      },
      {
        path: "notifications",
        component: <Configure />,
        name: "Notifications",
      },
      {
        path: "manage-subscriptions",
        component: <ManageSubscriptions />,
        name: "Manage Subscriptions",
      },
      {
        path: "delete-account",
        component: <DeleteUserAccount />,
        name: "Delete Account",
      },
    ],
  },
  {
    path: "/search-results",
    name: "Search",
    component: <SearchResults />,
    sidebar: true,
  },
  {
    path: "/user-profile",
    name: "Profile",
    component: <NewUserProfile />,
    sidebar: true,
  },
  {
    path: "/my-network",
    name: "My Network",
    component: <MyNetwork />,
    sidebar: true,
    subRoutes: [
      {
        path: ":username",
        component: <OthersNetwork />,
        name: "Others Network",
      },
    ],
  },
  {
    path: "/deleted-history",
    name: "Deleted History",
    component: <DeletedHistory />,
    sidebar: true,
  },
  {
    path: "/view-list/:subjectHref/:listID",
    name: "View List",
    component: <ViewList />,
    sidebar: false,
  },
  {
    path: "/confirm-connection/:userID",
    name: "Confirm Connection",
    component: <ConformConnection />,
    sidebar: false,
  },
  {
    path: "/accept-share/:listID",
    name: "Accept Share",
    component: <AcceptShare />,
    sidebar: false,
  },
];

export const onlyLoggedOutRoutes = [
  {
    path: "/",
    name: "Home",
    component: <Home />,
  },
  {
    path: "/register",
    name: "Register",
    component: <Register />,
  },
  {
    path: "/activate/:userID",
    name: "Activate",
    component: <Activate />,
    sidebar: false,
  },
  {
    path: "/reset-password/:userID",
    name: "Reset Password",
    component: <ResetPassword />,
  },
  {
    path: "/auth/login",
    name: "Login",
    component: <Login />,
  },
  {
    path: "/subscribe/:ID",
    name: "Subscribe",
    component: <SubscribeActivate />,
  },
];

export const publicRoutes = [
  {
    path: "/contact-us",
    name: "Contact Us",
    component: <ContactUs />,
    sidebar: false,
  },
  {
    path: "/blogs",
    name: "Blogs",
    component: <Blogs />,
    sidebar: true,
  },
  {
    path: "/posts/:postId",
    name: "Posts",
    component: <SharePost />,
    sidebar: false,
  },
  {
    path: "/blog/:blogID",
    name: "Blog Post",
    component: <BlogPost />,
    sidebar: false,
  },
  {
    path: "/:pageHref",
    name: "Demo Subject",
    component: <StaticPageRender />,
    sidebar: false,
  },
  {
    path: "/unsubscribe-weekly-updates/:ID",
    name: "Unsubscribe Weekly updates",
    component: <UnsubscribeWeeklyUpdates />,
    sidebar: false,
  },
  {
    path: "/unsubscribe-newsletter/:ID",
    name: "Unsubscribe Newsletter",
    component: <UnsubscribeNewsletter />,
    sidebar: false,
  },
  {
    path: "/business-profile",
    name: "Business Profile",
    component: <BusinessProfile />,
    sidebar: false,
  },
  {
    path: "/blogs-sitemap.xml",
    name: "Blogs XML",
    component: <BlogsXML />,
    sidebar: false,
  },
  {
    path: "/posts-sitemap.xml",
    name: "Posts XML",
    component: <PostsXML />,
    sidebar: false,
  },
  {
    path: "/sitemap.xml",
    name: "Sitemap XML",
    component: <SitemapXML />,
    sidebar: false,
  },
];
