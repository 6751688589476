import "./styles.scss";
import { Avatar, Container, Grid, Paper, Stack } from "@mui/material";
import PostComments from "Components/PostComments/PostComments";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSinglePostByHref } from "Utils/posts";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "Components/Spinner/Spinner";
import NoData from "Components/NoData/NoData";
import { useReduxUserProfile } from "Utils/hooks";
import PostText from "Components/PostCard/PostText";
import PostVideo from "Components/PostCard/PostVideo";
import NewMyList from "Components/NewMyList/NewMyList";
import DashboardListCard from "Components/DashboardListCard/DashboardListCard";
import Advertisement from "Components/Advertisement/Advertisement";
import { MetaTags, ReactTitle } from "react-meta-tags";

const SharePost = () => {
  // state
  const [title, setTitle] = useState("");

  // router
  const { postId } = useParams();

  // queries
  const { data, error, isLoading, refetch } = useSinglePostByHref(postId);

  // redux
  const reduxUser = useReduxUserProfile();

  // router
  const navigate = useNavigate();

  useEffect(() => {
    if (data && data.content) {
      const _title = data.content.split(" ").slice(0, 10).join(" ");
      setTitle(_title);
    }
    return () => {
      document.title = "Kewordal";
    };
  }, [data]);

  const handleUserClick = () => {
    if (!reduxUser) return;
    navigate(`/my-network/${data.user.username}`);
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <NoData
        message={
          error.response.data.message ||
          error.message ||
          "Something went wrong!"
        }
      />
    );
  }

  console.log(data);

  if (data) {
    return (
      <>
        <ReactTitle title={title} />
        <MetaTags>
          <link
            rel="canonical"
            href={`https://kewordal.com/posts/${data.href}`}
          />
        </MetaTags>
        <Container sx={{ my: "4rem" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Paper elevation={2} className="post-card">
                <div className="title-div">
                  <div onClick={handleUserClick} className="d-flex gap-2">
                    <Avatar
                      src={data?.user?.avatarURL}
                      alt={data?.user?.username}
                      onClick={handleUserClick}
                      className="cursor-pointer"
                    />
                    <div
                      onClick={handleUserClick}
                      className="d-flex flex-column cursor-pointer"
                    >
                      <span className="fw-500">
                        {data?.user?.username ?? ""}
                      </span>
                      {data?.createdAt && (
                        <span
                          className="text-muted"
                          style={{ fontSize: "11px" }}
                        >
                          {dayjs(data?.createdAt).fromNow()}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <PostText content={data?.content} />
                </div>
                <p className="subject-name">{data?.subject?.name ?? ""}</p>
                {data?.photo && data?.photo?.url && (
                  <div
                    style={{ backgroundImage: `url(${data?.photo?.url})` }}
                    className="image-div"
                  />
                )}
                {data?.video && <PostVideo video={data.video} />}

                <div className="footer-div">
                  <span className="footer-title">{data.list?.name ?? ""}</span>
                </div>

                {reduxUser && (
                  <div>
                    <PostComments
                      postID={data?.uuid}
                      likes={data?.likes}
                      dislikes={data?.dislikes}
                      refetchPosts={refetch}
                      user={reduxUser}
                      postHref={data.href}
                    />
                  </div>
                )}
              </Paper>
            </Grid>
            {reduxUser && (
              <>
                <Grid item xs={12} md={4}>
                  <Grid container spacing={2}>
                    <Stack spacing={2}>
                      <NewMyList />
                      <Advertisement />
                      <DashboardListCard title="Most Viewed" mostViewed />
                      <Advertisement />
                      <DashboardListCard title="Trending" trending />
                      <Advertisement />
                      <DashboardListCard title="Blogs Feed" blogsFeed />
                    </Stack>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      </>
    );
  }
};

export default SharePost;
