import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import Spinner from "Components/Spinner/Spinner";
import { getClientToken } from "Shared/baseURL";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

const UNSUB_REASONS = [
  "I'm not interested",
  "Too many emails",
  "I didn't signup for this",
  "I don't like your contacts",
  "Other",
];

const UnsubscribeWeeklyUpdates = () => {
  // state
  const navigate = useNavigate();
  const [renderForm, setRenderForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setIsSuccess] = useState(null);
  const [reason, setReason] = useState(UNSUB_REASONS[0]);

  // router
  const { ID } = useParams();

  useEffect(() => {
    if (ID) {
      validateUser();
    }
  }, [ID]);

  const validateUser = async () => {
    try {
      setIsLoading(true);
      const token = await getClientToken();
      await axios.get(`/users/${ID}.json`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRenderForm(true);
    } catch (error) {
      if (error.response.status === 404) {
        setError(`We couldn't find this user.`);
      } else if (error.response) {
        setError(error.response.data.message);
      } else {
        setError(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnsubscribeUser = async () => {
    try {
      setIsLoading(true);
      setRenderForm(false);
      const token = await getClientToken();
      const data = { reason };
      await axios.patch(`/users/${ID}/unsubscribe-weekly-updates`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setIsSuccess(true);
    } catch (error) {
      if (error.response.status === 404) {
        setError(`We couldn't find this user.`);
      } else if (error.response) {
        setError(error.response.data.message);
      } else {
        setError(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/auth/login");
  };

  const handleChangeReason = (e, val) => {
    setReason(val);
  };

  return (
    <Container className="mt-4" maxWidth="sm">
      {!isLoading && renderForm && (
        <Paper
          elevation={2}
          sx={{ p: 3, mb: 5, textAlign: "justify", borderRadius: 5 }}
        >
          <h5 className="" style={{ color: "#333" }}>
            Are you sure you want to unsubscribe from weekly updates?
          </h5>
          <FormControl>
            <FormLabel className="text-muted">
              So we can improve our service, please let us know why you want to
              unsubscribe:
            </FormLabel>
            <RadioGroup value={reason} onChange={handleChangeReason}>
              {UNSUB_REASONS.map((item, idx) => (
                <FormControlLabel
                  key={item + idx}
                  value={item}
                  control={<Radio />}
                  label={item}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <div className="center gap-3 mt-4">
            <Button
              color="secondary"
              variant="outlined"
              disabled={isLoading}
              onClick={handleBack}
            >
              Back to Kewordal
            </Button>
            <Button
              color="error"
              variant="contained"
              disableElevation
              disabled={isLoading}
              onClick={handleUnsubscribeUser}
            >
              Unsubscribe
            </Button>
          </div>
        </Paper>
      )}
      {isLoading && (
        <div className="mt-4">
          <Spinner />
        </div>
      )}
      {error && (
        <Box sx={{ mb: 5 }}>
          <Alert severity="error" variant="filled" sx={{ color: "white" }}>
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        </Box>
      )}
      {success && (
        <Paper
          elevation={2}
          sx={{ p: 3, mb: 5, textAlign: "justify", borderRadius: 5 }}
        >
          <h4 className="text-success text-center">
            Successfully unsubscribed
          </h4>
          <p>Oh no!</p>
          <p>
            We are sorry to hear that you are thinking of leaving us. We would
            love for you to stay and tell us how we can improve our platform;
            that could help us hugely, and achieve our goal of accelerating
            educational outcomes for learners everywhere.
          </p>
          <p>
            If there is anything that we can do to encourage you to remain,
            please do not hesitate to get in touch using the drop down menu in{" "}
            <Link to="/contact-us">Contact Us</Link> with your feedback.
          </p>
          <p>
            <p className="m-0 p-0 blue" style={{ fontWeight: 600 }}>
              Note:
            </p>
            You can always <span className="fw-500">"Resubscribe"</span> from{" "}
            <span className="fw-500">"Notifications"</span> in profile settings.
          </p>
          <p>Thank you from the Kewordal Team.</p>
        </Paper>
      )}
    </Container>
  );
};

export default UnsubscribeWeeklyUpdates;
