import { IconButton, ListItem, ListItemText } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import React, { lazy, useState } from "react";

import ViewKeyword from "Components/ViewKeyword/ViewKeyword";
import axios from "axios";
import { getToken } from "Shared/baseURL";
import { useNotify } from "Utils/hooks";
import KeywordsMenu from "./Menus/KeywordsMenu";

const EditKeyword = lazy(() => import("Components/EditKeyword/EditKeyword"));
const DeleteKeywordModal = lazy(() =>
  import("Components/DeleteKeyword/DeleteKeyword")
);

const RenderKeyword = ({
  keyword,
  refresh,
  keywordPosition,
  listHref,
  subjectHref,
  totalKeywords,
}) => {
  // props
  const { term, question } = keyword;

  // state
  const notify = useNotify();
  const [viewKeyword, setViewKeyword] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [pinLoading, setPinLoading] = useState(false);

  const handleOpenListMenu = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseListMenu = () => {
    setMenuAnchor(null);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
    handleCloseListMenu();
  };

  const handleEditModal = () => {
    setEditModal(true);
    handleCloseListMenu();
  };

  const handlePinKeyword = async (id) => {
    try {
      setPinLoading(true);
      const token = await getToken();
      const res = await axios.post(
        `/keywords/${id}/pin`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        notify("Keyword pinned.");
        handleCloseListMenu();
      } else {
        throw new Error("Error pinning the keyword.");
      }
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setPinLoading(false);
    }
  };

  const handleViewDetails = () => {
    handleCloseListMenu();
    setViewKeyword(true);
  };

  return (
    <>
      <ListItem
        disableGutters
        sx={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}
        secondaryAction={
          <IconButton onClick={handleOpenListMenu} size="small">
            <MoreHoriz />
          </IconButton>
        }
      >
        <ListItemText
          primary={term}
          onClick={handleViewDetails}
          sx={{ cursor: "pointer" }}
          title={question}
        />
      </ListItem>
      {deleteModal && (
        <DeleteKeywordModal
          open={deleteModal}
          close={setDeleteModal}
          id={keyword.uuid}
          refresh={refresh}
        />
      )}
      {viewKeyword && (
        <ViewKeyword
          open={viewKeyword}
          toggle={setViewKeyword}
          keyword={keyword}
          subjectHref={subjectHref}
          listHref={listHref}
        />
      )}
      {editModal && (
        <EditKeyword
          open={editModal}
          keyword={keyword}
          close={setEditModal}
          refresh={refresh}
        />
      )}
      {menuAnchor && (
        <KeywordsMenu
          menuAnchor={menuAnchor}
          keyword={keyword}
          keywordPosition={keywordPosition}
          handleCloseListMenu={handleCloseListMenu}
          handleEditModal={handleEditModal}
          handleOpenDeleteModal={handleOpenDeleteModal}
          handlePinKeyword={handlePinKeyword}
          handleViewDetails={handleViewDetails}
          pinLoading={pinLoading}
        />
      )}
    </>
  );
};

export default RenderKeyword;
