import { Menu, MenuItem } from "@mui/material";
import React from "react";

const SortingMenu = ({
  sortMenu,
  closeSortMenu,
  handleSelectSort,
  sorting,
}) => {
  return (
    <Menu
      anchorEl={sortMenu}
      open={Boolean(sortMenu)}
      onClose={closeSortMenu}
      PaperProps={{ sx: { minWidth: "100px" } }}
    >
      <MenuItem
        selected={sorting === "term"}
        onClick={() => handleSelectSort("term")}
        value="term"
      >
        Alphabetical
      </MenuItem>
      <MenuItem
        selected={sorting === "createdAt.DESC"}
        onClick={() => handleSelectSort("createdAt.DESC")}
        value="createdAt.DESC"
      >
        Most Recent
      </MenuItem>
    </Menu>
  );
};

export default SortingMenu;
