import { PersonAdd, Report } from "@mui/icons-material";
import { Avatar, Button, IconButton, Paper } from "@mui/material";
import { sendConnectRequest } from "Components/PeopleList/Api";
import Spinner from "Components/Spinner/Spinner";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getProfile } from "Redux/Actions";
import { useNotify } from "Utils/hooks";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SchoolIcon from "@mui/icons-material/School";
import ReportUser from "Components/ReportUser/ReportUser";

const UserSuggestionCard = ({ item, refetchUserData, userRedux }) => {
  // state
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const [isConnectedToMe, setIsConnectedToMe] = useState(false);
  const [reportUser, setReportUser] = useState(false);

  // redux
  const dispatch = useDispatch();

  useEffect(() => {
    if (item && userRedux) {
      const { sentConnections, connections } = userRedux;
      const checkConnectionExists =
        sentConnections.some((i) => i.uuid === item.uuid) ||
        connections.some((i) => i.uuid === item.uuid);
      setIsConnectedToMe(checkConnectionExists);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRedux]);

  const handleReportUser = () => {
    setReportUser(true);
  };

  const handleConnectRequest = async (userID) => {
    try {
      setIsLoading(true);
      const res = await sendConnectRequest(userID);
      if (res.message) {
        throw new Error(res.message);
      }
      notify(
        `Your invitation to connect was sent and you are now following ${item.username}.`
      );
      if (refetchUserData) {
        refetchUserData();
      }
      let token = localStorage.getItem("userToken");
      dispatch(getProfile(token));
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Paper sx={{ borderRadius: 4, marginBottom: 1.5 }}>
      <div className="network-card p-3">
        <div className="title-div">
          <div className="d-flex gap-2">
            <div>
              <Avatar
                src={item?.avatarURL}
                alt={item?.displayName}
                className="avatar"
              />
            </div>
            <div>
              <h5 className="name-heading">
                <div className="d-flex justify-content-center gap-1">
                  {item?.displayName}
                  <small className="small-text">({item.related})</small>
                </div>
                <IconButton
                  color="error"
                  size="small"
                  title="Report user"
                  disableRipple
                  onClick={handleReportUser}
                >
                  <Report />
                </IconButton>
              </h5>
              <small className="sub-text">{item?.aboutMe}</small>
            </div>
          </div>
          {isLoading ? (
            <Spinner size={20} />
          ) : (
            <div>
              {isConnectedToMe ? (
                <Button
                  size="small"
                  color="secondary"
                  variant="contained"
                  disableElevation
                  disabled
                >
                  Connected
                </Button>
              ) : (
                <Button
                  onClick={() => handleConnectRequest(item.uuid)}
                  startIcon={<PersonAdd />}
                  size="small"
                  color="success"
                  variant="contained"
                  disableElevation
                  className="success-btn"
                  disabled={isLoading}
                >
                  Connect
                </Button>
              )}
            </div>
          )}
        </div>
        <div className="mt-2">
          <div className="d-flex align-items-center gap-2">
            <AccountBalanceIcon fontSize="small" />
            <div>
              <small className="text">Studies at</small>
              <br />
              <small className="sub-text">{item?.studiesAt ?? "N/A"}</small>
            </div>
          </div>
          <br />
          <div className="d-flex align-items-center gap-2">
            <SchoolIcon fontSize="small" />
            <div>
              <small className="text">Education Level</small>
              <br />
              <small className="sub-text">{item?.degree ?? "N/A"}</small>
            </div>
          </div>
        </div>
      </div>
      {reportUser && (
        <ReportUser
          open={reportUser}
          toggle={setReportUser}
          userID={item.uuid}
        />
      )}
    </Paper>
  );
};

export default UserSuggestionCard;
