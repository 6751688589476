import "./product-card.styles.scss";

import PricesCard from "./PricesCard";
import { useEffect, useState } from "react";

const ProductCard = ({
  productID,
  prices,
  name,
  description,
  handleSelectPriceID,
  selectedPriceID,
}) => {
  // state
  const [isProductSelected, setIsProductSelected] = useState(null);

  useEffect(() => {
    setIsProductSelected(prices.some((p) => p.id === selectedPriceID));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPriceID]);

  return (
    <div className={`main ${isProductSelected && "selected"}`}>
      <div className="title">{name}</div>
      <div className="description">{description}</div>
      <div className="prices">
        {prices.map((p) => (
          <PricesCard
            key={p.id}
            priceID={p.id}
            amount={p.unitAmount}
            interval={p.recurring.interval}
            intervalCount={p.recurring.interval_count}
            handleSelectPriceID={handleSelectPriceID}
            selectedPriceID={selectedPriceID}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductCard;
