import { getActiveSubscription } from "Components/ManageSubscriptions/Apis";
import { getToken } from "Shared/baseURL";
import axios from "axios";
import { useInfiniteQuery, useQuery } from "react-query";

const fetchMyList = async () => {
  const token = await getToken();
  return axios
    .get(`/my-lists`, {
      headers: {
        Authorization: token,
      },
      params: {
        size: 10,
      },
    })
    .then((res) => res.data);
};

export const useMyListData = () => {
  return useQuery(["get-my-lists"], () => fetchMyList(), {
    refetchOnWindowFocus: true,
  });
};

const getSpecificUserProfile = async (id) => {
  const token = await getToken();
  return axios
    .get(`/users/${id}.json`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      if (error.response) {
        const { data, statusText } = error.response;
        const message = data.message || data.error_description || statusText;
        throw new Error(message);
      }
      throw new Error(error.message);
    });
};

/**
 * @param {string} id id of user
 * @returns data of user
 */
export const useSpecificUserData = (id) => {
  return useQuery(
    ["get-specific-user-data", id],
    () => getSpecificUserProfile(id),
    {
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
      retry: 1,
    }
  );
};

const getSpecificUserProfileByUsername = async (username) => {
  const token = await getToken();
  return axios
    .get(`/users/[username=${username}].json`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      if (error.response) {
        const { data, statusText, status } = error.response;
        let message = data.message || data.error_description || statusText;
        if (status === 404) {
          message = "Oops, it looks like that user profile does not exist";
        }
        throw new Error(message);
      }
      throw new Error(error.message);
    });
};

export const useSpecificUserDataByUsername = (username) => {
  return useQuery(
    ["get-specific-user-data-by-username", username],
    () => getSpecificUserProfileByUsername(username),
    {
      enabled: !!username,
      staleTime: 60 * 1000,
      refetchOnWindowFocus: false,
      retry: 1,
    }
  );
};

const getSuggestedConnections = async (userID, page) => {
  const token = await getToken();
  return axios
    .get(`/users/${userID}/suggested-connections.json`, {
      headers: {
        Authorization: token,
      },
      params: {
        page,
        size: 15,
      },
    })
    .then((res) => res.data);
};

/**
 * @description returns array of suggested connections
 * @param {string} userID
 * @returns array of suggested users
 */
export const useSuggestedConnections = (userID) => {
  return useInfiniteQuery(
    ["get-suggested-connections", userID],
    ({ pageParam = 1 }) => getSuggestedConnections(userID, pageParam),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => {
        const { pagination } = lastPage;
        if (pagination.page < pagination.totalPages) {
          return pagination.page + 1;
        } else {
          return null;
        }
      },
    }
  );
};

const getSentChallenges = async (page = 1, sort = "createdAt.DESC") => {
  const token = await getToken();
  return axios
    .get("/users/me/sent-challenges.json", {
      headers: {
        Authorization: token,
      },
      params: {
        size: 10,
        page,
        sort,
      },
    })
    .then((res) => res.data);
};

export const useSentChallenges = () => {
  return useInfiniteQuery({
    queryKey: ["get-sent-challenges"],
    queryFn: ({ pageParam = 1 }) => getSentChallenges(pageParam),
    getNextPageParam: (lastPage) => {
      const { pagination } = lastPage;
      if (pagination.page < pagination.totalPages) {
        return pagination.page + 1;
      } else {
        return undefined;
      }
    },
  });
};

const getReceivedChallenges = async (page = 1, sort = "createdAt.DESC") => {
  const token = await getToken();
  return axios
    .get("/users/me/received-challenges.json", {
      headers: {
        Authorization: token,
      },
      params: {
        page,
        size: 10,
        sort,
      },
    })
    .then((res) => res.data);
};

export const useReceivedChallenges = () => {
  return useInfiniteQuery({
    queryKey: ["get-received-challenges"],
    queryFn: ({ pageParam = 1 }) => getReceivedChallenges(pageParam),
    getNextPageParam: (lastPage) => {
      const { pagination } = lastPage;
      if (pagination.page < pagination.totalPages) {
        return pagination.page + 1;
      } else {
        return undefined;
      }
    },
  });
};

const getMyConnections = async () => {
  const token = await getToken();
  return axios
    .get(`/users/me/connections.json`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data);
};

export const useMyConnectionsData = () => {
  return useQuery(["get-my-connections"], () => getMyConnections());
};

const fetchMostViewedKeywords = async (page) => {
  const token = await getToken();
  return axios
    .get("/keywords/most-viewed.json", {
      params: {
        page,
        size: 10,
      },
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data);
};

export const useMostViewedKeywords = () => {
  return useInfiniteQuery(
    ["get-most-viewed-keywords"],
    ({ pageParam = 1 }) => fetchMostViewedKeywords(pageParam),
    {
      getNextPageParam: (lastPage) => {
        const { pagination } = lastPage;
        if (pagination.page < pagination.totalPages) {
          return pagination.page + 1;
        } else {
          return null;
        }
      },
    }
  );
};

const fetchMostViewedLists = async (page) => {
  const token = await getToken();
  return axios
    .get("/lists/most-viewed.json", {
      params: {
        page,
        size: 10,
      },
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data);
};

export const useMostViewedLists = () => {
  return useInfiniteQuery(
    ["get-most-viewed-lists"],
    ({ pageParam = 1 }) => fetchMostViewedLists(pageParam),
    {
      getNextPageParam: (lastPage) => {
        const { pagination } = lastPage;
        if (pagination.page < pagination.totalPages) {
          return pagination.page + 1;
        } else {
          return null;
        }
      },
    }
  );
};

const fetchTrendingKeywords = async (page) => {
  const token = await getToken();
  return axios
    .get("/keywords/trending.json", {
      params: {
        page,
        size: 10,
      },
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data);
};

export const useTrendingKeywords = () => {
  return useInfiniteQuery(
    ["get-trending-keywords"],
    ({ pageParam = 1 }) => fetchTrendingKeywords(pageParam),
    {
      getNextPageParam: (lastPage) => {
        const { pagination } = lastPage;
        if (pagination.page < pagination.totalPages) {
          return pagination.page + 1;
        } else {
          return null;
        }
      },
    }
  );
};

const fetchTrendingLists = async (page) => {
  const token = await getToken();
  return axios
    .get("/lists/trending.json", {
      params: {
        page,
        size: 10,
      },
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data);
};

export const useTrendingLists = () => {
  return useInfiniteQuery(
    ["get-trending-lists"],
    ({ pageParam = 1 }) => fetchTrendingLists(pageParam),
    {
      getNextPageParam: (lastPage) => {
        const { pagination } = lastPage;
        if (pagination.page < pagination.totalPages) {
          return pagination.page + 1;
        } else {
          return null;
        }
      },
    }
  );
};

export const useUserHasSubscription = () => {
  return useQuery({
    queryKey: ["user-has-any-active-subscription"],
    queryFn: getActiveSubscription,
    select: (data) => {
      const { subscriptions } = data;
      if (subscriptions.length > 0) {
        const { status } = subscriptions[0];

        if (status === "active") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  });
};
