import {
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

import FallbackImage1 from "assets/images/logo.svg";
import axios from "axios";
// import { deleteKeyword } from "Components/DeleteKeyword/Api.js";
import { useEffect, useState } from "react";
import { getToken } from "Shared/baseURL.js";
import { useNotify, useReduxUserProfile } from "../../Utils/hooks.js";

const ViewKeyword = ({
  open,
  toggle,
  keyword,
  fallBackImage,
  refetch,
  subjectHref,
  listHref,
}) => {
  //   props
  const {
    uuid,
    term,
    question,
    imageURL,
    notes,
    definition,
    link,
    videoURL,
    userUuid,
  } = keyword;

  // state
  const notify = useNotify();
  const [isMyKeyword, setIsMyKeyword] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [renderBreadcrumb, setRenderBreadcrumb] = useState(false);

  // redux
  const userData = useReduxUserProfile();

  useEffect(() => {
    if (userUuid && userData) {
      setIsMyKeyword(userData.uuid === userUuid);
    }
  }, [userUuid, userData]);

  useEffect(() => {
    addViewToKeyword();
  }, []);

  useEffect(() => {
    if (subjectHref && listHref && term) {
      setRenderBreadcrumb(true);
    }
  }, [subjectHref, listHref, term]);

  const onClose = (_e, reason) => {
    if (reason === "backdropClick") return null;
    toggle(false);
  };

  // const handleDeleteKeyword = async () => {
  //   try {
  //     setIsLoading(true);
  //     const res = await deleteKeyword(uuid);
  //     if (res.message) {
  //       throw new Error(res.message);
  //     }
  //     notify("Keyword deleted.", {
  //       action: (key) => (
  //         <Button size="small" sx={{ color: "white" }} onClick={handleRestore}>
  //           Undo
  //         </Button>
  //       ),
  //       autoHideDuration: 5000,
  //     });
  //     onClose();
  //     if (refetch) {
  //       refetch();
  //     }
  //   } catch (error) {
  //     notify(error.message, { variant: "error" });
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleRestore = async () => {
    try {
      const token = await getToken();
      await axios.patch(
        `keywords/${uuid}/restore`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify(`Keyword restored.`);
      if (refetch) {
        refetch();
      }
    } catch (error) {
      notify(error.message, { variant: "error" });
    }
  };

  const addViewToKeyword = async () => {
    try {
      const token = await getToken();
      await axios.patch(`/keywords/${uuid}/view`, null, {
        headers: {
          Authorization: token,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth
        maxWidth="md"
        aria-labelledby="view-keyword-details"
      >
        <DialogTitle id="view-keyword-details">Keyword Details</DialogTitle>
        <DialogContent>
          {renderBreadcrumb && (
            <Breadcrumbs separator=">" sx={{ cursor: "default" }}>
              <span
                style={{
                  cursor: "default",
                  textDecoration: "none",
                  color: "#01AAF5",
                }}
              >
                {subjectHref}
              </span>
              <span
                style={{
                  cursor: "default",
                  textDecoration: "none",
                  color: "#01AAF5",
                }}
              >
                {listHref}
              </span>
              <span>{term}</span>
            </Breadcrumbs>
          )}
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell variant="head">Image</TableCell>
                  <TableCell>
                    {imageURL ? (
                      <img
                        src={imageURL.split("^")[0]}
                        alt={term}
                        width="150px"
                        height="100px"
                      />
                    ) : (
                      <img
                        src={fallBackImage || FallbackImage1}
                        alt={term}
                        width="150px"
                        height="100px"
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Term</TableCell>
                  <TableCell>{term || "---"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Question</TableCell>
                  <TableCell>{question || "---"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Notes</TableCell>
                  <TableCell>{notes || "---"}</TableCell>
                </TableRow>
                {/* <TableRow>
                  <TableCell variant="head">Definition</TableCell>
                  <TableCell>{definition || "---"}</TableCell>
                </TableRow> */}
                <TableRow>
                  <TableCell variant="head">Link</TableCell>
                  <TableCell>
                    {link ? (
                      <a
                        href={link}
                        target="_blank"
                        rel="noreferrer"
                        className="blue"
                      >
                        Click
                      </a>
                    ) : (
                      "---"
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Video URL</TableCell>
                  <TableCell>
                    {videoURL ? (
                      <a
                        href={videoURL}
                        target="_blank"
                        rel="noreferrer"
                        className="blue"
                      >
                        Click
                      </a>
                    ) : (
                      "---"
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            // disabled={isLoading}
            onClick={onClose}
            color="secondary"
            variant="outlined"
            size="small"
          >
            Close
          </Button>
          {/* {isMyKeyword && (
            <Button
              disabled={isLoading}
              onClick={handleDeleteKeyword}
              color="error"
              variant="contained"
              size="small"
            >
              {isLoading ? "Deleting" : "Delete"}
            </Button>
          )} */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewKeyword;
