import "./styles.scss";

import { Container, Grid, Paper } from "@mui/material";
import { MetaTags } from "react-meta-tags";
import AboutAuthor from "Components/AboutAuthor/AboutAuthor";
// import BlogCards from "Components/BlogCards/BlogCards";
import BlogComments from "Components/BlogComments/BlogComments";
import { useBlogByID, useHandleGetBlogs } from "Pages/Blogs/Api";
import { useParams } from "react-router-dom";
import Spinner from "Components/Spinner/Spinner";
import NoPageSignUp from "Components/NoPage/NoPageSignUp";
import SplitContent from "./SplitContent";
import Advertisement from "Components/Advertisement/Advertisement";
import HomeBlogCards from "Components/BlogCards/HomeBlogCards";
import { useEffect } from "react";

const BlogPost = () => {
  // router
  const { blogID } = useParams();

  // query
  const { data: blog, isLoading, error, refetch } = useBlogByID(blogID);
  const { data: blogsData } = useHandleGetBlogs(1, "", blogID);

  useEffect(() => {
    scrollToTop();
  }, [blog, blogID]);

  const scrollToTop = () => {
    const elem = document.getElementById("main-container");

    if (elem) {
      elem.scrollIntoView({
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <div>
        <NoPageSignUp />
      </div>
    );
  }

  if (blog && blog.status !== "PUBLISHED") {
    return (
      <div>
        <NoPageSignUp />
      </div>
    );
  }

  if (blog) {
    const cleanHTML = blog.content.replace(/<[^>]*>/g, "").substr(0, 150);
    return (
      <div className="blog-post-page">
        <MetaTags>
          <title>{blog.title}</title>
          <meta name="description" content={cleanHTML} />
          <link
            rel="canonical"
            href={`https://kewordal.com/blog/${blog.href}`}
          />
          <meta property="og:title" content={blog.title} />
          <meta property="og:type" content="article" />
          <meta
            property="og:url"
            content={`https://kewordal.com/blog/${blog.href}`}
          />
          <meta property="og:image" content={blog?.photo?.url} />
          <meta property="og:site_name" content="Kewordal" />
          <meta property="og:description" content={cleanHTML} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="computing_news" />
          <meta name="twitter:domain" content="//kewordal.com" />
          <meta
            name="twitter:url"
            content={`https://kewordal.com/blog/${blog?.href}`}
          />
          <meta name="twitter:image" content={`https://${blog?.photo?.url}`} />
          <meta name="twitter:description" content={cleanHTML} />
          <meta itemprop="image" content={`https://${blog?.photo?.url}`} />
        </MetaTags>
        <Container id="main-container" maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} md={9}>
              <div className="image-container">
                <img src={blog?.photo?.url} alt="blog-post" />
              </div>
              <div className="details">
                {/* <h1 className="title">{blog?.title}</h1> */}
                <div className="date-details">
                  {blog.subject && (
                    <div className="chip-container">
                      <span>{blog?.subject?.name}</span>
                    </div>
                  )}
                  {/* <span>
                    {dayjs(blog?.createdAt).fromNow()} • 3 minute read
                  </span> */}
                </div>
              </div>
              <SplitContent content={blog?.content ?? ""} splitAt={500} />
              <AboutAuthor author={blog?.user} />
              <div className="mt-4">
                <Advertisement fullView />
              </div>
              <div className="blog-comment">
                <BlogComments
                  blogId={blog.uuid}
                  likes={blog?.likes || []}
                  dislikes={blog?.dislikes || []}
                  refetchBlogData={refetch}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <Advertisement />
            </Grid>
          </Grid>
        </Container>
        <Grid container className="related-articles" sm={8}>
          <Grid item>
            <h3 className="heading py-3">Latest Articles</h3>
          </Grid>
          <Grid item>
            <Grid container className="article-cards" spacing={2}>
              {blogsData &&
                blogsData?.blogs.slice(0, 3).map((item) => (
                  <Grid item xs={12} sm={4} key={item.uuid}>
                    <Paper
                      elevation={1}
                      style={{
                        padding: ".6rem",
                        borderRadius: "8px",
                      }}
                    >
                      <HomeBlogCards blog={item} />
                    </Paper>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
};

export default BlogPost;
