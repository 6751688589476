import { Grid, Paper } from "@mui/material";

import React from "react";
import RenderList from "./RenderList";
import Advertisement from "Components/Advertisement/Advertisement";

const RenderSubject = ({ subject, index, refresh }) => {
  // props
  const { subject: subjectName, lists } = subject;

  lists.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      <Paper elevation={0} className="subject-container">
        <p className="subject-title">
          {++index}. {subjectName}
        </p>
        <div className="list-container">
          <Grid container spacing={2}>
            {lists.map((list) => (
              <Grid item xs={12} sm={6} md={4} key={list.uuid}>
                <RenderList list={list} subject={subject} refresh={refresh} />
              </Grid>
            ))}
          </Grid>
        </div>
      </Paper>

      {index % 1 === 0 && (
        <div className="px-2 py-3">
          <Advertisement fullView />
        </div>
      )}
    </>
  );
};

export default RenderSubject;
