import { Box, Button, Container, Grid, LinearProgress } from "@mui/material";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNotify, useReduxUserProfile } from "Utils/hooks";
import * as Yup from "yup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CompleteProfileTextFields from "./CompleteProfileTextFields";
import CompleteProfileSelectField from "./CompleteProfileSelectFields";
import axios from "axios";
import { getToken } from "Shared/baseURL";
import { useDispatch } from "react-redux";
import { getProfile } from "Redux/Actions";
import { COUNTRIES } from "Utils/countries";
import { HEAR_FROM_US_OPTIONS, Races, UserTypes } from "Shared/Enums";
import Spinner from "Components/Spinner/Spinner";

const RegisterSchema = Yup.object().shape({
  email: Yup.string().email("Invalid Email").required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  howYouHeardOfUs: Yup.string().required("Required"),
  hearFromUsDescription: Yup.string(),
  countryFrom: Yup.object()
    .typeError("Invalid country")
    .shape({
      name: Yup.string().required("Required"),
      code: Yup.string(),
    })
    .nullable()
    .required("Required"),
  username: Yup.string().required("Required"),
  invitationCode: Yup.string().required("Required"),
  gender: Yup.string().required("Required"),
  otherTerm: Yup.string().when("gender", {
    is: "Other term preferred",
    then: Yup.string().required("Required"),
    otherwise: Yup.string().notRequired(),
  }),
  race: Yup.string().required("Required"),
  dateOfBirth: Yup.date()
    .typeError("Invalid date")
    .max(dayjs().subtract(13, "year"), "Must be at least 13 years old.")
    .required("Required"),
});

const CompleteProfile = ({ children }) => {
  // state
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // hooks
  const notify = useNotify();

  // redux
  const dispatch = useDispatch();
  const reduxUser = useReduxUserProfile();

  useEffect(() => {
    if (reduxUser) {
      console.log(reduxUser);

      setIsProfileComplete(!!reduxUser?.profileComplete);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [reduxUser]);

  const handleSubmit = async (values, setSubmitting) => {
    try {
      let data = {
        ...values,
        dateOfBirth: dayjs(values.dateOfBirth).format("YYYY-MM-DD"),
        showTutorial: true,
        // cityFrom: "",
        countryFrom: values.countryFrom.name,
        whoYouAre:
          UserTypes.find((u) => u.value === values.invitationCode)?.label ||
          values.invitationCode,
      };

      if (data.hearFromUsDescription) {
        data.howYouHeardOfUs = `${data.howYouHeardOfUs} (${data.hearFromUsDescription})`;
      }

      if (!data.disabilitiesDescription) {
        delete data.disabilitiesDescription;
      }

      delete data.hearFromUsDescription;
      delete data.otherTerm;
      delete data.confirmEmail;
      delete data.confirmPassword;

      const token = await getToken();
      await axios.patch(`/users/${reduxUser.uuid}`, data, {
        headers: {
          Authorization: token,
        },
      });
      dispatch(getProfile(token.split(" ")[1]));
      notify("Profile updated.");
    } catch (error) {
      let errorMessage = error?.response?.data?.message || error.message;
      notify(errorMessage, { variant: "error" });
    } finally {
      setSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  } else if (!isProfileComplete) {
    return (
      <Container maxWidth="md" sx={{ my: 4 }}>
        <h6>
          You have signed up via Google/Facebook. Please complete your profile
          to get access to all our services.
        </h6>

        <div className="mt-4">
          <Formik
            // enableReinitialize
            initialValues={{
              title: reduxUser?.title || "",
              firstName: reduxUser?.firstName || "",
              lastName: reduxUser?.lastName || "",
              email: reduxUser?.email || "",
              username: reduxUser?.username || "",
              dateOfBirth: reduxUser?.dateOfBirth
                ? dayjs(reduxUser.dateOfBirth)
                : dayjs().subtract(25, "year").subtract(1, "day"),
              invitationCode: reduxUser?.invitationCode
                ? UserTypes.find((c) => c.value === reduxUser?.invitationCode)
                    ?.value || ""
                : "",
              disabilities: reduxUser?.disabilities || "no",
              disabilitiesDescription: reduxUser?.disabilitiesDescription || "",
              gender: reduxUser?.gender || "Male",
              otherTerm: "",
              race: reduxUser?.race
                ? Races.find((r) => r.value === reduxUser?.race)?.value ??
                  reduxUser?.race
                : "",
              countryFrom: reduxUser?.countryFrom
                ? COUNTRIES.find((c) => c.name === reduxUser?.countryFrom) ||
                  null
                : null,
              howYouHeardOfUs: reduxUser?.howYouHeardOfUs
                ? HEAR_FROM_US_OPTIONS.find(
                    (opt) => opt.label === reduxUser?.howYouHeardOfUs
                  )?.label.split("(")[0] ?? reduxUser?.howYouHeardOfUs
                : "",
              hearFromUsDescription: reduxUser?.howYouHeardOfUs
                ? HEAR_FROM_US_OPTIONS.find(
                    (opt) => opt.label === reduxUser?.howYouHeardOfUs
                  )
                    ?.label?.split("(")[1]
                    ?.replace(")", "") ?? reduxUser?.howYouHeardOfUs
                : "",
            }}
            validationSchema={RegisterSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values, setSubmitting);
            }}
          >
            {({ values, isSubmitting, setFieldValue }) => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Form autoComplete="off">
                  <Grid
                    container
                    columnSpacing={2}
                    rowSpacing={3}
                    sx={{ mb: 2 }}
                    className="form-box"
                  >
                    <CompleteProfileTextFields
                      formValues={values}
                      disableEmailFields={true}
                    />
                    <CompleteProfileSelectField
                      shouldRenderRaceTextfield={
                        !Races.find((r) => r.value === reduxUser?.race)?.value
                      }
                      formValues={values}
                      setFieldValue={setFieldValue}
                      reduxUser={reduxUser}
                    />
                    <Grid item xs={12}>
                      <Box className="center">
                        <Button
                          size="large"
                          type="submit"
                          variant="contained"
                          disabled={isSubmitting}
                        >
                          Complete
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      {isSubmitting && <LinearProgress />}
                    </Grid>
                  </Grid>
                </Form>
              </LocalizationProvider>
            )}
          </Formik>
        </div>
      </Container>
    );
  } else {
    return children;
  }
};

export default CompleteProfile;
