import { Box, IconButton, Tooltip } from "@mui/material";
import { useState, lazy, useEffect } from "react";
import FallbackImage1 from "assets/images/logo.svg";
import FallbackImage2 from "assets/images/logo.svg";
import { useNotify } from "Utils/hooks";
import axios from "axios";
import { getToken } from "Shared/baseURL";
import { useSelector } from "react-redux";
import {
  DeleteOutline,
  Flag,
  SettingsOutlined,
  ThumbDown,
  ThumbDownOutlined,
  ThumbUp,
  ThumbUpOutlined,
  Visibility,
} from "@mui/icons-material";

const CloneKeyword = lazy(() => import("Components/CloneKeyword/CloneKeyword"));
const DeleteKeywordModal = lazy(() => import("../DeleteKeyword/DeleteKeyword"));
const EditKeyword = lazy(() => import("../EditKeyword/EditKeyword"));
const ReportKeyword = lazy(() => import("../ReportKeyword/ReportKeyword"));
const ViewKeyword = lazy(() => import("Components/ViewKeyword/ViewKeyword"));

const ViewListSearchResults = ({
  searchResultKeyword,
  index,
  kewordsId,
  refetch,
  likes,
  dislikes,
}) => {
  // props
  const { uuid, term, question, newImgURL, userUuid } = searchResultKeyword;

  // state
  const [btnLoading, setBtnLoading] = useState(false);
  const notify = useNotify();
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [reportKeywordDialog, setReportKeywordDialog] = useState(false);
  const [viewKeyword, setViewKeyword] = useState(false);
  const [cloneKeyword, setCloneKeyword] = useState(false);
  const [isLikedByMe, setIsLikedByMe] = useState(false);
  const [isDislikedByMe, setIsDislikedByMe] = useState(false);

  // redux
  const userRedux = useSelector((state) => state.user.profile);

  useEffect(() => {
    if (Array.isArray(likes)) {
      setIsLikedByMe(likes?.some((item) => item.uuid === userRedux.uuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [likes]);

  useEffect(() => {
    if (Array.isArray(dislikes)) {
      setIsDislikedByMe(dislikes?.some((item) => item.uuid === userRedux.uuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dislikes]);

  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
  };

  const handleEditModal = () => {
    setEditModal(true);
  };

  const handleReportKeyword = () => {
    setReportKeywordDialog(true);
  };

  const handleViewKeyword = () => {
    setViewKeyword(true);
  };

  const handleLikeKeyword = async () => {
    try {
      setBtnLoading(true);
      const token = await getToken();
      await axios.post(
        `keywords/${kewordsId}/likes`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify("Keyword liked.");
      refetch();
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setBtnLoading(false);
    }
  };

  const handleUnlikeKeword = async () => {
    try {
      setBtnLoading(true);
      const token = await getToken();
      await axios.delete(`keywords/${kewordsId}/likes`, {
        headers: {
          Authorization: token,
        },
      });
      notify("Keyword Unliked.");
      refetch();
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setBtnLoading(false);
    }
  };

  const handleDislikeKeyword = async () => {
    try {
      setBtnLoading(true);
      const token = await getToken();
      await axios.post(
        `keywords/${kewordsId}/dislikes`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify("Keyword Disliked.");
      refetch();
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setBtnLoading(false);
    }
  };

  const handleUndoDislikeKeword = async () => {
    try {
      setBtnLoading(true);
      const token = await getToken();
      await axios.delete(`keywords/${kewordsId}/dislikes`, {
        headers: {
          Authorization: token,
        },
      });
      notify("Undo disliked.");
      refetch();
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <Box className="view-list-cards">
      <Box className="first-box">
        {Boolean(newImgURL) ? (
          <Box
            className="center img-box cursor-pointer"
            onClick={handleViewKeyword}
            sx={{
              backgroundImage: `url(${newImgURL})`,
            }}
          ></Box>
        ) : (
          <Box
            className="center img-box cursor-pointer"
            onClick={handleViewKeyword}
          >
            <img
              src={index % 2 === 0 ? FallbackImage1 : FallbackImage2}
              alt={term}
              loading="lazy"
              className="card-image px-3"
            />
          </Box>
        )}
        <Box className="title-box-card p-2 pt-3">
          <div>
            <span onClick={handleViewKeyword} title={term ?? ""}>
              {term && term.toString().substr(0, 15)}
              {term.length > 15 ? "..." : ""}
            </span>
          </div>
        </Box>
        <Box sx={{ px: "1rem", py: "0.7rem" }}>
          <Box className="description">
            <Tooltip title={question}>
              <span className="black px-2">{question}</span>
            </Tooltip>
          </Box>
        </Box>
        <Box className="link-box">
          {/* {videoURL && (
            <a
              href={videoURL}
              target="_blank"
              className="link blue"
              rel="noreferrer"
            >
              {videoURL}
            </a>
          )}
          {link && (
            <a
              href={link}
              target="_blank"
              className="link blue"
              rel="noreferrer"
            >
              {link}
            </a>
          )}*/}
        </Box>
      </Box>
      <Box className="sticky-text-box">
        <div className="icon-div">
          <IconButton
            onClick={handleViewKeyword}
            size="small"
            title="View keyword"
          >
            <Visibility fontSize="inherit" />
          </IconButton>
          {isLikedByMe ? (
            <IconButton
              disabled={btnLoading}
              onClick={handleUnlikeKeword}
              size="small"
              className="liked-btn"
              title="Like keyword"
            >
              <ThumbUp fontSize="inherit" />
            </IconButton>
          ) : (
            <IconButton
              disabled={btnLoading}
              onClick={handleLikeKeyword}
              size="small"
              title="Like keyword"
            >
              <ThumbUpOutlined fontSize="inherit" />
            </IconButton>
          )}
          {isDislikedByMe ? (
            <IconButton
              disabled={btnLoading}
              onClick={handleUndoDislikeKeword}
              size="small"
              title="Dislike keyword"
              className="disliked-btn"
            >
              <ThumbDown fontSize="inherit" />
            </IconButton>
          ) : (
            <IconButton
              disabled={btnLoading}
              onClick={handleDislikeKeyword}
              size="small"
              title="Dislike keyword"
            >
              <ThumbDownOutlined fontSize="inherit" />
            </IconButton>
          )}
          {userRedux.uuid === userUuid && (
            <>
              <IconButton
                onClick={handleOpenDeleteModal}
                disableRipple
                size="small"
                color="error"
                title="Delete Keyword"
              >
                <DeleteOutline fontSize="inherit" />
              </IconButton>
              <IconButton
                onClick={handleEditModal}
                size="small"
                title="Update keyword"
              >
                <SettingsOutlined fontSize="inherit" />
              </IconButton>
            </>
          )}
          {userRedux && userRedux.uuid !== userUuid && (
            <IconButton
              disabled={btnLoading}
              onClick={() => handleReportKeyword(uuid)}
              size="small"
              title="Report keyword"
            >
              <Flag fontSize="inherit" />
            </IconButton>
          )}
        </div>
      </Box>
      {cloneKeyword && (
        <CloneKeyword
          open={cloneKeyword}
          toggle={setCloneKeyword}
          uuid={uuid}
          term={term}
        />
      )}
      {reportKeywordDialog && (
        <ReportKeyword
          open={reportKeywordDialog}
          toggleDialog={setReportKeywordDialog}
          uuid={uuid}
          name={term}
        />
      )}
      {deleteModal && (
        <DeleteKeywordModal
          open={deleteModal}
          close={setDeleteModal}
          id={uuid}
          refresh={refetch}
        />
      )}
      {editModal && (
        <EditKeyword
          open={editModal}
          keyword={searchResultKeyword}
          close={setEditModal}
          refresh={refetch}
        />
      )}
      {viewKeyword && (
        <ViewKeyword
          open={viewKeyword}
          toggle={setViewKeyword}
          keyword={searchResultKeyword}
          refetch={refetch}
          fallBackImage={index % 2 === 0 ? FallbackImage1 : FallbackImage2}
        />
      )}
    </Box>
  );
};

export default ViewListSearchResults;
