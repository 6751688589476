import { Notifications } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  Tooltip,
} from "@mui/material";
import { getProfile } from "Redux/Actions";
import { getToken } from "Shared/baseURL";
import { useNotify, useReduxUserProfile } from "Utils/hooks";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const Reminder = ({
  size = "medium",
  color = "default",
  user,
  subject,
  subjectHref,
  keyword,
  keywordID,
  list,
  listID,
}) => {
  // state
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState(false);
  const [question, setQuestion] = useState(false);
  const [image, setImage] = useState(false);
  const [title, setTitle] = useState("");
  const [bellTitle, setBellTitle] = useState("");

  // redux
  const dispatch = useDispatch();
  const reduxUser = useReduxUserProfile();

  useEffect(() => {
    if (reduxUser) {
      const {
        keywordNoteReminder,
        keywordQuestionReminder,
        keywordImageReminder,
      } = reduxUser;
      if (keywordNoteReminder === "OFF") {
        setNote(false);
      }
      if (keywordNoteReminder === "ON") {
        setNote(true);
      }
      if (keywordQuestionReminder === "OFF") {
        setQuestion(false);
      }
      if (keywordQuestionReminder === "ON") {
        setQuestion(true);
      }
      if (keywordImageReminder === "OFF") {
        setImage(false);
      }
      if (keywordImageReminder === "ON") {
        setImage(true);
      }
    }
  }, [reduxUser]);

  useEffect(() => {
    if (keyword) {
      setBellTitle(
        "Control from a keyword level whether you receive any notifications for this particular keyword."
      );
      setTitle("keyword");
    }
    if (user) {
      setBellTitle(
        "Control from a keyword level whether you receive any notifications for this particular keyword."
      );
      setTitle("user");
    }
    if (list) {
      setBellTitle(
        "Control from a list level whether you receive any notifications for keywords located under this list."
      );
      setTitle("list");
    }
    if (subject) {
      setBellTitle(
        "Control from a subject level whether you receive any notifications for lists or keywords located under this subject."
      );
      setTitle("subject");
    }
  }, [user, subject, list, keyword]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = async (val, key) => {
    if (keyword) {
      handleKeywordNotification(val, key);
    }
    if (list) {
      handleListNotification(val, key);
    }
    if (subject) {
      handleSubjectNotification(val, key);
    }
    if (user) {
      handleUserNotification(val, key);
    }
  };

  const handleKeywordNotification = async (val, key) => {
    try {
      let newVal = val ? "ON" : "OFF";
      const token = await getToken();
      let newKey = "";
      if (key === "KeywordNoteReminder") {
        newKey = "noteReminder";
      } else if (key === "keywordQuestionReminder") {
        newKey = "questionReminder";
      } else if (key === "keywordImageReminder") {
        newKey = "imageReminder";
      }
      const data = {
        [newKey]: newVal,
      };
      await axios.patch(`/keywords/${keywordID}`, data, {
        headers: {
          Authorization: token,
        },
      });
      notify("Reminder updated");
      dispatch(getProfile(token.split(" ")[1]));
    } catch (error) {
      notify(error.message, { variant: "error" });
    }
  };

  const handleSubjectNotification = async (val, key) => {
    try {
      let newVal = val ? "ON" : "OFF";
      const token = await getToken();
      const data = {
        [key]: newVal,
      };
      await axios.patch(`/subjects/[href=${subjectHref}]`, data, {
        headers: {
          Authorization: token,
        },
      });
      notify("Reminder updated");
      dispatch(getProfile(token.split(" ")[1]));
    } catch (error) {
      notify(error.message, { variant: "error" });
    }
  };

  const handleListNotification = async (val, key) => {
    try {
      let newVal = val ? "ON" : "OFF";
      const token = await getToken();
      const data = {
        [key]: newVal,
      };
      await axios.patch(`/list/${listID}`, data, {
        headers: {
          Authorization: token,
        },
      });
      notify("Reminder updated");
      dispatch(getProfile(token.split(" ")[1]));
    } catch (error) {
      notify(error.message, { variant: "error" });
    }
  };

  const handleUserNotification = async (val, key) => {
    try {
      let newVal = val ? "ON" : "OFF";
      const token = await getToken();
      const data = {
        [key]: newVal,
      };
      await axios.patch("/users/me", data, {
        headers: {
          Authorization: token,
        },
      });
      notify("Reminder updated");
      dispatch(getProfile(token.split(" ")[1]));
    } catch (error) {
      notify(error.message, { variant: "error" });
    }
  };

  return (
    <>
      <div>
        <Tooltip title={bellTitle}>
          <IconButton size={size} onClick={handleOpen} sx={{ color }}>
            <Notifications />
          </IconButton>
        </Tooltip>
      </div>
      {open && (
        <Dialog open={open} onClose={handleClose} fullWidth>
          <DialogTitle>Configure {title} notifications</DialogTitle>
          <DialogContent>
            <DialogContentText>
              "ON" will send reminder every 6 days.
            </DialogContentText>
            <hr />
            <div className="mt-3">
              <FormGroup className="d-flex gap-2">
                <FormControlLabel
                  checked={note}
                  label="Keyword Note Reminder"
                  control={<Switch />}
                  onChange={(e) =>
                    handleChange(e.target.checked, "keywordNoteReminder")
                  }
                />
                <FormControlLabel
                  checked={question}
                  label="Keyword Question Reminder"
                  control={<Switch />}
                  onChange={(e) =>
                    handleChange(e.target.checked, "keywordQuestionReminder")
                  }
                />
                <FormControlLabel
                  checked={image}
                  label="Keyword Image Reminder"
                  control={<Switch />}
                  onChange={(e) =>
                    handleChange(e.target.checked, "keywordImageReminder")
                  }
                />
              </FormGroup>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default Reminder;
