const NewSubjectCard = ({ subject, selectedSubject, handleSelectSubject }) => {
  // props
  const { name, image, uuid } = subject;

  return (
    <div
      id={`subject-card-${uuid}`}
      className={`subject-card ${uuid === selectedSubject?.uuid && "selected"}`}
      style={
        {
          // backgroundImage: `url(${image})`,
        }
      }
      onClick={() => handleSelectSubject(subject)}
    >
      <img
        src={image}
        alt={name}
        height="100%"
        width="100%"
        style={{ objectFit: "cover" }}
      />
      <p className={`name-div ${uuid === selectedSubject?.uuid && "selected"}`}>
        {name}
      </p>
    </div>
  );
};

export default NewSubjectCard;
