import "./styles.scss";

import { ArrowBack, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  Stack,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import AddSubjectCard from "Components/AddSubjectCard/AddSubjectCard";
import CustomToolTip from "Components/CustomToolTip/CustomToolTip";
import NewMyList from "Components/NewMyList/NewMyList";
import NoData from "Components/NoData/NoData";
import PostCard from "Components/PostCard/PostCard";
import CreatePost from "Components/CreatePost/CreatePost";
import Spinner from "Components/Spinner/Spinner";
import SubjectCards from "Components/SubjectCard/SubjectCard";
import TutorialDialog from "Components/TutorialDialog/TutorialDialog";
import { debounce } from "lodash";
import { useInView } from "react-intersection-observer";
import { usePostsBySubjectData } from "Utils/posts";
import { useSubjectsData } from "Components/NewDashboard/Api";
import Advertisement from "Components/Advertisement/Advertisement";
import AllSubjectsSelect from "Components/AllSubjectsSelect/AllSubjectsSelect";
import DashboardListCard from "Components/DashboardListCard/DashboardListCard";
import StickySidebar from "sticky-sidebar-v2";
import { useMediumScreenOrAbove } from "Utils/hooks";

const OldDashboard = () => {
  // ref
  let scrollBox = useRef(null);

  // state
  const [allSubjectsPosts, setAllSubjectsPosts] = useState(true);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [tutorialDialog, setTutorialDialog] = useState(true);
  const [showTooltip, setShowTooltip] = useState(true);
  const [searchSubject, setSearchSubject] = useState("");

  // hooks
  const isMediumScreenAndAbove = useMediumScreenOrAbove();

  // observer
  const { ref: loadMoreDiv, inView: loadMoreInView } = useInView({
    initialInView: false,
  });

  // queries
  const {
    isLoading: subjectsLoading,
    data: subjectsData,
    hasNextPage: hasMoreSubjects,
    isFetchingNextPage,
    fetchNextPage: fetchNextSubjects,
  } = useSubjectsData(searchSubject);

  const {
    data: postsData,
    isLoading: postsLoading,
    hasNextPage: hasNextPost,
    fetchNextPage: fetchNextPosts,
    isFetchingNextPage: isFetchingNextPosts,
    refetch: refetchPosts,
  } = usePostsBySubjectData(selectedSubject?.uuid, allSubjectsPosts);

  useEffect(() => {
    if (loadMoreInView && hasMoreSubjects) {
      fetchNextSubjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMoreInView]);

  useEffect(() => {
    if (isMediumScreenAndAbove) {
      new StickySidebar(".sticky-side-grid", {
        topSpacing: 20,
        bottomSpacing: 20,
        containerSelector: ".sticky-container",
        innerWrapperSelector: ".sidebar__inner",
      });
    } else {
      new StickySidebar(".sticky-side-grid", {
        topSpacing: 20,
        bottomSpacing: 20,
        containerSelector: ".sticky-container",
        innerWrapperSelector: ".sidebar__inner",
        scrollContainer: ".sticky-side-grid",
      });
    }
  }, [isMediumScreenAndAbove]);

  useEffect(() => {
    let previousSelectedSubject = localStorage.getItem(
      "dashboardSelectedSubject"
    );
    if (subjectsData && !selectedSubject) {
      const allSubjects = [];
      subjectsData.pages.forEach((page) => {
        page.subjects.forEach((item) => allSubjects.push(item));
      });
      const subjectObj = allSubjects.find(
        (item) => item.uuid === previousSelectedSubject
      );
      if (subjectObj) {
        handleSelectSubject(subjectObj);
        const selectedDiv = document.getElementById(
          `subject-card-${subjectObj.uuid}`
        );
        selectedDiv.scrollIntoView({
          inline: "center",
          behavior: "smooth",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectsData]);

  const handleSelectSubject = (subject) => {
    localStorage.setItem("dashboardSelectedSubject", subject.uuid);
    setAllSubjectsPosts(false);
    setSelectedSubject(subject);
  };

  const handleAllSubjectPosts = () => {
    localStorage.removeItem("dashboardSelectedSubject");
    setAllSubjectsPosts(true);
    setSelectedSubject(null);
  };

  const handleScrollLeft = () => {
    scrollBox.current.scrollBy({
      top: 0,
      left: -400,
      behavior: "smooth",
    });
  };

  const handleScrollRight = () => {
    scrollBox.current.scrollBy({
      top: 0,
      left: 400,
      behavior: "smooth",
    });
  };

  const handleSubjectSearch = debounce((value) => {
    setSearchSubject(value);
  }, 600);

  return (
    <Box className="dashboard">
      <Container>
        <div className="search-div">
          <MuiTextField
            className="input"
            size="small"
            fullWidth
            variant="filled"
            hiddenLabel
            placeholder="Search subject"
            onChange={(e) => handleSubjectSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </Container>

      <div>
        {subjectsLoading ? (
          <Box sx={{ my: 2 }} className="center">
            <CircularProgress />
          </Box>
        ) : (
          <CustomToolTip
            title="You can look through your subjects using the subject selection and narrow your search further using the Search Filter below the heading. Give it a try!"
            open={showTooltip}
            toggleTooltip={setShowTooltip}
            placement="top"
            showArrow
          >
            <div className="subject-list-div">
              <div className="icon-div div-1">
                <IconButton
                  onClick={handleScrollLeft}
                  edge="start"
                  className="icon-btn btn-1"
                >
                  <ArrowBack fontSize="inherit" />
                </IconButton>
              </div>
              <Container ref={scrollBox} className="subjects-card-container">
                <AddSubjectCard />
                <AllSubjectsSelect
                  title="All Subjects"
                  onClick={handleAllSubjectPosts}
                  isSelected={allSubjectsPosts}
                />
                {subjectsData?.pages.map((page) =>
                  page.subjects.map((subject, idx) => (
                    <SubjectCards
                      key={`${subject.uuid}-${idx}`}
                      handleSelectSubject={handleSelectSubject}
                      selectedSubject={selectedSubject}
                      subject={subject}
                    />
                  ))
                )}
                <span ref={loadMoreDiv} />
                {isFetchingNextPage && <span>Loading...</span>}
              </Container>
              <div className="icon-div div-2">
                <IconButton
                  onClick={handleScrollRight}
                  edge="end"
                  className="icon-btn btn-2"
                >
                  <ArrowBack fontSize="inherit" />
                </IconButton>
              </div>
            </div>
          </CustomToolTip>
        )}
      </div>

      <Container className="sticky-container">
        <Grid container spacing={2} columns={24} className="mt-4">
          <Grid item xs={24} md={16}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CreatePost refetchPosts={refetchPosts} />
              </Grid>
              {postsLoading ? (
                <Grid item xs={12}>
                  <Spinner />
                </Grid>
              ) : (
                postsData?.pages.map((page) =>
                  page.posts.length > 0 ? (
                    page.posts.map((post, idx) => (
                      <Grid item xs={12} key={`${post.uuid}-${idx}`}>
                        <PostCard
                          post={post}
                          refetchPosts={refetchPosts}
                          idx={idx}
                        />
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12}>
                      <NoData
                        message={`No posts related to "${
                          allSubjectsPosts
                            ? "All Subjects"
                            : selectedSubject.name
                        }"`}
                      />
                    </Grid>
                  )
                )
              )}
              {hasNextPost && (
                <Grid item xs={12}>
                  <div className="mt-1 center">
                    <Button
                      disabled={isFetchingNextPosts}
                      onClick={fetchNextPosts}
                    >
                      {isFetchingNextPosts ? "Loading..." : "Load More"}
                    </Button>
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={24} md={8}>
            <div className="sticky-side-grid">
              <Box className="sticky-side sidebar__inner">
                <Grid
                  container
                  spacing={2}
                  direction={{ xs: "row-reverse", md: "row" }}
                >
                  <Grid item xs={12} sm={6} md={12}>
                    <Advertisement />
                    <Box
                      sx={{
                        display: {
                          xs: "none",
                          sm: "block",
                          md: "none",
                        },
                        mt: "1rem",
                      }}
                    >
                      <Advertisement />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={12}>
                    <Stack spacing={2}>
                      <NewMyList />
                      <Advertisement />
                      <DashboardListCard title="Blogs Feed" blogsFeed />
                      <Advertisement />
                      <DashboardListCard title="Most Viewed" mostViewed />
                      <Advertisement />
                      <DashboardListCard title="Trending" trending />
                      <Advertisement />
                      <DashboardListCard title="News Feed" newsFeed />
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={12}
                    sx={{ display: { xs: "block", sm: "none", md: "block" } }}
                  >
                    <Advertisement />
                  </Grid>
                </Grid>
              </Box>
            </div>
          </Grid>
        </Grid>
      </Container>

      {/* Dialogs */}
      {tutorialDialog && (
        <TutorialDialog
          open={tutorialDialog}
          toggleDialog={setTutorialDialog}
          maxPage={2}
        />
      )}
    </Box>
  );
};

export default OldDashboard;
