import { Button } from "@mui/material";
import Spinner from "Components/Spinner/Spinner";
import { useMostViewedLists } from "Utils/queries";
import { useEffect, useState } from "react";
import RenderList from "./RenderList";

const MostViewedLists = () => {
  const [allLists, setAllLists] = useState([]);

  // queries
  const { isLoading, data, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useMostViewedLists();

  useEffect(() => {
    if (data) {
      const { pages } = data;
      let arr = [];
      pages.forEach((page) => {
        page.list.forEach((keyword) => {
          arr.push(keyword);
        });
      });
      setAllLists(arr);
    }
  }, [data]);

  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <div>
        {allLists.map((list, idx) => (
          <RenderList idx={idx} list={list} key={idx + list.uuid} />
        ))}
      </div>
      {hasNextPage && (
        <div className="center">
          <Button
            onClick={fetchNextPage}
            size="small"
            disabled={isFetchingNextPage}
          >
            {isFetchingNextPage ? "Loading" : "Load More"}
          </Button>
        </div>
      )}
    </>
  );
};

export default MostViewedLists;
