import axios from "axios";
import { getClientToken } from "../../Shared/baseURL";

export const registerUser = async (values) => {
  try {
    const clientToken = await getClientToken();
    const res = await axios.post("/register", values, {
      headers: {
        Authorization: `Bearer ${clientToken}`,
      },
    });
    return { data: res.data, status: res.status };
  } catch (error) {
    if (error.response) {
      const { data, status, statusText } = error.response;
      return {
        message: data.message ?? data.error_description ?? statusText,
        status,
      };
    }
  }
};
