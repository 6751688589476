import { Container, Grid } from "@mui/material";
import React from "react";
import Information from "./Information";
import Institutions from "./Institutions";

const MainContent = () => {
  return (
    <Container className="info-container">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Information title="Message from the CEO" />
            </Grid>
            <Grid item xs={12}>
              <Information title="CSR: Education" />
            </Grid>
            <Grid item xs={12}>
              <Information title="CSR: Environment" />
            </Grid>
            <Grid item xs={12}>
              <Information title="CSR: Community" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Institutions />
            </Grid>
            <Grid item xs={12}>
              <Information title="Blog articles written by our employees" />
            </Grid>
            <Grid item xs={12}>
              <Information title="Testimonials" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MainContent;
