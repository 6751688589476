import "./styles.scss";

import * as Yup from "yup";
import {
  Alert,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { updateUserPassword } from "Pages/Profile/Api";
import { useReduxUserProfile } from "Utils/hooks";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const passReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;

const SubmitSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Required"),
  password: Yup.string()
    .matches(passReg, {
      message:
        "Password must be between 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter",
    })
    .required("Required"),
  confirmPassword: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password")], "Password does not match"),
});

const ChangePassword = () => {
  // state
  const [alert, setAlert] = useState({ msg: "", type: "success" });
  const [cPassword, setCPassword] = useState(true);
  const [nPassword, setNPassword] = useState(true);
  const [conPassword, setConPassword] = useState(true);

  // redux
  const useRedux = useReduxUserProfile();

  const handleSubmit = async (values, setSubmitting, resetForm) => {
    setAlert({ msg: "", type: "error" });
    try {
      const res = await updateUserPassword(
        { password: values.password, oldPassword: values.oldPassword },
        useRedux.uuid
      );
      if (res.message) {
        throw new Error(res.message);
      }
      setAlert({ msg: "Password updated.", type: "success" });
      resetForm();
    } catch (error) {
      if (error.message === "WRONG_OLD_PASSWORD") {
        setAlert({ msg: "Invalid old password", type: "error" });
      } else if (error.message === "NEW_PASSWORD_MUST_BE_DIFFERENT") {
        setAlert({
          msg: "New password cannot be same as old password.",
          type: "error",
        });
      } else {
        setAlert({ msg: error.message, type: "error" });
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Paper className="p-4 rounded-3 paper">
      <div>
        <h4>Change Password</h4>
        <hr />
      </div>
      <div>
        <p>Password</p>
        <small>Create a new password that is at least 8 characters long.</small>
        <hr />
      </div>
      <Box sx={{ my: 2 }}>
        {alert.msg && (
          <Alert severity={alert.type} variant="filled" sx={{ color: "white" }}>
            {alert.msg}
          </Alert>
        )}
      </Box>
      <Formik
        initialValues={{
          oldPassword: "",
          password: "",
          confirmPassword: "",
        }}
        validationSchema={SubmitSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          handleSubmit(values, setSubmitting, resetForm);
        }}
      >
        {({ isSubmitting, submitForm }) => (
          <Form autoComplete="off">
            <Grid container>
              <Grid item xs={12}>
                <Grid container className="pt-3">
                  <Grid item xs={12} md={4} className="align-items-start">
                    <p className="text-style mb-3 mb-lg-0">
                      Type your current password
                    </p>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Field
                      component={TextField}
                      name="oldPassword"
                      label="Current Password"
                      autoComplete="off"
                      size="small"
                      fullWidth
                      type={cPassword ? "password" : "text"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setCPassword(!cPassword)}
                            >
                              {cPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container className="pt-3">
                  <Grid item xs={12} md={4} className="align-items-start">
                    <p className="text-style mb-3 mb-lg-0">
                      Type your new password
                    </p>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Field
                      component={TextField}
                      name="password"
                      label="New Password"
                      fullWidth
                      autoComplete="off"
                      size="small"
                      type={nPassword ? "password" : "text"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setNPassword(!nPassword)}
                            >
                              {nPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container className="pt-3">
                  <Grid item xs={12} md={4} className="align-items-start">
                    <p className="text-style  mb-3 mb-lg-0">
                      Retype your new password
                    </p>
                  </Grid>
                  <Grid item className="" xs={12} md={4}>
                    <Field
                      component={TextField}
                      name="confirmPassword"
                      label="Confirm Password"
                      fullWidth
                      autoComplete="off"
                      size="small"
                      type={conPassword ? "password" : "text"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setConPassword(!conPassword)}
                            >
                              {conPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                  onClick={submitForm}
                  className="px-3 rounded-3 mt-4"
                >
                  {isSubmitting ? "Saving changes" : "Save Changes"}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};

export default ChangePassword;
