import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { hideNotification, showNotification } from "Redux/Actions/notification";
import {
  useGetNotification,
  useNotify,
  useReduxUserProfile,
} from "Utils/hooks";

import axios from "axios";
import { getProfile } from "Redux/Actions";
import { getToken } from "Shared/baseURL";
import { useDispatch } from "react-redux";

const Configure = () => {
  // state
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const [tutorialShow, setTutorialShow] = useState(false);
  const [hintShow, setHintShow] = useState(false);
  const [isSubscribedWeeklyUpdates, setIsSubscribedWeeklyUpdates] =
    useState(false);
  const [isSubscribedNewsletter, setIsSubscribedNewsletter] = useState(false);

  // redux
  const dispatch = useDispatch();
  const notificationShow = useGetNotification();
  const userRedux = useReduxUserProfile();

  useEffect(() => {
    const {
      showTutorial,
      showHints,
      unsubscribedWeeklyUpdates,
      unsubscribedNewsletter,
    } = userRedux;
    setTutorialShow(!!showTutorial);
    setHintShow(!!showHints);
    setIsSubscribedWeeklyUpdates(!unsubscribedWeeklyUpdates);
    setIsSubscribedNewsletter(!unsubscribedNewsletter);
  }, [userRedux]);

  const handleChange = async (value, type) => {
    try {
      setIsLoading(true);
      let token = await getToken();
      let data = {
        [type]: value,
      };
      const res = await axios.patch("/users/me", data, {
        headers: {
          Authorization: token,
        },
      });
      if (res.status === 200) {
        dispatch(getProfile(token.split(" ")[1]));
      }
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleNotification = (status) => {
    if (status) {
      dispatch(showNotification());
    } else {
      dispatch(hideNotification());
    }
  };

  const handleSubscribeChange = (e, val) => {
    if (val) {
      handleSubscribeWeeklyUpdates();
    }
    if (!val) {
      handleUnsubscribeWeeklyUpdates();
    }
  };

  const handleSubscribeWeeklyUpdates = async () => {
    try {
      setIsLoading(true);
      const token = await getToken();
      await axios.patch(
        `/users/${userRedux.uuid}/re-subscribe-weekly-updates`,
        null,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify("Subscribed weekly updates successfully.");
      dispatch(getProfile(token.split(" ")[1]));
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnsubscribeWeeklyUpdates = async () => {
    try {
      setIsLoading(true);
      const data = {
        reason: userRedux.unsubscribedWeeklyUpdatesReason,
      };
      const token = await getToken();
      await axios.patch(
        `/users/${userRedux.uuid}/unsubscribe-weekly-updates`,
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify("Unsubscribed weekly updates successfully.");
      dispatch(getProfile(token.split(" ")[1]));
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubscribeNewsletterChange = (e, val) => {
    if (val) {
      handleSubscribeNewsletter();
    }
    if (!val) {
      handleUnsubscribeNewsletter();
    }
  };

  const handleSubscribeNewsletter = async () => {
    try {
      setIsLoading(true);
      const token = await getToken();
      await axios.patch(
        `/users/${userRedux.uuid}/re-subscribe-newsletter`,
        null,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify("Subscribed newsletter successfully.");
      dispatch(getProfile(token.split(" ")[1]));
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnsubscribeNewsletter = async () => {
    try {
      setIsLoading(true);
      const data = {
        reason: userRedux.unsubscribedWeeklyUpdatesReason,
      };
      const token = await getToken();
      await axios.patch(
        `/users/${userRedux.uuid}/unsubscribe-newsletter`,
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify("Unsubscribed from newsletter successfully.");
      dispatch(getProfile(token.split(" ")[1]));
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Paper className="rounded-3 p-4 paper">
      <div>
        <h4>Notification Settings</h4>
        <hr />
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel
            checked={notificationShow}
            onChange={(e) => handleNotification(e.target.checked)}
            control={<Checkbox />}
            label="Notifications"
          />
          <FormHelperText>
            {notificationShow
              ? "You have opted to receive notifications from users connected to you."
              : "You have opted out of notifications and will therefore not receive updates about other users connected to you."}
          </FormHelperText>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            disabled={isLoading}
            checked={hintShow}
            onChange={(e) => handleChange(e.target.checked, "showHints")}
            control={<Checkbox />}
            label="Show hints on dashboard"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            disabled={isLoading}
            checked={tutorialShow}
            onChange={(e) => handleChange(e.target.checked, "showTutorial")}
            control={<Checkbox />}
            label="Show tutorial in dashboard"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            disabled={isLoading}
            checked={isSubscribedNewsletter}
            onChange={handleSubscribeNewsletterChange}
            control={<Checkbox />}
            label="Subscribe Newsletter"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            disabled={isLoading}
            checked={isSubscribedWeeklyUpdates}
            onChange={handleSubscribeChange}
            control={<Checkbox />}
            label="Subscribe Weekly Updates"
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Configure;
