import axios from "axios";
import { getToken } from "../../Shared/baseURL";

//  POST '/users/<myUserUuid>/FOLLOWS/users/<followedUserUuid> endpoint.

export const sendFollowRequest = async (myID, otherUserID) => {
  try {
    const token = await getToken();
    const res = await axios.post(
      `/users/${myID}/FOLLOWS/users/${otherUserID}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (res.status >= 200 || res.status < 300) {
      const { data, status } = res;
      return {
        status,
        data,
      };
    }
  } catch (error) {
    if (error.response) {
      const { data, status, statusText } = error.response;
      return {
        status,
        message: data.message || data.error_description || statusText,
      };
    }
  }
};

export const sendConnectRequest = async (otherUserID) => {
  try {
    const token = await getToken();
    const res = await axios.post(
      `/users/connect/${otherUserID}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );
    if (res.status >= 200 || res.status < 300) {
      const { data, status } = res;
      return {
        status,
        data,
      };
    }
  } catch (error) {
    if (error.response) {
      const { data, status, statusText } = error.response;
      return {
        status,
        message: data.message || data.error_description || statusText,
      };
    }
  }
};
