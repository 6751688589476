import { getClientToken } from "Shared/baseURL";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Spinner from "Components/Spinner/Spinner";
import XMLViewer from "react-xml-viewer";

const BlogsXML = () => {
  // state
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchXML();
  }, []);

  const fetchXML = async () => {
    try {
      setIsLoading(true);
      const token = await getClientToken();

      await axios
        .get("/blogs-sitemap.xml", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        })
        .then((res) => {
          const { data: xmlData } = res;
          console.log(xmlData);
          // setData(xmlStringData);
          // const xmlBlob = new Blob([xmlStringData], { type: "text/xml" });
          // window.open("https://www.google.com.pk", "_blank");
        });
    } catch (error) {
      setData(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return <XMLViewer xml={data} collapsible />;
};

export default BlogsXML;
