import { LinkedIn } from "@mui/icons-material";
import {
  FaTiktok,
  FaFacebook,
  FaInstagram,
  FaDiscord,
  FaPinterest,
  FaYoutube,
  FaReddit,
  FaTelegram,
  FaWhatsapp,
} from "react-icons/fa";
import { FaSnapchat, FaXTwitter } from "react-icons/fa6";

const SocialLinks = () => {
  return (
    <div className="social-links">
      <div>
        <p className="footer-heading">Follow Us</p>
        <p style={{ color: "#333", fontSize: ".9rem" }}>
          Join us on social media for the latest updates
        </p>
      </div>
      <div className="link-wrapper">
        <a
          title="Facebook"
          href="https://www.facebook.com/kewordal"
          target="_blank"
          rel="noreferrer"
          className="link"
        >
          <FaFacebook />
        </a>
        <a
          title="Instagram"
          href="https://www.instagram.com/kewordal"
          target="_blank"
          rel="noreferrer"
          className="link"
        >
          <FaInstagram />
        </a>
        <a
          title="X"
          href="https://www.twitter.com/kewordal"
          target="_blank"
          rel="noreferrer"
          className="link"
        >
          <FaXTwitter />
        </a>
        <a
          title="LinkedIn"
          href="https://www.linkedin.com/company/kewordal"
          target="_blank"
          rel="noreferrer"
          className="link"
        >
          <LinkedIn />
        </a>
        <a
          title="TikTok"
          href="http://www.tiktok.com/@kewordal"
          target="_blank"
          rel="noreferrer"
          className="link"
        >
          <FaTiktok />
        </a>
        <a
          title="Discord"
          href="https://discord.com"
          target="_blank"
          rel="noreferrer"
          className="link"
        >
          <FaDiscord />
        </a>
      </div>
      <div className="link-wrapper mt-4">
        <a
          title="Pinterest"
          href="https://www.pinterest.co.uk/kewordal"
          target="_blank"
          rel="noreferrer"
          className="link"
        >
          <FaPinterest />
        </a>
        <a
          title="Youtube"
          href="https://www.youtube.com/@kewordal"
          target="_blank"
          rel="noreferrer"
          className="link"
        >
          <FaYoutube />
        </a>
        <a
          title="Snapchat"
          href="https://www.snapchat.com/explore/kewordal"
          target="_blank"
          rel="noreferrer"
          className="link"
        >
          <FaSnapchat />
        </a>
        <a
          title="Reddit"
          href="https://www.reddit.com/user/Kewordal"
          target="_blank"
          rel="noreferrer"
          className="link"
        >
          <FaReddit />
        </a>
        <a
          title="Telegram"
          href="#"
          target="_blank"
          rel="noreferrer"
          className="link"
        >
          <FaTelegram />
        </a>
        <a
          title="Whatsapp"
          href="#"
          target="_blank"
          rel="noreferrer"
          className="link"
        >
          <FaWhatsapp />
        </a>
      </div>
    </div>
  );
};

export default SocialLinks;
