import { CircularProgress, Container } from "@mui/material";
import { Box } from "@mui/system";
import { useQuery } from "react-query";
import axios from "axios";
import { getClientToken } from "../../Shared/baseURL";
import { useParams } from "react-router-dom";
import NoPageSignUp from "Components/NoPage/NoPageSignUp";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
// import { MetaTags } from "react-meta-tags";
// import { PAGE_META_TAGS } from "./page-meta";

const fetcher = async (pageHref) => {
  const token = await getClientToken();
  return axios
    .get(`/pages/[href=${pageHref}].json`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
};

const DemoSubject = () => {
  // state
  const [renderHtml, setRenderHtml] = useState("");
  const [videoURL, setVideoURL] = useState("");

  // router
  const { pageHref } = useParams();
  // const href = useHref();

  // query
  const { isLoading, data, error, isFetching } = useQuery(
    ["fetch-demo-subject", pageHref],
    () => fetcher(pageHref),
    {
      refetchOnWindowFocus: false,
      retry: 1,
      staleTime: 5 * (60 * 1000), // 5 minutes
    }
  );

  useEffect(() => {
    if (data) {
      scrollToTop();
      setRenderHtml(data.content);
      // if (pageHref.includes("demo")) {
      //   setVideoURL(
      //     `https://kewordal-images.s3.eu-west-1.amazonaws.com/assets/kewordal-media/video/demo/${data.href.replace(
      //       "demo-",
      //       ""
      //     )}-landing-page-intro.mp4`
      //   );
      // }
    }
  }, [data]);

  useEffect(() => {
    if (renderHtml) {
      scrollToTop();
    }
  }, [renderHtml]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Container sx={{ mt: 3 }}>
      {/* <MetaTags>
        {PAGE_META_TAGS.find((item) => item.key === href)?.tags}
      </MetaTags> */}
      <Box>
        {isLoading || isFetching ? (
          <Box className="center">
            <CircularProgress />
          </Box>
        ) : error ? (
          <Box>
            <NoPageSignUp />
          </Box>
        ) : (
          data && (
            <>
              <div dangerouslySetInnerHTML={{ __html: renderHtml }} />
              {videoURL && (
                <div className="center mb-5">
                  <ReactPlayer url={videoURL} controls muted playing />
                </div>
              )}
            </>
          )
        )}
      </Box>
    </Container>
  );
};

export default DemoSubject;
