import "./styles.scss";

import { CircularProgress, Container, Grid, Stack } from "@mui/material";
import RenderError from "Components/RenderError/RenderError";
import RenderSubject from "./RenderSubject";
import { useReduxUserProfile } from "Utils/hooks";
import { useUserAllLists } from "./Api";
import Advertisement from "Components/Advertisement/Advertisement";
import DashboardListCard from "Components/DashboardListCard/DashboardListCard";
import NewMyList from "Components/NewMyList/NewMyList";
import { useEffect } from "react";
import StickySidebar from "sticky-sidebar-v2";

const AllLists = () => {
  // redux
  const userRedux = useReduxUserProfile();

  // queries
  const { isLoading, data, error, refetch } = useUserAllLists(userRedux.uuid);

  useEffect(() => {
    setTimeout(() => {
      new StickySidebar(".sticky-side-grid", {
        topSpacing: 20,
        bottomSpacing: 20,
        containerSelector: ".main-container",
        innerWrapperSelector: ".sidebar__inner",
      });
    }, 200);
  }, []);

  if (isLoading) {
    return (
      <div className="center mt-4">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <RenderError message={error.message} />;
  }

  return (
    <Container className="all-lists-page main-container" sx={{ mb: 10 }}>
      <h4>All My Lists</h4>
      <div className="mt-3">
        <Grid container spacing={2} columns={16}>
          <Grid item xs={16} md={11}>
            {data.map((subject, index) => (
              <RenderSubject
                key={subject.subjectHref}
                index={index}
                subject={subject}
                refresh={refetch}
              />
            ))}
          </Grid>
          <Grid item xs={16} md={5}>
            <div className="sticky-side-grid">
              <div className="sidebar__inner">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Stack spacing={2}>
                      <NewMyList />
                      <Advertisement />
                      <DashboardListCard title="Blogs Feed" blogsFeed />
                      <Advertisement />
                      <DashboardListCard title="Most Viewed" mostViewed />
                      <Advertisement />
                      <DashboardListCard title="Trending" trending />
                      <Advertisement />
                      <DashboardListCard title="News Feed" newsFeed />
                    </Stack>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default AllLists;
