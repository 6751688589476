import { AccessTime, WarningAmber } from "@mui/icons-material";
import { Box, Button, LinearProgress, Stack } from "@mui/material";
import Spinner from "Components/Spinner/Spinner";
import { useNavigateToSubscription, useReduxUserProfile } from "Utils/hooks";
import { useUserHasSubscription } from "Utils/queries";
import React, { useEffect, useState } from "react";
import { ABOUT_REACH_LIMIT_PERCENT, SHOW_SUB_COMPONENT } from "../sub-enums";

const sharedButtonProps = {
  size: "small",
  color: "warning",
  sx: {
    textTransform: "initial",
  },
};

const sharedIconProps = {
  size: "small",
  color: "warning",
};

const CreateListSubMessage = ({ children }) => {
  // state
  const [createListLimit, setCreateListLimit] = useState(null);
  const [renderSubComponent, setRenderSubComponent] = useState(null);
  const [limitPercent, setLimitPercent] = useState(0);

  // hooks
  const navigateToSub = useNavigateToSubscription();

  // queries
  const { data: userHasSub, isLoading } = useUserHasSubscription();

  // redux
  const reduxUser = useReduxUserProfile();

  useEffect(() => {
    if (reduxUser && !isLoading) {
      const { subscription } = reduxUser;
      setCreateListLimit(subscription.createLists || null);

      // calculate limit percent
      const _limitPercent =
        (subscription.createLists.existing / subscription.createLists.limit) *
        100;

      setLimitPercent(_limitPercent);

      // no sub
      if (!userHasSub) {
        if (_limitPercent >= 100) {
          setRenderSubComponent(SHOW_SUB_COMPONENT.SHOW_REACH_LIMIT);
        } else if (_limitPercent >= ABOUT_REACH_LIMIT_PERCENT) {
          setRenderSubComponent(SHOW_SUB_COMPONENT.ABOUT_REACH_LIMIT);
        } else {
          setRenderSubComponent(SHOW_SUB_COMPONENT.NO_SUB);
        }
      }
      // user has sub
      else {
        // reach limit check
        if (_limitPercent >= 100) {
          setRenderSubComponent(SHOW_SUB_COMPONENT.SHOW_REACH_LIMIT);
        } else if (_limitPercent >= ABOUT_REACH_LIMIT_PERCENT) {
          setRenderSubComponent(SHOW_SUB_COMPONENT.ABOUT_REACH_LIMIT);
        } else {
          setRenderSubComponent(SHOW_SUB_COMPONENT.SHOW_CHILDREN);
        }
      }
    }
  }, [reduxUser, userHasSub, isLoading]);

  if (isLoading) {
    return <Spinner />;
  }

  // no sub
  if (renderSubComponent === SHOW_SUB_COMPONENT.NO_SUB) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            justifyContent: "space-between",
            flexWrap: "nowrap",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <WarningAmber {...sharedIconProps} />
            <small>You can create up to 5 lists every 3 months</small>
          </div>
          <Button {...sharedButtonProps} onClick={navigateToSub}>
            Upgrade
          </Button>
        </Box>
        <div className="mt-2">{children}</div>
      </>
    );
  }

  if (renderSubComponent === SHOW_SUB_COMPONENT.ABOUT_REACH_LIMIT) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            justifyContent: "space-between",
            flexWrap: "nowrap",
          }}
        >
          <WarningAmber {...sharedIconProps} />
          <div style={{ flexGrow: 1 }}>
            <small>You're about to reach your limit</small>
            <div
              style={{ display: "flex", alignItems: "center", gap: "0.2rem" }}
            >
              <div style={{ flexGrow: 1 }}>
                <LinearProgress
                  value={limitPercent}
                  color="warning"
                  sx={{
                    backgroundColor: "#eee",
                  }}
                  variant="determinate"
                />
              </div>
              <small>
                {createListLimit.existing}/{createListLimit.limit}
              </small>
            </div>
          </div>
          <Button {...sharedButtonProps} onClick={navigateToSub}>
            Upgrade
          </Button>
        </Box>
        <div className="mt-2">{children}</div>
      </>
    );
  }

  if (renderSubComponent === SHOW_SUB_COMPONENT.SHOW_REACH_LIMIT) {
    return (
      <Stack spacing={2}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            justifyContent: "space-between",
            flexWrap: "nowrap",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              flexGrow: 1,
            }}
          >
            <AccessTime {...sharedIconProps} />
            <div
              style={{
                flexGrow: 1,
              }}
            >
              <small>
                You have reached your limit. Upgrade now to create new lists.
              </small>
            </div>
            <Box
              sx={{
                display: "flex",
                gap: "0.5rem",
                alignItems: "flex-end",
                color: "primary.main",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.15rem",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
              >
                <small style={{ fontWeight: 600 }}>
                  {createListLimit.daysRemaining}
                </small>
                <small style={{ fontSize: 10 }}>days</small>
              </div>
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.15rem",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
              >
                <small style={{ fontWeight: 600 }}>23</small>
                <small style={{ fontSize: 10 }}>hrs</small>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.15rem",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
              >
                <small style={{ fontWeight: 600 }}>59</small>
                <small style={{ fontSize: 10 }}>mins</small>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.15rem",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
              >
                <small style={{ fontWeight: 600 }}>59</small>
                <small style={{ fontSize: 10 }}>secs</small>
              </div> */}
            </Box>
          </div>
        </Box>
        <Button
          {...sharedButtonProps}
          variant="contained"
          fullWidth
          disableElevation
          size="large"
          onClick={navigateToSub}
        >
          Upgrade
        </Button>
      </Stack>
    );
  }

  // only return children
  if (renderSubComponent === SHOW_SUB_COMPONENT.SHOW_CHILDREN) {
    return <>{children}</>;
  }
};

export default CreateListSubMessage;
