import "./styles.scss";

import NoData from "Components/NoData/NoData";
import React from "react";
import { Grid } from "@mui/material";

const AboutMe = ({ user }) => {
  return (
    <div className="about-me-component">
      {user ? (
        <div>
          <h5>Bio</h5>
          <p>{user?.aboutMe ?? "Nothing added about user"}</p>
          <h5>Place of Study</h5>
          <p>{user?.studiesAt}</p>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <h5>From</h5>
              <p>{user?.countryFrom}</p>
            </Grid>
            <Grid sm={6} xs={12} item>
              <h5>Ethnicity</h5>
              <p>{user?.race || "N/A"}</p>
            </Grid>
            {user?.department && (
              <Grid xs={12} item sm={6}>
                <h5>Department</h5>
                <p>{user?.department}</p>
              </Grid>
            )}
            {user?.degree && (
              <Grid xs={12} sm={6} item>
                <h5>Qualifications</h5>
                <p>{user?.degree}</p>
              </Grid>
            )}
            {user?.highestQualification && (
              <Grid xs={12} item sm={6}>
                <h5>Highest Qualification</h5>
                <p>{user?.highestQualification}</p>
              </Grid>
            )}
            <Grid xs={12} sm={6} item>
              <h5>Gender</h5>
              <p>{user?.gender}</p>
            </Grid>
            <Grid xs={12} sm={6} item>
              <h5>Disabilities</h5>
              <p style={{ textTransform: "capitalize" }}>
                {user?.disabilities || "No"}
              </p>
            </Grid>
          </Grid>
        </div>
      ) : (
        <NoData message="Nothing to show!" />
      )}
    </div>
  );
};

export default AboutMe;
