import "./styles.scss";

import {
  AttachMoney,
  Delete,
  Lock,
  Logout,
  Notifications,
  Person,
} from "@mui/icons-material";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import React from "react";
import { useDispatch } from "react-redux";
import { userLogout } from "Redux/Actions";

const AccountManagement = () => {
  // redux
  const dispatch = useDispatch();

  // router
  const location = useLocation();
  const navigate = useNavigate();

  const handleTabClick = (val) => {
    navigate(`/edit-profile/${val}`);
  };

  const handleLogout = () => {
    dispatch(userLogout());
    navigate("/");
  };

  return (
    <div className="account-management">
      <p>Account Management</p>
      <List>
        <ListItemButton
          onClick={() => handleTabClick("user-details")}
          selected={location.pathname === "/edit-profile/user-details"}
          disableGutters
        >
          <ListItemIcon>
            <Person />
          </ListItemIcon>
          <ListItemText primary="My Details" />
        </ListItemButton>
        <ListItemButton
          onClick={() => handleTabClick("change-password")}
          selected={location.pathname === "/edit-profile/change-password"}
          disableGutters
        >
          <ListItemIcon>
            <Lock />
          </ListItemIcon>
          <ListItemText primary="Password" />
        </ListItemButton>
        <ListItemButton
          onClick={() => handleTabClick("manage-subscriptions")}
          selected={location.pathname === "/edit-profile/manage-subscriptions"}
          disableGutters
        >
          <ListItemIcon>
            <AttachMoney />
          </ListItemIcon>
          <ListItemText primary="Manage Subscriptions" />
        </ListItemButton>
        <ListItemButton
          onClick={() => handleTabClick("notifications")}
          selected={location.pathname === "/edit-profile/notifications"}
          disableGutters
        >
          <ListItemIcon>
            <Notifications />
          </ListItemIcon>
          <ListItemText primary="Notifications Settings" />
        </ListItemButton>
        <ListItemButton onClick={handleLogout} disableGutters>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
        <ListItemButton
          onClick={() => handleTabClick("delete-account")}
          selected={location.pathname === "/edit-profile/delete-account"}
          className="error-text"
          disableGutters
        >
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText primary="Delete Account" />
        </ListItemButton>
      </List>
    </div>
  );
};

export default AccountManagement;
