import { Drawer } from "@mui/material";
import StripePayment from "Components/StripePayment/StripePayment";
import React from "react";

const SubscriptionPaymentDrawer = ({
  open,
  toggle,
  subscriptionData,
  refetch,
}) => {
  const onClose = (status) => {
    toggle(status);
    if (refetch) refetch();
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      sx={{ zIndex: (theme) => theme.zIndex.modal + 10 }}
    >
      <div className="p-4">
        {subscriptionData && (
          <StripePayment
            clientSecret={subscriptionData.clientSecret}
            closePayment={onClose}
          />
        )}
      </div>
    </Drawer>
  );
};

export default SubscriptionPaymentDrawer;
