import ViewKeyword from "Components/ViewKeyword/ViewKeyword";
import { useState } from "react";

const RenderListKeywords = ({ keyword, idx }) => {
  // state
  const [viewKeyword, setViewKeyword] = useState(false);

  const handleClickMyList = () => {
    setViewKeyword(true);
  };

  return (
    <>
      <div
        className="list-item"
        key={keyword.uuid}
        onClick={() => handleClickMyList(keyword.uuid)}
      >
        <span>{++idx}.</span>
        <span className="list-name">{keyword.term}</span>
      </div>

      {viewKeyword && (
        <ViewKeyword
          keyword={keyword}
          open={viewKeyword}
          toggle={setViewKeyword}
        />
      )}
    </>
  );
};

export default RenderListKeywords;
