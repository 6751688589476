import { SHOW_NOTIFICATION, HIDE_NOTIFICATION } from "../ActionTypes";

const initialState = true;

export const Notification = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NOTIFICATION: {
      return true;
    }

    case HIDE_NOTIFICATION: {
      return false;
    }

    default: {
      return state;
    }
  }
};
