import { Avatar } from "@mui/material";
import Spinner from "Components/Spinner/Spinner";
import axios from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

const fetchNews = () => {
  return axios
    .get("https://newsdata.io/api/1/news", {
      params: {
        q: "learning",
        apikey: "pub_3296525c1bfed9266f0d915cbefba0a277659",
        country: "gb",
        category: "education,environment,science,technology",
        language: "en",
      },
    })
    .then((res) => res.data);
};

const NewsFeed = () => {
  // state
  const [news, setNews] = useState([]);

  // queries
  const { isLoading, data } = useQuery({
    queryKey: ["get-news-feed"],
    queryFn: () => fetchNews(),
  });

  useEffect(() => {
    if (data && data.results) {
      const arr = data.results.slice(0, 10);
      setNews(arr);
    }
  }, [data]);

  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const handleClickNews = (url) => {
    window.open(url);
  };

  return (
    <div className="blogs-feed">
      {news.map((item, idx) => (
        <>
          <div
            className="list-item-blogs-feed"
            key={item.article_id + "-" + idx}
            onClick={() => handleClickNews(item.link)}
            title={item.description}
          >
            <div className="d-flex gap-3">
              <Avatar src={item.image_url} alt="blog-title" variant="rounded" />
              <div>
                <span className="list-name cursor-pointer">{item.title}</span>
              </div>
            </div>
          </div>
        </>
      ))}
    </div>
  );
};

export default NewsFeed;
