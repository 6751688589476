import { blueGrey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export const projectName = "Kewordal";

export const snackBarPlacement = { horizontal: "left", vertical: "bottom" };

const primaryColor = "#01AAF5";
const successColor = "#8ac246";
const secondaryColor = blueGrey["700"];
const tableHeadColor = "#7ad1f8";
const tooltipBgColor = "#424242";

export const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    success: {
      main: successColor,
    },
    warning: {
      main: "#FEC004",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          color: "white",
          borderRadius: "6px",
        },
        outlined: {
          borderRadius: "6px",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: tableHeadColor,
          color: "white",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          "& > .MuiBackdrop-root": {
            backgroundColor: "rgba(0,0,0,.2)",
            backdropFilter: "blur(15px)",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          "& .MuiTooltip-tooltip": {
            backgroundColor: tooltipBgColor,
            fontSize: "12px",
          },
        },
      },
    },
  },
});
