import { useInfiniteQuery, useQuery } from "react-query";

import axios from "axios";
import { getClientToken, getToken } from "Shared/baseURL";

const fetchPosts = async (page, content = "") => {
  const token = await getToken();
  return axios
    .get("/posts.json", {
      headers: {
        Authorization: token,
      },
      params: {
        size: 10,
        page,
        sort: "repostedAt.DESC",
        content: content,
      },
    })
    .then((res) => res.data);
};

export const usePostsData = (content) => {
  return useInfiniteQuery(
    ["get-all-posts"],
    ({ pageParam = 1 }) => fetchPosts(pageParam, content),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => {
        const { pagination } = lastPage;
        if (pagination.page < pagination.totalPages) {
          return pagination.page + 1;
        } else {
          return null;
        }
      },
    }
  );
};

const fetchSubjectPosts = async (subjectID, page) => {
  const token = await getToken();
  return axios
    .get(`subjects/${subjectID}/posts.json`, {
      headers: {
        Authorization: token,
      },
      params: {
        page,
        size: 10,
        sort: "repostedAt.DESC",
      },
    })
    .then((res) => res.data);
};

/**
 * @param {string} subjectID
 * @returns posts related to subject
 */
export const usePostsBySubjectData = (subjectID, allSubjectsPosts) => {
  return useInfiniteQuery(
    ["get-posts-by-subject", subjectID, allSubjectsPosts],
    allSubjectsPosts
      ? ({ pageParam = 1 }) => fetchPosts(pageParam)
      : ({ pageParam = 1 }) => fetchSubjectPosts(subjectID, pageParam),
    {
      enabled: !!subjectID || allSubjectsPosts,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => {
        const { pagination } = lastPage;
        if (pagination.page < pagination.totalPages) {
          return pagination.page + 1;
        } else {
          return null;
        }
      },
    }
  );
};

const fetchUserPosts = async (userID, page) => {
  const token = await getToken();
  return axios
    .get(`/users/${userID}/posts.json`, {
      headers: {
        Authorization: token,
      },
      params: {
        size: 10,
        page,
        // fields:
        //   "likes,content,photo.url,video.url,user.email,user.uuid,user.avatarURL,user.username,uuid,subject.name,list.name",
      },
    })
    .then((res) => res.data);
};

export const useSpecificUserPosts = (userID) => {
  return useInfiniteQuery(
    ["get-user-specific-posts", userID],
    ({ pageParam = 1 }) => fetchUserPosts(userID, pageParam),
    {
      enabled: !!userID,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => {
        const { pagination } = lastPage;
        if (pagination.page < pagination.totalPages) {
          return pagination.page + 1;
        } else {
          return null;
        }
      },
    }
  );
};

const getComments = async (listID, page) => {
  const token = await getToken();
  return axios
    .get(`/posts/${listID}/comments.json`, {
      headers: {
        Authorization: token,
      },
      params: {
        size: 10,
        fields: "comment,uuid,createdAt,user.avatarURL,user.username,user.uuid",
        page: page,
      },
    })
    .then((res) => res.data);
};

/**
 * @description gets all comments related to passed post id
 * @param {string} postID
 * @returns array of comments
 */
export const usePostsCommentsData = (postID, inView) => {
  return useInfiniteQuery(
    ["get-posts-comments", postID],
    ({ pageParam = 1 }) => getComments(postID, pageParam),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 30 * 1000,
      enabled: !!postID && inView,
      getNextPageParam: (lastPage) => {
        const { pagination } = lastPage;
        if (pagination.page < pagination.totalPages) {
          return pagination.page + 1;
        } else {
          return undefined;
        }
      },
    }
  );
};

const fetchSinglePost = async (postId) => {
  const token = await getClientToken();
  return axios
    .get(`/posts/${postId}.json`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
};

export const useSinglePost = (id) => {
  return useQuery(["get-single-post", id], () => fetchSinglePost(id), {
    refetchOnWindowFocus: false,
  });
};

const fetchSinglePostByHref = async (href) => {
  const token = await getClientToken();
  return axios
    .get(`/posts/[href=${href}].json`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
};

export const useSinglePostByHref = (href) => {
  return useQuery(
    ["get-single-post", href],
    () => fetchSinglePostByHref(href),
    {
      refetchOnWindowFocus: false,
    }
  );
};
