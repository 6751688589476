import Spinner from "Components/Spinner/Spinner";
import styles from "./styles.module.scss";

import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Input,
  Paper,
} from "@mui/material";
import { getToken } from "Shared/baseURL";
import { useNotify } from "Utils/hooks";
import axios from "axios";
import React, { useState } from "react";
import TestYourSelfSubMessage from "Components/SubscriptionMessages/Game/TestYourSelfSubMessage";

const ChallengeDialog = ({
  emailDialog,
  setEmailDialog,
  challengeEmail,
  setChallengeEmail,
  setChallengeUserID,
  handleCloseEmailDialog,
  handleChallengeFriend,
}) => {
  // state
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const [connections, setConnections] = useState([]);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);

  const handleSuggestionClick = (user) => {
    setChallengeUserID(user.uuid);
    setChallengeEmail(user.email);
    setConnections([]);
    setIsBtnDisabled(false);
  };

  const handleInputChange = async (email) => {
    try {
      setConnections([]);
      setIsLoading(true);
      setChallengeEmail(email);
      const token = await getToken();
      const res = await axios.get(
        `/users/me/connections-only.json?email,username,firstName,lastName=${email}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const { data } = res;
      if (data.connections) {
        setConnections(data.connections);
      }
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={emailDialog}
      onClose={() => setEmailDialog(false)}
      aria-labelledby="email-dialog"
      fullWidth
    >
      <DialogTitle>Challenge a friend</DialogTitle>
      <DialogContent>
        <div className={styles["input-container"]}>
          <Input
            value={challengeEmail}
            onChange={(e) => handleInputChange(e.target.value)}
            placeholder="Provide email"
            autoFocus
            fullWidth
            className="input-field"
          />
          <div className="mt-3">
            <TestYourSelfSubMessage isChallenge bgColor="#eee">
              <div className={styles["loader-div"]}>
                {isLoading && <Spinner size={30} />}
              </div>
              {connections.length > 0 && (
                <Paper className={styles["suggestion-list"]}>
                  {connections.map((item) => (
                    <div
                      className={styles["list-item"]}
                      onClick={() => handleSuggestionClick(item)}
                    >
                      <Avatar src={item.avatarURL} />
                      {item.username}
                    </div>
                  ))}
                </Paper>
              )}
              <div className="d-flex justify-content-end gap-2 flex-wrap">
                <Button onClick={handleCloseEmailDialog} color="error">
                  Cancel
                </Button>
                <Button
                  onClick={handleChallengeFriend}
                  color="primary"
                  variant="contained"
                  disableElevation
                  disabled={isLoading || isBtnDisabled}
                >
                  Start Challenge
                </Button>
              </div>
            </TestYourSelfSubMessage>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ChallengeDialog;
