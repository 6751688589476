import axios from "axios";
import { getToken } from "../../Shared/baseURL";
import { useQuery } from "react-query";

export const PostNewList = async (data) => {
  try {
    const token = await getToken();
    const res = await axios.post("/lists", data, {
      headers: {
        Authorization: token,
      },
    });
    if (res.status >= 200 || res.status < 300) {
      const { data, status } = res;
      return {
        status,
        data,
      };
    }
  } catch (error) {
    if (error.response) {
      const { data, status, statusText } = error.response;
      return {
        status,
        message: data.message || data.error_description || statusText,
      };
    }
  }
};

export const getSearchSubjects = async (page = 1, name = "") => {
  try {
    const token = await getToken();
    const res = await axios.get("/subjects.json", {
      params: {
        page,
        name,
        sort: "name",
      },
      headers: {
        Authorization: token,
      },
    });
    return res;
  } catch (error) {
    if (error.response) {
      const { data, status, statusText } = error.response;
      return {
        status,
        message: data.message || data.error_description || statusText,
      };
    }
  }
};

const fetchSubjects = async (name) => {
  const token = await getToken();
  return axios
    .get("/subjects.json", {
      params: {
        page: 1,
        name,
        sort: "name",
      },
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data);
};

export const useSubjectsData = (name = "") => {
  return useQuery(["get-subjects-data-for-select", name], () =>
    fetchSubjects(name)
  );
};
