import Spinner from "Components/Spinner/Spinner";
import { useHandleGetBlogs } from "Pages/Blogs/Api";
import dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";

const BlogsFeed = () => {
  // queries
  const { data, isLoading } = useHandleGetBlogs(1, "");

  // router
  const navigate = useNavigate();

  const handleBlogClick = (href) => {
    navigate(`/blog/${href}`);
  };

  const handleUserClick = (username) => {
    if (username) {
      navigate(`/my-network/${username}`);
    }
  };

  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  if (data) {
    return (
      <div className="blogs-feed">
        {data.blogs.map((blog, idx) => (
          <>
            <div className="list-item-blogs-feed" key={blog.uuid}>
              <div className="d-flex gap-2">
                <span>{++idx}.</span>
                <div onClick={() => handleBlogClick(blog.href)}>
                  <span className="list-name cursor-pointer">{blog.title}</span>
                </div>
              </div>
              <div>
                <div
                  className="user-details"
                  onClick={() => handleUserClick(blog?.user?.username)}
                >
                  <small>
                    {blog?.user?.username} (
                    {dayjs(blog.createdAt).format("YYYY-MM-DD")})
                  </small>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    );
  }
};

export default BlogsFeed;
