import { CircularProgress } from "@mui/material";

const Spinner = ({ size = 40 }) => {
  return (
    <div className="d-flex justify-content-center mt-3">
      <CircularProgress size={size} />
    </div>
  );
};

export default Spinner;
