import axios from "axios";
import { useInfiniteQuery } from "react-query";
import { getToken } from "../../Shared/baseURL";

const fetchSearchKeywords = async (q, page) => {
  const token = await getToken();
  return axios
    .get("/search/keywords", {
      params: {
        q,
        page,
        size: 15,
      },
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data);
};

export const useSearchKeywords = (query) => {
  return useInfiniteQuery(
    ["search-keywords", query],
    ({ pageParam = 1 }) => fetchSearchKeywords(query, pageParam),
    {
      enabled: !!query,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => {
        const { pagination } = lastPage;
        if (pagination.page < pagination.totalPages) {
          return pagination.page + 1;
        } else {
          return undefined;
        }
      },
    }
  );
};

const fetchSearchLists = async (q, page) => {
  const token = await getToken();
  return axios
    .get("/search/lists", {
      params: {
        q,
        page,
        size: 15,
      },
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data);
};

export const useSearchLists = (query) => {
  return useInfiniteQuery(
    ["search-lists", query],
    ({ pageParam = 1 }) => fetchSearchLists(query, pageParam),
    {
      enabled: !!query,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => {
        const { pagination } = lastPage;
        if (pagination.page < pagination.totalPages) {
          return pagination.page + 1;
        } else {
          return undefined;
        }
      },
    }
  );
};

const fetchSearchUsers = async (q, page) => {
  const token = await getToken();
  return axios
    .get("/search/users", {
      params: {
        q,
        page,
        size: 15,
      },
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data);
};

export const useSearchUsers = (query) => {
  return useInfiniteQuery(
    ["search-users", query],
    ({ pageParam = 1 }) => fetchSearchUsers(query, pageParam),
    {
      enabled: !!query,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => {
        const { pagination } = lastPage;
        if (pagination.page < pagination.totalPages) {
          return pagination.page + 1;
        } else {
          return undefined;
        }
      },
    }
  );
};

const fetchSearchPosts = async (page, content = "") => {
  const token = await getToken();
  return axios
    .get("/search/posts", {
      headers: {
        Authorization: token,
      },
      params: {
        size: 10,
        page,
        q: content,
      },
    })
    .then((res) => res.data);
};

export const useSearchPosts = (content) => {
  return useInfiniteQuery(
    ["get-all-posts", content],
    ({ pageParam = 1 }) => fetchSearchPosts(pageParam, content),
    {
      enabled: !!content,
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => {
        const { pagination } = lastPage;
        if (pagination.page < pagination.totalPages) {
          return pagination.page + 1;
        } else {
          return null;
        }
      },
    }
  );
};

export const getListBySubject = async (subjectID) => {
  try {
    const token = await getToken();
    const res = await axios.get(`/subjects/${subjectID}/lists.json`, {
      headers: {
        Authorization: token,
      },
    });
    if (res.status === 200) {
      const { data, status } = res;
      return {
        status,
        data,
      };
    }
  } catch (error) {
    if (error.response) {
      const { data, status, statusText } = error.response;
      return {
        status,
        message: data.message || data.error_description || statusText,
      };
    }
  }
};
