import { Button, Grid } from "@mui/material";

import NoData from "Components/NoData/NoData";
import PostCard from "Components/PostCard/PostCard";
import React from "react";
import Spinner from "Components/Spinner/Spinner";
import { useSpecificUserPosts } from "Utils/posts";

const MyPosts = ({ user }) => {
  // props
  const { uuid: userID } = user;
  // queries
  const {
    isLoading,
    data: postsData,
    refetch: refetchPosts,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useSpecificUserPosts(userID);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div>
      <Grid container spacing={2}>
        {postsData?.pages.map((page) =>
          page.posts.length > 0 ? (
            page.posts.map((post, idx) => (
              <Grid item xs={12} key={`${post.uuid}-${idx}`}>
                <PostCard
                  post={post}
                  refetchPosts={refetchPosts}
                  myPosts
                  idx={idx}
                />
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <div className="bg-white py-5">
                <NoData message="No posts to show!" />
              </div>
            </Grid>
          )
        )}
        <Grid item xs={12}>
          {hasNextPage && (
            <Button
              color="secondary"
              fullWidth
              disabled={isFetchingNextPage}
              onClick={fetchNextPage}
            >
              {isFetchingNextPage ? "Loading..." : "Load More"}
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default MyPosts;
