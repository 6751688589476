import { useState } from "react";
import TrendingKeywords from "./Keywords/TrendingKeywords";
import { Divider, ToggleButton, ToggleButtonGroup } from "@mui/material";
import TrendingLists from "./Lists/TrendingLists";

const LIST_ENUMS = {
  KEYWORDS: "keywords",
  LISTS: "lists",
};

const Trending = () => {
  // state
  const [view, setView] = useState(LIST_ENUMS.LISTS);

  const handleView = (e, val) => {
    setView(val);
  };

  return (
    <>
      <ToggleButtonGroup
        color="primary"
        value={view}
        exclusive
        onChange={handleView}
        size="small"
        fullWidth
      >
        <ToggleButton value={LIST_ENUMS.LISTS}>Lists</ToggleButton>
        <ToggleButton value={LIST_ENUMS.KEYWORDS}>Keywords</ToggleButton>
      </ToggleButtonGroup>
      <Divider className="my-3" />
      {LIST_ENUMS.KEYWORDS === view && <TrendingKeywords />}
      {LIST_ENUMS.LISTS === view && <TrendingLists />}
    </>
  );
};

export default Trending;
