import { Search } from "@mui/icons-material";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import Advertisement from "Components/Advertisement/Advertisement";
import EmptySearch from "Components/EmptySearch/EmptySearch";
import SearchList from "Components/SearchList/SearchList";
import React from "react";

const ListsTab = ({
  searchQuery,
  setActiveTab,
  postCount,
  listCount,
  peopleCount,
  allList,
  keywordsCount,
  listsLoading,
  refetchLists,
  isFetchingNextLists,
  fetchNextLists,
  hasNextLists,
  isFetchingLists,
  initialRender,
}) => {
  if (initialRender) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ my: 2 }}>
            <h5 className="text-muted">
              <Search /> Type to search...
            </h5>
          </Box>
        </Grid>
      </Grid>
    );
  }

  if (listsLoading || isFetchingNextLists || isFetchingLists) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ my: 2 }} className="center">
            <CircularProgress />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <div>
      <span
        style={{
          marginTop: "1rem",
          marginLeft: "5px",
          marginBottom: "1rem",
          display: "inline-block",
          color: "#666666",
        }}
      >
        {searchQuery && `${listCount} results found for "${searchQuery}"`}
      </span>
      <Grid key="list-search" container spacing={2}>
        {allList.length < 1 ? (
          <Grid item xs={12}>
            <EmptySearch
              tabName="lists"
              setActiveTab={setActiveTab}
              searchQuery={searchQuery}
              posts={postCount}
              people={peopleCount}
              lists={listCount}
              kewords={keywordsCount}
            />
          </Grid>
        ) : (
          allList.map((item, idx) => (
            <>
              <Grid item xs={12}>
                <SearchList listItem={item} handleSearch={refetchLists} />
              </Grid>
              {idx % 2 === 0 && (
                <Grid item xs={12} key={`advertisement-` + idx}>
                  <Advertisement />
                </Grid>
              )}
            </>
          ))
        )}
        {hasNextLists && (
          <Grid item xs={12}>
            <div className="mt-1 center">
              <Button disabled={isFetchingNextLists} onClick={fetchNextLists}>
                {isFetchingNextLists ? "Loading..." : "Load More"}
              </Button>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default ListsTab;
