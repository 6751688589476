import * as yup from "yup";

import { Autocomplete } from "formik-mui";
import {
  CircularProgress,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  TextField,
  Tooltip,
} from "@mui/material";
import { Close, Done, Info } from "@mui/icons-material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { debounce } from "lodash";
import { useSnackbar } from "notistack";
import AddSubjectCard from "Components/AddSubjectCard/AddSubjectCard";
import { getSearchSubjects } from "Components/AddList/Api";
import axios from "axios";
import { getToken } from "Shared/baseURL";

const SubmitSchema = yup.object().shape({
  subject: yup.object().nullable(true).required("Required"),
});

const LinkList = ({ listID, open, close, refresh }) => {
  // state
  const { enqueueSnackbar: notify } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [subjectList, setSubjectList] = useState([]);

  // queries

  const handleClose = () => {
    setSubjectList([]);
    close(false);
  };

  const handleInputChange = debounce(async (value) => {
    if (!value) {
      setSubjectList([]);
      return;
    }
    setLoading(true);
    // setOptionOpen(false);
    try {
      const res = await getSearchSubjects(1, value);
      if (res.status === 200) {
        const { subjects } = res.data;
        setSubjectList(subjects);
      }
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setLoading(false);
      // setOptionOpen(true);
    }
  }, 500);

  const handleSubmit = async (values, setSubmitting) => {
    try {
      let data = {
        subject: values.subject.href,
      };
      const token = await getToken();
      await axios.patch(`/lists/${listID}`, data, {
        headers: {
          Authorization: token,
        },
      });
      notify("List linked.");
      handleClose();
      if (refresh) {
        refresh();
      }
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="link-list"
        fullWidth
      >
        <DialogTitle>Link List</DialogTitle>
        <DialogContent>
          <DialogContentText>Type to search subject by name.</DialogContentText>
          <Box sx={{ py: 2 }}>
            <Formik
              initialValues={{ subject: null, name: "" }}
              validationSchema={SubmitSchema}
              onSubmit={(values, { setSubmitting }) =>
                handleSubmit(values, setSubmitting)
              }
            >
              {({ isSubmitting, submitForm }) => (
                <Form>
                  <Box>
                    <Field
                      name="subject"
                      component={Autocomplete}
                      options={subjectList}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(opt, val) => opt.uuid === val.uuid}
                      filterSelectedOptions
                      loading={loading}
                      onInputChange={(e, value) => {
                        handleInputChange(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="subject"
                          label="Select Subject"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                    <ErrorMessage
                      name="subject"
                      render={(msg) => (
                        <small className="text-danger">{msg}</small>
                      )}
                    />
                  </Box>
                  <Box
                    sx={{
                      mt: 2,
                      display: "flex",
                      justifyContent: "center",
                      gap: "20px",
                    }}
                  >
                    <Button
                      disabled={isSubmitting}
                      onClick={handleClose}
                      variant="outlined"
                      color="secondary"
                      startIcon={<Close />}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={isSubmitting}
                      onClick={submitForm}
                      variant="contained"
                      disableElevation
                      startIcon={<Done />}
                    >
                      Create
                    </Button>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
                  >
                    <AddSubjectCard>
                      <Button
                        // onClick={() => setOptionOpen(false)}
                        color="secondary"
                        endIcon={
                          <Tooltip
                            arrow
                            title='If you cannot find your subject under any of the existing subjects on our platform you can put in a request to Kewordal for us to add it.  To do this, click on the "Add Subject" button here.'
                          >
                            <Info />
                          </Tooltip>
                        }
                      >
                        Add Subject
                      </Button>
                    </AddSubjectCard>
                  </Box>
                  {isSubmitting && <LinearProgress sx={{ my: 2 }} />}
                </Form>
              )}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default LinkList;
