import { Button } from "@mui/material";
import {
  useElements,
  useStripe,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useNotify } from "Utils/hooks";
import React, { useState } from "react";

const CheckoutForm = ({ onClose }) => {
  // state
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  //   hooks
  const notify = useNotify();

  // stripe hooks
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsBtnLoading(true);
      const res = await stripe.confirmPayment({
        elements: elements,
        redirect: "if_required",
      });

      if (res.error) {
        throw new Error(res.error.message);
      }

      notify("Subscribed successfully");
      onClose("success");
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsBtnLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h5>Provide Payment Details</h5>
      <PaymentElement />
      <div className="d-flex justify-content-center gap-3 flex-wrap mt-4">
        <Button
          id="submit"
          onClick={() => onClose("cancel-payment")}
          variant="outlined"
          color="error"
          disabled={isBtnLoading}
        >
          Cancel
        </Button>
        <Button
          id="submit"
          type="submit"
          variant="contained"
          disableElevation
          disabled={isBtnLoading}
        >
          Subscribe
        </Button>
      </div>
    </form>
  );
};

export default CheckoutForm;
