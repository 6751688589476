import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useNotify, useReduxUserProfile } from "Utils/hooks";
import React, { useEffect, useState } from "react";
import { cancelSubscription } from "./Apis";
import { Link } from "react-router-dom";

const CancelSubscription = ({
  open,
  toggle,
  subscriptionID,
  status,
  refetch,
}) => {
  // state
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [isSponsored, setIsSponsored] = useState(false);
  const [cancelReason, setCancelReason] = useState("");

  // redux
  const reduxUser = useReduxUserProfile();

  // hooks
  const notify = useNotify();

  useEffect(() => {
    if (reduxUser && reduxUser.sponsoredBy) {
      setIsSponsored(!!reduxUser.sponsoredBy);
    }
  }, [reduxUser]);

  const onClose = () => {
    toggle();
  };

  const handleCancelSubscription = async () => {
    try {
      setIsBtnLoading(true);

      await cancelSubscription(subscriptionID, cancelReason);
      notify("Subscription cancelled successfully");

      if (refetch) refetch();
      setCancelReason("");
      onClose();
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsBtnLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Cancel Paid Subscription</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {status === "active" && <ActiveMessage isSponsored={isSponsored} />}
          {status !== "active" && <InactiveMessage />}
        </DialogContentText>
        {isSponsored && (
          <div>
            <TextField
              value={cancelReason}
              onChange={(e) => setCancelReason(e.target.value)}
              label="Reason"
              variant="standard"
              placeholder="Please provided reason to cancel"
              size="small"
              required
              fullWidth
            />
          </div>
        )}
        <Button
          className="mt-4"
          color="error"
          variant="outlined"
          fullWidth
          disabled={isBtnLoading || (isSponsored && !cancelReason)}
          onClick={handleCancelSubscription}
        >
          Continue
        </Button>
      </DialogContent>
    </Dialog>
  );
};

const ActiveMessage = ({ isSponsored }) => {
  return isSponsored ? (
    <p>
      Your subscription is being sponsored for the current period and you do not
      have to pay to use our service. Are you sure you want to continue to
      cancellation?
    </p>
  ) : (
    <p>
      <span className="fw-500 text-black">Important:</span> We are sorry you are
      thinking about cancelling your Kewordal subscription, promotion, or trial,
      which you can do at any period. After you cancel it, your plan will expire
      at the end of your current billing cycle. To avoid getting charged for
      another billing cycle, you need to cancel your plan at least one day
      before your next scheduled billing date. If you have a billing related
      issue, please <Link to="/contact-us">contact us</Link>.
    </p>
  );
};

const InactiveMessage = () => {
  return (
    <p>
      Are you sure you want to cancel your Kewordal subscription? After you
      cancel, you'll return to a Basic (free) account.
    </p>
  );
};

export default CancelSubscription;
