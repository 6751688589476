import { Box, Button } from "@mui/material";
import { useReduxUserProfile } from "Utils/hooks";

import NoData from "Components/NoData/NoData";
import Spinner from "Components/Spinner/Spinner";
import { useSuggestedConnections } from "Utils/queries";
import UserSuggestionCard from "./UserSuggestionCard";
import { useEffect, useState } from "react";

const Suggestions = ({ refetchUserData }) => {
  // state
  const [allSuggestions, setAllSuggestions] = useState([]);

  // redux
  const userRedux = useReduxUserProfile();

  // query
  const {
    data: suggestionData,
    isLoading: dataLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useSuggestedConnections(userRedux.uuid);

  useEffect(() => {
    if (suggestionData) {
      const { pages } = suggestionData;
      let arr = [];
      pages.forEach((page) => {
        page.suggestedConnections.forEach((item) => arr.push(item));
      });
      setAllSuggestions(arr);
    }
  }, [suggestionData]);

  if (dataLoading) {
    return <Spinner />;
  }

  if (Array.isArray(allSuggestions) && allSuggestions.length < 1) {
    return <NoData />;
  }

  return (
    <Box>
      {allSuggestions?.map((item) => (
        <UserSuggestionCard
          userRedux={userRedux}
          key={item.uuid}
          item={item}
          refetchUserData={refetchUserData}
        />
      ))}
      {hasNextPage && (
        <div className="center">
          <Button onClick={fetchNextPage} disabled={isFetchingNextPage}>
            {isFetchingNextPage ? "Loading..." : "Load More"}
          </Button>
        </div>
      )}
    </Box>
  );
};

export default Suggestions;
