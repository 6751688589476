/**
 * @description converts file to base64
 * @param {File} imageFile
 * @returns {String} base64 string
 */
const ImageToBase64 = async (imageFile) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.onload = () => {
      const result = reader.result;
      resolve(result);
    };
  })
    .then((result) => result)
    .catch((error) => {
      console.log(error);
      return false;
    });
};

export default ImageToBase64;
