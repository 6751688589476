import { getToken } from "../../Shared/baseURL";
import axios from "axios";

export const PostInvites = async (value) => {
  try {
    const token = await getToken();
    const res = await axios.post("/users/invite", value, {
      headers: {
        Authorization: token,
      },
    });
    if (res.status === 200) {
      return { data: res.data, status: res.status };
    }
    throw new Error("Error fetching users.");
  } catch (error) {
    console.log(error.message);
    return false;
  }
};
