import { Check } from "@mui/icons-material";
import { Chip, TableCell, TableRow } from "@mui/material";
import CloneKeywordList from "Components/CloneKeywordList/CloneKeywordList";
import { useDisclosure } from "Utils/hooks";
import { useEffect, useState } from "react";
import { ROLES_TO_SHOW } from "./enums";
import { getUsernameComponent } from "./utils-challenges";
import { useUserHasSubscription } from "Utils/queries";
import SubscriptionNeeded from "Components/SubscriptionNeeded/SubscriptionNeeded";

const RenderSentList = ({ list, refetch, closeChallengesDialog }) => {
  // state
  const [userRole, setUserRole] = useState("");
  const [username, setUsername] = useState("");

  // hooks
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: isSubscriptionOpen, onToggle: onToggleSubscription } =
    useDisclosure();

  // queries
  // queries
  const { data: userSubscription, isLoading: subscriptionLoading } =
    useUserHasSubscription();

  useEffect(() => {
    if (list && list.userRoles) {
      const { userRoles, related, to } = list;
      let arr = [];
      userRoles.forEach((role) => {
        if (ROLES_TO_SHOW.some((item) => role.includes(item))) {
          arr.push(role);
        }
      });

      const name = getUsernameComponent(to, related);
      setUsername(name);

      setUserRole(arr.join(", "));
    }
  }, [list]);

  const cloneKewordalList = () => {
    if (!userSubscription) {
      onToggleSubscription();
      return;
    }

    onToggle();
  };

  return (
    <>
      <TableRow hover key={list.uuid}>
        <TableCell>
          <div className="d-flex gap-2 align-items-center">
            {username}
            {userRole && (
              <small className="text-muted text-lowercase">({userRole})</small>
            )}
          </div>
        </TableCell>
        <TableCell>{list.list}</TableCell>
        <TableCell>
          {list.answered ? (
            <span className="text-success">Yes</span>
          ) : (
            <span className="text-danger">No</span>
          )}
        </TableCell>
        <TableCell>
          {list.listUuidToClone ? (
            <Chip
              title="Clone this list"
              icon={<Check />}
              size="small"
              disabled={subscriptionLoading}
              onClick={cloneKewordalList}
              label="Save"
              variant="outlined"
            />
          ) : (
            <Chip size="small" label="Saved" color="success" />
          )}
        </TableCell>
      </TableRow>

      {isSubscriptionOpen && (
        <SubscriptionNeeded
          open={isSubscriptionOpen}
          toggle={onToggleSubscription}
          cb={closeChallengesDialog}
        />
      )}

      {isOpen && (
        <CloneKeywordList
          open={isOpen}
          setCloneModal={onToggle}
          term={list.list}
          uuid={list.listUuid}
          refetchListData={refetch}
        />
      )}
    </>
  );
};

export default RenderSentList;
