import { Button, Paper } from "@mui/material";
import { useDisclosure } from "Utils/hooks";
import React, { useEffect, useState } from "react";
import AddSubscription from "./AddSubscription";
import { useActiveSubscriptions } from "./Apis";
import Spinner from "Components/Spinner/Spinner";
import ActiveSubscriptionCard from "./ActiveSubscriptionCard";

const ManageSubscriptions = () => {
  // state
  const [allActiveSubscriptions, setAllActiveSubscriptions] = useState([]);

  // hooks
  const { isOpen, onToggle } = useDisclosure();

  // queries
  const { isLoading, data, refetch } = useActiveSubscriptions();

  useEffect(() => {
    if (data) {
      setAllActiveSubscriptions(data.subscriptions);
    }
  }, [data]);

  const handleAddSubscription = () => {
    onToggle();
  };

  return (
    <Paper className="rounded-3 p-4 paper">
      <div>
        <h4>Manage Subscriptions</h4>
        <hr />
      </div>

      {isLoading && <Spinner />}

      {!isLoading && !isOpen && allActiveSubscriptions.length <= 0 && (
        <div>
          <Button onClick={handleAddSubscription} variant="contained">
            Add Subscription
          </Button>
        </div>
      )}

      {!isLoading && allActiveSubscriptions.length > 0 && (
        <div>
          <div>
            <h5 className="muted faded">Subscriptions</h5>
          </div>
          {allActiveSubscriptions.map((subscription) => (
            <ActiveSubscriptionCard
              key={subscription.id}
              subscriptionID={subscription.id}
              startDate={subscription.current_period_start}
              endDate={subscription.current_period_end}
              interval={subscription.plan.interval}
              intervalCount={subscription.plan.interval_count}
              description={subscription.plan.productName}
              price={subscription.plan.amount}
              currency={subscription.plan.currency}
              selectedPriceID={subscription.plan.id}
              status={subscription.status}
              cancelAt={subscription.cancel_at}
              refetch={refetch}
            />
          ))}
        </div>
      )}

      {isOpen && (
        <AddSubscription
          open={isOpen}
          toggle={onToggle}
          refetch={refetch}
          captureHowYouHeard
        />
      )}
    </Paper>
  );
};

export default ManageSubscriptions;
