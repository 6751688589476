import { Box, Button, CircularProgress, Container } from "@mui/material";
import { lazy, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { ArrowBack } from "@mui/icons-material";
import RenderError from "Components/RenderError/RenderError";
import { useSpecificUserDataByUsername } from "Utils/queries";
import ProfileInfo from "Pages/MyNetwork/ProfileInfo";
import { useNotify, useReduxUserProfile } from "Utils/hooks";
import axios from "axios";
import { getToken } from "Shared/baseURL";
import { useDispatch } from "react-redux";
import { getProfile } from "Redux/Actions";
import DisconnectUser from "Components/DisconnectDialog/DisconnectDialog";
import Advertisement from "Components/Advertisement/Advertisement";
import SponsoredBy from "Components/SponsoredBy/SponsoredBy";

//  Components
const ProfileList = lazy(() =>
  import("Components/ProfileUserList/ProfileUserList")
);

const OthersNetwork = () => {
  // state
  const notify = useNotify();
  const [btnLoading, setBtnLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [differentUser, setDifferentUser] = useState(false);
  const [isFollowed, setIsFollowed] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isPendingConnection, setIsPendingConnection] = useState(false);
  const [disconnectDialog, setDisconnectDialog] = useState(false);
  const [isSentConnection, setIsSentConnection] = useState(false);

  // router
  const { username } = useParams();
  const navigate = useNavigate();

  // redux
  const dispatch = useDispatch();
  const userRedux = useReduxUserProfile();

  // queries
  const {
    data: user,
    isLoading,
    refetch: refetchUserData,
    error,
  } = useSpecificUserDataByUsername(username);

  useEffect(() => {
    if (user) {
      setDifferentUser(user.uuid !== userRedux.uuid);

      const connected = userRedux.connections.find((i) => i.uuid === user.uuid);
      setIsConnected(connected);

      const sent = userRedux.sentConnections.find((i) => i.uuid === user.uuid);
      setIsSentConnection(sent);

      const followed = userRedux.following.some((i) => i.uuid === user.uuid);
      setIsFollowed(followed);

      const pendingConnection = userRedux.pendingConnections.find(
        (i) => i.uuid === user.uuid
      );
      setIsPendingConnection(pendingConnection?.connectionRelationshipUuid);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userRedux]);

  const handleActiveTab = (key) => {
    setActiveTab(key);
  };

  const handleViewUserList = () => {
    navigate(`/lists/${user.username}`);
  };

  const handleConnect = async () => {
    try {
      setBtnLoading(true);
      const token = await getToken();
      await axios.post(
        `/users/connect/${user.uuid}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify(
        `Your invitation to connect was sent and you are now following ${username}.`
      );
      dispatch(getProfile(token.split(" ")[1]));
    } catch (error) {
      notify(
        error?.response?.data?.message === "CONNECTION_EXISTS"
          ? "Connection already exists"
          : error.message,
        {
          variant: "error",
        }
      );
    } finally {
      setBtnLoading(false);
    }
  };

  const handleFollow = async () => {
    try {
      setBtnLoading(true);
      const token = await getToken();
      await axios.post(
        `/users/${userRedux.uuid}/FOLLOWS/users/${user.uuid}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify("User followed.");
      dispatch(getProfile(token.split(" ")[1]));
    } catch (error) {
      notify(
        error?.response?.data?.message === "CONNECTION_EXISTS"
          ? "Connection already exists"
          : error.message,
        {
          variant: "error",
        }
      );
    } finally {
      setBtnLoading(false);
    }
  };

  const handleUnfollow = async () => {
    try {
      setBtnLoading(true);
      const token = await getToken();
      await axios.delete(
        `/users/${userRedux.uuid}/FOLLOWS/users/${user.uuid}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify("User unfollowed.");
      dispatch(getProfile(token.split(" ")[1]));
    } catch (error) {
      notify(
        error?.response?.data?.message === "CONNECTION_EXISTS"
          ? "Connection already exists"
          : error.message,
        {
          variant: "error",
        }
      );
    } finally {
      setBtnLoading(false);
    }
  };

  const handleDisconnect = () => {
    setDisconnectDialog(true);
  };

  const handleAcceptConnection = async () => {
    try {
      setBtnLoading(true);
      const token = await getToken();
      await axios.post(
        `/users/confirm-connection/${isPendingConnection}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify("Connection accepted");
      dispatch(getProfile(token.split(" ")[1]));
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setBtnLoading(false);
    }
  };

  const handleDeclineConnection = async () => {
    try {
      setBtnLoading(true);
      const relation = userRedux.sentConnections.find(
        (i) => i.username === username
      );
      const token = await getToken();
      await axios.delete(
        `/relationships/${relation.connectionRelationshipUuid}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify("Connection rejected");
      dispatch(getProfile(token.split(" ")[1]));
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setBtnLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  if (isLoading) {
    return (
      <Box className="center mt-5">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <RenderError message={error.message} renderBlackText />;
  }

  return (
    <Container className="my-network-page">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          {user && (
            <SponsoredBy
              name={user?.sponsoredBy?.name}
              image={user?.sponsoredBy?.logo}
            />
          )}
        </div>
        <Advertisement placeEnd />
      </div>
      {user && (
        <Box>
          <Box className="btn-box">
            <Button
              onClick={handleBack}
              startIcon={<ArrowBack />}
              variant="contained"
              color="secondary"
              size="small"
              disableElevation
            >
              Go Back
            </Button>
          </Box>
          <ProfileInfo
            user={user}
            isLoggedUser={false}
            differentUser={differentUser}
          >
            {differentUser && (
              <Box sx={{ mt: 4, display: "flex", gap: "0.5rem" }}>
                <>
                  {isPendingConnection && (
                    <>
                      <Button
                        disableElevation
                        onClick={handleAcceptConnection}
                        disabled={btnLoading}
                        variant="contained"
                        color="primary"
                      >
                        Accept Connection
                      </Button>
                      <Button
                        disableElevation
                        onClick={handleDeclineConnection}
                        disabled={btnLoading}
                        variant="contained"
                        color="primary"
                      >
                        Decline Connection
                      </Button>
                    </>
                  )}
                  {isConnected && (
                    <Button
                      disableElevation
                      onClick={handleDisconnect}
                      disabled={btnLoading}
                      variant="contained"
                      color="primary"
                    >
                      Disconnect
                    </Button>
                  )}
                  {!isConnected &&
                    !isSentConnection &&
                    !isPendingConnection && (
                      <Button
                        disableElevation
                        onClick={handleConnect}
                        disabled={btnLoading}
                        variant="contained"
                        color="primary"
                      >
                        Connect
                      </Button>
                    )}
                  {isSentConnection && (
                    <Button
                      disableElevation
                      onClick={handleDeclineConnection}
                      disabled={btnLoading}
                      variant="contained"
                      color="primary"
                    >
                      Withdraw Connection
                    </Button>
                  )}
                  {isFollowed ? (
                    <Button
                      disableElevation
                      onClick={handleUnfollow}
                      disabled={btnLoading}
                      variant="contained"
                      color="primary"
                    >
                      Unfollow
                    </Button>
                  ) : (
                    <Button
                      disableElevation
                      onClick={handleFollow}
                      disabled={isFollowed || btnLoading}
                      variant="contained"
                      color="primary"
                    >
                      Follow
                    </Button>
                  )}
                  <Button
                    disableElevation
                    onClick={handleViewUserList}
                    variant="contained"
                  >
                    Kewordal Lists
                  </Button>
                </>
              </Box>
            )}
          </ProfileInfo>
          <Box className="tabs">
            <span
              onClick={() => handleActiveTab(0)}
              className={`${activeTab === 0 && "active"} span-0`}
              title="Connections"
            >
              CONNECTIONS
            </span>
            <span
              onClick={() => handleActiveTab(1)}
              className={`${activeTab === 1 && "active"} span-1`}
              title="Following"
            >
              FOLLOWING
            </span>
            <span
              onClick={() => handleActiveTab(2)}
              className={`${activeTab === 2 && "active"} span-2`}
              title="Followers"
            >
              FOLLOWERS
            </span>
          </Box>
          <Box className="tabs-content custom-scroll">
            <AnimatePresence mode="wait">
              {activeTab === 0 && (
                <ProfileList
                  user={user}
                  type="connections"
                  differentUser={true}
                  refetch={refetchUserData}
                />
              )}
              {activeTab === 1 && (
                <ProfileList
                  user={user}
                  type="following"
                  differentUser={true}
                  refetch={refetchUserData}
                />
              )}
              {activeTab === 2 && (
                <ProfileList
                  user={user}
                  type="followers"
                  differentUser={true}
                  refetch={refetchUserData}
                />
              )}
            </AnimatePresence>
          </Box>
        </Box>
      )}
      {disconnectDialog && (
        <DisconnectUser
          currentUserID={user.uuid}
          open={disconnectDialog}
          setDisconnectDialog={setDisconnectDialog}
          disconnectWithID={user.uuid}
          refetch={refetchUserData}
        />
      )}
    </Container>
  );
};

export default OthersNetwork;
