import "./styles.scss";

import BlogsFeed from "./BlogsFeed/BlogsFeed";
import MostViewed from "./MostViewed";
import NewsFeed from "./NewFeed/NewsFeed";
import Trending from "./Trending";

const DashboardListCard = ({
  title = "",
  trending,
  mostViewed,
  blogsFeed,
  newsFeed,
}) => {
  return (
    <div className="dashboard-lists custom-scroll">
      <div>
        <div className="title-div">
          <span className="list-title">{title}</span>
        </div>
        {trending && <Trending />}
        {mostViewed && <MostViewed />}
        {blogsFeed && <BlogsFeed />}
        {newsFeed && <NewsFeed />}
      </div>
      <div className="footer-div"></div>
    </div>
  );
};

export default DashboardListCard;
