import ReadMore from "Components/ReadMore/ReadMore";

const PostText = ({ content }) => {
  const newContent = content.replaceAll("\n", "<br />");

  return (
    <>
      <p className="content-text">
        <ReadMore text={newContent} />
      </p>
    </>
  );
};

export default PostText;
