import { ArrowBack } from "@mui/icons-material";
import { Avatar, Box, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ListName from "./ListName";
import Reminder from "Components/Reminder/Reminder";
import CloneListSubMessage from "Components/SubscriptionMessages/List/CloneListSubMessage";
import SponsoredBy from "Components/SponsoredBy/SponsoredBy";

const RenderViewListSubject = ({ subject, isListCreatedByMe }) => {
  // router
  const navigate = useNavigate();

  const handleViewUser = (username) => {
    navigate(`/my-network/${username}`);
  };

  return (
    <>
      <Box className="title-box">
        <div
          className="title-div"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            flexWrap: "wrap",
          }}
        >
          <IconButton onClick={() => navigate(-1)} color="primary" size="small">
            <ArrowBack fontSize="small" />
          </IconButton>
          <span className="subject-title">{subject.subject}</span>
          <Reminder subject subjectHref={subject.subjectHref} />
        </div>
        <div>{!isListCreatedByMe && <CloneListSubMessage />}</div>
      </Box>
      <Box>
        <ListName subject={subject} isListCreatedByMe={isListCreatedByMe} />
        <div className="d-flex align-items-center justify-content-between mb-3">
          <p className="list-author">
            <span
              className="blue"
              onClick={() => handleViewUser(subject.user.username)}
            >
              <Avatar
                src={subject.user?.avatarURL}
                alt={subject?.user?.displayName}
              />
              {subject?.user?.displayName}
            </span>
            <Reminder user />
          </p>
          <SponsoredBy
            image={subject?.user?.sponsoredBy?.logo}
            bgColor="white"
          />
        </div>
      </Box>
    </>
  );
};

export default RenderViewListSubject;
