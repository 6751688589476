import { Menu, MenuItem } from "@mui/material";
import React from "react";

const SizeMenu = ({
  viewSize,
  viewSizeMenu,
  closeViewSizeMenu,
  handleSelectViewSize,
}) => {
  return (
    <Menu
      anchorEl={viewSizeMenu}
      open={Boolean(viewSizeMenu)}
      onClose={closeViewSizeMenu}
    >
      <MenuItem
        selected={viewSize === 20}
        onClick={() => handleSelectViewSize(20)}
        value={20}
      >
        VIEW 20
      </MenuItem>
      <MenuItem
        selected={viewSize === 50}
        onClick={() => handleSelectViewSize(50)}
        value={50}
      >
        VIEW 50
      </MenuItem>
      <MenuItem
        selected={viewSize === 100}
        onClick={() => handleSelectViewSize(100)}
        value={100}
      >
        VIEW 100
      </MenuItem>
    </Menu>
  );
};

export default SizeMenu;
