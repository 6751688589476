import { Avatar } from "@mui/material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const RepostUser = ({ user }) => {
  // router
  const navigate = useNavigate();

  const viewUserProfile = () => {
    navigate(`/my-network/${user.username}`);
  };

  return (
    <div className="d-flex gap-2 mb-3">
      <Avatar
        src={user?.avatarURL}
        alt={user?.username}
        onClick={() => viewUserProfile(user?.username)}
        className="cursor-pointer"
      />
      <div className="d-flex flex-column">
        <span
          className="fw-500 cursor-pointer"
          onClick={() => viewUserProfile(user?.username)}
        >
          {user?.username ?? ""}
        </span>
        {user?.at && (
          <span className="text-muted" style={{ fontSize: "11px" }}>
            reposted this {dayjs(user?.at).fromNow()}
          </span>
        )}
      </div>
    </div>
  );
};

export default RepostUser;
