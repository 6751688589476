import { ArrowBack } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const NoPageSignUp = () => {
  // router
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <section className="center flex-column mt-3 mb-4">
      <span style={{ fontSize: "3rem" }} className="text-danger">
        404
      </span>
      <p>The page you tried was not found.</p>
      <p>
        You may have typed the address incorrectly or used an outdated link.
      </p>
      <p>
        If you found a broken link from our site or another site, please{" "}
        <a href="/contact-us">email us.</a> Thank you!
      </p>
      <Button
        onClick={handleGoBack}
        startIcon={<ArrowBack />}
        variant="outlined"
        color="error"
      >
        Go Back
      </Button>
    </section>
  );
};

export default NoPageSignUp;
