import "./styles.scss";

import { ArrowBack, Delete, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { lazy, useEffect, useRef, useState } from "react";
import { useListsDataBySubjectForNewDashboard, useSubjectsData } from "./Api";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import NewListCard from "./NewListCard";
import NewMyList from "Components/NewMyList/NewMyList";
import NewSubjectCard from "./NewSubjectCard";
import NoData from "Components/NoData/NoData";
import Spinner from "Components/Spinner/Spinner";
import { debounce } from "lodash";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import Advertisement from "Components/Advertisement/Advertisement";
import AddListController from "./AddListController";
import AllSubjectsSelect from "Components/AllSubjectsSelect/AllSubjectsSelect";
import { useMediumScreenOrAbove, useReduxUserProfile } from "Utils/hooks";
import AddSubjectCard from "Components/AddSubjectCard/AddSubjectCard";
import DashboardListCard from "Components/DashboardListCard/DashboardListCard";
import StickySidebar from "sticky-sidebar-v2";

const MotionGrid = motion(Grid);

const AddList = lazy(() => import("Components/AddList/AddList"));

const NewDashboard = ({ showMine, showAddList, userProfile }) => {
  // ref
  const subjectContainerRef = useRef();

  // state
  const [allSubjectsLists, setAllSubjectsLists] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [searchSubject, setSearchSubject] = useState("");
  const [explore, setExplore] = useState(true);
  const [addListDialog, setAddListDialog] = useState(false);

  // hooks
  const isMediumScreenAndAbove = useMediumScreenOrAbove();

  // observer
  const { ref: loadMoreDiv, inView: loadMoreInView } = useInView({
    initialInView: false,
  });

  // router
  const { username } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // redux
  const currentUser = useReduxUserProfile();

  // queries
  const {
    isLoading: subjectsLoading,
    data: subjectsData,
    hasNextPage: hasMoreSubjects,
    isFetchingNextPage,
    fetchNextPage: fetchNextSubjects,
  } = useSubjectsData(searchSubject);

  const {
    isLoading: listLoading,
    data: listData,
    refetch: refetchListData,
    hasNextPage: hasNextListData,
    isFetchingNextPage: isFetchingNextListData,
    fetchNextPage: fetchNextListData,
  } = useListsDataBySubjectForNewDashboard(
    selectedSubject?.href,
    explore,
    allSubjectsLists,
    currentUser.uuid
  );

  useEffect(() => {
    if (showMine) {
      setExplore(false);
    } else {
      setExplore(true);
    }
  }, [showMine]);

  useEffect(() => {
    const addListParam = searchParams.get("addList");
    if (addListParam === "1") {
      setAddListDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    const mineOnly = searchParams.get("showMyList");
    if (!showMine) {
      if (mineOnly === "1") setExplore(false);
      else setExplore(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loadMoreInView && hasMoreSubjects) {
      fetchNextSubjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMoreInView]);

  useEffect(() => {
    let previousSelectedSubject = localStorage.getItem("listsPage");
    if (subjectsData && !selectedSubject) {
      // const { subjects } = subjectsData.pages[0];
      if (previousSelectedSubject) {
        handleSelectSubject(JSON.parse(previousSelectedSubject));
        const selectedDiv = document.getElementById(
          `subject-card-${JSON.parse(previousSelectedSubject).uuid}`
        );
        if (selectedDiv) {
          selectedDiv.scrollIntoView({
            inline: "center",
            behavior: "smooth",
          });
        }
      } else {
        // handleSelectSubject(subjects[0]);
        handleAllSubjectLists();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectsData]);

  useEffect(() => {
    if (isMediumScreenAndAbove && !username) {
      new StickySidebar(".sticky-side-grid", {
        topSpacing: 20,
        bottomSpacing: 20,
        containerSelector: ".sticky-container",
        innerWrapperSelector: ".sidebar__inner",
      });
    } else {
      new StickySidebar(".sticky-side-grid", {
        topSpacing: 20,
        bottomSpacing: 20,
        containerSelector: ".sticky-container",
        innerWrapperSelector: ".sidebar__inner",
        scrollContainer: ".sticky-side-grid",
      });
    }
  }, [isMediumScreenAndAbove, username]);

  const handleAllSubjectLists = () => {
    setAllSubjectsLists(true);
    setSelectedSubject(null);
    localStorage.removeItem("listsPage");
  };

  const handleSelectSubject = (subject) => {
    localStorage.setItem("listsPage", JSON.stringify(subject));
    setSelectedSubject(subject);
    setAllSubjectsLists(false);
  };

  // scrolling
  const handleScrollLeft = () => {
    subjectContainerRef.current.scrollBy({
      top: 0,
      left: -400,
      behavior: "smooth",
    });
  };

  const handleScrollRight = () => {
    subjectContainerRef.current.scrollBy({
      top: 0,
      left: 400,
      behavior: "smooth",
    });
  };

  const handleAddList = () => {
    setAddListDialog(true);
  };

  const handleViewDeletedLists = () => {
    navigate("/deleted-history");
  };

  const handleSubjectSearch = debounce((value) => {
    setSearchSubject(value);
  }, 600);

  if (!username) {
    return (
      <div className="new-dashboard-container">
        <Container>
          {showAddList && (
            <Box className="d-flex gap-2 " sx={{ mb: 3 }}>
              <Button
                className="rounded-pill text-capitalize"
                onClick={handleAddList}
                size="medium"
                variant="contained"
                disableElevation
                startIcon={<AddCircleOutlineIcon />}
              >
                Add New List
              </Button>
              <Button
                sx={{ px: "16px" }}
                className="rounded-pill bg-white text-capitalize"
                onClick={handleViewDeletedLists}
                size="medium"
                startIcon={<Delete />}
              >
                Deleted History
              </Button>
            </Box>
          )}
          <div className="search-div">
            <TextField
              className="input"
              size="small"
              fullWidth
              variant="filled"
              hiddenLabel
              placeholder="Search subject"
              onChange={(e) => handleSubjectSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
            {/* <Button
            color="primary"
            startIcon={<Add />}
            variant="contained"
            size="small"
          >
            Add Subject
          </Button> */}
          </div>
        </Container>

        <div className="subject-list-div custom-scroll">
          <div className="icon-div div-1">
            <IconButton
              onClick={handleScrollLeft}
              edge="start"
              className="icon-btn btn-1"
            >
              <ArrowBack fontSize="inherit" />
            </IconButton>
          </div>
          <Container ref={subjectContainerRef} className="subjects-container">
            <AddSubjectCard />
            {subjectsLoading ? (
              <Spinner />
            ) : (
              <>
                <AllSubjectsSelect
                  title="All Subjects"
                  onClick={handleAllSubjectLists}
                  isSelected={allSubjectsLists}
                />
                {subjectsData?.pages.map((page) =>
                  page.subjects.map((subject, idx) => (
                    <NewSubjectCard
                      initial={{ opacity: 0, y: 50 }}
                      animate={{
                        opacity: 1,
                        y: 0,
                        transition: {
                          delay: idx * 0.05,
                        },
                      }}
                      key={subject.uuid}
                      subject={subject}
                      selectedSubject={selectedSubject}
                      handleSelectSubject={handleSelectSubject}
                    />
                  ))
                )}
              </>
            )}
            <span ref={loadMoreDiv} />
            {isFetchingNextPage && <span>Loading...</span>}
          </Container>
          <div className="icon-div div-2">
            <IconButton
              onClick={handleScrollRight}
              edge="end"
              className="icon-btn btn-2"
            >
              <ArrowBack fontSize="inherit" />
            </IconButton>
          </div>
        </div>

        <Container className="sticky-container" sx={{ mb: 10 }}>
          <Grid container spacing={2} columns={24} className="mt-4">
            {!showAddList && (
              <Grid item xs={24}>
                <AddListController
                  refetchListData={refetchListData}
                  selectedSubject={selectedSubject}
                />
              </Grid>
            )}
            {/* main content */}
            <Grid item xs={24} md={16}>
              <Grid container columnGap={2} rowSpacing={4}>
                {!showMine && (
                  <Grid item xs={12}>
                    <Paper elevation={2}>
                      <div className="center">
                        <ToggleButtonGroup
                          fullWidth
                          color="primary"
                          size="medium"
                          value={explore}
                          exclusive
                          onChange={(_e, val) => {
                            if (val === null) return;
                            setExplore(val);
                          }}
                        >
                          <ToggleButton value={true}>Show All</ToggleButton>
                          <ToggleButton value={false}>Mine Only</ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                    </Paper>
                  </Grid>
                )}
                {listLoading && (
                  <Grid item xs={12}>
                    <Spinner />
                  </Grid>
                )}
                {!listLoading &&
                  listData &&
                  listData.pages.map((page) =>
                    page.lists.length > 0 ? (
                      page.lists.map((list, idx) => (
                        <MotionGrid
                          item
                          key={`${list.uuid}-${idx}`}
                          xs={12}
                          initial={{ opacity: 0, y: 50 }}
                          animate={{
                            opacity: 1,
                            y: 0,
                            transition: {
                              delay: idx * 0.05,
                            },
                          }}
                        >
                          <NewListCard
                            list={list}
                            selectedSubject={selectedSubject}
                            refetchListData={refetchListData}
                            userProfile={userProfile}
                            idx={idx}
                          />
                        </MotionGrid>
                      ))
                    ) : (
                      <Grid item xs={12}>
                        <NoData message="No lists available!" />
                      </Grid>
                    )
                  )}
                {isFetchingNextListData && (
                  <Grid item xs={12}>
                    <Spinner />
                  </Grid>
                )}
                {hasNextListData && !isFetchingNextListData && (
                  <Grid item xs={12}>
                    <div className="center">
                      <Button onClick={fetchNextListData}>Load More</Button>
                    </div>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {/* right side-bar */}
            <Grid item xs={24} md={8}>
              <div className="sticky-side-grid">
                <div className="sidebar__inner">
                  <Grid
                    container
                    spacing={2}
                    direction={{ xs: "row-reverse", md: "row" }}
                  >
                    <Grid item xs={12} sm={6} md={12}>
                      <Advertisement />
                      <Box
                        sx={{
                          display: {
                            xs: "none",
                            sm: "block",
                            md: "none",
                          },
                          mt: "1rem",
                        }}
                      >
                        <Advertisement />
                      </Box>
                    </Grid>
                    {!showMine && (
                      <>
                        <Grid item xs={12} sm={6} md={12}>
                          <Stack spacing={2}>
                            <NewMyList />
                            <Advertisement />
                            <DashboardListCard title="Blogs Feed" blogsFeed />
                            <Advertisement />
                            <DashboardListCard title="Most Viewed" mostViewed />
                            <Advertisement />
                            <DashboardListCard title="Trending" trending />
                            <Advertisement />
                            <DashboardListCard title="News Feed" newsFeed />
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={12}
                          sx={{
                            display: { xs: "block", sm: "none", md: "block" },
                          }}
                        >
                          <Advertisement />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>

        {addListDialog && (
          <AddList
            selectedSubject={selectedSubject}
            open={addListDialog}
            close={setAddListDialog}
            refresh={refetchListData}
          />
        )}
      </div>
    );
  }

  if (username) {
    return <Outlet />;
  }
};

export default NewDashboard;
