import {
  DeleteOutline,
  LocalOfferOutlined,
  SettingsOutlined,
  Visibility,
} from "@mui/icons-material";
import {
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material";
import React from "react";

const KeywordsMenu = ({
  menuAnchor,
  handleCloseListMenu,
  handleViewDetails,
  handleEditModal,
  handleOpenDeleteModal,
  pinLoading,
  handlePinKeyword,
  keyword,
}) => {
  return (
    <Menu
      anchorEl={menuAnchor}
      open={Boolean(menuAnchor)}
      onClose={handleCloseListMenu}
      PaperProps={{
        sx: {
          borderRadius: "10px",
          px: 2,
        },
      }}
    >
      <MenuList dense>
        <>
          <MenuItem disableGutters onClick={handleViewDetails}>
            <ListItemIcon>
              <Visibility />
            </ListItemIcon>
            <ListItemText>View Details</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem disableGutters onClick={handleEditModal}>
            <ListItemIcon>
              <SettingsOutlined />
            </ListItemIcon>
            <ListItemText>Edit Keyword</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem disableGutters onClick={handleOpenDeleteModal}>
            <ListItemIcon>
              <DeleteOutline />
            </ListItemIcon>
            <ListItemText>Delete Keyword</ListItemText>
          </MenuItem>
          <Divider />
        </>
        <MenuItem
          disableGutters
          disabled={pinLoading}
          onClick={() => handlePinKeyword(keyword.uuid)}
        >
          <ListItemIcon>
            {pinLoading ? (
              <CircularProgress size={15} />
            ) : (
              <LocalOfferOutlined />
            )}
          </ListItemIcon>
          <ListItemText>Pin Keyword</ListItemText>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default KeywordsMenu;
