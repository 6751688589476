import "./styles.scss";
import { List } from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import React from "react";
import RenderNotificationList from "./RenderNotificationList";

const NotificationDialog = ({
  anchorEl,
  toggle,
  notifications = [],
  setNotificationsFalseApi,
}) => {
  const onClose = () => {
    toggle(null);
  };

  const handleMarkReadClick = () => {
    setNotificationsFalseApi();
    onClose();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "8px",
          padding: "0.5rem 1rem",
          backgroundColor: "white",
          minWidth: "330px",
          minHeight: "250px",
          maxHeight: "350px",
          maxWidth: "400px",
          overflow: "hidden",
        },
      }}
    >
      {notifications.length > 0 ? (
        <>
          <div className="d-flex justify-content-between ">
            <h3 className=" notification">Notifications</h3>
            <span onClick={handleMarkReadClick} className="mark-read">
              Mark all as read
            </span>
          </div>
          <List dense className="py-2 overflow-scroll list custom-scroll-thin ">
            {notifications.map((item, idx) => (
              <RenderNotificationList key={item.uuid} item={item} />
            ))}
          </List>
        </>
      ) : (
        <p className="text-muted text-center d-flex align-items-center justify-content-center gap-2 mt-5 pt-5">
          <span>
            <ErrorOutline />
          </span>
          No notifications
        </p>
      )}
    </Menu>
  );
};

export default NotificationDialog;
