import "./styles.scss";

import {
  ArrowForward,
  DeleteOutline,
  EditOutlined,
  PushPinOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import { lazy, useState } from "react";

import DeleteKeyword from "Components/DeleteKeyword/DeleteKeyword";
import axios from "axios";
import { getToken } from "Shared/baseURL";
import { useNotify } from "Utils/hooks";

const EditKeyword = lazy(() => import("Components/EditKeyword/EditKeyword"));
const ViewKeyword = lazy(() => import("Components/ViewKeyword/ViewKeyword"));

const ProfileKeywordsTags = ({
  keywords = [],
  keywordsCount = 3,
  refetchListData,
}) => {
  let remainingKeywords = keywords.length - keywordsCount;

  return (
    <Grid container spacing={2} className="profile-keywords-list">
      {keywords.slice(0, keywordsCount).map((keyword) => (
        <Grid item xs={12} sm={6} key={keyword.uuid}>
          <RenderKeywordTag
            keyword={keyword}
            refetchListData={refetchListData}
          />
        </Grid>
      ))}
      {remainingKeywords > 0 && (
        <Grid item xs={12} sm={6}>
          <div className="keyword-item">
            <span>View All</span>
            <div className="action-div">
              <IconButton size="small">
                <ArrowForward fontSize="inherit" />
              </IconButton>
            </div>
          </div>
        </Grid>
      )}
    </Grid>
  );
};

const RenderKeywordTag = ({ keyword, refetchListData }) => {
  // state
  const notify = useNotify();
  const [pinLoading, setPinLoading] = useState(false);
  const [viewKeyword, setViewKeyword] = useState(false);
  const [editKeyword, setEditKeyword] = useState(false);
  const [deleteKeyword, setDeleteKeyword] = useState(false);

  const handlePinKeyword = async () => {
    try {
      setPinLoading(true);
      const token = await getToken();
      const res = await axios.post(
        `/keywords/${keyword.uuid}/pin`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        notify("Keyword pinned.");
        refetchListData();
      } else {
        throw new Error("Error pinning the keyword.");
      }
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setPinLoading(false);
    }
  };

  return (
    <div className="keyword-item">
      <span>{keyword.term}</span>
      <div className="action-div">
        <IconButton size="small" onClick={() => setViewKeyword(true)}>
          <VisibilityOutlined fontSize="inherit" />
        </IconButton>
        <IconButton
          size="small"
          disabled={pinLoading}
          onClick={() => handlePinKeyword(true)}
        >
          <PushPinOutlined
            fontSize="inherit"
            sx={{ transform: "rotate(45deg)" }}
          />
        </IconButton>
        <IconButton size="small" onClick={() => setEditKeyword(true)}>
          <EditOutlined fontSize="inherit" />
        </IconButton>
        <IconButton size="small" onClick={() => setDeleteKeyword(true)}>
          <DeleteOutline fontSize="inherit" />
        </IconButton>
      </div>
      {viewKeyword && (
        <ViewKeyword
          keyword={keyword}
          open={viewKeyword}
          toggle={setViewKeyword}
        />
      )}
      {editKeyword && (
        <EditKeyword
          open={editKeyword}
          close={setEditKeyword}
          keyword={keyword}
          refresh={refetchListData}
        />
      )}
      {deleteKeyword && (
        <DeleteKeyword
          id={keyword.uuid}
          open={deleteKeyword}
          close={setDeleteKeyword}
          refresh={refetchListData}
        />
      )}
    </div>
  );
};

export default ProfileKeywordsTags;
