import { useState } from "react";
import MostViewedKeywords from "./Keywords/MostViewedKeywords";
import MostViewedLists from "./Lists/MostViewedLists";
import { Divider, ToggleButton, ToggleButtonGroup } from "@mui/material";

const LIST_ENUMS = {
  KEYWORDS: "keywords",
  LISTS: "lists",
};

const MostViewed = () => {
  // state
  const [view, setView] = useState(LIST_ENUMS.LISTS);

  const handleView = (e, val) => {
    setView(val);
  };

  return (
    <>
      <ToggleButtonGroup
        color="primary"
        value={view}
        exclusive
        onChange={handleView}
        size="small"
        fullWidth
      >
        <ToggleButton value={LIST_ENUMS.LISTS}>Lists</ToggleButton>
        <ToggleButton value={LIST_ENUMS.KEYWORDS}>Keywords</ToggleButton>
      </ToggleButtonGroup>
      <Divider className="my-3" />
      {LIST_ENUMS.KEYWORDS === view && <MostViewedKeywords />}
      {LIST_ENUMS.LISTS === view && <MostViewedLists />}
    </>
  );
};

export default MostViewed;
