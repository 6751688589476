import React, { memo } from "react";

import { InfoOutlined } from "@mui/icons-material";

const NoData = ({ message = "No data available!" }) => {
  return (
    <p className="indicate-empty text-center my-5">
      <InfoOutlined fontSize="large" />
      <br />
      {message}
    </p>
  );
};

export default memo(NoData);
