import { Box, Button } from "@mui/material";
import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { onlyLoggedOutRoutes, protectedRoutes, publicRoutes } from "./routes";
import BackToTop from "Components/BackToTop/BackToTop";
import { ErrorBoundary } from "react-error-boundary";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import LoadingFallback from "./Components/LoadingFallback/LoadingFallback";
import NoPage from "./Components/NoPage/NoPage";
import OnlyForLoggedOut from "./Components/RoutesWrapper/OnlyForLoggedOut";
import ProtectedRoute from "./Components/RoutesWrapper/ProtectedRoute";
import axios from "axios";
import { baseURL } from "./Shared/baseURL";
import { projectName } from "./theme/themeConfig";
import { useReduxUser } from "./Utils/hooks";
import AppSpeedDial from "Components/AppSpeedDial/AppSpeedDial";
import { useDispatch } from "react-redux";
import { userLogout } from "Redux/Actions";
// import { MetaTags } from "react-meta-tags";
// const Chatbot = lazy(() => import("Components/ChatBot/ChatBot"));

// default baseURL
axios.defaults.baseURL = baseURL;

const App = () => {
  // state
  const [renderHeaderFooter, setRenderHeaderFooter] = useState(true);

  // router
  const location = useLocation();
  const navigate = useNavigate();

  // redux
  const userRedux = useReduxUser();
  const dispatch = useDispatch();

  useEffect(() => {
    const check = [
      "/blogs-sitemap.xml",
      "/sitemap.xml",
      "/posts-sitemap.xml",
    ].includes(location.pathname);
    setRenderHeaderFooter(!check);
    sessionStorage.setItem("tutorialDialog", JSON.stringify([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleChatbot();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRedux]);

  function handleLogout() {
    dispatch(userLogout());
    navigate("/auth/login");
  }

  const handleChatbot = () => {
    try {
      const elem = document.getElementsByTagName("flowise-chatbot")[0];
      if (elem) {
        elem.classList.add("hide-tag");
        // if (!userRedux) {
        //   elem.classList.add("hide-tag");
        //   elem.classList.remove("show-tag");
        // } else {
        //   elem.classList.add("show-tag");
        //   elem.classList.remove("hide-tag");
        // }
      }
    } catch (error) {
      console.error(error);
    }
  };

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response) {
        if (error.response.status === 401) {
          handleLogout();
        }
      }
      return Promise.reject(error);
    }
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        overflowX: "hidden",
      }}
    >
      {/* <MetaTags>
        <title>
          Kewordal: The Fun & Engaging Learning Platform for Students
        </title>
        <meta
          name="description"
          content="Kewordal is a fun and engaging online learning platform that assists students in mastering new concepts through interactive games, quizzes, and lessons."
        />
      </MetaTags> */}
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {/* Header */}
        {renderHeaderFooter && (
          <header className="sticky-header-box">
            <Suspense fallback={<LoadingFallback />}>
              <Header drawerWidth={240} user={userRedux} />
            </Suspense>
          </header>
        )}
        {/* Main Content */}
        <main className="main-content-app">
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Suspense fallback={<LoadingFallback />}>
              <Routes>
                {publicRoutes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={route.component}
                  />
                ))}
                {onlyLoggedOutRoutes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      <OnlyForLoggedOut
                        element={route.component}
                        user={userRedux}
                      />
                    }
                  />
                ))}
                {protectedRoutes.map((route) =>
                  route.subRoutes ? (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={
                        <ProtectedRoute
                          element={route.component}
                          user={userRedux}
                        />
                      }
                    >
                      {route.subRoutes.map((subRoute) => (
                        <Route
                          key={subRoute.path}
                          path={subRoute.path}
                          element={subRoute.component}
                        />
                      ))}
                    </Route>
                  ) : (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={
                        <ProtectedRoute
                          element={route.component}
                          user={userRedux}
                        />
                      }
                    />
                  )
                )}
                <Route path="*" element={<NoPage />} />
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </main>
        {/* Footer */}
        {renderHeaderFooter && (
          <footer>
            <Suspense fallback={<LoadingFallback />}>
              <Footer projectName={projectName} />
            </Suspense>
          </footer>
        )}
      </ErrorBoundary>
      {/* Back to Top */}
      <BackToTop />

      {/* {userRedux && <GlobalUserSuggestions />} */}
      <AppSpeedDial />

      {/* chatbot */}
      {/* {userRedux && (
        <Suspense fallback={<p>loading chat...</p>}>
          <Chatbot />
        </Suspense>
      )} */}
    </Box>
  );
};

export const ReactContent = () => {
  // redux
  const userRedux = useReduxUser();

  return (
    <main className="main-content-app">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Suspense fallback={<LoadingFallback />}>
          <Routes>
            {publicRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.component}
              />
            ))}
            {onlyLoggedOutRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <OnlyForLoggedOut
                    element={route.component}
                    user={userRedux}
                  />
                }
              />
            ))}
            {protectedRoutes.map((route) =>
              route.subRoutes ? (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <ProtectedRoute
                      element={route.component}
                      user={userRedux}
                    />
                  }
                >
                  {route.subRoutes.map((subRoute) => (
                    <Route
                      key={subRoute.path}
                      path={subRoute.path}
                      element={subRoute.component}
                    />
                  ))}
                </Route>
              ) : (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <ProtectedRoute
                      element={route.component}
                      user={userRedux}
                    />
                  }
                />
              )
            )}
            <Route path="*" element={<NoPage />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </main>
  );
};

export const ReactHeader = () => {
  // redux
  const userRedux = useReduxUser();

  return (
    <header className="sticky-header-box">
      <Suspense fallback={<LoadingFallback />}>
        <Header drawerWidth={240} user={userRedux} />
      </Suspense>
    </header>
  );
};

export const ReactFooter = () => {
  return (
    <footer>
      <Suspense fallback={<LoadingFallback />}>
        <Footer projectName={projectName} />
      </Suspense>
    </footer>
  );
};

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div role="alert" className="p-4">
      <p>Something went wrong:</p>
      <pre>{JSON.stringify(error.message)}</pre>
      <Button color="error" variant="outlined" onClick={resetErrorBoundary}>
        Try again
      </Button>
    </div>
  );
};

export default App;
