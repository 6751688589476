export const HINTS_ENUMS = [
  "Create a subject",
  "Create a Kewordal list",
  "Creating content",
  "Create a game or challenge",
  "Create a following",
];

export const HINTS_DATA = {
  "Create a Kewordal list": {
    title: "How do I create a Kewordal list?",
    content: (
      <ul>
        <li>Go to the "List" page and click on "Add New List" button</li>
        <li>
          Select the "Subject" that your list applies to by typing it in, and it
          should automatically come up
        </li>
        <li>
          Once you have selected your subject add the name you want to give your
          Kewordal list
        </li>
        <li>
          Now you are ready to add keywords to your newly created Kewordal list
        </li>
      </ul>
    ),
    videoURL:
      "https://kewordal-images.s3.eu-west-1.amazonaws.com/assets/kewordal-media/video/howto/how-to-create-a-kewordal-list.mp4",
  },
  "Create a subject": {
    title: "Can I create a subject on Kewordal?",
    content: `If you are learning a subject not currently present on our platform, you can request that we add this.  Simply click on the "Add Subject" button in the top left-hand corner of the dashboard page; and follow the instructions, i.e., the exact name of the subject, the course URL/link, and an image that represents the course.`,
    videoURL:
      "https://kewordal-images.s3.eu-west-1.amazonaws.com/assets/kewordal-media/video/howto/learn-how-to-create-a-new-subject.mp4",
  },
  "Creating content": {
    title: "How do I add content to my keyword?",
    content: `Once you have created a Kewordal list title you can add content.  To do this, simply go to the relevant box and type in or copy and paste in your information, ensuring the information is correct and in line with what you wish to remember later.  The boxes you can add content to include "note", "question" (ensure you do not add the answer in the question box), "image", "video", and "link".`,
  },
  "Create a game or challenge": {
    title:
      "How do I create a game to challenge and play against friends and peers?",
    content: `To create a game to challenge and play against friends or peers, first create a Kewordal list with at least four keywords with the relevant text featuring in the "Add Question" field.  You can now play a game and challenge a friend or peer.`,
    videoURL:
      "https://kewordal-images.s3.eu-west-1.amazonaws.com/assets/kewordal-media/video/how-to-create-a-kewordal-multiple-choice-game.mp4",
  },
  "Create a following": {
    title: "How do I create a following and become an influencer on Kewordal?",
    content: (
      <>
        <p>
          To create a "following" and to become an "influencer" on Kewordal, you
          will need to do as many of the following activities as is practically
          possible:
        </p>
        <ul>
          <li>
            build up a network by inviting your friends and others to connect on
            Kewordal. This will also benefit your learning when they begin to
            create content that will interest you
          </li>
          <li>
            create Kewordal lists and populate them with content that other
            users are more likely to like, share or comment on
          </li>
          <li>share your content with friends and peers</li>
          <li>challenge your friends to multiple choice Kewordal games</li>
          <li>
            create accurate comprehensive Kewordal lists that other users of the
            platform are more likely to access
          </li>
          <li>
            share posts on the dashboard that others like, repost or comment on
          </li>
        </ul>
        <p>
          Being active on Kewordal will help to strengthen your profile and
          place you in a good position to be a social learning influencer;
          locally, nationally, and potentially, internationally; as we continue
          to grow.
        </p>
      </>
    ),
  },
};
