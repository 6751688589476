import React from "react";
import BookImage from "assets/images/books.png";

const AllSubjectsSelect = ({ title = "All Subjects", onClick, isSelected }) => {
  return (
    <div
      onClick={onClick}
      className={`subject-card ${isSelected && "selected"}`}
      style={{
        backgroundImage: `url(${BookImage})`,
      }}
    >
      <p className={`name-div ${isSelected && "selected"}`}>{title}</p>
    </div>
  );
};

export default AllSubjectsSelect;
