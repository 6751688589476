import styles from "./styles.module.scss";

import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
import { Box, Button, Stack } from "@mui/material";
import { useNotify } from "Utils/hooks";
import * as Yup from "yup";
import { getClientToken } from "Shared/baseURL";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";

const submitSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email required"),
  firstName: Yup.string().required("Required"),
  surName: Yup.string().required("Required"),
});

const Subscribe = ({ isFooter = false }) => {
  // state
  const [isCaptchaValidated, setIsCaptchaValidated] = useState(false);

  // hooks
  const notify = useNotify();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const data = { ...values };

      const token = await getClientToken();
      await axios.post(`/newsletters/subscribe`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      notify("Subscribed to newsletter");
    } catch (error) {
      if (error?.response?.data?.message === "USER_ALREADY_SIGNED_UP") {
        notify("It looks like you are already subscribed to our newsletters.", {
          variant: "default",
        });
      } else {
        notify(error.message, { variant: "error" });
      }
    } finally {
      setSubmitting(false);
    }
  };

  const handleCaptchaChange = (value) => {
    setIsCaptchaValidated(true);
  };

  return (
    <div className={styles["main"]}>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={submitSchema}
        initialValues={{
          email: "",
          firstName: "",
          surName: "",
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Stack spacing={2}>
              <Field
                id="newsletter-subscribe-email"
                // autoFocus
                component={TextField}
                placeholder="Email"
                name="email"
                size="small"
                fullWidth
                required
              />
              <Stack
                spacing={2}
                direction={{
                  xs: "column",
                  md: "row",
                }}
              >
                <Field
                  component={TextField}
                  placeholder="First Name"
                  name="firstName"
                  size="small"
                  fullWidth
                  required
                />
                <Field
                  component={TextField}
                  placeholder="Surname"
                  name="surName"
                  size="small"
                  fullWidth
                  required
                />
              </Stack>
              <div>
                <ReCAPTCHA
                  size="normal"
                  sitekey="6LciS88pAAAAAFc5hd7rp--NGyHhpDs_07gv4aVN"
                  onChange={handleCaptchaChange}
                />
              </div>
              <Box
                sx={
                  isFooter
                    ? {
                        width: "100%",
                      }
                    : {
                        display: "flex",
                        justifyContent: "start",
                      }
                }
              >
                <Button
                  type="submit"
                  variant="contained"
                  disableElevation
                  sx={
                    isFooter
                      ? { width: "100%" }
                      : {
                          width: { xs: "100%", sm: "400px" },
                        }
                  }
                  disabled={isSubmitting || !isCaptchaValidated}
                >
                  Subscribe
                </Button>
              </Box>
            </Stack>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Subscribe;
