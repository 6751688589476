const RenderTab = ({ text, count, initialRender }) => {
  return (
    <span>
      {text}{" "}
      {!initialRender && (
        <span>
          (<span className="blue">{count}</span>)
        </span>
      )}
    </span>
  );
};

export default RenderTab;
