import React from "react";

import DesertPic from "../../assets/images/desert.png";
import { Share } from "@mui/icons-material";
import { useNotify } from "Utils/hooks";

const Information = ({ title = "", text = "" }) => {
  // state
  const notify = useNotify();

  const handleShare = () => {
    notify("Link copied.", { variant: "default" });
  };

  return (
    <div className="information">
      <div className="title">
        <span>{title}</span>
        <img src={DesertPic} alt="pic" />
      </div>
      <div className="text">
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam
          provident quas perferendis! Officia fugiat ut, atque consectetur
          aliquid soluta asperiores optio possimus reiciendis minima accusamus
          sit sapiente minus eos iste.
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsam
          provident quas perferendis! Officia fugiat ut, atque consectetur
          aliquid soluta asperiores optio possimus reiciendis minima accusamus
          sit sapiente minus eos iste.
        </p>
      </div>
      <div className="links">
        <Share titleAccess="Share" onClick={handleShare} />
      </div>
    </div>
  );
};

export default Information;
