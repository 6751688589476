import { useState } from "react";
import { InfoOutlined } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, styled } from "@mui/material";
import DashboardTutorials from "Components/DashboardTutorials/DashboardTutorials";
import { HINTS_ENUMS } from "Components/DashboardTutorials/text-data";

const CustomMenuItem = styled(MenuItem)({
  color: "#01aaf5",
});

const ShowHints = () => {
  // state
  const [anchorEl, setAnchorEl] = useState(null);
  const [showTutorials, setShowTutorials] = useState(false);
  const [hint, setHint] = useState(null);

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  const handleHint = (h) => {
    setHint(h);
    handleClose();
    setShowTutorials(true);
  };

  return (
    <div>
      <IconButton
        disableRipple
        onClick={handleOpen}
        color="info"
        size="large"
        title="Hints"
      >
        <InfoOutlined fontSize="inherit" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            minWidth: "220px",
            border: "2px solid #8ac246",
          },
          className: "px-3",
        }}
      >
        <span className="fw-bold black">Learn how to:</span>
        {HINTS_ENUMS.map((hint) => (
          <CustomMenuItem
            disableGutters
            key={hint}
            onClick={() => handleHint(hint)}
          >
            {hint}
          </CustomMenuItem>
        ))}
      </Menu>
      {showTutorials && (
        <DashboardTutorials
          open={showTutorials}
          hint={hint}
          toggle={setShowTutorials}
        />
      )}
    </div>
  );
};

export default ShowHints;
