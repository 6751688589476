import { Flag, GroupAdd, Send } from "@mui/icons-material";
import {
  Box,
  Fab,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Tooltip,
} from "@mui/material";
import SuggestionsDialog from "Components/GlobalUserSuggestions/SuggestionsDialog";
import SendInvites from "Components/SendInvites/SendInvites";
import { useReduxUser } from "Utils/hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { red } from "@mui/material/colors";

const AppSpeedDial = () => {
  // state
  const [sendInvites, setSendInvites] = useState(false);
  const [suggestionDialog, setSuggestionDialog] = useState(false);

  // redux
  const reduxUser = useReduxUser();

  // router
  const navigate = useNavigate();

  const handleInvites = () => {
    setSendInvites(true);
  };

  const handleSuggestion = () => {
    setSuggestionDialog(true);
  };

  const handleReport = () => {
    navigate("/contact-us/?bug=1");
  };

  if (reduxUser) {
    return (
      <>
        <SpeedDial
          ariaLabel="App Speed Dial"
          icon={<SpeedDialIcon sx={{ color: "white" }} />}
          sx={{ position: "fixed", bottom: 86, right: 18 }}
          title="Actions"
          direction="left"
        >
          <SpeedDialAction
            icon={<Send />}
            tooltipTitle="Send Invites"
            onClick={handleInvites}
          />
          <SpeedDialAction
            icon={<GroupAdd />}
            tooltipTitle="User suggestions"
            onClick={handleSuggestion}
          />

          <SpeedDialAction
            icon={<Flag />}
            tooltipTitle="Report a bug"
            onClick={handleReport}
          />
        </SpeedDial>
        {sendInvites && (
          <SendInvites open={sendInvites} toggleDialog={setSendInvites} />
        )}
        {suggestionDialog && (
          <SuggestionsDialog
            open={suggestionDialog}
            toggle={setSuggestionDialog}
          />
        )}
      </>
    );
  }

  if (!reduxUser) {
    return (
      <Box sx={{ position: "fixed", bottom: 20, left: 24, zIndex: 9999 }}>
        <Tooltip title="Report a bug">
          <Fab
            onClick={handleReport}
            size="medium"
            color="default"
            sx={{
              "&:hover": {
                backgroundColor: red["500"],
                color: "white",
              },
            }}
          >
            <Flag />
          </Fab>
        </Tooltip>
      </Box>
    );
  }
};

export default AppSpeedDial;
