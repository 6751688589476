import "./styles.scss";

import {
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
} from "@mui/material";
import { cloneDeep, debounce } from "lodash";
import { useEffect, useRef, useState } from "react";

import NewMyList from "Components/NewMyList/NewMyList";
import { Search } from "@mui/icons-material";
import {
  useSearchKeywords,
  useSearchLists,
  useSearchPosts,
  useSearchUsers,
} from "./Api";
import Advertisement from "Components/Advertisement/Advertisement";
import { useSearchParams } from "react-router-dom";
import KeywordsTab from "./KeywordsTab";
import PostsTab from "./PostsTab";
import ListsTab from "./ListsTab";
import PeoplesTab from "./PeoplesTab";
import RenderTab from "./RenderTab";

const SearchResults = () => {
  // ref
  const searchInputRef = useRef(null);

  // state
  const [allPosts, setAllPosts] = useState([]);
  const [allKeywords, setAllKeywords] = useState([]);
  const [allList, setAllList] = useState([]);
  const [allPeopleList, setAllPeopleList] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState(null);
  const [postCount, setPostCount] = useState(0);
  const [keywordsCount, setKeywordsCount] = useState(0);
  const [listCount, setListCount] = useState(0);
  const [peopleCount, setPeopleCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [initialRender, setInitialRender] = useState(true);

  // router
  const [searchParams, setSearchParams] = useSearchParams();

  // queries
  const {
    data: postsData,
    isLoading: postsLoading,
    hasNextPage: hasNextPost,
    fetchNextPage: fetchNextPosts,
    isFetchingNextPage: isFetchingNextPosts,
    refetch: refetchPosts,
    isFetching: isFetchingPosts,
  } = useSearchPosts(searchQuery);

  const {
    data: keywordsData,
    isLoading: keywordsLoading,
    fetchNextPage: fetchNextKeywords,
    hasNextPage: hasNextKeywords,
    isFetchingNextPage: isFetchingNextKeywords,
    refetch: refetchKeywords,
    isFetching: isFetchingKeywords,
  } = useSearchKeywords(searchQuery);

  const {
    data: listsData,
    isLoading: listsLoading,
    fetchNextPage: fetchNextLists,
    hasNextPage: hasNextLists,
    isFetchingNextPage: isFetchingNextLists,
    refetch: refetchLists,
    isFetching: isFetchingLists,
  } = useSearchLists(searchQuery);

  const {
    data: usersData,
    isLoading: usersLoading,
    fetchNextPage: fetchNextUsers,
    hasNextPage: hasNextUsers,
    isFetchingNextPage: isFetchingNextUsers,
    refetch: refetchUsers,
    isFetching: isFetchingUsers,
  } = useSearchUsers(searchQuery);

  useEffect(() => {
    if (keywordsData) {
      const { pages } = keywordsData;
      setKeywordsCount(pages[0].pagination.total);
      let arr = [];
      pages.forEach((page) => {
        page.keywords.forEach((keyword) => arr.push(keyword));
      });
      getNewImgURL(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywordsData]);

  useEffect(() => {
    if (usersData) {
      const { pages } = usersData;
      setPeopleCount(pages[0].pagination.total);
      let arr = [];
      pages.forEach((page) => {
        page.users.forEach((keyword) => arr.push(keyword));
      });
      setAllPeopleList(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersData]);

  useEffect(() => {
    if (listsData) {
      const { pages } = listsData;
      setListCount(pages[0].pagination.total);
      let arr = [];
      pages.forEach((page) => {
        page.lists.forEach((list) => arr.push(list));
      });
      setAllList(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listsData]);

  useEffect(() => {
    if (postsData) {
      const { pages } = postsData;
      setPostCount(pages[0].pagination.total);
      let arr = [];
      pages.forEach((page) => {
        page.posts.forEach((post) => arr.push(post));
      });
      setAllPosts(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postsData]);

  useEffect(() => {
    const q = searchParams.get("q");
    if (!!q) {
      handleSearchQuery(q);
      if (searchInputRef.current) {
        searchInputRef.current.value = q;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchQuery) {
      setInitialRender(false);
    } else {
      setInitialRender(true);
      setPostCount(0);
      setKeywordsCount(0);
      setListCount(0);
      setPeopleCount(0);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (!keywordsLoading && !usersLoading && !listsLoading && !postsLoading) {
      setIsLoading(true);
      let countArr = [
        { count: postCount, tab: 0 },
        { count: keywordsCount, tab: 1 },
        { count: listCount, tab: 2 },
        { count: peopleCount, tab: 3 },
      ].sort((a, b) => b.count - a.count);
      setActiveTab(countArr[0].tab);
      setIsLoading(false);
    }
  }, [
    keywordsCount,
    postCount,
    listCount,
    peopleCount,
    keywordsLoading,
    postsLoading,
    usersLoading,
    listsLoading,
  ]);

  const handleActiveTab = (key) => {
    setActiveTab(key);
  };

  const handleSearchQuery = debounce((query) => {
    setSearchParams({ q: query });
    setSearchQuery(query);
  }, 700);

  const getNewImgURL = (keywords) => {
    let arr = cloneDeep(keywords);
    arr.forEach((item) => {
      const imgURL = item?.imageURL?.split("^")[0];
      if (imgURL) {
        const isNotExternal = imgURL.includes("https://kewordal-images.s3");
        const length = imgURL?.split("/")?.length;
        const url = imgURL?.split("/")[length - 1];
        item.newImgURL = isNotExternal ? setNewImgURL(url) : imgURL;
      } else {
        item.newImgURL = null;
      }
    });
    setAllKeywords(arr);
  };

  const setNewImgURL = (url) => {
    const baseURL = process.env.REACT_APP_BASEURL;
    return `${baseURL}/img/post/${url}?w=320&h=200&fit=true&bg=aaaaaa`;
  };

  const handleEnterKey = (e) => {
    const fieldValue = e.target.value;
    const enterKey = e.code === "Enter";
    if (enterKey && fieldValue) {
      refetchKeywords();
      refetchLists();
      refetchPosts();
      refetchUsers();
    }
  };

  const handleClickSearch = () => {
    const fieldValue = searchInputRef.current.value;
    if (fieldValue) {
      refetchKeywords();
      refetchLists();
      refetchPosts();
      refetchUsers();
    }
  };

  return (
    <Box className="search-results">
      <Box className="title-box">
        <Container>
          <Grid
            container
            columns={24}
            className="d-flex flex-column pt-4 main-container"
          >
            <Grid item xs={24} md={14}>
              <div className="input-container">
                <input
                  ref={searchInputRef}
                  placeholder="Search..."
                  onChange={(e) => handleSearchQuery(e.target.value)}
                  onKeyUp={handleEnterKey}
                />
                <IconButton size="medium" onClick={handleClickSearch}>
                  <Search />
                </IconButton>
              </div>
            </Grid>
            <Grid item xs={24} md={10}>
              <div className=" d-flex justify-content-between p-3 ">
                <span
                  onClick={() => handleActiveTab(0)}
                  className={`p-2 rounded-3  h6 tab ${
                    activeTab === 0 && "fw-bold active"
                  }`}
                >
                  <RenderTab
                    text="Posts"
                    count={postCount}
                    initialRender={initialRender}
                  />
                </span>
                <span
                  onClick={() => handleActiveTab(1)}
                  className={`p-2 h6 rounded-3  tab ${
                    activeTab === 1 && "fw-bold active"
                  }`}
                >
                  <RenderTab
                    text="Keywords"
                    count={keywordsCount}
                    initialRender={initialRender}
                  />
                </span>
                <span
                  onClick={() => handleActiveTab(2)}
                  className={`p-2 h6 rounded-3 tab ${
                    activeTab === 2 && "fw-bold active"
                  }`}
                >
                  <RenderTab
                    text="Lists"
                    count={listCount}
                    initialRender={initialRender}
                  />
                </span>
                <span
                  onClick={() => handleActiveTab(3)}
                  className={`p-2 h6   rounded-3 tab ${
                    activeTab === 3 && "fw-bold active"
                  }`}
                >
                  <RenderTab
                    text="People"
                    count={peopleCount}
                    initialRender={initialRender}
                  />
                </span>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container>
        <Grid container spacing={2} columns={24}>
          {isLoading ? (
            <Grid item xs={16}>
              <Box sx={{ my: 2 }} className="center">
                <CircularProgress />
              </Box>
            </Grid>
          ) : (
            <Grid item xs={24} md={16}>
              <Box className="cards-wrapper custom-scroll">
                {activeTab === 0 ? (
                  <PostsTab
                    allPosts={allPosts}
                    postsLoading={postsLoading}
                    fetchNextPosts={fetchNextPosts}
                    hasNextPost={hasNextPost}
                    isFetchingNextPosts={isFetchingNextPosts}
                    listCount={listCount}
                    postCount={postCount}
                    peopleCount={peopleCount}
                    searchQuery={searchQuery}
                    setActiveTab={setActiveTab}
                    refetchPosts={refetchPosts}
                    keywordsCount={keywordsCount}
                    isFetchingPosts={isFetchingPosts}
                    initialRender={initialRender}
                  />
                ) : activeTab === 1 ? (
                  <KeywordsTab
                    keywordsLoading={keywordsLoading}
                    refetchKeywords={refetchKeywords}
                    isFetchingNextKeywords={isFetchingNextKeywords}
                    fetchNextKeywords={fetchNextKeywords}
                    hasNextKeywords={hasNextKeywords}
                    allKeywords={allKeywords}
                    keywordsCount={keywordsCount}
                    listCount={listCount}
                    peopleCount={peopleCount}
                    postCount={postCount}
                    searchQuery={searchQuery}
                    setActiveTab={setActiveTab}
                    isFetchingKeywords={isFetchingKeywords}
                    initialRender={initialRender}
                  />
                ) : activeTab === 2 ? (
                  <ListsTab
                    listsLoading={listsLoading}
                    refetchLists={refetchLists}
                    isFetchingNextLists={isFetchingNextLists}
                    fetchNextLists={fetchNextLists}
                    hasNextLists={hasNextLists}
                    allList={allList}
                    searchQuery={searchQuery}
                    listCount={listCount}
                    keywordsCount={keywordsCount}
                    peopleCount={peopleCount}
                    postCount={postCount}
                    setActiveTab={setActiveTab}
                    isFetchingLists={isFetchingLists}
                    initialRender={initialRender}
                  />
                ) : (
                  activeTab === 3 && (
                    <PeoplesTab
                      usersLoading={usersLoading}
                      fetchNextUsers={fetchNextUsers}
                      hasNextUsers={hasNextUsers}
                      isFetchingNextUsers={isFetchingNextUsers}
                      refetchUsers={refetchUsers}
                      allPeopleList={allPeopleList}
                      keywordsCount={keywordsCount}
                      listCount={listCount}
                      peopleCount={peopleCount}
                      postCount={postCount}
                      searchQuery={searchQuery}
                      setActiveTab={setActiveTab}
                      isFetchingUsers={isFetchingUsers}
                      initialRender={initialRender}
                    />
                  )
                )}
              </Box>
            </Grid>
          )}
          <Grid item xs={24} md={8} sx={{ mt: 4.7 }}>
            <Grid
              container
              spacing={2}
              direction={{ xs: "row-reverse", md: "row" }}
            >
              <Grid item xs={12} sm={6} md={12}>
                <Advertisement />
                <Box
                  sx={{
                    display: {
                      xs: "none",
                      sm: "block",
                      md: "none",
                    },
                    mt: "1rem",
                  }}
                >
                  <Advertisement />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <NewMyList />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={12}
                sx={{ display: { xs: "block", sm: "none", md: "block" } }}
              >
                <Advertisement />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SearchResults;
