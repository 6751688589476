import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Alert, TextField } from "@mui/material";
import { useNotify } from "Utils/hooks";

const ConfirmPassword = ({ onCancel, onConfirm }) => {
  // state
  const notify = useNotify();
  const [password, setPassword] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  const onClose = () => {
    onCancel();
  };

  const handleConfirm = async () => {
    try {
      setBtnLoading(true);
      setAlertMsg("");
      const error = await onConfirm(password);
      if (error) {
        throw error;
      }
    } catch (error) {
      if (error.message.includes("already exists")) {
        handleDuplicationError(error.message);
      } else if (error.message === "INVALID_PASSWORD") {
        setAlertMsg("Password is invalid");
      } else {
        setAlertMsg(error.message);
      }
    } finally {
      setBtnLoading(false);
    }
  };

  const handleDuplicationError = (message = "Something went wrong.") => {
    if (message.includes("email")) {
      setAlertMsg("User with this email already exists.", {
        variant: "error",
      });
    } else if (message.includes("username")) {
      notify("User with this username already exists.", { variant: "error" });
    } else {
      notify(message);
    }
  };

  const handlePressEnter = (e) => {
    if (!password || btnLoading) return;
    if (e.key === "Enter") {
      handleConfirm();
    }
  };

  return (
    <Dialog fullWidth open={true} onClose={onClose}>
      <DialogTitle>Confirm Password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To save your changes, please confirm your password
        </DialogContentText>
        <TextField
          fullWidth
          value={password}
          className="mt-2"
          placeholder="* * * * * *"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          onKeyUp={handlePressEnter}
        />
        {alertMsg && (
          <div className="mt-3">
            <Alert severity="error">{alertMsg}</Alert>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="error"
          variant="outlined"
          disabled={btnLoading}
        >
          Cancel
        </Button>
        <Button
          disableElevation
          onClick={handleConfirm}
          color="primary"
          variant="contained"
          disabled={!password || btnLoading}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmPassword;
