import axios from "axios";
import { getToken } from "Shared/baseURL";
import { useQuery } from "react-query";

const fetchUserListBySubjects = async (userID) => {
  const token = await getToken();
  return axios
    .get(`/users/${userID}/lists-by-subjects.json`, {
      headers: {
        Authorization: token,
      },
      params: {
        sort: "createdAt.DESC",
      },
    })
    .then((res) => res.data);
};

export const useUserAllLists = (userID) => {
  return useQuery(
    ["get-user-lists-by-subjects", userID],
    () => fetchUserListBySubjects(userID),
    {
      refetchOnWindowFocus: false,
      enabled: !!userID,
      select: (data) => {
        const { subjects } = data;
        return subjects;
      },
    }
  );
};
