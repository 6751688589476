import "./styles.scss";

import { Avatar, Box, Button, IconButton, Paper } from "@mui/material";
import {
  Person,
  PersonAdd,
  PersonOff,
  PersonRemove,
  Report,
} from "@mui/icons-material";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import DisconnectUser from "Components/DisconnectDialog/DisconnectDialog";
import SchoolIcon from "@mui/icons-material/School";
import UnfollowUser from "../UnfollowDialog/UnfollowDialog";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNotify } from "Utils/hooks";
import {
  sendConnectRequest,
  sendFollowRequest,
} from "Components/PeopleList/Api";
import { getProfile } from "Redux/Actions";
import Spinner from "Components/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import { getToken } from "Shared/baseURL";
import axios from "axios";
import ReportUser from "Components/ReportUser/ReportUser";
import SponsoredBy from "Components/SponsoredBy/SponsoredBy";

const SearchPeopleList = ({ user, handleSearch, refetch }) => {
  // props
  const {
    studiesAt,
    uuid,
    avatarURL,
    username,
    aboutMe,
    highestQualification,
    related,
    displayName,
  } = user;

  // states
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const [disconnectDialog, setDisconnectDialog] = useState(false);
  const [unfollowDialog, setUnfollowDialog] = useState(false);
  const [isFollowed, setIsFollowed] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isPendingConnection, setIsPendingConnection] = useState(false);
  const [isSentConnection, setIsSentConnection] = useState(false);
  const [reportUser, setReportUser] = useState(false);

  // redux
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.profile);

  useEffect(() => {
    if (user) {
      const connected = currentUser.connections.find(
        (i) => i.uuid === user.uuid
      );
      setIsConnected(connected);

      const sent = currentUser.sentConnections.find(
        (i) => i.uuid === user.uuid
      );
      setIsSentConnection(sent);

      const followed = currentUser.following.some((i) => i.uuid === user.uuid);
      setIsFollowed(followed);

      const pendingConnection = currentUser.pendingConnections.find(
        (i) => i.uuid === user.uuid
      );
      setIsPendingConnection(pendingConnection?.connectionRelationshipUuid);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, currentUser]);

  // router
  const navigate = useNavigate();

  const handleDisconnect = () => {
    setDisconnectDialog(true);
  };

  const handleUnFollow = () => {
    setUnfollowDialog(true);
  };

  const handleReportUser = () => {
    setReportUser(true);
  };

  const handleConnectRequest = async () => {
    try {
      setIsLoading(true);
      const res = await sendConnectRequest(uuid);
      if (res.message) {
        throw new Error(res.message);
      }
      notify(
        `Your invitation to connect was sent and you are now following ${username}.`
      );
      if (refetch) {
        refetch();
      }
      let token = localStorage.getItem("userToken");
      dispatch(getProfile(token));
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFollowRequest = async () => {
    try {
      setIsLoading(true);
      const res = await sendFollowRequest(currentUser.uuid, uuid);
      if (res.message) {
        throw new Error(res.message);
      }
      notify("User followed.");
      if (refetch) {
        refetch();
      }
      let token = localStorage.getItem("userToken");
      dispatch(getProfile(token));
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewUser = () => {
    if (username) {
      navigate(`/my-network/${username}`);
    }
  };

  const handleAcceptConnection = async () => {
    try {
      setIsLoading(true);
      const token = await getToken();
      await axios.post(
        `/users/confirm-connection/${isPendingConnection}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify("Connection accepted");
      dispatch(getProfile(token.split(" ")[1]));
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeclineConnection = async () => {
    try {
      setIsLoading(true);
      const token = await getToken();
      await axios.delete(`/relationships/${isPendingConnection}`, {
        headers: {
          Authorization: token,
        },
      });
      notify("Connection declined");
      dispatch(getProfile(token.split(" ")[1]));
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Paper sx={{ borderRadius: 4, marginBottom: 1.5 }}>
        <div className="network-card p-3">
          <div className="title-div">
            <div className="d-flex gap-2">
              <div>
                <Avatar
                  src={avatarURL}
                  alt={username}
                  className="avatar cursor-pointer"
                  onClick={handleViewUser}
                />
              </div>
              <div>
                <div className="d-flex align-items-center gap-2">
                  <h5
                    className="name-heading cursor-pointer m-0 p-0"
                    onClick={handleViewUser}
                  >
                    <div className="d-flex justify-content-center gap-1">
                      {displayName}
                      {related && (
                        <small className="small-text">({related})</small>
                      )}
                    </div>
                  </h5>
                  <IconButton
                    color="error"
                    size="small"
                    title="Report user"
                    disableRipple
                    onClick={handleReportUser}
                  >
                    <Report />
                  </IconButton>
                </div>
                <small className="sub-text">{aboutMe ? aboutMe : "N/A"}</small>
              </div>
            </div>
            {isLoading ? (
              <Spinner size={20} />
            ) : (
              <div className="btn-box">
                {isPendingConnection && (
                  <>
                    <Button
                      disableElevation
                      onClick={handleAcceptConnection}
                      disabled={isLoading}
                      className="disconnect-btn success"
                    >
                      Accept
                    </Button>
                    <Button
                      disableElevation
                      onClick={handleDeclineConnection}
                      disabled={isLoading}
                      className="disconnect-btn success"
                    >
                      Decline
                    </Button>
                  </>
                )}
                {isSentConnection && (
                  <Button
                    disableElevation
                    startIcon={<PersonOff />}
                    onClick={handleDisconnect}
                    disabled={isLoading}
                    color="error"
                    className="error-btn"
                  >
                    Withdraw
                  </Button>
                )}
                {isConnected && (
                  <Button
                    onClick={handleDisconnect}
                    startIcon={<PersonOff />}
                    size="small"
                    color="error"
                    className="error-btn"
                  >
                    Disconnect
                  </Button>
                )}
                {!isConnected && !isSentConnection && !isPendingConnection && (
                  <Button
                    onClick={handleConnectRequest}
                    startIcon={<Person />}
                    size="small"
                    color="success"
                    className="success-btn"
                  >
                    Connect
                  </Button>
                )}
                {isFollowed ? (
                  <Button
                    onClick={handleUnFollow}
                    startIcon={<PersonRemove />}
                    size="small"
                    color="error"
                    className="error-btn"
                  >
                    Unfollow
                  </Button>
                ) : (
                  <Button
                    onClick={handleFollowRequest}
                    startIcon={<PersonAdd />}
                    size="small"
                    color="primary"
                    className="disconnect-btn"
                  >
                    Follow
                  </Button>
                )}
              </div>
            )}
          </div>
          <div className="mt-2">
            <div className="d-flex align-items-center gap-2">
              <AccountBalanceIcon fontSize="small" />
              <div>
                <small className="text">Studies at</small>
                <br />
                <small className="sub-text">
                  {studiesAt ? studiesAt : "N/A"}
                </small>
              </div>
            </div>
            <br />
            <div className="d-flex flex-wrap gap-4 align-items-start justify-content-between">
              <div className="d-flex align-items-center gap-2">
                <SchoolIcon fontSize="small" />
                <div>
                  <small className="text">Education Level</small>
                  <br />
                  <small className="sub-text">
                    {highestQualification ? highestQualification : "N/A"}
                  </small>
                </div>
              </div>
              <div>
                <SponsoredBy image={user?.sponsoredBy?.logo} smallImage />
              </div>
            </div>
          </div>
        </div>
        {disconnectDialog && (
          <DisconnectUser
            currentUserID={currentUser.uuid}
            open={disconnectDialog}
            setDisconnectDialog={setDisconnectDialog}
            disconnectWithID={uuid}
            refetch={handleSearch}
          />
        )}
        {unfollowDialog && (
          <UnfollowUser
            currentUserID={currentUser?.uuid}
            open={unfollowDialog}
            setUnfollowDialog={setUnfollowDialog}
            unfollowUserID={uuid}
            refetch={handleSearch}
          />
        )}
        {reportUser && (
          <ReportUser open={reportUser} toggle={setReportUser} userID={uuid} />
        )}
      </Paper>
    </Box>
  );
};

export default SearchPeopleList;
