import "./styles.scss";

import { useEffect, useState } from "react";
import NoData from "Components/NoData/NoData";
import Suggestions from "Components/Suggestions/Suggestions";
import UserCard from "./UserCard";

const NetworkCard = ({ data, activeTab, refetchUserData }) => {
  // states
  const [allList, setAllList] = useState([]);

  useEffect(() => {
    if (data) {
      setAllList(data[activeTab]);
    }
  }, [activeTab, data]);

  if (Array.isArray(data[activeTab]) && data[activeTab].length < 1) {
    return <NoData />;
  }

  return (
    <div>
      {allList?.map((item) => (
        <UserCard
          key={item.uuid}
          item={item}
          data={data}
          activeTab={activeTab}
          refetchUserData={refetchUserData}
        />
      ))}
      {activeTab === "suggestions" && (
        <Suggestions refetchUserData={refetchUserData} />
      )}
    </div>
  );
};

export default NetworkCard;
