import { applyMiddleware, combineReducers, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";

import { Notification } from "./Reducers/notification";
import { User } from "./authReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

const rootReducer = combineReducers({
  user: User,
  notification: Notification,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const makeStore = () => {
  if (process.env.NODE_ENV === "production") {
    return createStore(persistedReducer, applyMiddleware(thunk));
  } else {
    return createStore(
      persistedReducer,
      composeWithDevTools(applyMiddleware(thunk))
    );
  }
};

const store = makeStore();

const persistor = persistStore(store);

export { store, persistor };
