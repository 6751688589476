import "./styles.scss";

import * as yup from "yup";
import { Autocomplete, TextField } from "formik-mui";
import {
  Box,
  Button,
  Container,
  Divider,
  FormLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField as MuiTextField,
  Stack,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useNotify, useReduxUserProfile } from "Utils/hooks";
import { matchIsValidTel } from "mui-tel-input";
import { InquiryCategory } from "Shared/Enums";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { countryList } from "Shared/countryList";
import { getClientToken } from "Shared/baseURL";
import { useSearchParams } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
import ImageToBase64 from "Utils/imageToBase64";

const SubmitSchema = yup.object().shape({
  email: yup.string().email("Invalid Email.").required("Required"),
  fullName: yup.string().required("Required"),
  inquiryCategory: yup.string().required("Required"),
  phone: yup.string(),
  description: yup.string().required("Required"),
  subject: yup.string().required("Required"),
  country: yup
    .object()
    .shape({
      title: yup.string().required("Required"),
      value: yup.string().required("Required"),
    })
    .nullable()
    .required("Required"),
});

const SubmitSchemaWithUserRedux = yup.object().shape({
  email: yup.string().email("Invalid Email.").required("Required"),
  fullName: yup.string().required("Required"),
  inquiryCategory: yup.string().required("Required"),
  phone: yup.string(),
  description: yup.string(),
  subject: yup.string().required("Required"),
  country: yup
    .object()
    .shape({
      title: yup.string().required("Required"),
      value: yup.string().required("Required"),
    })
    .nullable(),
});

const ContactUs = () => {
  // ref
  const fileRef = useRef(null);

  // state
  const notify = useNotify();
  const [inquiry, setInquiry] = useState("");
  const [showFileInput, setShowFileInput] = useState(false);
  const [fileInput, setFileInput] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [filteredInquiryCategories, setFilteredInquiryCategories] = useState(
    []
  );

  // redux
  const userRedux = useReduxUserProfile();

  // router
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const checkInquiry = searchParams.get("advertiseWithUs");
    const checkReport = searchParams.get("report");
    const checkBug = searchParams.get("bug");
    if (checkInquiry) {
      setInquiry("Advertise with Us");
    } else if (checkReport) {
      setInquiry("Report offensive content");
      setShowFileInput(true);
    } else if (checkBug) {
      setInquiry("Report bug");
      setShowFileInput(true);
    } else {
      setInquiry("");
    }

    return () => {
      document.title =
        "Kewordal: The Fun & Engaging Learning Platform for Students";
    };
  }, [searchParams]);

  useEffect(() => {
    const checkBug = searchParams.get("bug");

    if (checkBug) {
      const elem = document.getElementById("inquiry-h3");
      elem.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [searchParams, userRedux]);

  useEffect(() => {
    let arr = [...InquiryCategory];
    if (!userRedux) {
      arr = arr.filter((categ) => categ.value !== "GDPR");
    }
    setFilteredInquiryCategories(arr);
  }, [userRedux]);

  useEffect(() => {
    if (fileInput) {
      handleFilePreview();
    }
  }, [fileInput]);

  const handleFileSelect = () => {
    fileRef.current.click();
  };

  const handleSubmit = async (values, setSubmitting, resetForm) => {
    if (values.country) {
      values.country = values.country.title;
    }

    // only validate phone for logged out users
    if (!userRedux) {
      const isValidPhone = matchIsValidTel(values.phone);
      if (!isValidPhone) {
        return notify("Invalid phone number", { variant: "error" });
      }
    }

    try {
      const formData = new FormData();

      Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
      });

      if (fileInput) {
        formData.append("file", fileInput);
      }

      const token = await getClientToken();
      const res = await axios.post("/contact-kewordal", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 201) {
        resetForm();
        setFileInput(null);
        notify(
          "We have received your query. We'll get back to you as soon as possible.",
          {
            autoHideDuration: 4000,
          }
        );
      }
    } catch (error) {
      notify("There was a problem registering your query.", {
        variant: "error",
      });
      notify(error.message, { variant: "error" });
    } finally {
      setSubmitting(false);
    }
  };

  const handleOnPaste = (e) => {
    const items = e.clipboardData.items;

    for (let i = 0; i < items.length; i++) {
      if (items[i].type.includes("image")) {
        const file = items[i].getAsFile();
        setFileInput(file);
      }
    }
  };

  const handleFilePreview = async () => {
    const data = await ImageToBase64(fileInput);
    setFilePreview(data);
  };

  return (
    <Container maxWidth="md" sx={{ my: 3 }} className="contact-us-page">
      <MetaTags>
        <title>Contact Us - Kewordal</title>
        <meta
          name="description"
          content="Get in touch with us and let us address your queries and concerns. Contact our team for prompt assistance and support"
        />
      </MetaTags>
      <Grid
        container
        spacing={2}
        columns={20}
        direction={{ xs: "column-reverse", sm: "row" }}
      >
        <Grid item xs={20} sm={12}>
          <Box>
            <Formik
              enableReinitialize
              initialValues={
                userRedux
                  ? {
                      fullName: userRedux.displayName,
                      email: userRedux.email,
                      inquiryCategory: inquiry,
                      phone: userRedux.phone,
                      subject: "",
                      description: "",
                      country: null,
                    }
                  : {
                      fullName: "",
                      email: "",
                      inquiryCategory: inquiry,
                      phone: "",
                      subject: "",
                      description: "",
                      country: null,
                    }
              }
              validationSchema={
                userRedux ? SubmitSchemaWithUserRedux : SubmitSchema
              }
              onSubmit={(values, { setSubmitting, resetForm }) =>
                handleSubmit(values, setSubmitting, resetForm)
              }
            >
              {({ values, isSubmitting, submitForm, setFieldValue }) => (
                <Form autoComplete="off">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <h3 id="inquiry-h3">{inquiry || "Contact Us"}</h3>
                    </Grid>
                    {!userRedux && (
                      <>
                        <Grid item xs={12}>
                          <FormLabel>Full Name</FormLabel>
                          <Field
                            component={TextField}
                            name="fullName"
                            fullWidth
                            size="small"
                            variant="filled"
                            hiddenLabel
                            placeholder="Full Name"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>Email</FormLabel>
                          <Field
                            component={TextField}
                            name="email"
                            fullWidth
                            size="small"
                            variant="filled"
                            hiddenLabel
                            placeholder="Email"
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <FormLabel>Inquiry Category</FormLabel>
                      <Field
                        component={TextField}
                        type="text"
                        name="inquiryCategory"
                        select
                        size="small"
                        fullWidth
                        hiddenLabel
                        variant="filled"
                        placeholder="Select Inquiry Type"
                        onChange={(e) => {
                          // setInquiry(e.target.value);
                          setFieldValue("inquiryCategory", e.target.value);
                        }}
                      >
                        {filteredInquiryCategories.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    {!userRedux && (
                      <>
                        <Grid item xs={12}>
                          <FormLabel>Phone Number</FormLabel>
                          <Field
                            component={TextField}
                            name="phone"
                            fullWidth
                            size="small"
                            variant="filled"
                            hiddenLabel
                            placeholder="Phone"
                            helperText="Optional"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>Country</FormLabel>
                          <Field
                            name="country"
                            component={Autocomplete}
                            options={countryList}
                            getOptionLabel={(option) => option.title}
                            hiddenLabel
                            renderInput={(params) => (
                              <MuiTextField
                                {...params}
                                name="country"
                                hiddenLabel
                                variant="filled"
                                fullWidth
                                placeholder="Type to search..."
                              />
                            )}
                          />
                          <ErrorMessage
                            name="country"
                            render={(msg) => (
                              <p
                                style={{
                                  color: "#d32f2f",
                                  marginLeft: "14px",
                                  marginTop: "4px",
                                  marginBottom: "0px",
                                  fontSize: "0.75rem",
                                }}
                              >
                                {msg}
                              </p>
                            )}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <FormLabel>Subject</FormLabel>
                      <Field
                        component={TextField}
                        name="subject"
                        fullWidth
                        size="small"
                        variant="filled"
                        hiddenLabel
                        placeholder="Subject"
                      />
                    </Grid>
                    {values.inquiryCategory !== "GDPR" && (
                      <Grid item xs={12}>
                        <FormLabel>Description</FormLabel>
                        <Field
                          component={TextField}
                          name="description"
                          fullWidth
                          size="small"
                          variant="filled"
                          hiddenLabel
                          placeholder="Description"
                          multiline
                          minRows={3}
                          onPaste={handleOnPaste}
                        />
                      </Grid>
                    )}
                    {showFileInput && (
                      <Grid item xs={12}>
                        <FormLabel>Attach a screenshot (optional)</FormLabel>
                        <div>
                          <Stack direction="row" spacing={2}>
                            <Button onClick={handleFileSelect}>
                              {fileInput ? fileInput.name : "Select file"}
                            </Button>
                            <input
                              hidden
                              type="file"
                              accept="image/*"
                              max={1}
                              ref={fileRef}
                              onChange={(e) => setFileInput(e.target.files[0])}
                            />
                            {filePreview && (
                              <div>
                                <img
                                  src={filePreview}
                                  alt="input-file-preview"
                                  height={100}
                                  width={100}
                                  style={{
                                    objectFit: "contain",
                                  }}
                                />
                              </div>
                            )}
                          </Stack>
                        </div>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Box className="d-flex justify-content-end">
                        <LoadingButton
                          onClick={submitForm}
                          loading={isSubmitting}
                          variant="contained"
                        >
                          Submit
                        </LoadingButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
        <Grid item xs={20} sm={8} className="info-box">
          <h5>Before you submit</h5>
          <List>
            <ListItem>
              <ListItemIcon>1.</ListItemIcon>
              <ListItemText primary="Kewordal support is currently available in English only." />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemIcon>2.</ListItemIcon>
              <ListItemText primary="Add as much detail as possible." />
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemIcon>3.</ListItemIcon>
              <ListItemText
                primary="Show us"
                secondary="Add a screenshot or a link to a video. It will help us understand better what you are experiencing. Please do not include any personal or sensitive information."
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ContactUs;
