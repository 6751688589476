import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useReceivedChallenges } from "Utils/queries";
import { useEffect, useState } from "react";
import RenderReceivedList from "./RenderReceivedList";

const ReceivedChallenges = ({ closeChallengesDialog }) => {
  // state
  const [allData, setAllData] = useState([]);

  // queries
  const {
    data,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useReceivedChallenges();

  useEffect(() => {
    if (data) {
      const { pages } = data;
      let arr = [];
      pages.forEach((page) => {
        page.challenges.forEach((challenge) => {
          arr.push(challenge);
        });
      });
      setAllData(arr);
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className="center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>From</TableCell>
              <TableCell>List</TableCell>
              <TableCell>Answered</TableCell>
              <TableCell>Challenge</TableCell>
              <TableCell>Clone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allData.map((list) => (
              <RenderReceivedList
                key={list.uuid}
                list={list}
                closeChallengesDialog={closeChallengesDialog}
                refetch={refetch}
              />
            ))}
          </TableBody>
        </Table>
        {hasNextPage && (
          <div className="mt-3">
            <Button
              disabled={isFetchingNextPage}
              onClick={fetchNextPage}
              fullWidth
            >
              {isFetchingNextPage ? "Loading..." : "Load more"}
            </Button>
          </div>
        )}
      </TableContainer>
    </div>
  );
};

export default ReceivedChallenges;
