import React from "react";

const PricesCard = ({
  amount,
  priceID,
  interval,
  intervalCount,
  handleSelectPriceID,
  selectedPriceID,
}) => {
  const handlePriceSelect = () => {
    handleSelectPriceID(priceID);
  };

  return (
    <div
      className={`price-card ${selectedPriceID === priceID && "selected"}`}
      onClick={handlePriceSelect}
    >
      <div className="price-amount">£ {amount}</div>
      <div className="price-interval">
        {intervalCount === 3 ? "quarterly" : `${interval}ly`}
      </div>
    </div>
  );
};

export default PricesCard;
