import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import MentionUsers from "Components/MentionUsers/MentionUsers";
import { useNotify } from "Utils/hooks";
import { getToken } from "Shared/baseURL";
import axios from "axios";

const EditPost = ({ toggle, open, content = "", postID, refetchPosts }) => {
  // state
  const notify = useNotify();
  const [newContent, setNewContent] = useState(content);
  const [isLoading, setIsLoading] = useState(false);

  const handleContentChange = (val) => {
    setNewContent(val);
    //   setHighLightWords([]);
  };

  const handleKeyPress = (e) => {
    if (e.ctrlKey && e.keyCode === 13 && newContent) {
      handleSubmitPost();
    }
  };

  const handleSubmitPost = async () => {
    try {
      setIsLoading(true);
      const token = await getToken();
      const data = {
        content: newContent,
      };
      await axios.patch(`/posts/${postID}`, data, {
        headers: {
          Authorization: token,
        },
      });
      refetchPosts();
      notify("Post updated successfully");
      onClose();
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const onClose = () => {
    toggle(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          minHeight: "230px",
        },
      }}
    >
      <DialogTitle>Edit Post</DialogTitle>
      <DialogContent>
        <MentionUsers
          value={newContent}
          handleChange={handleContentChange}
          handleKeyPress={handleKeyPress}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleSubmitPost}
          color="primary"
          variant="contained"
          disableElevation
          disabled={isLoading || !newContent}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPost;
