import { useInfiniteQuery, useQuery } from "react-query";

import axios from "axios";
import { getToken } from "Shared/baseURL";

/**
 * @description gets all subjects of current user
 * @returns {object} api response
 */
const getAllSubjects = async (page, name) => {
  const token = await getToken();
  return axios
    .get("/subjects.json", {
      params: {
        page,
        name,
        sort: "name",
      },
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data);
};

export const useSubjectsData = (href = "") => {
  return useInfiniteQuery(
    ["get-all-subjects", href],
    ({ pageParam = 1 }) => getAllSubjects(pageParam, href),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => {
        const { pagination } = lastPage;
        if (pagination.page < pagination.totalPages) {
          return pagination.page + 1;
        } else {
          return undefined;
        }
      },
    }
  );
};

const getListBySubject = async (subjectID, explore, name) => {
  let url = "";
  if (explore) url = `/subjects/${subjectID}/lists.json`;
  if (!explore) url = `/subjects/${subjectID}/my-lists.json`;
  const token = await getToken();
  return axios
    .get(url, {
      headers: {
        Authorization: token,
      },
      params: {
        name,
      },
    })
    .then((res) => res.data);
};

const getListBySubjectForNewDashboard = async (
  page,
  subjectHref,
  explore,
  allSubjectsLists,
  userID
) => {
  let url = "";
  if (allSubjectsLists && explore) {
    url = `/subjects/all/lists.json`;
  }
  if (explore && !allSubjectsLists) {
    url = `/subjects/[href=${subjectHref}]/lists.json`;
  }
  if (!explore && !allSubjectsLists) {
    url = `/users/me/lists.json?subject=${subjectHref}`;
  }
  if (allSubjectsLists && !explore) {
    url = `/users/${userID}/lists.json`;
  }
  const token = await getToken();
  return axios
    .get(url, {
      headers: {
        Authorization: token,
      },
      params: {
        page,
        size: 15,
        sort: "createdAt.DESC",
      },
    })
    .then((res) => res.data);
};

export const useListsDataBySubjectForNewDashboard = (
  subjectHref,
  explore,
  allSubjectsLists,
  userID
) => {
  return useInfiniteQuery(
    ["get-lists-by-subjects", subjectHref, explore, allSubjectsLists],
    ({ pageParam = 1 }) =>
      getListBySubjectForNewDashboard(
        pageParam,
        subjectHref,
        explore,
        allSubjectsLists,
        userID
      ),
    {
      refetchOnWindowFocus: false,
      enabled: !!subjectHref || !!allSubjectsLists,
      getNextPageParam: (lastPage) => {
        const { pagination } = lastPage;
        if (pagination.page < pagination.totalPages) {
          return pagination.page + 1;
        } else {
          return undefined;
        }
      },
    }
  );
};

export const useListsDataBySubject = (subjectID, explore, name = "") => {
  return useQuery(
    ["get-lists-by-subjects", subjectID, explore, name],
    () => getListBySubject(subjectID, explore, name),
    {
      refetchOnWindowFocus: false,
      enabled: !!subjectID,
    }
  );
};
