import { Check } from "@mui/icons-material";
import { Button, Chip, TableCell, TableRow } from "@mui/material";
import CloneKeywordList from "Components/CloneKeywordList/CloneKeywordList";
import { useDisclosure } from "Utils/hooks";
import { useEffect, useState } from "react";
import { ROLES_TO_SHOW } from "./enums";
import { getUsernameComponent } from "./utils-challenges";
import AnswerChallenge from "./AnswerChallenge";
import SubscriptionNeeded from "Components/SubscriptionNeeded/SubscriptionNeeded";
import { useUserHasSubscription } from "Utils/queries";

const RenderReceivedList = ({ list, refetch, closeChallengesDialog }) => {
  // state
  const [userRole, setUserRole] = useState("");
  const [username, setUsername] = useState("");

  // queries
  const { data: userSubscription, isLoading: subscriptionLoading } =
    useUserHasSubscription();

  // hooks
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: isSubscriptionOpen, onToggle: onToggleSubscription } =
    useDisclosure();
  const { isOpen: isChallengeOpen, onToggle: onChallengeToggle } =
    useDisclosure();

  useEffect(() => {
    if (list && list.userRoles) {
      const { userRoles, from, related } = list;
      let arr = [];
      userRoles.forEach((role) => {
        if (ROLES_TO_SHOW.some((item) => role.includes(item))) {
          arr.push(role);
        }
      });

      const name = getUsernameComponent(from, related);
      setUsername(name);

      setUserRole(arr.join(", "));
    }
  }, [list]);

  const handlePlayChallenge = () => {
    onChallengeToggle();
  };

  const cloneKewordalList = () => {
    if (!userSubscription) {
      onToggleSubscription();
      return;
    }

    onToggle();
  };

  return (
    <>
      <TableRow hover>
        <TableCell>
          <div className="d-flex gap-2 align-items-center">
            {username}
            {userRole && (
              <small className="text-muted text-lowercase">({userRole})</small>
            )}
          </div>
        </TableCell>
        <TableCell>{list.list}</TableCell>
        <TableCell>
          {list.answered ? (
            <span className="text-success">Yes</span>
          ) : (
            <span className="text-danger">No</span>
          )}
        </TableCell>
        <TableCell>
          <Button
            disabled={list.answered}
            onClick={handlePlayChallenge}
            size="small"
            variant="outlined"
          >
            {list.answered ? "Played" : "Play"}
          </Button>
        </TableCell>
        <TableCell>
          {list.listUuidToClone ? (
            <Chip
              title="Clone this list"
              icon={<Check />}
              size="small"
              disabled={subscriptionLoading}
              onClick={cloneKewordalList}
              label="Save"
              variant="outlined"
            />
          ) : (
            <Chip size="small" label="Saved" color="success" />
          )}
        </TableCell>
      </TableRow>

      {isSubscriptionOpen && (
        <SubscriptionNeeded
          open={isSubscriptionOpen}
          toggle={onToggleSubscription}
          cb={closeChallengesDialog}
        />
      )}

      {isChallengeOpen && (
        <AnswerChallenge
          key={`answer-challenge-${list.uuid}`}
          challengeID={list.uuid}
          challengerID={list.fromUuid}
          open={isChallengeOpen}
          close={onChallengeToggle}
          listHref={list.listHref}
          refetchChallenges={refetch}
        />
      )}
      {isOpen && (
        <CloneKeywordList
          open={isOpen}
          setCloneModal={onToggle}
          term={list.list}
          uuid={list.listUuid}
          refetchListData={refetch}
        />
      )}
    </>
  );
};

export default RenderReceivedList;
