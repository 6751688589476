import "./styles.scss";

import React, { memo, useEffect, useState } from "react";

import { Divider, Grid } from "@mui/material";
import FooterLinks from "./FooterLinks";

import SocialLinks from "./SocialLinks";
import PolicesLinks from "./PoliciesLinks";
import dayjs from "dayjs";
import ContactUsLinks from "./ContactUsLinks";
import { useReduxUserProfile } from "Utils/hooks";

const Footer = () => {
  // state
  const [mdScreen, setMdScreen] = useState(3);
  const [renderNewsletter, setRenderNewsletter] = useState(false);

  // redux
  const reduxUser = useReduxUserProfile();

  useEffect(() => {
    if (!reduxUser) {
      setRenderNewsletter(true);
    } else {
      setRenderNewsletter(false);
    }
  }, [reduxUser]);

  useEffect(() => {
    if (!renderNewsletter) {
      setMdScreen(4);
    } else {
      setMdScreen(3);
    }
  }, [renderNewsletter]);

  return (
    <div className="footer">
      <Grid
        container
        spacing={{
          xs: 5,
          sm: 3,
          md: 2,
        }}
        sx={{
          padding: {
            xs: "1rem 1rem",
            sm: "1rem 4rem",
          },
        }}
      >
        <Grid item xs={12}>
          <Divider
            sx={{
              backgroundColor: "#01AAF5",
              height: "2px",
              border: "1px solid #01AAF5",
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={mdScreen} lg={true}>
          <ContactUsLinks renderNewsletter={renderNewsletter} />
        </Grid>
        <Grid item xs={12} sm={6} md={mdScreen} lg={true}>
          <FooterLinks />
        </Grid>
        <Grid item xs={12} sm={6} md={mdScreen} lg={true}>
          <PolicesLinks />
        </Grid>
        <Grid item xs={12} sm={6} md={mdScreen} lg={true}>
          <SocialLinks />
        </Grid>
        <Grid item xs={12}>
          <Divider
            sx={{
              backgroundColor: "#01AAF5",
              height: "2px",
              border: "1px solid #01AAF5",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="center">
            <span style={{ color: "#333" }}>
              © {dayjs().get("year")} Kewordal, All Rights Reserved.
            </span>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(Footer);
