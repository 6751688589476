import { Search } from "@mui/icons-material";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import EmptySearch from "Components/EmptySearch/EmptySearch";
import PostCard from "Components/PostCard/PostCard";
import React from "react";

const PostsTab = ({
  searchQuery,
  setActiveTab,
  postCount,
  listCount,
  peopleCount,
  hasNextPost,
  isFetchingNextPosts,
  refetchPosts,
  fetchNextPosts,
  keywordsCount,
  postsLoading,
  allPosts,
  isFetchingPosts,
  initialRender,
}) => {
  if (initialRender) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ my: 2 }}>
            <h5 className="text-muted">
              <Search /> Type to search...
            </h5>
          </Box>
        </Grid>
      </Grid>
    );
  }

  if (postsLoading || isFetchingNextPosts || isFetchingPosts) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ my: 2 }} className="center">
            <CircularProgress />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <div>
      <span
        style={{
          marginTop: "1rem",
          marginLeft: "5px",
          marginBottom: "1rem",
          display: "inline-block",
          color: "#666666",
        }}
      >
        {searchQuery && `${postCount} results found for "${searchQuery}"`}
      </span>
      <Grid key="keywords-list" container spacing={2}>
        {allPosts.length < 1 ? (
          <Grid item xs={12}>
            <EmptySearch
              tabName="lists"
              setActiveTab={setActiveTab}
              searchQuery={searchQuery}
              posts={postCount}
              people={peopleCount}
              lists={listCount}
              kewords={keywordsCount}
            />
          </Grid>
        ) : (
          allPosts.map((post, idx) => (
            <>
              <Grid item xs={12} key={post.uuid + idx}>
                <PostCard post={post} refetchPosts={refetchPosts} idx={idx} />
              </Grid>
            </>
          ))
        )}
        {hasNextPost && (
          <Grid item xs={12}>
            <div className="mt-1 center">
              <Button disabled={isFetchingNextPosts} onClick={fetchNextPosts}>
                {isFetchingNextPosts ? "Loading..." : "Load More"}
              </Button>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default PostsTab;
