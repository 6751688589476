import "./styles.scss";

import { Avatar } from "@mui/material";

const AboutAuthor = ({ author }) => {
  return (
    <div className="author-div">
      <span>About the Author</span>
      <div className="profile">
        <Avatar src={author?.avatarURL} className="avatar-image" />
        <div className="profile-details">
          <span>{author?.username}</span>
          {/* <span className="about-post">Jun 20, 2022 13:01</span> */}
        </div>
      </div>
      <p className="description">{author?.about}</p>
      {/* <span className="more-posts">More posts by Dr. Mahone</span> */}
    </div>
  );
};

export default AboutAuthor;
