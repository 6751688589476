import "./styles.scss";

import {
  Avatar,
  Button,
  Chip,
  CircularProgress,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  Image,
  List as ListIcon,
  MenuBook,
  Slideshow,
} from "@mui/icons-material";
import { lazy, useRef, useState } from "react";
import { useNotify, useReduxUserProfile } from "Utils/hooks";
// import { HighlightWithinTextarea } from "react-highlight-within-textarea";
// import Highlighter from "react-highlight-words";
import Spinner from "Components/Spinner/Spinner";
import axios from "axios";
import { getToken } from "Shared/baseURL";
import MentionUsers from "Components/MentionUsers/MentionUsers";

// components
const SelectSubject = lazy(() =>
  import("Components/SelectSubject/SelectSubject")
);
const SelectList = lazy(() => import("Components/SelectList/SelectList"));

const CreatePost = ({ refetchPosts }) => {
  // ref
  const videoRef = useRef();
  const imageRef = useRef();

  // state
  const notify = useNotify();
  const [selectSubjectDialog, setSelectSubjectDialog] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectListDialog, setSelectListDialog] = useState(false);
  const [selectedList, setSelectedList] = useState(null);
  const [imageURL, setImageURL] = useState("");
  const [showImageMenu, setShowImageMenu] = useState(null);
  const [showVideoMenu, setShowVideoMenu] = useState(null);
  const [showImageURLField, setShowImageURLField] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [showVideoURLField, setShowVideoURLField] = useState(false);
  const [videoURL, setVideoURL] = useState("");
  const [videoFile, setVideoFile] = useState(null);
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [highlightWords, setHighLightWords] = useState([]);

  // redux
  const userProfile = useReduxUserProfile();

  const handleSelectSubject = () => {
    setSelectSubjectDialog(true);
  };

  const clearAllFields = () => {
    setContent("");
    setImageFile(null);
    setImageURL("");
    setShowImageURLField(false);
    setVideoFile(null);
    setVideoURL("");
    setShowVideoURLField(false);
    setSelectedSubject(null);
    setSelectedList(null);
  };

  const checkModerationFunction = async (res, token) => {
    setIsLoading(true);
    return await axios.get(res?.data?.checkUrl, {
      headers: {
        Authorization: token,
      },
    });
  };

  const handleSubmitPost = async () => {
    try {
      setHighLightWords([]);
      setIsLoading(true);
      let formData = new FormData();
      formData.append("content", content);
      if (selectedSubject)
        formData.append("aboutSubjectHref", selectedSubject.href);
      if (selectedList) formData.append("aboutListHref", selectedList.href);
      if (imageFile) formData.append("hasPhotoUrl", imageFile);
      if (videoFile) formData.append("hasVideoUrl", videoFile);
      if (imageURL) formData.append("hasPhotoUrl", imageURL);
      if (videoURL) formData.append("hasVideoUrl", videoURL.split("?")[0]);
      let token = await getToken();
      const res = await axios.post("/posts", formData, {
        headers: {
          Authorization: token,
        },
      });
      if (res?.status === 201) {
        clearAllFields();
        notify("Post submitted successfully.");
        setTimeout(() => {
          refetchPosts();
        }, 2000);
        setIsLoading(false);
      }
      if (res?.data?.message === "MODERATION_UPLOAD_IN_PROGRESS") {
        const intervalId = await setInterval(async () => {
          setIsLoading(true);
          await checkModerationFunction(res, token)
            .then(async (res) => {
              if (res?.data?.message === "MODERATION_SUCCESSFUL") {
                clearInterval(intervalId);
                clearAllFields();
                notify("Post submitted successfully.");
                setTimeout(() => {
                  refetchPosts();
                }, 2000);
                setIsLoading(false);
              }
            })
            .catch((err) => {
              if (err?.response?.data?.message === "MODERATION_UNSUCCESSFUL") {
                notify(
                  "The content was not uploaded because it was inappropriate.",
                  {
                    variant: "error",
                  }
                );
              } else if (err?.response?.data?.message === "MODERATION_ERROR") {
                notify(
                  "Sorry, we were unable to moderate your video. Please use another video format.",
                  { variant: "error" }
                );
              } else if (err?.response) {
                notify(err?.response?.data?.error, {
                  variant: "error",
                });
              } else {
                notify(err?.message, {
                  variant: "error",
                });
              }
              clearInterval(intervalId);
              clearAllFields();
              setIsLoading(false);
            });
        }, 4000);
      }
    } catch (error) {
      if (error?.response?.data?.message === "MODERATION_UNSUCCESSFUL") {
        handleHightLightWords(error.response.data);

        notify("The content was not uploaded because it was inappropriate.", {
          variant: "error",
        });

        setIsLoading(false);
      } else if (error?.response) {
        notify(error?.response?.data?.message, { variant: "error" });
        setIsLoading(false);
      } else {
        notify(error?.message, { variant: "error" });
        setIsLoading(false);
      }
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleHightLightWords = (errors) => {
    if (Array.isArray(errors.errors) && errors.errors.length > 0) {
      const errorObject = errors.errors[0];
      const { kewordalModerationLabels } = errorObject;
      let arr = [];
      kewordalModerationLabels.forEach((item) => {
        arr.push(item.name);
      });
      setHighLightWords(arr);
    }
  };

  const handleContentChange = (val) => {
    setContent(val);
    setHighLightWords([]);
  };

  const handleImageData = (type) => {
    setShowVideoURLField(false);
    if (type === "link") {
      setVideoURL("");
      setImageFile(null);
      setVideoFile(null);
      setShowImageURLField(true);
    }
    if (type === "image") {
      setImageURL("");
      setVideoURL("");
      setVideoFile(null);
      imageRef.current.click();
      setShowImageURLField(false);
    }
    setShowImageMenu(null);
  };

  const handleVideoData = (type) => {
    setShowImageURLField(false);
    if (type === "link") {
      setImageURL("");
      setVideoFile(null);
      setImageFile(null);
      setShowVideoURLField(true);
    }
    if (type === "image") {
      setVideoURL("");
      setImageURL("");
      setImageFile(null);
      videoRef.current.click();
      setShowVideoURLField(false);
    }
    setShowVideoMenu(null);
  };

  const handleKeyPress = (e) => {
    if (e.ctrlKey && e.keyCode === 13 && content) {
      handleSubmitPost();
    }
  };

  if (isLoading) {
    notify("Uploading in progress, please wait...", {
      action: () => <Spinner size={20} />,
    });
  }

  return (
    <>
      <div className="share-post-component">
        <div className="main-div">
          <Avatar src={userProfile.avatarURL} />
          <div className="input-div">
            {/* <HighlightWithinTextarea
              value={content}
              highlight={highlightWords}
              onChange={(value) => {
                setContent(value);
              }}
            /> */}
            <MentionUsers
              value={content}
              handleChange={handleContentChange}
              handleKeyPress={handleKeyPress}
            />
          </div>
        </div>
        {highlightWords.length > 0 && (
          <div className="mt-4">
            <div className="highlight-words">
              <span>Inappropriate words:</span>
              {highlightWords.map((word) => (
                <span className="highlight-red">{word}</span>
              ))}
            </div>
          </div>
        )}
        <div className="btn-div">
          <Button
            onClick={(e) => setShowImageMenu(e.currentTarget)}
            startIcon={<Image />}
          >
            Photo
          </Button>
          <input
            ref={imageRef}
            type="file"
            accept=".png,.jpeg,.jpg"
            onChange={(e) => setImageFile(e.target.files[0])}
            max={1}
            className="d-none"
          />
          <Button
            onClick={(e) => setShowVideoMenu(e.currentTarget)}
            startIcon={<Slideshow />}
          >
            Video
          </Button>
          <input
            ref={videoRef}
            type="file"
            accept="video/mp4,video/x-m4v,video/*"
            onChange={(e) => setVideoFile(e.target.files[0])}
            max={1}
            className="d-none"
          />
          <Button onClick={handleSelectSubject} startIcon={<MenuBook />}>
            Choose Subject
          </Button>
          <Button
            className={`${!selectedSubject && "disabled"}`}
            disabled={!selectedSubject}
            onClick={() => setSelectListDialog(true)}
            startIcon={<ListIcon />}
          >
            Choose List
          </Button>
        </div>
        <div className="mt-3">
          {showImageURLField && (
            <div className="input-div-fields">
              <input
                value={imageURL}
                accept=".png,.jpeg,.jpg"
                onChange={(e) => setImageURL(e.target.value)}
                placeholder="Enter image link"
              />
            </div>
          )}
          {showVideoURLField && (
            <div className="input-div-fields">
              <input
                value={videoURL}
                onChange={(e) => setVideoURL(e.target.value)}
                placeholder="Enter video link"
              />
            </div>
          )}
          {selectedSubject && (
            <div className="mt-2">
              <Chip
                size="small"
                color="secondary"
                label={selectedSubject.href}
              ></Chip>
              &nbsp;
              {selectedList && (
                <Chip
                  size="small"
                  color="secondary"
                  label={selectedList.href}
                ></Chip>
              )}
            </div>
          )}
        </div>
        <div>{imageFile && <p>{imageFile?.name}</p>}</div>
        <div>{videoFile && <p>{videoFile?.name}</p>}</div>
        <div className="mt-3 d-flex justify-content-end">
          <Button
            endIcon={
              isLoading && <CircularProgress className="text-white" size={18} />
            }
            variant="contained"
            size="small"
            disableElevation
            disabled={isLoading || !content}
            onClick={handleSubmitPost}
          >
            {isLoading ? "" : "Post"}
          </Button>
        </div>
      </div>
      <Menu
        open={Boolean(showImageMenu)}
        anchorEl={showImageMenu}
        onClose={() => setShowImageMenu(null)}
      >
        <MenuItem dense onClick={() => handleImageData("link")}>
          Enter Link
        </MenuItem>
        <MenuItem dense onClick={() => handleImageData("image")}>
          Select File
        </MenuItem>
      </Menu>
      <Menu
        open={Boolean(showVideoMenu)}
        anchorEl={showVideoMenu}
        onClose={() => setShowVideoMenu(null)}
      >
        <MenuItem dense onClick={() => handleVideoData("link")}>
          Enter Link
        </MenuItem>
        {/* <MenuItem dense onClick={() => handleVideoData("image")}>
          Select File
        </MenuItem> */}
      </Menu>
      {selectSubjectDialog && (
        <SelectSubject
          open={selectSubjectDialog}
          toggle={setSelectSubjectDialog}
          selectedSubject={selectedSubject}
          setSelectedSubject={setSelectedSubject}
        />
      )}
      {selectListDialog && (
        <SelectList
          open={selectListDialog}
          selectedList={selectedList}
          setSelectedList={setSelectedList}
          toggle={setSelectListDialog}
          selectedSubject={selectedSubject}
        />
      )}
    </>
  );
};
// test

export default CreatePost;
