import "./styles.scss";

import {
  Avatar,
  AvatarGroup,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Input,
  InputAdornment,
} from "@mui/material";
import {
  ChatBubbleOutline,
  Flag,
  FlagOutlined,
  Send,
  Share,
  ThumbDown,
  ThumbDownOutlined,
  ThumbUp,
  ThumbUpOutlined,
} from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { useNotify, useReduxUserProfile } from "Utils/hooks";

import RenderListComments from "Components/RenderListComments/RenderListComments";
import Spinner from "Components/Spinner/Spinner";
import axios from "axios";
import { getToken } from "Shared/baseURL";
import { useListCommentsData } from "Utils/comments";
import ReportList from "Components/ReportList/ReportList";
import ShareList from "Components/ShareList/ShareList";

const ListComments = ({
  listID,
  commentsInView,
  likes,
  reportedBy,
  refetchListData,
  dislikes,
}) => {
  // ref
  const commentInputRef = useRef();

  // state
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [textForLikes, setTextForLikes] = useState("");
  const [isReportedByMe, setIsReportedByMe] = useState(false);
  const [isLikedByMe, setIsLikedByMe] = useState(false);
  const [isDislikedByMe, setIsDislikedByMe] = useState(false);
  const [reportedByMe, setReportedByMe] = useState(false);
  const [reportListDialog, setReportListDialog] = useState(false);
  const [shareList, setShareList] = useState(false);

  // redux
  const reduxUser = useReduxUserProfile();

  // queries
  const {
    isLoading: commentsLoading,
    data: commentsData,
    refetch,
    hasNextPage: hasNextComments,
    fetchNextPage: fetchNextComments,
    isFetchingNextPage: isFetchingNextComments,
  } = useListCommentsData(listID, commentsInView);

  useEffect(() => {
    let likesLength = likes.length;
    let text = "";
    switch (likes.length) {
      case 0: {
        text = "0 liked this.";
        break;
      }
      case 1: {
        let firstUser = likes[0];
        text = `${firstUser.username} liked this.`;
        break;
      }
      default: {
        let firstUser = likes[0];
        text = `${firstUser.username} and ${likesLength - 1} other liked this.`;
        break;
      }
    }
    setTextForLikes(text);
  }, [likes]);

  useEffect(() => {
    if (Array.isArray(likes)) {
      setIsLikedByMe(likes?.some((item) => item.uuid === reduxUser.uuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [likes]);

  useEffect(() => {
    if (Array.isArray(dislikes)) {
      setIsDislikedByMe(dislikes?.some((item) => item.uuid === reduxUser.uuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dislikes]);

  useEffect(() => {
    if (Array.isArray(reportedBy)) {
      setIsReportedByMe(
        reportedBy?.some((item) => item.uuid === reduxUser.uuid)
      );
      setReportedByMe(reportedBy?.some((item) => item === reduxUser.uuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportedBy, isReportedByMe]);

  const handlePostComment = async () => {
    if (!comment) {
      return;
    }
    try {
      setIsLoading(true);
      const token = await getToken();
      await axios.post(
        `lists/${listID}/comments`,
        { comment },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify("Comment posted.");
      setComment("");
      refetch();
    } catch (error) {
      if (error?.response?.data?.message === "MODERATION_UNSUCCESSFUL") {
        notify("The content was not uploaded because it was inappropriate.", {
          variant: "error",
        });
      } else {
        notify(error.message, { variant: "error" });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeComment = (e) => {
    setComment(e.target.value);
  };

  const handleClickCommentBtn = () => {
    commentInputRef.current.scrollIntoView({ block: "center" });
    commentInputRef.current.focus();
  };

  // like list
  const handleLikeList = async () => {
    try {
      const token = await getToken();
      await axios.post(
        `lists/${listID}/likes`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (isDislikedByMe) {
        await handleUndoDislikeList(false);
      }
      notify("List liked.");
      await refetchListData();
    } catch (error) {
      notify(error.message, { variant: "error" });
    }
  };

  // dislike list
  const handleDislikeList = async () => {
    try {
      const token = await getToken();
      await axios.post(
        `lists/${listID}/dislikes`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (isLikedByMe) {
        await handleUnlikeList(false);
      }
      notify("List Disliked.");
      await refetchListData();
    } catch (error) {
      notify(error.message, { variant: "error" });
    }
  };
  // undo like
  const handleUnlikeList = async (showNotify = false) => {
    try {
      const token = await getToken();
      await axios.delete(`lists/${listID}/likes`, {
        headers: {
          Authorization: token,
        },
      });
      if (showNotify) {
        notify("List unliked.");
      }
      await refetchListData();
    } catch (error) {
      notify(error.message, { variant: "error" });
    }
  };
  // undo dislikes
  const handleUndoDislikeList = async (showNotify = false) => {
    try {
      const token = await getToken();
      await axios.delete(`lists/${listID}/dislikes`, {
        headers: {
          Authorization: token,
        },
      });
      if (showNotify) {
        notify("Undo Disliked.");
      }
      await refetchListData();
    } catch (error) {
      notify(error.message, { variant: "error" });
    }
  };

  const handleShare = () => {
    setShareList(true);
  };

  const handlePressEnter = (e) => {
    if (e.key === "Enter") handlePostComment();
  };

  if (commentsLoading) {
    return <Spinner size={25} />;
  }

  return (
    <div className="list-comments-component">
      <Divider />
      <div className="info-div">
        <div className="avatar-div">
          <AvatarGroup max={4}>
            {likes.map((item, idx) => (
              <Avatar
                key={item.username + idx}
                alt={item.username}
                src={item.avatarURL}
              />
            ))}
          </AvatarGroup>
          <span>{textForLikes}</span>
        </div>
        <div className="comments-reposts">
          <span>{commentsData?.pages[0].pagination.total} comments</span>
        </div>
      </div>
      <Divider />
      <div className="btn-div">
        {isLikedByMe ? (
          <Button
            title="Dislike"
            startIcon={<ThumbUp />}
            className="liked-btn"
            onClick={() => handleUnlikeList(true)}
          >
            <span className="btn-text">Liked</span>
          </Button>
        ) : (
          <Button
            title="Like"
            startIcon={<ThumbUpOutlined />}
            onClick={handleLikeList}
          >
            <span className="btn-text">Like</span>
          </Button>
        )}
        {isDislikedByMe ? (
          <Button
            title="Disliked"
            startIcon={<ThumbDown />}
            className="disliked-btn"
            // sx={{ pointerEvents: "none" }}
            onClick={() => handleUndoDislikeList(true)}
          >
            <span className="btn-text">Disliked</span>
          </Button>
        ) : (
          <Button
            title="Dislike"
            startIcon={<ThumbDownOutlined />}
            onClick={handleDislikeList}
            className="dislike-btn"
          >
            <span className="btn-text">Dislike</span>
          </Button>
        )}
        <Button
          startIcon={<ChatBubbleOutline />}
          onClick={handleClickCommentBtn}
        >
          <span className="btn-text">Comment</span>
        </Button>
        <Button startIcon={<Share />} onClick={handleShare}>
          <span className="btn-text">Share</span>
        </Button>
        {isReportedByMe || reportedByMe ? (
          <Button startIcon={<Flag />} className="report-btn">
            <span className="btn-text">Reported</span>
          </Button>
        ) : (
          <Button
            startIcon={<FlagOutlined />}
            onClick={() => setReportListDialog(!reportListDialog)}
          >
            <span className="btn-text">Report</span>
          </Button>
        )}
      </div>
      <Divider />
      <div className="comments-list custom-scroll-thin">
        {commentsData?.pages.map((pagesData) =>
          pagesData?.comments.map((item) => (
            <>
              <RenderListComments
                key={item.uuid}
                commentID={item.uuid}
                comment={item.comment}
                name={item.user.username}
                date={item.createdAt}
                image={item.user.avatarURL}
                item={item}
                userID={item.user.username}
                refetch={refetch}
              />
              <Divider className="comment-divider" />
            </>
          ))
        )}
      </div>
      {hasNextComments && !isFetchingNextComments && (
        <div className="center">
          <Button size="small" color="secondary" onClick={fetchNextComments}>
            Load More
          </Button>
        </div>
      )}
      {isFetchingNextComments && <Spinner size={20} />}
      <div className="post-comment-div">
        <Input
          ref={commentInputRef}
          value={comment}
          onChange={handleChangeComment}
          onKeyUp={handlePressEnter}
          placeholder="Write a comment"
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                disabled={!comment}
                color="primary"
                size="small"
                aria-label="toggle password visibility"
                onClick={handlePostComment}
                edge="end"
              >
                {isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <Send sx={{ rotate: "-45deg" }} />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
      {reportListDialog && (
        <ReportList
          listID={listID}
          open={reportListDialog}
          toggle={setReportListDialog}
          refetchListData={refetchListData}
        />
      )}
      {shareList && (
        <ShareList listID={listID} open={shareList} toggle={setShareList} />
      )}
    </div>
  );
};

export default ListComments;
