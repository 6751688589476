import "./styles.scss";

import { AnimatePresence, motion } from "framer-motion";
import { Box, Grid, Paper } from "@mui/material";
import React, { useState } from "react";
import NetworkCard from "Components/NetworkCard/NetworkCard";
import Spinner from "Components/Spinner/Spinner";
import { useReduxUserProfile } from "Utils/hooks";
import { useSpecificUserData } from "Utils/queries";
import Advertisement from "Components/Advertisement/Advertisement";

const animation = {
  initial: { opacity: 0, x: 50 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: 50 },
};

const Network = () => {
  // state
  const [activeTab, setActiveTab] = useState("connections");

  // redux
  const userRedux = useReduxUserProfile();

  // queries
  const {
    data,
    isLoading,
    refetch: refetchUserData,
  } = useSpecificUserData(userRedux.uuid);

  const handleTabs = (type) => {
    setActiveTab(type);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Box>
      <Grid container spacing={2} className="main">
        <Grid item xs={12} sm={3.5}>
          <Paper sx={{ borderRadius: 4 }}>
            <div className=" p-2 main-div">
              <div
                className={` d-flex justify-content-between align-items-center p-2 my-2 rounded hover-div ${
                  activeTab === "connections" && "active"
                } `}
                onClick={() => handleTabs("connections")}
              >
                <span className="">Connections</span>
                <span>{data?.connections?.length}</span>
              </div>
              <div
                className={` d-flex justify-content-between align-items-center p-2 my-2 rounded hover-div ${
                  activeTab === "following" && "active"
                } `}
                onClick={() => handleTabs("following")}
              >
                <span>Following</span>
                <span>{data?.following?.length}</span>
              </div>
              <div
                className={` d-flex justify-content-between align-items-center p-2 my-2 rounded hover-div ${
                  activeTab === "followers" && "active"
                } `}
                onClick={() => handleTabs("followers")}
              >
                <span>Followers</span>
                <span>{data?.followers?.length}</span>
              </div>
              <div
                className={` d-flex justify-content-between align-items-center p-2 my-2 rounded hover-div ${
                  activeTab === "suggestions" && "active"
                } `}
                onClick={() => handleTabs("suggestions")}
              >
                <span>Suggestions</span>
              </div>
            </div>
          </Paper>
          <div className="mt-3">
            <Advertisement />
          </div>
        </Grid>
        <Grid item xs={12} sm={8.5}>
          <AnimatePresence mode="wait">
            <motion.div
              key={activeTab}
              initial={animation.initial}
              animate={animation.animate}
              exit={animation.exit}
            >
              <NetworkCard
                data={data}
                activeTab={activeTab}
                refetchUserData={refetchUserData}
              />
            </motion.div>
          </AnimatePresence>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Network;
