import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect } from "react";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const StripePayment = ({ clientSecret, closePayment }) => {
  useEffect(() => {
    if (clientSecret) {
    }
  }, [clientSecret]);

  const onClose = (status) => {
    closePayment(status);
  };

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
      }}
    >
      <CheckoutForm onClose={onClose} />
    </Elements>
  );
};

export default StripePayment;
