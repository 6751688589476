/* eslint-disable no-loop-func */

import "./styles.scss";

import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { cloneDeep, random, shuffle } from "lodash";
import { Close } from "@mui/icons-material";
import RelativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import { fetchKewordsByListID } from "../../Pages/ViewList/Api";
import { useNotify } from "Utils/hooks";
import GameResults from "Components/PlayGame/GameResults";
import { PostGame } from "Components/PlayGame/Api";

dayjs.extend(RelativeTime);

const AnswerChallenge = ({
  open,
  close,
  listHref,
  challengeID,
  challengerID,
  refetchChallenges,
}) => {
  // state
  const [isLoading, setIsLoading] = useState(false);
  const [allQuestions, setAllQuestions] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [disableOptions, setDisableOptions] = useState(false);
  const [startTime] = useState(dayjs());
  const [timePassed, setTimePassed] = useState("");
  const [showTime, setShowTime] = useState(true);
  const [gameResults, setGameResults] = useState(null);

  // hooks
  const notify = useNotify();

  useEffect(() => {
    if (allQuestions.length > 0) {
      let timeInterval = setInterval(() => {
        const time = dayjs().diff(startTime, "seconds");
        setTimePassed(time);
      }, 1000);
      return () => {
        clearInterval(timeInterval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allQuestions]);

  useEffect(() => {
    getAllKeywords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllKeywords = async () => {
    setIsLoading(true);
    try {
      const res = await fetchKewordsByListID(listHref);
      if (res.message) {
        throw new Error(res.message);
      }
      const { keywords } = res.data;
      let arr = keywords.filter(
        (item) => item.uuid && item.term && item.question
      );
      arr = arr.map((item) => ({
        ...item,
        selectedAnswer: "",
        selectedAnswerUuid: "",
      }));
      generateQuestions(arr);
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const generateQuestions = (keywords = []) => {
    if (keywords.length < 4) {
      notify("Must have at least 4 keywords to play game.", {
        variant: "info",
      });
      handleClose();
      return;
    }

    let filteredKeywords = cloneDeep(keywords);
    let allFilteredKeywords = cloneDeep(keywords);

    // random questions
    let randomQuestions = [];
    if (filteredKeywords.length >= 4 && filteredKeywords.length <= 7) {
      let count = 0;
      while (count < allFilteredKeywords.length) {
        let randomNum = random(0, filteredKeywords.length - 1);
        let arr = cloneDeep(randomQuestions);
        arr = [...arr, { ...filteredKeywords[randomNum] }];
        randomQuestions = cloneDeep(arr);
        filteredKeywords = filteredKeywords.filter(
          (item) => item.uuid !== filteredKeywords[randomNum].uuid
        );
        count++;
      }
    } else {
      for (let i = 0; i < 7; i++) {
        let randomNum = random(0, filteredKeywords.length - 1);
        let arr = cloneDeep(randomQuestions);
        arr = [...arr, { ...filteredKeywords[randomNum] }];
        randomQuestions = cloneDeep(arr);
        filteredKeywords = filteredKeywords.filter(
          (item) => item.uuid !== filteredKeywords[randomNum].uuid
        );
      }
    }

    let answers = cloneDeep(keywords);
    answers = answers.map((item) => ({
      term: item.term,
      uuid: item.uuid,
      correct: null,
      wrong: null,
      showCorrect: false,
    }));

    for (let i = 0; i < randomQuestions.length; i++) {
      const uniqNumbersArray = generateUniqueNumbersArray(
        randomQuestions[i],
        answers
      );

      let arr = [
        uniqNumbersArray[0],
        uniqNumbersArray[1],
        uniqNumbersArray[2],
        {
          term: randomQuestions[i]?.term,
          uuid: randomQuestions[i]?.uuid,
          correct: null,
          wrong: null,
          showCorrect: false,
        },
      ];
      randomQuestions[i].answers = shuffle(arr);
    }
    setAllQuestions(randomQuestions);
  };

  const generateUniqueNumbersArray = (question, answers) => {
    let cloneQuestion = cloneDeep(question);
    let filteredAnswers = cloneDeep(answers);
    filteredAnswers = filteredAnswers.filter(
      (item) => item.uuid !== cloneQuestion.uuid
    );
    let num1 = filteredAnswers[0];
    let num2 = filteredAnswers[1];
    let num3 = filteredAnswers[2];
    return [num1, num2, num3];
  };

  const handleAnswerSelect = (answer, idx) => {
    setDisableOptions(true);
    let arr = cloneDeep(allQuestions);
    arr[currentIndex].selectedAnswer = answer.term;
    arr[currentIndex].selectedAnswerUuid = answer.uuid;
    if (arr[currentIndex].uuid === answer.uuid) {
      let answerArr = cloneDeep(arr[currentIndex].answers);
      answerArr[idx].correct = true;
      arr[currentIndex].answers = answerArr;
      arr[currentIndex].correct = true;
    } else {
      let answerArr = cloneDeep(arr[currentIndex].answers);
      answerArr = answerArr.map((item) => ({ ...item, showCorrect: true }));
      answerArr[idx].wrong = true;
      arr[currentIndex].answers = answerArr;
      arr[currentIndex].correct = false;
    }
    setAllQuestions(arr);
    if (currentIndex < arr.length) {
      let newIndex = currentIndex + 1;
      if (newIndex === arr.length) {
        setTimeout(() => {
          submitGame(arr);
        }, 750);
      } else {
        setTimeout(() => {
          setCurrentIndex(newIndex);
          setDisableOptions(false);
        }, 750);
      }
    }
  };

  const submitGame = async (data) => {
    let arr = [...data];
    let newArr = [];
    let i = 1;
    arr.forEach((item) => {
      let selectedAns = item.answers.map((answer) => answer.uuid).join("#");
      let str = `${i}#${item.correct}#${item.uuid}#${selectedAns}`;
      i++;
      newArr.push(str);
    });
    let postData = {
      listHref,
      time: timePassed,
      keywordUuid: newArr,
    };
    if (challengeID) {
      postData.challengeUuid = challengeID;
    }
    setIsLoading(true);
    try {
      setShowTime(false);
      const res = await PostGame(postData);
      if (res.message) {
        throw new Error(res.message);
      }
      notify("Game posted.");
      setGameResults(res.data);
      if (refetchChallenges) {
        refetchChallenges();
      }
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    close();
  };

  return (
    <Box className="play-game-component">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="view-score-dialog"
        fullWidth
        maxWidth={gameResults ? "sm" : "lg"}
        PaperProps={{
          sx: {
            borderRadius: "10px",
          },
        }}
      >
        {gameResults ? (
          <GameResults
            challengeUserID={challengerID}
            gameResults={gameResults}
            handleClose={handleClose}
            gameID={gameResults.uuid}
            showConnect
          />
        ) : (
          <Box>
            <Box className="game-title-box">
              <span>
                Question {currentIndex + 1} / {allQuestions.length}
              </span>
              <Box className="center">
                {timePassed && showTime && (
                  <Typography
                    sx={{ mr: { xs: 5, md: 15, lg: 30 } }}
                    className="fw-400"
                  >
                    Timer: {timePassed}s
                  </Typography>
                )}
                <IconButton size="large" onClick={handleClose}>
                  <Close fontSize="large" sx={{ color: "black" }} />
                </IconButton>
              </Box>
            </Box>
            <DialogContent>
              <Box sx={{ my: 2 }}>
                {isLoading ? (
                  <Box className="center">
                    <CircularProgress />
                  </Box>
                ) : (
                  allQuestions.length > 0 && (
                    <Grid container>
                      <Grid item xs={12} md={8}>
                        <Box className="game-question-box">
                          {allQuestions[currentIndex]?.question}
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <p className="answer-desc">
                              SELECT THE <span>ONE </span> CORRECT ANSWER
                            </p>
                          </Grid>
                          {allQuestions[currentIndex]?.answers.map(
                            (item, idx) => (
                              <Grid item xs={6}>
                                <Box
                                  onClick={() => handleAnswerSelect(item, idx)}
                                  className={`game-answer-box ${
                                    item?.correct === true && "right-answer"
                                  } ${item?.wrong === true && "wrong-answer"} ${
                                    allQuestions[currentIndex].uuid ===
                                      item.uuid &&
                                    item.showCorrect &&
                                    "show-correct-answer"
                                  } ${disableOptions && "disable-options"}`}
                                >
                                  {item?.term}
                                </Box>
                              </Grid>
                            )
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                )}
              </Box>
            </DialogContent>
          </Box>
        )}
      </Dialog>
    </Box>
  );
};

export default AnswerChallenge;
