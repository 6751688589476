import "./header.scss";

import { Box } from "@mui/material";
import { memo } from "react";
import { useNavigate } from "react-router-dom";

import Logo from "assets/images/logo.svg";
import UserHeader from "./UserHeader";
import LoggedOutLinks from "./LoggedOutLinks";

const Header = ({ drawerWidth, user }) => {
  // router
  const navigate = useNavigate();

  const handleLogoClick = () => {
    if (user) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  };

  return (
    <Box
      sx={{
        padding: {
          xs: "0 1rem",
          md: "0 4rem",
        },
      }}
      className="header-box"
    >
      <Box className="header-title-btn">
        <img
          src={Logo}
          onClick={handleLogoClick}
          alt="Logo"
          className="logo-image"
        />
        {/* <small>(Beta)</small> */}
      </Box>
      {user && <UserHeader drawerWidth={drawerWidth} />}
      {!user && <LoggedOutLinks />}
    </Box>
  );
};

export default memo(Header);
