import { Container } from "@mui/material";
import React from "react";
import { useReduxUserProfile } from "Utils/hooks";

const BackgroundImage = () => {
  // redux
  const reduxUser = useReduxUserProfile();

  return (
    <div className="background-image">
      <Container className="inner-container">
        <span className="text-title">
          {reduxUser?.displayName || "John Doe"}
        </span>
        <div className="round-image" />
      </Container>
    </div>
  );
};

export default BackgroundImage;
