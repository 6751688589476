import "./login.scss";

import * as Yup from "yup";

import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
} from "@mui/material";
import {
  Close,
  FacebookOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
  Google as GoogleIcon,
} from "@mui/icons-material";
import { Field, Form, Formik } from "formik";
import React, { Suspense, lazy, useState } from "react";
import { getProfile, userLogin } from "../../Redux/Actions";
import { handleFacebookLogin, requestGoogleLogin, requestLogin } from "./api";
import { useNavigate, useSearchParams } from "react-router-dom";

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import InfoIcon from "../../assets/icons/info.png";
import LoadingFallback from "../../Components/LoadingFallback/LoadingFallback";
import Logo from "../../assets/images/logo1.svg";
import { TextField } from "formik-mui";
// import GoogleIcon from "../../assets/icons/google.png";
// import TwitterIcon from "../../assets/icons/twitter-shade.png";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import axios from "axios";
import { getClientToken } from "Shared/baseURL";
import { useGoogleLogin } from "@react-oauth/google";

// components
const ResetDialog = lazy(() =>
  import("Components/ResetPasswordDialog/ResetPasswordDialog")
);
// const RegisterDialog = lazy(() => import("../../Components/Register/Register"));

const LoginSchema = Yup.object().shape({
  username: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
});

const Login = () => {
  //state
  const { enqueueSnackbar: notify } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resetPasswordDialog, setResetPasswordDialog] = useState(false);
  const [facebookLoading, setFacebookLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    type: "info",
    message: "",
  });

  // router
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // redux
  const dispatch = useDispatch();

  // google login
  const googleLogin = useGoogleLogin({
    flow: "implicit",
    scope: [
      "https://www.googleapis.com/auth/user.gender.read",
      "https://www.googleapis.com/auth/user.birthday.read",
    ].join(" "),
    onSuccess: handleGoogleLogin,
    onError: () => {
      notify("Google login failed", { variant: "error" });
    },
  });

  async function handleGoogleLogin(tokenResponse) {
    try {
      setIsLoading(true);
      const { access_token } = tokenResponse;
      const res = await requestGoogleLogin(access_token);
      const { access_token: accessToken } = res;
      const userData = await dispatch(getProfile(accessToken));
      const { emailValidated, status } = userData;
      if (emailValidated) {
        if (
          status === "USER_DISAPPROVED" ||
          status === "WRONG_STATUS_DISAPPROVED"
        ) {
          setAlertMessage({
            type: "info",
            message: (
              <div>
                Unfortunately you are not allowed to use our platform at this
                stage due to a breach of our Terms and Conditions. To resolve
                this, please
                <span
                  onClick={() => navigate("/contact-us")}
                  className="cursor-pointer ps-1 fw-bold text-decoration-underline"
                >
                  contact us.
                </span>
              </div>
            ),
          });
        } else {
          localStorage.setItem("refreshToken", res.refresh_token);
          localStorage.setItem("userToken", res.access_token);
          localStorage.setItem("logTime", dayjs().format());
          handleNavigateToDashboard(res);
        }
      }
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  }

  const handleLogin = async (values, setSubmitting) => {
    try {
      setAlertMessage({ type: "info", message: "" });
      const res = await requestLogin(values);
      if (!(res.status >= 200 && res.status < 300)) {
        if (res.status === 400 && res?.message === "Bad credentials") {
          throw new Error("Email or password is invalid.");
        }
        throw new Error(`${res.status}: ${res.message}`);
      }
      const data = res.data;
      const userData = await dispatch(getProfile(data.access_token));
      const { emailValidated, status } = userData;
      if (emailValidated) {
        if (
          status === "USER_DISAPPROVED" ||
          status === "WRONG_STATUS_DISAPPROVED"
        ) {
          setAlertMessage({
            type: "info",
            message: (
              <div>
                Unfortunately you are not allowed to use our platform at this
                stage due to a breach of our Terms and Conditions. To resolve
                this, please
                <span
                  onClick={() => navigate("/contact-us")}
                  className="cursor-pointer ps-1 fw-bold text-decoration-underline"
                >
                  contact us.
                </span>
              </div>
            ),
          });
        } else {
          localStorage.setItem("refreshToken", data.refresh_token);
          localStorage.setItem("userToken", data.access_token);
          localStorage.setItem("logTime", dayjs().format());
          handleNavigateToDashboard(data);
        }
      } else {
        setAlertMessage({
          type: "info",
          message: (
            <div>
              Your account has not been activated. Please check your email and
              click the activation link.
              <span
                onClick={() => resendActivationLink(values.username)}
                className="cursor-pointer ps-1 fw-bold text-decoration-underline"
              >
                Resend Link
              </span>
            </div>
          ),
        });
      }
    } catch (error) {
      setAlertMessage({
        type: "error",
        message: error.message,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const resendActivationLink = async (userEmail) => {
    if (!userEmail) return;
    try {
      setIsLoading(true);
      setAlertMessage({
        type: "info",
        message: "",
      });
      const token = await getClientToken();
      await axios.post(
        `/users/${userEmail}/resend-activation-link`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAlertMessage({
        type: "info",
        message: "New activation link has been sent to your email.",
      });
    } catch (error) {
      setAlertMessage({
        type: "error",
        message: error.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = () => {
    setResetPasswordDialog(true);
  };

  const responseFacebook = (response) => {
    setAlertMessage({ type: "info", message: "" });
    if (!response.accessToken) {
      setAlertMessage({
        type: "error",
        message: "Something went wrong.",
      });
    } else {
      handleLoginContinueFacebook(response.accessToken);
    }
  };

  const handleLoginContinueFacebook = async (facebookToken) => {
    try {
      setFacebookLoading(true);
      const res = await handleFacebookLogin(facebookToken);
      if (res.message) {
        throw new Error(res.message);
      }
      const data = res.data;
      const userData = await dispatch(getProfile(data.access_token));
      const { emailValidated, status } = userData;
      if (emailValidated) {
        if (
          status === "USER_DISAPPROVED" ||
          status === "WRONG_STATUS_DISAPPROVED"
        ) {
          setAlertMessage({
            type: "info",
            message: (
              <div>
                Unfortunately you are not allowed to use our platform at this
                stage due to a breach of our Terms and Conditions. To resolve
                this, please
                <span
                  onClick={() => navigate("/contact-us")}
                  className="cursor-pointer ps-1 fw-bold text-decoration-underline"
                >
                  contact us.
                </span>
              </div>
            ),
          });
        } else {
          localStorage.setItem("refreshToken", data.refresh_token);
          localStorage.setItem("userToken", data.access_token);
          localStorage.setItem("logTime", dayjs().format());
          handleNavigateToDashboard(data);
        }
      } else {
        notify(
          "Your account has not been activated. Please check your email and click the activation link.",
          { variant: "error" }
        );
        setAlertMessage({
          type: "info",
          message:
            "Your account has not been activated. Please check your email and click the activation link.",
        });
      }
    } catch (error) {
      setAlertMessage({
        type: "error",
        message: error.message,
      });
    } finally {
      setFacebookLoading(false);
    }
  };

  const handleNavigateToDashboard = (data) => {
    let redirectURL = searchParams.get("redirectURL");
    if (redirectURL) {
      navigate(redirectURL);
    } else {
      navigate("/dashboard");
    }
    notify("Logged in.");
    dispatch(userLogin(data));
  };

  const handleKeyPress = (event, submitForm) => {
    if (event.code === "Enter") {
      submitForm();
    }
  };

  return (
    <Box className="login">
      <Container>
        <Grid container rowSpacing={2}>
          <Grid item xs={12} md={6} className="d-flex justify-content-center">
            <Box sx={{ display: "flex" }}>
              <Box className="carousel-box">
                <span className="title-big black">IMPROVE</span>
                <br />
                <span className="title-small black">
                  YOUR CHANCES OF ATTAINING THE
                </span>
                <br />
                <span className="title-big black">HIGHEST GRADES</span> <br />
                <span className="title-small black">
                  IN YOUR EXAMS AND TESTS BY JOINING
                </span>
                <br />
                <img
                  src={Logo}
                  alt="logo"
                  loading="lazy"
                  className="logo-image"
                />
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: {
                      xs: "column",
                      md: "row",
                    },
                  }}
                >
                  <span className="black shade fw-300">REVISION</span>
                  <Box
                    sx={{ display: { xs: "none", md: "block" } }}
                    className="divider"
                  />
                  <span className="black shade fw-500">SOCIAL</span>
                  <Box
                    sx={{ display: { xs: "none", md: "block" } }}
                    className="divider"
                  />
                  <span className="black shade fw-700">LEARNING</span>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Suspense fallback={<LoadingFallback />}>
            <Grid item xs={12} md={6} className="d-flex justify-content-center">
              <Box className="form-box">
                <Formik
                  initialValues={{ username: "", password: "" }}
                  validationSchema={LoginSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    handleLogin(values, setSubmitting);
                  }}
                >
                  {({ isSubmitting, submitForm }) => (
                    <Form
                      onKeyUp={(e) => handleKeyPress(e, submitForm)}
                      autoComplete="off"
                    >
                      <Box marginY={3}>
                        <Field
                          component={TextField}
                          name="username"
                          // placeholder="Login..."
                          label="Email"
                          fullWidth
                          autoComplete="off"
                          size="small"
                        />
                      </Box>
                      <Box marginY={3}>
                        <Field
                          component={TextField}
                          name="password"
                          type={showPassword ? "text" : "password"}
                          autoComplete="off"
                          label="Password"
                          fullWidth
                          size="small"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPassword(!showPassword)}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOffOutlined />
                                  ) : (
                                    <VisibilityOutlined />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <Box
                        marginY={2}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          disabled={
                            isSubmitting || facebookLoading || isLoading
                          }
                          variant="contained"
                          className="blue"
                          size="large"
                          onClick={submitForm}
                        >
                          Login
                        </Button>
                        <Button
                          disabled={
                            isSubmitting || facebookLoading || isLoading
                          }
                          variant="contained"
                          className="grey"
                          size="large"
                          onClick={() => navigate("/register")}
                        >
                          Register
                        </Button>
                      </Box>
                      {(isSubmitting || facebookLoading || isLoading) && (
                        <Box mb={1}>
                          <LinearProgress />
                        </Box>
                      )}
                      {alertMessage.message && (
                        <Alert
                          action={
                            <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                setAlertMessage((oldState) => ({
                                  ...oldState,
                                  message: "",
                                }));
                              }}
                            >
                              <Close fontSize="inherit" />
                            </IconButton>
                          }
                          variant="filled"
                          severity={alertMessage.type}
                          sx={{ mb: 2 }}
                        >
                          {alertMessage.message}
                        </Alert>
                      )}
                    </Form>
                  )}
                </Formik>
                <span className="fw-400 text-center fs-6 d-block mb-2">
                  Login with:
                </span>
                <Box className="center gap-3">
                  <GoogleIcon
                    onClick={googleLogin}
                    sx={{ color: "#2E3A59", cursor: "pointer" }}
                  />
                  <FacebookLogin
                    appId={process.env.REACT_APP_FACEBOOK_API_KEY}
                    autoLoad={false}
                    callback={responseFacebook}
                    scope="public_profile, email, user_birthday"
                    fields="name, email, picture"
                    render={(renderProps) => (
                      <FacebookOutlined
                        onClick={renderProps.onClick}
                        className="icons"
                        sx={{ mr: "1rem", color: "#2E3A59" }}
                      />
                    )}
                  />
                  {/* <img
                    src={GoogleIcon}
                    alt="google-icon"
                    loading="lazy"
                    className="icons"
                  />
                  <img
                    src={TwitterIcon}
                    alt="twitter-icon"
                    loading="lazy"
                    className="icons"
                  /> */}
                </Box>
                <p className="fw-400 text-center fs-5 mt-3">
                  <img
                    src={InfoIcon}
                    onClick={handleResetPassword}
                    alt="info-icon"
                    loading="lazy"
                    className="icons"
                  />
                  <span
                    onClick={handleResetPassword}
                    className="cursor-pointer"
                  >
                    FORGOT PASSWORD?
                  </span>
                </p>
              </Box>
            </Grid>
          </Suspense>
        </Grid>
      </Container>
      {/* Dialogs */}
      {resetPasswordDialog && (
        <ResetDialog
          open={resetPasswordDialog}
          toggleDialog={setResetPasswordDialog}
        />
      )}
    </Box>
  );
};

export default Login;
