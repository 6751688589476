import { CiMail } from "react-icons/ci";
import Subscribe from "Components/SubscribeNewsletter/SubscribeNewsletter";

const ContactUsLinks = ({ renderNewsletter }) => {
  return (
    <div style={{ color: "#333" }} className="social-links">
      <p className="footer-heading">Contact Us</p>
      <a
        href="mailto:info@kewordal.com"
        style={{
          display: "flex",
          alignItems: "center",
          gap: ".6rem",
          textDecoration: "none",
          marginBottom: "10px",
          marginTop: "10px",
          color: "#333",
        }}
      >
        <CiMail color="#01AAF5" /> info@kewordal.com
      </a>
      {renderNewsletter && (
        <>
          <p style={{ color: "#333", fontSize: ".9rem" }}>
            Subscribe to our newsletter to get regular updates
          </p>
          <div>
            <Subscribe isFooter />
          </div>
        </>
      )}
    </div>
  );
};

export default ContactUsLinks;
