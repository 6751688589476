import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { HINTS_DATA } from "./text-data";
import ReactPlayer from "react-player";

const DashboardTutorials = ({ open, toggle, hint }) => {
  // state
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [videoURL, setVideoURL] = useState("");

  useEffect(() => {
    if (hint) {
      const data = HINTS_DATA[hint];
      if (data) {
        setTitle(data.title);
        setContent(data.content);
        setVideoURL(data.videoURL);
      }
    }
  }, [hint]);

  const onClose = () => {
    setTitle("");
    setContent("");
    toggle(false);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div>{content}</div>
        </DialogContentText>
        {videoURL && (
          <div className="mt-3">
            <ReactPlayer
              url={videoURL}
              width="100%"
              height={300}
              controls
              playing
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DashboardTutorials;
