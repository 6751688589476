const { Add } = require("@mui/icons-material");
const { Box, Button } = require("@mui/material");
const { default: AddList } = require("Components/AddList/AddList");
const { useState } = require("react");
const { useNavigate } = require("react-router-dom");

const AddListController = ({ refetchListData, selectedSubject }) => {
  // state
  const [addListDialog, setAddListDialog] = useState(false);

  // router
  const navigate = useNavigate();

  const handleViewDeletedLists = () => {
    navigate("/deleted-history");
  };

  const handleAddList = () => {
    setAddListDialog(true);
  };

  return (
    <Box className="center gap-2" sx={{ my: 3 }}>
      <Button
        onClick={handleAddList}
        // size="small"
        variant="contained"
        startIcon={<Add />}
      >
        Add New List
      </Button>
      <Button
        onClick={handleViewDeletedLists}
        // size="small"
        color="secondary"
        variant="contained"
      >
        Deleted History
      </Button>
      <AddList
        open={addListDialog}
        close={setAddListDialog}
        refresh={() => refetchListData()}
        selectedSubject={selectedSubject}
      />
    </Box>
  );
};

export default AddListController;
