const BlogEllipsis = ({ text, length = 250 }) => {
  if (text.length > 150) {
    return (
      <p
        className="description"
        dangerouslySetInnerHTML={{ __html: `${text.substring(0, length)}...` }}
      />
    );
  } else {
    return (
      <p className="description" dangerouslySetInnerHTML={{ __html: text }} />
    );
  }
};

export default BlogEllipsis;
