import styles from "./styles.module.scss";
import { Close, Done, Edit, InfoOutlined } from "@mui/icons-material";
import { IconButton, TextField, Tooltip } from "@mui/material";
import { useState } from "react";
import { useNotify } from "Utils/hooks";
import { getToken } from "Shared/baseURL";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Reminder from "Components/Reminder/Reminder";

const ListName = ({ subject, isListCreatedByMe }) => {
  //   state
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const [editName, setEditName] = useState(false);
  const [newListName, setNewListName] = useState(subject.name);

  // router
  const navigate = useNavigate();

  const handleEdit = () => {
    setEditName(true);
  };

  const handleCloseEdit = (setInitialName = true) => {
    if (setInitialName) {
      setNewListName(subject.name);
    }
    setEditName(false);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const token = await getToken();
      const data = { name: newListName };
      const res = await axios.patch(`/lists/${subject.uuid}`, data, {
        headers: { Authorization: token },
      });
      notify("List name updated.");
      navigate(`/view-list/${subject.subjectHref}/${res.data.href}`);
      setNewListName(res.data.name);
      handleCloseEdit(false);
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles["main"]}>
      {editName ? (
        <div className={styles["input-div"]}>
          <h4 className="yellow">Enter new list name</h4>
          <div className={styles["controls"]}>
            <TextField
              value={newListName}
              label="Name"
              variant="standard"
              required
              onChange={(e) => setNewListName(e.target.value)}
              disabled={isLoading}
            />
            <IconButton
              size="small"
              color="success"
              disabled={isLoading || newListName === subject.name}
              onClick={handleSubmit}
            >
              <Done />
            </IconButton>
            <IconButton size="small" color="error" onClick={handleCloseEdit}>
              <Close />
            </IconButton>
          </div>
        </div>
      ) : (
        <div className={styles["name-div"]}>
          <p className="title-big mt-3">{newListName}</p>
          {isListCreatedByMe && (
            <>
              <IconButton title="Edit list name" onClick={handleEdit}>
                <Edit />
              </IconButton>
              <Reminder list listID={subject.uuid} />
            </>
          )}
          <Tooltip title="Keywords created or located in lists are not verified for relevance">
            <InfoOutlined color="primary" />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default ListName;
