import { Alert, AlertTitle, Box, Container } from "@mui/material";
import Spinner from "Components/Spinner/Spinner";
import { getClientToken } from "Shared/baseURL";
import axios from "axios";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

const fetchUser = async (ID) => {
  const token = await getClientToken();
  return await axios
    .post(
      `/subscribers/${ID}/activate`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data)
    .catch((error) => {
      if (error.response) {
        return error.response.data.message;
      }
      return error;
    });
};

const SubscribeActivate = () => {
  // router
  const { ID } = useParams();

  // query
  const { isLoading, error } = useQuery(
    ["get-user-profile", ID],
    () => fetchUser(ID),
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return (
      <div className="mt-4">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <Container className="mt-4" maxWidth="sm">
        <Box sx={{ mb: 5 }}>
          <Alert severity="error" variant="filled" sx={{ color: "white" }}>
            <AlertTitle>Error</AlertTitle>
            {error.message}
          </Alert>
        </Box>
      </Container>
    );
  }

  return (
    <Container className="mt-4" maxWidth="sm">
      <Box sx={{ mb: 5 }}>
        <Alert severity="success" variant="filled" sx={{ color: "white" }}>
          <AlertTitle>Success</AlertTitle>
          Subscription activated
        </Alert>
      </Box>
    </Container>
  );
};

export default SubscribeActivate;
