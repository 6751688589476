import {
  DeleteOutline,
  EditOutlined,
  IosShareOutlined,
  ShareOutlined,
  ThumbDown,
  ThumbDownOutlined,
  ThumbUp,
  ThumbUpOutlined,
} from "@mui/icons-material";
import { Button } from "@mui/material";
import { getToken } from "Shared/baseURL";
import { useNotify, useReduxUserProfile } from "Utils/hooks";
import axios from "axios";
import { useEffect, useState } from "react";
import SharePostDialog from "Components/SharePostDialog/SharePostDialog";
import EditPost from "Components/EditPost/EditPost";

const PostActions = ({
  postID,
  refetchPosts,
  likes,
  dislikes,
  user,
  postHref,
  content,
}) => {
  // state
  const notify = useNotify();
  const [isPostMine, setIsPostMine] = useState(false);
  const [isReposting, setIsReposting] = useState(false);
  const [isLikedByMe, setIsLikedByMe] = useState(false);
  const [isDislikedByMe, setIsDislikedByMe] = useState(false);
  const [sharePost, setSharePost] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editPost, setEditPost] = useState(false);

  // redux
  const reduxUser = useReduxUserProfile();

  useEffect(() => {
    setIsPostMine(reduxUser.username === user.username);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (Array.isArray(likes)) {
      setIsLikedByMe(likes?.some((item) => item.uuid === reduxUser.uuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [likes]);

  useEffect(() => {
    if (Array.isArray(dislikes)) {
      setIsDislikedByMe(dislikes?.some((item) => item.uuid === reduxUser.uuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dislikes]);

  const handleLikePost = async () => {
    try {
      const token = await getToken();
      await axios.post(
        `/posts/${postID}/likes`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (isDislikedByMe) {
        await handleUnDislikeList(false);
      }
      notify("Post Liked.");
      refetchPosts();
    } catch (error) {
      notify(error.message, { variant: "error" });
    }
  };

  const handleDislikePost = async () => {
    try {
      const token = await getToken();
      await axios.post(
        `/posts/${postID}/dislikes`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (isLikedByMe) {
        await handleUnlikeList(false);
      }
      notify("Post Disliked.");
      await refetchPosts();
    } catch (error) {
      notify(error.message, { variant: "error" });
    }
  };

  const handleUnlikeList = async (showNotify = false) => {
    try {
      const token = await getToken();
      await axios.delete(`posts/${postID}/likes`, {
        headers: {
          Authorization: token,
        },
      });
      if (showNotify) {
        notify("Post unliked.");
      }
      await refetchPosts();
    } catch (error) {
      notify(error.message, { variant: "error" });
    }
  };

  const handleUnDislikeList = async (showNotify = false) => {
    try {
      const token = await getToken();
      await axios.delete(`posts/${postID}/dislikes`, {
        headers: {
          Authorization: token,
        },
      });
      if (showNotify) {
        notify("Undo Dislike.");
      }
      await refetchPosts();
    } catch (error) {
      notify(error.message, { variant: "error" });
    }
  };

  const handleRepost = async () => {
    try {
      setIsReposting(true);
      const token = await getToken();
      await axios.post(
        `/posts/${postID}/repost`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      await refetchPosts();
      notify("Repost successfully");
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsReposting(false);
    }
  };

  const handleDeletePost = async () => {
    try {
      setIsDeleting(true);
      const token = await getToken();
      await axios.delete(`/posts/${postID}`, {
        headers: {
          Authorization: token,
        },
      });
      await refetchPosts();
      notify("Post deleted successfully");
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleEditPost = () => {
    setEditPost(true);
  };

  return (
    <>
      {isLikedByMe ? (
        <Button
          title="Dislike"
          startIcon={<ThumbUp />}
          className="liked-btn"
          // sx={{ pointerEvents: "none" }}
          onClick={() => handleUnlikeList(true)}
        >
          <span className="btn-text">Liked</span>
        </Button>
      ) : (
        <Button
          title="Like"
          startIcon={<ThumbUpOutlined />}
          onClick={handleLikePost}
          className="like-btn"
        >
          <span className="btn-text">Like</span>
        </Button>
      )}
      {isDislikedByMe ? (
        <Button
          title="Disliked"
          startIcon={<ThumbDown />}
          className="disliked-btn"
          // sx={{ pointerEvents: "none" }}
          onClick={() => handleUnDislikeList(true)}
        >
          <span className="btn-text">Disliked</span>
        </Button>
      ) : (
        <Button
          title="Dislike"
          startIcon={<ThumbDownOutlined />}
          onClick={handleDislikePost}
          className="dislike-btn"
        >
          <span className="btn-text">Dislike</span>
        </Button>
      )}
      <Button
        startIcon={<IosShareOutlined />}
        onClick={handleRepost}
        disabled={isReposting}
      >
        <span className="btn-text">
          {isReposting ? "Reposting..." : "Repost"}
        </span>
      </Button>
      <Button startIcon={<ShareOutlined />} onClick={() => setSharePost(true)}>
        <span className="btn-text">Share</span>
      </Button>
      {isPostMine && (
        <>
          <Button
            startIcon={<EditOutlined />}
            onClick={handleEditPost}
            disabled={isDeleting}
          >
            Edit
          </Button>
          <Button
            startIcon={<DeleteOutline />}
            onClick={handleDeletePost}
            disabled={isDeleting}
          >
            <span className="btn-text">
              {isDeleting ? "Deleting..." : "Delete"}
            </span>
          </Button>
        </>
      )}
      {sharePost && (
        <SharePostDialog
          postId={postID}
          open={sharePost}
          toggle={setSharePost}
          postHref={postHref}
        />
      )}
      {editPost && (
        <EditPost
          open={editPost}
          toggle={setEditPost}
          content={content}
          postID={postID}
          refetchPosts={refetchPosts}
        />
      )}
    </>
  );
};

export default PostActions;
