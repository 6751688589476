import { ArrowCircleDown, ArrowCircleUp } from "@mui/icons-material";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import React from "react";

const Institutions = () => {
  return (
    <Box
      sx={{
        mx: {
          sm: 0,
          md: "3.5rem",
        },
      }}
    >
      <div className="institution-container">
        <div className="title">
          <span>Institution's numbers:</span>
          <div className="icons-wrapper">
            <ArrowCircleUp className="icons" />
            <ArrowCircleDown className="icons" />
          </div>
        </div>

        <TableContainer className="table-container">
          <Table>
            <TableBody>
              <TableRow hover>
                <TableCell colSpan={2}>Endorsed by</TableCell>
                <TableCell align="right">92</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell colSpan={2}>Followers</TableCell>
                <TableCell align="right">300</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell colSpan={2}>Following</TableCell>
                <TableCell align="right">53</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell colSpan={2}>Currently Sponsoring</TableCell>
                <TableCell align="right">500</TableCell>
              </TableRow>
              <TableRow hover>
                <TableCell colSpan={2}>Total Sponsors to date</TableCell>
                <TableCell align="right">542</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Box>
  );
};

export default Institutions;
