import "./styles.scss";

import {
  Add,
  AddLink,
  Delete,
  InsertChartOutlined,
  KeyboardArrowDown,
  PeopleAltOutlined,
  PersonOutlineOutlined,
} from "@mui/icons-material";
import { Box, Button, CircularProgress, Container, Grid } from "@mui/material";
import Advertisement from "Components/Advertisement/Advertisement";
import CustomToolTip from "Components/CustomToolTip/CustomToolTip";
import LinkList from "Components/LinkList/LinkList";
import NoData from "Components/NoData/NoData";
import ChallengeDialog from "Pages/AllLists/Dialogs/ChallengeDialog";
import { getToken } from "Shared/baseURL";
import { useNotify } from "Utils/hooks";
import { arrayMoveImmutable } from "array-move";
import axios from "axios";
import { lazy, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import * as Yup from "yup";
import { fetchKewordsByListID, fetchSubjectObject } from "./Api";
import RenderViewListSubject from "./RenderViewListSubject";
import SortingMenu from "./ViewListMenu/SortingMenu";
import SizeMenu from "./ViewListMenu/SizeMenu";
import ViewMenu from "./ViewListMenu/ViewMenu";

// Validation Schema
const EmailValidation = Yup.object().shape({
  challengeEmail: Yup.string().email().required(),
});

// Components
const CloneKewordalListModal = lazy(() =>
  import("Components/CloneKeywordList/CloneKeywordList")
);
const ViewListCard = lazy(() =>
  import("Components/ViewListCards/ViewListCards")
);
const AddKeyword = lazy(() => import("Components/AddKeyword/AddKeyword"));
const ViewScores = lazy(() => import("Components/ViewScores/ViewScores"));
const PlayGame = lazy(() => import("Components/PlayGame/PlayGame"));
const TutorialDialog = lazy(() =>
  import("Components/TutorialDialog/TutorialDialog")
);
const DeleteKewordalList = lazy(() =>
  import("Components/DeleteKewordalList/DeleteKewordalList")
);

const TooltipTitle =
  "To add a new keyword simply click on the phrase create. Click the test yourself button to play a multiple choice game to test your knowledge of your keywords and challenge to.";

const SortableItem = SortableElement(
  ({ item, view, idx, getAllKewordsByList, subjectHref, listHref }) => (
    <Grid
      item
      xs={12}
      sm={6}
      md={view === "full" ? 6 : 4}
      lg={view === "full" ? 4 : 3}
      key={item.uuid}
    >
      <ViewListCard
        key={item.uuid}
        keyword={item}
        view={view}
        index={idx}
        dislikes={item.dislikes}
        likes={item.likes}
        getAllKewordsByList={getAllKewordsByList}
        refresh={getAllKewordsByList}
        kewordsId={item?.uuid}
        refetch={getAllKewordsByList}
        keywordPosition={parseInt(item.position) ?? idx + 1}
        subjectHref={subjectHref}
        listHref={listHref}
      />
    </Grid>
  )
);

const ViewList = () => {
  // state
  const [isListCreatedByMe, setIsListCreatedByMe] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [subject, setSubject] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [allKeywords, setAllKeywords] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadMore, setLoadMore] = useState(true);
  const [viewScores, setViewScores] = useState(false);
  const [viewMenu, setViewMenu] = useState(null);
  const [viewSizeMenu, setViewSizeMenu] = useState(null);
  const [sortMenu, setSortMenu] = useState(null);
  const [playGame, setPlayGame] = useState(false);
  const [tutorialDialog, setTutorialDialog] = useState(true);
  const [challenge, setChallenge] = useState(false);
  const [emailDialog, setEmailDialog] = useState(false);
  const [challengeEmail, setChallengeEmail] = useState("");
  const [challengeUserID, setChallengeUserID] = useState("");
  const [cloneDialog, setCloneDialog] = useState(false);
  const [showTooltip, setShowTooltip] = useState(true);
  const [challengedGame, setChallengedGame] = useState(null);
  const [openDeleteModal, setDeleteListModal] = useState(false);
  const [linkListModal, setLinkListModal] = useState(false);

  // filters
  const [view, setView] = useState("full");
  const [viewSize, setViewSize] = useState(20);
  const [sorting, setSorting] = useState("");

  // hooks
  const notify = useNotify();

  // redux
  const loggedUser = useSelector((state) => state.user.profile);

  // router
  const [searchParams] = useSearchParams();
  const { listID, subjectHref } = useParams();

  useEffect(() => {
    getAllKewordsByList(1, false, true);
    if (!subject) {
      getSubject();
    }
    const renderTutorialDialog = JSON.parse(
      sessionStorage.getItem("tutorialDialog")
    );
    if (!renderTutorialDialog.includes("view-list")) {
      setTimeout(() => {
        setTutorialDialog(true);
      }, 700);
      let arr = [...renderTutorialDialog, "view-list"];
      sessionStorage.setItem("tutorialDialog", JSON.stringify(arr));
    } else {
      setTutorialDialog(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting, viewSize]);

  useEffect(() => {
    const challengeCheck = searchParams.get("challenge");
    if (allKeywords.length > 3 && challengeCheck) {
      handleValidateChallenge(challengeCheck);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allKeywords, searchParams]);

  useEffect(() => {
    if (subject) {
      const check = subject.user.userUuid === loggedUser.uuid;
      setIsListCreatedByMe(check);
    }
  }, [subject, loggedUser]);

  const handleValidateChallenge = async (challengeID) => {
    setIsLoading(true);
    try {
      const token = await getToken();
      const res = await axios.get(
        `/users/me/challenges.json?uuid=${challengeID}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const { challenges } = res.data;
      if (challenges.length > 0) {
        handlePlayChallenge(challengeID);
      } else {
        notify("No challenge found.", {
          variant: "default",
          persist: true,
        });
      }
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePlayChallenge = (challengeID) => {
    setChallengedGame(challengeID);
    setPlayGame(true);
  };

  const handleLoadMore = () => {
    let newPage = currentPage + 1;
    getAllKewordsByList(newPage, true);
  };

  const getAllKewordsByList = async (
    page = 1,
    load = false,
    refresh = false
  ) => {
    try {
      setLoadMore(load);
      if (!load && !refresh) {
        setIsLoading(true);
      }
      const res = await fetchKewordsByListID(listID, page, sorting, viewSize);
      if (res.message) {
        throw new Error(res.message);
      }
      const { keywords, pagination } = res.data;
      let arr = [];
      if (load) {
        arr = [...allKeywords];
      }
      arr = [...arr, ...keywords];
      arr.forEach((item) => {
        const imgURL = item?.imageURL?.split("^")[0];
        if (imgURL) {
          const isNotExternal = imgURL.includes("https://kewordal-images.s3");
          const length = imgURL?.split("/")?.length;
          const url = imgURL?.split("/")[length - 1];
          item.newImgURL = isNotExternal ? setNewImgURL(url) : imgURL;
        } else {
          item.newImgURL = null;
        }
      });
      setPagination(pagination);
      setAllKeywords(arr);
      setCurrentPage(pagination.page);
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
      setLoadMore(false);
    }
  };

  const setNewImgURL = (url) => {
    const baseURL = process.env.REACT_APP_BASEURL;
    return `${baseURL}/img/post/${url}?w=320&h=200&fit=true&bg=aaaaaa`;
  };

  const getSubject = async () => {
    try {
      const res = await fetchSubjectObject(listID);
      if (res.message) {
        throw new Error(res.message);
      }
      setSubject(res);
    } catch (error) {
      notify(error.message, { variant: "error" });
    }
  };

  const closeViewSizeMenu = () => {
    setViewSizeMenu(null);
  };

  const handleSelectViewSize = (value) => {
    setViewSize(value);
    closeViewSizeMenu();
  };

  const handleSelectView = (value) => {
    setView(value);
    closeViewMenu();
  };

  const handleSelectSort = (value) => {
    if (value === sorting) {
      setSorting("");
      closeSortMenu();
      return;
    }
    setSorting(value);
    closeSortMenu();
  };

  const closeViewMenu = () => {
    setViewMenu(null);
  };

  const closeSortMenu = () => {
    setSortMenu(null);
  };

  const handleTestYourself = () => {
    setPlayGame(true);
  };

  const handleChallengeFriend = async () => {
    const check = await EmailValidation.isValid({ challengeEmail });
    if (!check) {
      notify("Please provide valid email.", { variant: "error" });
      return;
    }
    handleCloseEmailDialog();
    setChallenge(true);
    setPlayGame(true);
  };

  const handleCloseEmailDialog = () => {
    setEmailDialog(false);
  };

  const handleCloneList = () => {
    setCloneDialog(true);
  };

  const handleDeleteList = async () => {
    setDeleteListModal(true);
  };

  const handleLinkList = () => {
    setLinkListModal(true);
  };

  const SortableList = SortableContainer(
    ({ keywords, view, getAllKewordsByList }) => {
      return (
        <Grid container spacing={2} className="py-4">
          {keywords.map((item, index) => (
            <SortableItem
              key={item.uuid}
              index={index}
              idx={index}
              getAllKewordsByList={getAllKewordsByList}
              view={view}
              item={item}
              subjectHref={subjectHref}
              listHref={listID}
            />
          ))}
        </Grid>
      );
    }
  );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let newArr = arrayMoveImmutable(allKeywords, oldIndex, newIndex);
    setAllKeywords(newArr);
    saveSortedArrayInBackend(newArr);
  };

  const saveSortedArrayInBackend = async (keywords) => {
    try {
      const data = keywords.map((item) => item.uuid);
      const token = await getToken();
      await axios.patch(
        "/keywords/sort",
        { uuids: data },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify("Keywords sorted");
    } catch (error) {
      notify(error.message, { variant: "error" });
    }
  };

  return (
    <Box className="view-lists-page">
      <Container>
        {isLoading ? (
          <Box className="center">
            <CircularProgress />
          </Box>
        ) : (
          <>
            {subject && (
              <RenderViewListSubject
                isListCreatedByMe={isListCreatedByMe}
                subject={subject}
              />
            )}
            <Grid container spacing={2} columns={14}>
              <Grid item xs={14} md={11}>
                <Box className="control-box">
                  <CustomToolTip
                    title={TooltipTitle}
                    placement="top"
                    showArrow
                    open={showTooltip}
                    toggleTooltip={setShowTooltip}
                  >
                    <div className="gap-3 d-flex align-items-start align-items-xl-center justify-content-between flex-column flex-xl-row">
                      <Box className="btn-box ">
                        <Button
                          startIcon={<PersonOutlineOutlined />}
                          variant="contained"
                          size="small"
                          onClick={handleTestYourself}
                          disabled={
                            allKeywords.filter((i) => i.question).length < 4
                          }
                          className="mt-2 mt-xxl-0 text-capitalize "
                        >
                          Test Yourself
                        </Button>
                        <Button
                          startIcon={<PeopleAltOutlined />}
                          variant="contained"
                          size="small"
                          onClick={() => setEmailDialog(true)}
                          disabled={
                            allKeywords.filter((i) => i.question).length < 4
                          }
                          className="mt-2 mt-xxl-0 text-capitalize"
                        >
                          Challenge
                        </Button>
                        <Button
                          onClick={() => setViewScores(true)}
                          startIcon={<InsertChartOutlined />}
                          variant="contained"
                          size="small"
                          className="mt-2 mt-xxl-0 text-capitalize"
                        >
                          View Scores
                        </Button>
                        {!isListCreatedByMe && (
                          <Button
                            onClick={handleCloneList}
                            startIcon={<Add />}
                            variant="contained"
                            size="small"
                            className="mt-2 mt-xxl-0 text-capitalize"
                          >
                            Clone List
                          </Button>
                        )}
                        {isListCreatedByMe && (
                          <>
                            <Button
                              onClick={handleLinkList}
                              startIcon={<AddLink />}
                              variant="contained"
                              size="small"
                              className="mt-2 mt-xxl-0 text-capitalize"
                            >
                              Link List
                            </Button>
                            <Button
                              onClick={handleDeleteList}
                              startIcon={<Delete />}
                              variant="contained"
                              size="small"
                              className="mt-2 mt-xxl-0 text-capitalize"
                            >
                              Delete List
                            </Button>
                          </>
                        )}
                      </Box>
                      <div className="sort-box">
                        <Button
                          endIcon={<KeyboardArrowDown />}
                          className="bg-white"
                          onClick={(e) => setSortMenu(e.currentTarget)}
                        >
                          Sort By
                        </Button>
                        <Button
                          endIcon={<KeyboardArrowDown />}
                          className="bg-white"
                          onClick={(e) => setViewSizeMenu(e.currentTarget)}
                        >
                          VIEW {viewSize}
                        </Button>
                        <Button
                          endIcon={<KeyboardArrowDown />}
                          className="bg-white"
                          onClick={(e) => setViewMenu(e.currentTarget)}
                        >
                          {view} VIEW
                        </Button>
                      </div>
                    </div>
                  </CustomToolTip>
                  {/* Add keyword (Renders only if list is created by logged user) */}
                  {subject?.userUuid === loggedUser?.uuid && (
                    <Box className="form-box">
                      <p className="fw-300 fs5 mt-4 mb-4">ADD NEW KEYWORD</p>
                      <AddKeyword
                        listHref={listID}
                        subjectHref={subjectHref}
                        getAllKewordsByList={getAllKewordsByList}
                      />
                    </Box>
                  )}
                </Box>

                <Box className="main-content">
                  {allKeywords.length > 0 ? (
                    <SortableList
                      view={view}
                      getAllKewordsByList={getAllKewordsByList}
                      keywords={allKeywords}
                      onSortEnd={onSortEnd}
                      pressDelay={300}
                      axis="xy"
                    />
                  ) : (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <NoData />
                      </Grid>
                    </Grid>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box className="center">
                        {pagination &&
                          currentPage < pagination.totalPages &&
                          (loadMore ? (
                            <CircularProgress />
                          ) : (
                            <Button
                              onClick={handleLoadMore}
                              size="small"
                              variant="text"
                            >
                              Load More
                            </Button>
                          ))}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <div>
                  <Advertisement fullView />
                </div>
              </Grid>
              <Grid item xs={14} md={3}>
                <Advertisement />
              </Grid>
            </Grid>
          </>
        )}
      </Container>
      {/* View Size Menu */}
      <SizeMenu
        key="size-menu"
        viewSize={viewSize}
        viewSizeMenu={viewSizeMenu}
        handleSelectViewSize={handleSelectViewSize}
        closeViewSizeMenu={closeViewSizeMenu}
      />
      {/* View Menu */}
      <ViewMenu
        key="view-menu"
        view={view}
        viewMenu={viewMenu}
        closeViewMenu={closeViewMenu}
        handleSelectView={handleSelectView}
      />
      {/* Sorting menu */}
      <SortingMenu
        key="sorting-menu"
        sorting={sorting}
        sortMenu={sortMenu}
        handleSelectSort={handleSelectSort}
        closeSortMenu={closeSortMenu}
      />
      {/* Dialogs */}
      {viewScores && (
        <ViewScores
          listName={subject?.name}
          subjectName={subject?.subject}
          listHref={listID}
          open={viewScores}
          close={setViewScores}
          listClonedCount={subject.timesCloned}
          listPlayedCount={subject.timesPlayed}
        />
      )}
      {playGame && (
        <PlayGame
          open={playGame}
          listHref={listID}
          close={setPlayGame}
          challengeEmail={challengeEmail}
          setChallengeEmail={setChallengeEmail}
          challengeUserID={challengeUserID}
          setChallengeUserID={setChallengeUserID}
          challenge={challenge}
          toggleChallenge={setChallenge}
          listAttributes={subject}
          challengedGame={challengedGame}
          setChallengedGame={setChallengedGame}
        />
      )}
      {openDeleteModal && (
        <DeleteKewordalList
          navigateBack
          name={subject.name}
          open={openDeleteModal}
          setDeleteListModal={setDeleteListModal}
          id={subject.uuid}
          refresh={getAllKewordsByList}
          totalKeywords={
            Array.isArray(allKeywords) &&
            allKeywords.filter((item) => item.uuid).length
          }
        />
      )}
      {tutorialDialog && (
        <TutorialDialog
          open={tutorialDialog}
          toggleDialog={setTutorialDialog}
          page={5}
          maxPage={6}
        />
      )}
      {subject && (
        <CloneKewordalListModal
          uuid={subject.uuid}
          term={subject.name}
          open={cloneDialog}
          setCloneModal={setCloneDialog}
        />
      )}
      {linkListModal && (
        <LinkList
          listID={subject.uuid}
          open={linkListModal}
          close={setLinkListModal}
          refresh={getAllKewordsByList}
        />
      )}
      {emailDialog && (
        <ChallengeDialog
          challengeEmail={challengeEmail}
          emailDialog={emailDialog}
          handleChallengeFriend={handleChallengeFriend}
          handleCloseEmailDialog={handleCloseEmailDialog}
          setChallengeEmail={setChallengeEmail}
          setChallengeUserID={setChallengeUserID}
          setEmailDialog={setEmailDialog}
        />
      )}
    </Box>
  );
};

export default ViewList;
