import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const SubscriptionNeeded = ({ open, toggle, cb }) => {
  // router
  const navigate = useNavigate();

  const onClose = () => {
    toggle();
    if (cb) {
      cb();
    }
  };

  const handleNavigateToSubscription = () => {
    navigate("/edit-profile/manage-subscriptions");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Subscription Required</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This feature is only available to subscribed users. Please consider
          subscribing to unlock all the benefits.
        </DialogContentText>
        <div className="d-flex gap-2 my-4 justify-content-center">
          <Button onClick={onClose} color="error" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleNavigateToSubscription}
            variant="contained"
            disableElevation
            color="primary"
          >
            Manage Subscription
          </Button>
        </div>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export default SubscriptionNeeded;
