import { AnimatePresence, motion } from "framer-motion";
import { Fab, Tooltip } from "@mui/material";

import { ArrowUpward } from "@mui/icons-material";
import classes from "./styles.module.scss";
import { useState } from "react";
import { useReduxUser } from "Utils/hooks";

const BackToTop = () => {
  // state
  const [visible, setVisible] = useState(false);

  // redux
  const reduxUser = useReduxUser();

  window.onscroll = () => {
    let scrolled = window.scrollY;
    if (scrolled > 1500) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({
      behavior: "smooth",
      left: 0,
      top: 0,
    });
  };

  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          style={{
            bottom: reduxUser ? "170px" : "20px",
          }}
          className={classes["main"]}
          initial={{ x: 100, y: 0, opacity: 0, display: "none" }}
          animate={{
            x: 0,
            y: 0,
            opacity: 1,
            display: "block",
          }}
          exit={{
            x: 100,
            y: 0,
            opacity: 0,
          }}
        >
          <Tooltip title="Scroll to top">
            <Fab onClick={scrollTop} size="medium" color="secondary">
              <ArrowUpward />
            </Fab>
          </Tooltip>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default BackToTop;
