import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";

/**
 * Used to show toast messages
 * @returns notify
 */
export const useNotify = () => {
  const { enqueueSnackbar: notify } = useSnackbar();
  return notify;
};

/**
 * Returns user stored in redux that is logged in.
 * @returns {object} user
 */
export const useReduxUser = () => {
  const user = useSelector((state) => state.user.user);
  return user;
};

/**
 * Returns user profile stored in redux that is logged in.
 * @returns {object} proifle
 */
export const useReduxUserProfile = () => {
  const profile = useSelector((state) => state?.user?.profile || null);
  return profile;
};

/**
 * Returns user notification setting
 * @returns {boolean}
 */
export const useGetNotification = () => {
  const notification = useSelector((state) => state.notification);
  return notification;
};

/**
 * A custom hook for managing disclosure state.
 *
 * @returns {Object} - An object containing the disclosure state and functions to control it.
 * @property {boolean} isOpen - The current state of the disclosure.
 * @property {function} onToggle - A function to toggle the disclosure state.
 * @property {function} onClose - A function to close the disclosure.
 * @property {function} onOpen - A function to open the disclosure.
 */
export const useDisclosure = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);
  const onToggle = () => setIsOpen(!isOpen);

  return {
    isOpen,
    onToggle,
    onClose,
    onOpen,
  };
};

export const useNavigateToSubscription = () => {
  const navigate = useNavigate();
  return () => navigate("/edit-profile/manage-subscriptions");
};

export const useMediumScreenOrAbove = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up("md"));
};
