import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { getToken } from "Shared/baseURL";
import { useNotify } from "Utils/hooks";
import axios from "axios";
import { useState } from "react";

const ReportUser = ({ userID, toggle, open }) => {
  // state
  const notify = useNotify();
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    toggle(false);
  };

  const handleReport = async () => {
    try {
      setIsLoading(true);
      const token = await getToken();
      const data = { content };
      await axios.post(`/users/${userID}/report`, data, {
        headers: {
          Authorization: token,
        },
      });
      notify("User reported successfully.");
      handleClose();
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEnter = (e) => {
    if (!content || isLoading) return;

    if (e.code === "Enter") {
      handleReport();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Report User</DialogTitle>
      <DialogContent>
        <DialogContentText>Please provide reason for report</DialogContentText>
        <div className="mt-2">
          <TextField
            value={content}
            onChange={(e) => setContent(e.target.value)}
            multiline
            minRows={3}
            maxRows={6}
            fullWidth
            placeholder="Reason *"
            disabled={isLoading}
          />
        </div>
        <div className="mt-3 d-flex gap-2 justify-content-end">
          <Button
            variant="outlined"
            color="secondary"
            disabled={!userID || isLoading}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            disabled={!userID || !content || isLoading}
            onClick={handleReport}
            onKeyUp={handleEnter}
          >
            Report
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ReportUser;
