import {
  Box,
  Button,
  Dialog,
  DialogContent,
  LinearProgress,
} from "@mui/material";
import { Close, Done } from "@mui/icons-material";
import React, { useState } from "react";

import axios from "axios";
import { deleteKeyword } from "./Api";
import { getToken } from "Shared/baseURL";
import { useSnackbar } from "notistack";

const DeleteKeyword = ({ close, id, open, refresh }) => {
  // state
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar: notify } = useSnackbar();

  const closeModal = () => {
    close(false);
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      const res = await deleteKeyword(id);
      if (res.message) {
        throw new Error(res.message);
      }
      notify("Keyword deleted.", {
        action: (key) => (
          <Button size="small" sx={{ color: "white" }} onClick={handleRestore}>
            Undo
          </Button>
        ),
        autoHideDuration: 5000,
      });
      closeModal();
      if (refresh) {
        refresh();
      }
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRestore = async () => {
    try {
      const token = await getToken();
      await axios.patch(
        `keywords/${id}/restore`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify(`Keyword restored.`);
      refresh();
    } catch (error) {
      notify(error.message, { variant: "error" });
    }
  };

  return (
    <Box className="delete-kewordal-list">
      <Dialog
        open={open}
        onClose={closeModal}
        aria-labelledby="delete-kewordal"
        fullWidth
        maxWidth="xs"
      >
        <DialogContent>
          <Box sx={{ py: 2 }}>
            <p className="text-center fw-500" style={{ fontSize: "17px" }}>
              Delete this keyword?
            </p>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "20px",
                mt: 5,
              }}
            >
              <Button
                onClick={closeModal}
                disabled={isLoading}
                variant="outlined"
                color="secondary"
                startIcon={<Close />}
              >
                Cancel
              </Button>
              <Button
                onClick={handleDelete}
                variant="contained"
                color="error"
                disableElevation
                startIcon={<Done />}
                disabled={isLoading}
              >
                Yes
              </Button>
            </Box>
            {isLoading && (
              <Box mt={2}>
                <LinearProgress />
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default DeleteKeyword;
