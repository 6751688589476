import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useSentChallenges } from "Utils/queries";
import React, { useEffect, useState } from "react";
import RenderSentList from "./RenderSentList";

const SentChallenges = ({ closeChallengesDialog }) => {
  // state
  const [allData, setAllData] = useState([]);

  // queries
  const {
    data,
    isLoading,
    refetch,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useSentChallenges();

  useEffect(() => {
    if (data) {
      const { pages } = data;
      let arr = [];
      pages.forEach((page) => {
        page.challenges.forEach((challenge) => {
          arr.push(challenge);
        });
      });
      setAllData(arr);
    }
  }, [data]);

  if (isLoading) {
    return (
      <div className="center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>To</TableCell>
              <TableCell>List</TableCell>
              <TableCell>Answered</TableCell>
              <TableCell>Clone</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allData.map((list) => (
              <RenderSentList
                list={list}
                key={list.uuid}
                refetch={refetch}
                closeChallengesDialog={closeChallengesDialog}
              />
            ))}
          </TableBody>
        </Table>
        {hasNextPage && (
          <div className="mt-3">
            <Button
              disabled={isFetchingNextPage}
              onClick={fetchNextPage}
              fullWidth
            >
              {isFetchingNextPage ? "Loading..." : "Load more"}
            </Button>
          </div>
        )}
      </TableContainer>
    </div>
  );
};

export default SentChallenges;
