import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { getClientToken } from "Shared/baseURL";
import { useNotify } from "Utils/hooks";
import axios from "axios";
import React, { lazy, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import Marquee from "react-fast-marquee";
import Slider from "react-slick";

// lazy imports
const HomeSubjectCard = lazy(() =>
  import("Components/HomeSubjectCard/HomeCard")
);

const AUTOPLAY_SPEED = 5000;

const SubjectSection = () => {
  // state
  const [allSubjects, setAllSubjects] = useState([]);
  const [subjectLoading, setSubjectLoading] = useState(true);
  const [activeSlide, setActiveSlide] = useState(0);

  // hooks
  const notify = useNotify();

  useEffect(() => {
    getAllSubjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // router
  const navigate = useNavigate();

  const handleJoin = () => {
    navigate("/register");
  };

  const getAllSubjects = async () => {
    setSubjectLoading(true);
    try {
      const token = await getClientToken();
      const res = await axios.get("/subjects.json", {
        params: {
          page: 1,
          sort: "name",
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        const { subjects } = res.data;
        let arr = subjects
          .filter((item) => Boolean(item.gif) && item.name !== "Biology")
          .map((item) => ({
            uuid: item.uuid,
            href: item.href,
            name: item.name,
            video: item.video,
            videoURL: item?.gif?.replace("gif", "mp4") || "",
          }))
          .slice(0, 17);
        setAllSubjects(arr);
        setActiveSlide(0);
      }
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setSubjectLoading(false);
    }
  };

  const settings = {
    arrows: true,
    className: "slider variable-width",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    autoplaySpeed: AUTOPLAY_SPEED,
    dots: false,
    pauseOnHover: true,
    pauseOnDotsHover: true,
    slidesToShow: 1,
    speed: 500,
    focusOnSelect: true,
    variableWidth: true,
    autoplay: true,
    afterChange: (current) => setActiveSlide(current),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          variableWidth: false,
        },
      },
    ],
  };

  return (
    <Box>
      <Box sx={{ mt: "2rem" }}>
        <Typography
          component="h2"
          sx={{
            fontWeight: 600,
            color: "#333",
            fontSize: { xs: "1.5rem", md: "2.7rem" },
          }}
          className="text-center"
        >
          Classify Every Keyword
        </Typography>
        <Box
          sx={{
            padding: {
              xs: "0 2rem",
              md: "0 10rem",
            },
          }}
        >
          <p
            className="black text-center fw-400"
            style={{ fontSize: "0.95rem" }}
          >
            More than 60 subjects to help you stay on top of your learning.
          </p>
        </Box>
      </Box>
      <Box
        sx={{
          mt: "1.8rem",
        }}
      >
        <div>
          <div>
            {subjectLoading && (
              <Box className="center">
                <CircularProgress />
              </Box>
            )}

            {/* <Marquee pauseOnHover speed={100}>
              {allSubjects.map((item) => (
                <div key={item.uuid}>
                  <div style={{ width: "fit-content", marginRight: "0.95rem" }}>
                    <HomeCards subject={item} />
                  </div>
                </div>
              ))}
            </Marquee> */}

            {allSubjects.length > 0 && (
              <Slider
                {...settings}
                nextArrow={<NextArrow />}
                prevArrow={<PrevArrow />}
              >
                {allSubjects.map((item, idx) => (
                  <div key={item.uuid}>
                    <CustomSlides
                      subject={item}
                      isActive={activeSlide === idx}
                    />
                  </div>
                ))}
              </Slider>
            )}
          </div>
          <div className="center">
            <Button
              onClick={handleJoin}
              color="primary"
              size="small"
              sx={{ borderRadius: "11px", px: 2 }}
              disableElevation
              variant="contained"
            >
              Join Now
            </Button>
          </div>
        </div>
      </Box>
    </Box>
  );
};

const CustomSlides = ({ subject, isActive }) => {
  return (
    <Box
      sx={{
        marginRight: "1rem",
        opacity: isActive ? 1 : 0.4,
        borderRadius: "7px",
        overflow: "hidden",
        // maxWidth: "500px",
      }}
    >
      <HomeSubjectCard subject={subject} isActive={isActive} />
    </Box>
  );
};

function NextArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      onClick={onClick}
      style={{
        ...style,
        cursor: "pointer",
        display: "block",
        background: "#01aaf5",
        position: "absolute",
        right: "20px",
        top: "50%",
        borderRadius: "50%",
        padding: "5px",
        transform: "translateY(-50%)",
      }}
    >
      <ArrowForward sx={{ color: "white" }} />
    </div>
  );
}

function PrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      onClick={onClick}
      style={{
        ...style,
        cursor: "pointer",
        display: "block",
        background: "#01aaf5",
        position: "absolute",
        left: "20px",
        top: "50%",
        borderRadius: "50%",
        padding: "5px",
        transform: "translateY(-50%)",
        zIndex: 1,
      }}
    >
      <ArrowBack sx={{ color: "white" }} />
    </div>
  );
}

export default SubjectSection;
