import "./styles.scss";

import {
  CircularProgress,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDeletedKeywordsData, useDeletedListsData } from "./Api";

import React from "react";
import RenderDeletedKeywordsRows from "Components/RenderDeletedKeywordsRows/RenderDeletedKeywordsRows";
import RenderDeletedListRows from "Components/RenderDeletedListRows/RenderDeletedListRows";

const DeletedHistory = () => {
  // query
  const {
    isLoading: listLoading,
    data: listsData,
    refetch: refetchList,
  } = useDeletedListsData();

  const {
    isLoading: keywordLoading,
    data: keywordsData,
    refetch: refetchKeyword,
  } = useDeletedKeywordsData();

  return (
    <div className="deleted-history-container">
      <Container>
        {/* lists container */}
        <div>
          <hr />
          <h4 className="black text-center">Deleted Lists</h4>
          <hr />
          {listLoading ? (
            <div className="center">
              <CircularProgress />
            </div>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Href</TableCell>
                    <TableCell>Deleted At</TableCell>
                    <TableCell>Options</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listsData?.lists.map((list) => (
                    <RenderDeletedListRows
                      key={list.uuid}
                      list={list}
                      refetch={refetchList}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>

        {/* keywords container */}
        <div className="mt-4">
          <hr />
          <h4 className="black text-center">Deleted Keywords</h4>
          <hr />
          {keywordLoading ? (
            <div className="center">
              <CircularProgress />
            </div>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Href</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Options</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {keywordsData?.keywords.map((keyword) => (
                    <RenderDeletedKeywordsRows
                      key={keyword.uuid}
                      keyword={keyword}
                      refetch={refetchKeyword}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </Container>
    </div>
  );
};

export default DeletedHistory;
