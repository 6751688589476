import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  LinearProgress,
} from "@mui/material";
import { Close, Done } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { getToken } from "../../Shared/baseURL";
import axios from "axios";
import CloneListSubMessage from "Components/SubscriptionMessages/List/CloneListSubMessage";

const CloneKeywordList = ({
  setCloneModal,
  open,
  term = "---",
  uuid,
  refetchListData,
}) => {
  // state
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar: notify } = useSnackbar();

  const closeModal = () => {
    setCloneModal(false);
  };

  const handleClone = async () => {
    try {
      setIsLoading(true);
      const token = await getToken();
      await axios.post(
        `/lists/${uuid}/clone`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify("List cloned successfully.");
      if (refetchListData) {
        refetchListData();
      }
      closeModal();
    } catch (error) {
      if (error.response.data) {
        const { message } = error.response.data;
        if (message === "CANNOT_CLONE_OWN_CLONE") {
          notify("Cannot clone our own cloned list.", { variant: "error" });
        } else if (message === "CANNOT_CLONE_TWICE") {
          notify("List cannot be cloned twice.", { variant: "error" });
        } else if (message === "USER_NOT_ALLOWED_TO_CLONE_OWN_LIST") {
          notify("You cannot clone your own list.", { variant: "error" });
        } else {
          notify(error.message, { variant: "error" });
        }
      } else {
        notify(error.message, { variant: "error" });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box className="clone-kewordal-list">
      <Dialog
        open={open}
        onClose={closeModal}
        aria-labelledby="clone-kewordal-list"
        fullWidth
        maxWidth="xs"
      >
        <DialogContent>
          <Box sx={{ py: 2, px: 4 }}>
            <p className="text-center fw-500" style={{ fontSize: "17px" }}>
              Clone this Kewordal List about {term}?
            </p>
            <Box mt={2}>
              <CloneListSubMessage bgColor="#eee">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "20px",
                    mt: 3,
                  }}
                >
                  <Button
                    onClick={closeModal}
                    variant="outlined"
                    color="secondary"
                    startIcon={<Close fontSize="small" />}
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleClone}
                    variant="contained"
                    disableElevation
                    startIcon={<Done fontSize="small" />}
                    disabled={isLoading}
                  >
                    Yes
                  </Button>
                </Box>
              </CloneListSubMessage>
            </Box>

            {isLoading && (
              <Box mt={2}>
                <LinearProgress />
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CloneKeywordList;
