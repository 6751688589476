import "./styles.scss";

import {
  // ThumbDownOutlined,
  ThumbUpOutlined,
  // ChatBubbleOutline,
  // IosShareOutlined,
} from "@mui/icons-material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import BlogEllipsis from "./BlogEllipsis";
import { useEffect, useState } from "react";
// import { extractStringFromHTML } from "Utils/helper-functions";

const BlogCards = ({ blog, idx }) => {
  // state
  const [description, setDescription] = useState("");

  // router
  const navigate = useNavigate();

  useEffect(() => {
    if (blog) {
      // const _description = extractStringFromHTML(blog?.content)
      //   .slice(0, 350)
      //   .replace(blog.title, "");

      setDescription(blog.description || "");
    }
  }, [blog]);

  const handleViewBlog = () => {
    navigate(`/blog/${blog.href}`);
    window.scrollTo(0, 0);
  };

  return (
    <div className="blog-cards">
      {blog?.photo?.url && (
        <img src={blog?.photo?.url} alt="blog-pic" onClick={handleViewBlog} />
      )}
      <div className="details-container">
        <div className="details">
          <div className="title mb-2" onClick={handleViewBlog}>
            {blog?.title}
          </div>
          <BlogEllipsis text={description} length={300} />
        </div>
        {blog.subjects && (
          <div className="tags-container">
            <span>{blog?.subject?.name}</span>
          </div>
        )}
        <div className="created-details">
          <div className="author-details">
            <div className="name-details">
              <span className="author-name">{blog?.user?.username}</span>
              <span className="posted-date">
                {dayjs(blog?.lastUpdated).fromNow()} - 3 min read
              </span>
            </div>
          </div>
          <div className="likes-details">
            <span>
              <ThumbUpOutlined fontSize="small" /> {blog?.likes?.length}
            </span>
            {/* <span>
            <ThumbDownOutlined fontSize="small" /> {blog?.dislikes?.length}
          </span> */}
            {/* <span>
            <ChatBubbleOutline fontSize="small" /> {idx * 3}
          </span>
          <span>
            <IosShareOutlined fontSize="small" /> {idx * 4}
          </span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCards;
