import CompleteProfile from "Components/CompleteProfile/CompleteProfile";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ element, user }) => {
  // router
  const location = useLocation();

  let url = `/auth/login/?redirectURL=${location.pathname}/${location.search}`;
  if (url.endsWith("/")) {
    url = url.slice(0, -1);
  }

  if (!user) {
    return <Navigate to={url} replace />;
  }

  return <CompleteProfile>{element}</CompleteProfile>;
};

export default ProtectedRoute;
