import { TableCell, TableRow } from "@mui/material";

import { LoadingButton } from "@mui/lab";
import React from "react";
import axios from "axios";
import dayjs from "dayjs";
import { getToken } from "Shared/baseURL";
import { useNotify } from "Utils/hooks";
import { useState } from "react";

const RenderDeletedListRows = ({ list, refetch }) => {
  // state
  const notify = useNotify();
  const [btnLoading, setBtnLoading] = useState(false);

  const handleRestore = async () => {
    try {
      setBtnLoading(true);
      const token = await getToken();
      await axios.patch(
        `lists/${list.uuid}/restore`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify(`List ${list.name} restored.`);
      refetch();
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>{list.name}</TableCell>
        <TableCell>{list.href}</TableCell>
        <TableCell>
          {dayjs(list.lastUpdated).format("YYYY-MM-DD HH:mm")}
        </TableCell>
        <TableCell>
          <LoadingButton
            onClick={handleRestore}
            loading={btnLoading}
            size="small"
            variant="contained"
            disableElevation
          >
            Restore
          </LoadingButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default RenderDeletedListRows;
