import "./styles.scss";

import { Box, CircularProgress, Container } from "@mui/material";
import { Outlet, useParams } from "react-router-dom";
import { lazy, useState } from "react";

import { AnimatePresence } from "framer-motion";
import RenderError from "Components/RenderError/RenderError";
import { useReduxUserProfile } from "Utils/hooks";
import { useSpecificUserDataByUsername } from "Utils/queries";
import ProfileInfo from "./ProfileInfo";
import Advertisement from "Components/Advertisement/Advertisement";
import SponsoredBy from "Components/SponsoredBy/SponsoredBy";

//  Components
const ProfileUserList = lazy(() =>
  import("Components/ProfileUserList/ProfileUserList")
);
const SuggestedConnections = lazy(() =>
  import("Components/SuggestedConnections/SuggestedConnections")
);

const MyNetwork = () => {
  // state
  const [activeTab, setActiveTab] = useState(0);

  // router
  const { username } = useParams();

  // redux
  const userRedux = useReduxUserProfile();

  // queries
  const {
    data: user,
    isLoading,
    refetch: refetchUserData,
    error,
  } = useSpecificUserDataByUsername(userRedux.username);

  const handleActiveTab = (key) => {
    setActiveTab(key);
  };

  if (isLoading) {
    return (
      <Box className="center mt-5">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <RenderError message={error.message} />;
  }

  if (!username) {
    return (
      <Container className="my-network-page">
        <div className="d-flex justify-content-between align-items-center mb-3">
          {user && (
            <SponsoredBy
              name={user?.sponsoredBy?.name}
              image={user?.sponsoredBy?.logo}
            />
          )}
          <Advertisement placeEnd />
        </div>
        {user && (
          <Box>
            <ProfileInfo user={user} isLoggedUser={true} />
            <Box className="tabs">
              <span
                onClick={() => handleActiveTab(0)}
                className={`${activeTab === 0 && "active"} span-0`}
                title="Connections"
              >
                CONNECTIONS
              </span>
              <span
                onClick={() => handleActiveTab(1)}
                className={`${activeTab === 1 && "active"} span-1`}
                title="Following"
              >
                FOLLOWING
              </span>
              <span
                onClick={() => handleActiveTab(2)}
                className={`${activeTab === 2 && "active"} span-2`}
                title="Followers"
              >
                FOLLOWERS
              </span>
              <span
                onClick={() => handleActiveTab(3)}
                className={`${activeTab === 3 && "active"} span-4`}
                title="Pending Connections"
              >
                PENDING CONNECTIONS
              </span>
              <span
                onClick={() => handleActiveTab(4)}
                className={`${activeTab === 4 && "active"} span-4`}
                title="Sent Connections"
              >
                SENT CONNECTIONS
              </span>
              <span
                onClick={() => handleActiveTab(5)}
                className={`${activeTab === 5 && "active"} span-3`}
                title="Suggestions"
              >
                SUGGESTIONS
              </span>
            </Box>
            <Box className="tabs-content custom-scroll">
              <AnimatePresence mode="wait">
                {activeTab === 0 && (
                  <ProfileUserList
                    user={user}
                    type="connections"
                    differentUser={false}
                    refetch={refetchUserData}
                    renderMenuBtn={true}
                  />
                )}
                {activeTab === 1 && (
                  <ProfileUserList
                    user={user}
                    type="following"
                    differentUser={false}
                    refetch={refetchUserData}
                    renderMenuBtn={true}
                  />
                )}
                {activeTab === 2 && (
                  <ProfileUserList
                    user={user}
                    type="followers"
                    differentUser={false}
                    refetch={refetchUserData}
                    renderMenuBtn={false}
                  />
                )}
                {activeTab === 3 && (
                  <ProfileUserList
                    user={user}
                    type="pendingConnections"
                    differentUser={false}
                    refetch={refetchUserData}
                    isPendingConnections={true}
                    renderMenuBtn={true}
                  />
                )}
                {activeTab === 4 && (
                  <ProfileUserList
                    user={user}
                    type="sentConnections"
                    differentUser={false}
                    refetch={refetchUserData}
                    renderMenuBtn={true}
                  />
                )}
                {activeTab === 5 && (
                  <SuggestedConnections
                    user={user}
                    type="suggestions"
                    userID={userRedux.uuid}
                    differentUser={false}
                    refetchUserData={refetchUserData}
                    renderMenuBtn={true}
                  />
                )}
              </AnimatePresence>
            </Box>
            <div>
              <Advertisement fullView />
            </div>
          </Box>
        )}
      </Container>
    );
  }

  if (username) {
    return <Outlet />;
  }
};

export default MyNetwork;
