import { InfoOutlined, Menu } from "@mui/icons-material";
import { IconButton, List, Tooltip } from "@mui/material";
import React, { lazy, useEffect, useState } from "react";
import * as Yup from "yup";
import RenderKeyword from "./RenderKeyword";
import { useNavigate } from "react-router-dom";
import ListMenu from "./Menus/ListMenu";
import ChallengeDialog from "./Dialogs/ChallengeDialog";
import { useNotify } from "Utils/hooks";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import axios from "axios";
import { getToken } from "Shared/baseURL";
import Reminder from "Components/Reminder/Reminder";
import ShareList from "Components/ShareList/ShareList";

// Validation Schema
const EmailValidation = Yup.object().shape({
  challengeEmail: Yup.string().email().required(),
});

// components
const DeleteKewordal = lazy(() =>
  import("Components/DeleteKewordalList/DeleteKewordalList")
);
const EditKewordal = lazy(() =>
  import("Components/EditKewordalList/EditKewordalList")
);
const AddKeyword = lazy(() => import("Components/EditKeyword/EditKeyword"));
const LinkList = lazy(() => import("Components/LinkList/LinkList"));
const PlayGame = lazy(() => import("Components/PlayGame/PlayGame"));

const SortableItem = SortableElement(
  ({ keyword, keywords, refresh, listHref, subjectHref }) => (
    <RenderKeyword
      key={keyword.uuid}
      keyword={keyword}
      keywordPosition={keyword.position}
      totalKeywords={keywords.length}
      subjectHref={subjectHref}
      listHref={listHref}
      refresh={refresh}
    />
  )
);

const RenderList = ({ list, subject, refresh }) => {
  //props
  const { name, uuid, href: listHref, keywords } = list;
  const { subjectHref } = subject;

  // state
  const notify = useNotify();
  const [openDeleteModal, setDeleteListModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [addKeywordModal, setAddKeywordModal] = useState(false);
  const [linkListModal, setLinkListModal] = useState(false);
  const [playGame, setPlayGame] = useState(false);
  const [challengeEmail, setChallengeEmail] = useState("");
  const [challenge, setChallenge] = useState(false);
  const [challengedGame, setChallengedGame] = useState(null);
  const [emailDialog, setEmailDialog] = useState(false);
  const [allKeywords, setAllKeywords] = useState([]);
  const [challengeUserID, setChallengeUserID] = useState("");
  const [shareList, setShareList] = useState(false);

  // router
  const navigate = useNavigate();

  useEffect(() => {
    if (keywords) {
      let arr = keywords
        .filter((item) => item.term && item.uuid)
        .map((item, idx) => ({
          ...item,
          position: parseInt(item.position) ?? idx + 1,
        }));

      setAllKeywords(arr);
    }
  }, [keywords]);

  const handleOpenDeleteModal = () => {
    handleCloseListMenu(false);
    setDeleteListModal(true);
  };

  const handleOpenEditModal = () => {
    handleCloseListMenu(false);
    setOpenEditModal(true);
  };

  const handleOpenListMenu = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseListMenu = () => {
    setMenuAnchor(null);
  };

  const handleOpenAddKeyword = () => {
    setAddKeywordModal(true);
    handleCloseListMenu();
  };

  const handleLinkList = () => {
    setLinkListModal(true);
    handleCloseListMenu();
  };

  const handleViewList = () => {
    navigate(`/view-list/${subject.subjectHref}/${listHref}`);
  };

  const handlePlayGame = () => {
    setPlayGame(true);
    handleCloseListMenu();
  };

  const handleChallengeFriend = async () => {
    const check = await EmailValidation.isValid({ challengeEmail });
    if (!check) {
      notify("Please provide valid email.", { variant: "error" });
      return;
    }
    handleCloseEmailDialog();
    setChallenge(true);
    setPlayGame(true);
  };

  const handleCloseEmailDialog = () => {
    setEmailDialog(false);
    handleCloseListMenu();
  };

  const handleChallengeGame = () => {
    setEmailDialog(true);
  };

  const handleShareList = () => {
    setShareList(true);
    handleCloseListMenu();
  };

  const SortableList = SortableContainer(
    ({ keywords, refresh, subjectHref, listHref }) => {
      return (
        <div>
          {keywords.map((item, index) => (
            <SortableItem
              index={index}
              keyword={item}
              keywords={keywords}
              key={item.uuid}
              refresh={refresh}
              subjectHref={subjectHref}
              listHref={listHref}
            />
          ))}
        </div>
      );
    }
  );

  const onSortEnd = ({ oldIndex, newIndex }) => {
    let newArr = arrayMoveImmutable(allKeywords, oldIndex, newIndex);
    setAllKeywords(newArr);
    saveSortedArrayInBackend(newArr);
  };

  const saveSortedArrayInBackend = async (keywords) => {
    try {
      const data = keywords.map((item) => item.uuid);
      const token = await getToken();
      await axios.patch(
        "/keywords/sort",
        { uuids: data },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify("Keywords sorted");
      refresh();
    } catch (error) {
      notify(error.message, { variant: "error" });
    }
  };

  return (
    <>
      <div className="list-card">
        <div className="title-div">
          <span
            className="list-title"
            onClick={handleViewList}
            title="View list"
          >
            {name}
            <Tooltip title="Keywords created or located in lists are not verified for relevance">
              <InfoOutlined fontSize="small" className="ms-2" />
            </Tooltip>
          </span>
          <div className="d-flex ">
            <IconButton
              onClick={handleOpenListMenu}
              size="small"
              sx={{ color: "white" }}
            >
              <Menu />
            </IconButton>
            <Reminder size="small" color="white" />
          </div>
        </div>
        <div className="keywords-container custom-scroll">
          <List disablePadding dense>
            <SortableList
              keywords={allKeywords}
              refresh={refresh}
              onSortEnd={onSortEnd}
              pressDelay={200}
              subjectHref={subjectHref}
              listHref={listHref}
            />
          </List>
        </div>
      </div>
      {menuAnchor && (
        <ListMenu
          menuAnchor={menuAnchor}
          handleLinkList={handleLinkList}
          handleCloseListMenu={handleCloseListMenu}
          handleOpenAddKeyword={handleOpenAddKeyword}
          handleOpenDeleteModal={handleOpenDeleteModal}
          handleOpenEditModal={handleOpenEditModal}
          handlePlayGame={handlePlayGame}
          handleChallengeGame={handleChallengeGame}
          keywordsCount={keywords.length}
          handleShareList={handleShareList}
        />
      )}
      {openDeleteModal && (
        <DeleteKewordal
          open={openDeleteModal}
          setDeleteListModal={setDeleteListModal}
          id={uuid}
          refresh={refresh}
          totalKeywords={
            Array.isArray(keywords) &&
            keywords.filter((item) => item.uuid).length
          }
          name={name}
        />
      )}
      {linkListModal && (
        <LinkList
          open={linkListModal}
          close={setLinkListModal}
          listID={uuid}
          refresh={refresh}
        />
      )}
      {/* Edit Kewordal List */}
      {openEditModal && (
        <EditKewordal
          open={openEditModal}
          id={uuid}
          listName={name}
          setOpenEditModal={setOpenEditModal}
          subject={subject}
          refresh={refresh}
        />
      )}
      {/* Add Keyword in list */}
      {addKeywordModal && (
        <AddKeyword
          open={addKeywordModal}
          close={setAddKeywordModal}
          subjectHref={subject.subjectHref}
          listName={name}
          listHref={listHref}
          refresh={refresh}
          addKeyword
        />
      )}
      {playGame && (
        <PlayGame
          open={playGame}
          listHref={listHref}
          close={setPlayGame}
          challengeEmail={challengeEmail}
          setChallengeEmail={setChallengeEmail}
          challengeUserID={challengeUserID}
          setChallengeUserID={setChallengeUserID}
          challenge={challenge}
          toggleChallenge={setChallenge}
          listAttributes={{
            uuid,
            name,
          }}
          challengedGame={challengedGame}
          setChallengedGame={setChallengedGame}
        />
      )}
      {emailDialog && (
        <ChallengeDialog
          emailDialog={emailDialog}
          setEmailDialog={setEmailDialog}
          challengeEmail={challengeEmail}
          handleChallengeFriend={handleChallengeFriend}
          handleCloseEmailDialog={handleCloseEmailDialog}
          setChallengeEmail={setChallengeEmail}
          setChallengeUserID={setChallengeUserID}
        />
      )}
      {shareList && (
        <ShareList listID={uuid} open={shareList} toggle={setShareList} />
      )}
    </>
  );
};

export default RenderList;
