import axios from "axios";
import { basicAuthHeaders, getClientToken } from "../../Shared/baseURL";

export const requestLogin = async (values) => {
  try {
    const params = new URLSearchParams();
    params.append("username", values.username);
    params.append("password", values.password);
    const res = await axios.post("/oauth/token", params, {
      params: {
        grant_type: "password",
      },
      auth: {
        ...basicAuthHeaders,
      },
    });
    return res;
  } catch (error) {
    if (error.response) {
      const { data, status, statusText } = error.response;
      return {
        status,
        message: data.message || data.error_description || statusText,
      };
    }
  }
};

export const requestGoogleLogin = async (accessToken) => {
  const token = await getClientToken();
  return axios
    .post(
      "/users/login/google",
      { accessToken },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data);
};

export const handleFacebookLogin = async (accessToken) => {
  try {
    const token = await getClientToken();
    return await axios.post(
      "/users/login/facebook",
      { accessToken },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    if (error.response) {
      const { data, status, statusText } = error.response;
      return {
        status,
        message: data.message || data.error_description || statusText,
      };
    }
  }
};
