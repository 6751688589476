import "./styles.scss";

import {
  Avatar,
  AvatarGroup,
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
} from "@mui/material";
import { Send } from "@mui/icons-material";
import React, { useRef, useState } from "react";
import { useNotify } from "Utils/hooks";

import RenderComments from "Components/RenderListComments/RenderListComments";

import Spinner from "Components/Spinner/Spinner";
import axios from "axios";
import { getToken } from "Shared/baseURL";
import { useEffect } from "react";
import { usePostsCommentsData } from "Utils/posts";
import PostActions from "./PostActions";

const PostComments = ({
  postID,
  commentsInView,
  likes,
  refetchPosts,
  dislikes,
  user,
  repostedBy = [],
  postHref,
  content = "",
}) => {
  // ref
  const commentInputRef = useRef();

  //   state
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const [totalReposts, setTotalReposts] = useState(0);
  const [comment, setComment] = useState("");
  const [textForLikes, setTextForLikes] = useState("");
  const [moderationErrors, setModerationErrors] = useState("");

  // queries
  const {
    isLoading: commentsLoading,
    data: commentsData,
    refetch,
    hasNextPage: hasNextComments,
    fetchNextPage: fetchNextComments,
    isFetchingNextPage: isFetchingNextComments,
  } = usePostsCommentsData(postID, commentsInView);

  useEffect(() => {
    let likesLength = likes?.length;
    let text = "";
    if (likesLength) {
      switch (likes?.length) {
        case 0: {
          text = "0 liked this.";
          break;
        }
        case 1: {
          let firstUser = likes[0];
          text = `${firstUser.username} liked this.`;
          break;
        }
        default: {
          let firstUser = likes[0];
          text = `${firstUser.username} and ${
            likesLength - 1
          } other liked this.`;
          break;
        }
      }
    }
    setTextForLikes(text);
  }, [likes]);

  useEffect(() => {
    if (repostedBy) {
      setTotalReposts(repostedBy.length);
    }
  }, [repostedBy]);

  const handleChangeComment = (e) => {
    setComment(e.target.value);
  };

  const handlePressEnter = (e) => {
    if (e.key === "Enter") handlePostComment();
  };

  const handlePostComment = async () => {
    setModerationErrors("");
    if (!comment) {
      return;
    }
    try {
      setIsLoading(true);
      const token = await getToken();
      await axios.post(
        `posts/${postID}/comments`,
        { comment },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify("Comment posted.");
      setComment("");
      refetch();
    } catch (error) {
      if (error?.response?.data?.message === "MODERATION_UNSUCCESSFUL") {
        if (error?.response?.data?.errors) {
          let errors = [];
          error?.response?.data?.errors?.forEach((err) => {
            const { kewordalModerationLabels } = err;
            kewordalModerationLabels.forEach((item) => {
              errors.push(item.name);
            });
          });
          setModerationErrors(errors.join(", "));
        }

        notify("The content was not uploaded because it was inappropriate.", {
          variant: "error",
        });
      } else {
        notify(error.message, { variant: "error" });
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (commentsLoading) {
    return <Spinner size={25} />;
  }

  return (
    <div className="post-comments-component">
      <Divider />
      <div className="info-div">
        <div className="avatar-div">
          <AvatarGroup max={4}>
            {likes?.map((item, idx) => (
              <Avatar
                alt={item?.username}
                src={item?.avatarURL}
                key={item.username + idx}
              />
            ))}
          </AvatarGroup>
          <span>{textForLikes}</span>
        </div>
        <div className="comments-reposts">
          <span>{commentsData?.pages[0].pagination.total} comment(s)</span>
          <span> • </span>
          <span>{totalReposts} repost(s)</span>
        </div>
      </div>
      <Divider />
      <div className="btn-div">
        <PostActions
          user={user}
          postID={postID}
          dislikes={dislikes}
          likes={likes}
          refetchPosts={refetchPosts}
          postHref={postHref}
          content={content}
        />
      </div>
      <Divider />
      <div className="comments-list custom-scroll-thin">
        {commentsData?.pages.map((pagesData) =>
          pagesData?.comments.map((item) => (
            <>
              <RenderComments
                key={item.uuid}
                commentID={item.uuid}
                comment={item.comment}
                name={item.user.username}
                date={item.createdAt}
                image={item.user.avatarURL}
                userID={item.user.username}
                refetch={refetch}
              />
              <Divider className="comment-divider" />
            </>
          ))
        )}
      </div>
      {hasNextComments && !isFetchingNextComments && (
        <div className="center">
          <Button size="small" color="secondary" onClick={fetchNextComments}>
            Load More
          </Button>
        </div>
      )}
      {isFetchingNextComments && <Spinner size={20} />}
      <div className="post-comment-div">
        <Input
          ref={commentInputRef}
          value={comment}
          onChange={handleChangeComment}
          onKeyUp={handlePressEnter}
          placeholder="Write a comment"
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                disabled={!comment}
                color="primary"
                size="small"
                aria-label="toggle password visibility"
                onClick={handlePostComment}
                edge="end"
              >
                {isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  <Send sx={{ rotate: "-45deg" }} />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
        {moderationErrors && (
          <FormHelperText error>
            This field contains inappropriate words "{moderationErrors}"
          </FormHelperText>
        )}
      </div>
    </div>
  );
};

export default PostComments;
