import { useState, useEffect } from "react";

const ReadMore = ({ text = "", textLimit = 600 }) => {
  // state
  const [showWholeText, setShowWholeText] = useState(false);
  const [initialText, setInitialText] = useState("");
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    if (text) {
      // if text is less or equal than 300
      if (text.length <= textLimit) {
        setShowWholeText(true);
      } else {
        setInitialText(text.toString().substring(0, textLimit));
        setHasMore(true);
        setShowWholeText(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, textLimit]);

  const handleMore = () => {
    const initial = text
      .toString()
      .substring(0, initialText.length + textLimit);
    setInitialText(initial);
    if (initial.length === text.length) {
      setHasMore(false);
    } else {
      setHasMore(true);
    }
  };

  if (showWholeText) {
    return <span dangerouslySetInnerHTML={{ __html: text }}></span>;
  } else {
    return (
      <>
        <span dangerouslySetInnerHTML={{ __html: initialText }}></span>
        {hasMore && (
          <span
            onClick={handleMore}
            className="blue cursor-pointer"
            size="small"
          >
            ...Read More
          </span>
        )}
      </>
    );
  }
};

export default ReadMore;
