import "./index.scss";
import "bootstrap/dist/css/bootstrap.min.css"; // css file
import "bootstrap/dist/js/bootstrap.min.js"; // js file
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Collapse, IconButton } from "@mui/material";
import { QueryClient, QueryClientProvider } from "react-query";
import { persistor, store } from "./Redux/createStore";
import { snackBarPlacement, theme } from "./theme/themeConfig";
import AOS from "aos";
import App, { ReactHeader, ReactFooter, ReactContent } from "./App";
import { BrowserRouter } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { StyledEngineProvider } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { createRef } from "react";
import { createRoot } from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "buffer";

AOS.init({});

// React 18 API
const rootApp = document.getElementById("root");
const root = createRoot(rootApp);

// ----- for testing -----
const header = document.getElementById("header");
// const rootHeader = createRoot(header);

// const content = document.getElementById("content");
// const rootContent = createRoot(content);

const footer = document.getElementById("footer");
// const rootFooter = createRoot(footer);

// ------------------------------

// notistack
const notistackRef = createRef();
const dismissNotification = (key) => {
  notistackRef.current.closeSnackbar(key);
};

// react query
const queryClient = new QueryClient();

const ReactApp = (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_OAUTH_API_KEY}>
          <BrowserRouter>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <SnackbarProvider
                  ref={notistackRef}
                  anchorOrigin={snackBarPlacement}
                  autoHideDuration={3000}
                  variant="success"
                  preventDuplicate
                  maxSnack={1}
                  TransitionComponent={Collapse}
                  action={(key) => (
                    <IconButton
                      onClick={() => dismissNotification(key)}
                      sx={{ color: "white" }}
                      size="small"
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  )}
                >
                  <App />
                </SnackbarProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </BrowserRouter>
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </PersistGate>
  </Provider>
);

const ReactRenderHeader = (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <SnackbarProvider
                ref={notistackRef}
                anchorOrigin={snackBarPlacement}
                autoHideDuration={3000}
                variant="success"
                preventDuplicate
                maxSnack={1}
                TransitionComponent={Collapse}
                action={(key) => (
                  <IconButton
                    onClick={() => dismissNotification(key)}
                    sx={{ color: "white" }}
                    size="small"
                  >
                    <Close fontSize="small" />
                  </IconButton>
                )}
              >
                <ReactHeader />
              </SnackbarProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </PersistGate>
  </Provider>
);

// const ReactRenderContent = (
//   <Provider store={store}>
//     <PersistGate persistor={persistor}>
//       <QueryClientProvider client={queryClient}>
//         <BrowserRouter>
//           <StyledEngineProvider injectFirst>
//             <ThemeProvider theme={theme}>
//               <SnackbarProvider
//                 ref={notistackRef}
//                 anchorOrigin={snackBarPlacement}
//                 autoHideDuration={3000}
//                 variant="success"
//                 preventDuplicate
//                 maxSnack={1}
//                 TransitionComponent={Collapse}
//                 action={(key) => (
//                   <IconButton
//                     onClick={() => dismissNotification(key)}
//                     sx={{ color: "white" }}
//                     size="small"
//                   >
//                     <Close fontSize="small" />
//                   </IconButton>
//                 )}
//               >
//                 <ReactContent />
//               </SnackbarProvider>
//             </ThemeProvider>
//           </StyledEngineProvider>
//         </BrowserRouter>
//       </QueryClientProvider>
//     </PersistGate>
//   </Provider>
// );

const ReactRenderFooter = (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <SnackbarProvider
                ref={notistackRef}
                anchorOrigin={snackBarPlacement}
                autoHideDuration={3000}
                variant="success"
                preventDuplicate
                maxSnack={1}
                TransitionComponent={Collapse}
                action={(key) => (
                  <IconButton
                    onClick={() => dismissNotification(key)}
                    sx={{ color: "white" }}
                    size="small"
                  >
                    <Close fontSize="small" />
                  </IconButton>
                )}
              >
                <ReactFooter />
              </SnackbarProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </PersistGate>
  </Provider>
);

// rootHeader.render(ReactRenderHeader);
// rootContent.render(ReactRenderContent);
// rootFooter.render(ReactRenderFooter);

const pathname = window.location.pathname;

// home seo div
const homeSeoContent = document.getElementById("home-content-seo");

if (pathname !== "/") {
  if (homeSeoContent) {
    homeSeoContent.remove(homeSeoContent);
  }
}

// build whole app when root element is empty
if (!rootApp.innerHTML || pathname === "/") {
  root.render(ReactApp);
} else {
  if (header && footer) {
    const rootContainer = document.getElementById("root");
    if (rootContainer) {
      rootContainer.classList.add("custom-container");
    }

    const rootHeader = createRoot(header);
    const rootFooter = createRoot(footer);

    rootHeader.render(ReactRenderHeader);
    rootFooter.render(ReactRenderFooter);
  }
}
