import {
  Box,
  Button,
  Dialog,
  DialogContent,
  LinearProgress,
} from "@mui/material";
import { Close, Done } from "@mui/icons-material";
import React, { useState } from "react";

import axios from "axios";
import { getProfile } from "../../Redux/Actions";
import { getToken } from "../../Shared/baseURL";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

const UnFollowDialog = ({
  open,
  setUnfollowDialog,
  currentUserID,
  unfollowUserID,
  setSelectedUserMenu,
  refetch,
}) => {
  // state
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar: notify } = useSnackbar();

  // redux
  const dispatch = useDispatch();

  const closeModal = () => {
    if (setUnfollowDialog) setUnfollowDialog(false);
    if (setSelectedUserMenu) setSelectedUserMenu(null);
  };

  const handleDisconnect = async () => {
    try {
      setIsLoading(true);
      const token = await getToken();
      const res = await axios.delete(
        `/users/${currentUserID}/FOLLOWS/users/${unfollowUserID}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        let token = localStorage.getItem("userToken");
        dispatch(getProfile(token));
        closeModal();
        notify("User unfollow.");
        if (refetch) {
          refetch();
        }
      } else {
        throw new Error("Error unfollow user.");
      }
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box className="disconnect-dialog">
      <Dialog
        open={open}
        onClose={closeModal}
        aria-labelledby="disconnect-dialog"
        fullWidth
        maxWidth="xs"
      >
        <DialogContent>
          <Box sx={{ py: 2 }}>
            <p className="text-center fw-500" style={{ fontSize: "17px" }}>
              Unfollow this user?
            </p>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "20px",
                mt: 3,
              }}
            >
              <Button
                onClick={closeModal}
                color="secondary"
                variant="outlined"
                startIcon={<Close fontSize="small" />}
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                onClick={handleDisconnect}
                color="error"
                variant="contained"
                disableElevation
                startIcon={<Done fontSize="small" />}
                disabled={isLoading}
              >
                Yes
              </Button>
            </Box>
            {isLoading && (
              <Box mt={2}>
                <LinearProgress />
              </Box>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default UnFollowDialog;
