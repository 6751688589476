import "./styles.scss";

import { Avatar, Button, Paper, Tooltip } from "@mui/material";
import { lazy, useState } from "react";

import DummyImage1 from "assets/images/logo.svg";
import ListComments from "Components/ListComments/ListComments";
import dayjs from "dayjs";
import { uniqBy } from "lodash";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

// components
const PlayGame = lazy(() => import("Components/PlayGame/PlayGame"));

const SearchList = ({ listItem, handleSearch }) => {
  // props
  let {
    name,
    subject,
    author,
    keywordCount,
    uuid,
    subjectHref,
    image,
    href,
    createdAt,
    likes,
    dislikes,
    reportedBy,
    mainKeywordImg,
  } = listItem;

  // state
  const [playGame, setPlayGame] = useState(false);

  // observer
  const { ref: commentsRef, inView: commentsInView } = useInView({});

  // router
  const navigate = useNavigate();

  // removing duplicates
  author = uniqBy(author, "uuid");
  author = author[0];

  const handlePlayGame = () => {
    setPlayGame(true);
  };

  const handleViewList = () => {
    navigate(`/view-list/${subjectHref}/${href}`);
  };

  const handleViewUser = () => {
    if (author.username) {
      navigate(`/my-network/${author.username}`);
    }
  };

  return (
    <Paper elevation={2} className="search-list pb-3 mb-1 rounded-4">
      <div className="d-flex align-items-center justify-content-between p-3 pb-0 flex-wrap">
        <span className="title" onClick={handleViewList}>
          {name}
        </span>
        <div className="d-flex gap-2">
          <Tooltip
            arrow
            title={
              keywordCount < 4
                ? "At least 4 keywords are required to play game."
                : ""
            }
          >
            <span>
              <Button
                disabled={keywordCount < 4 ? true : false}
                onClick={handlePlayGame}
                disableElevation
                variant="contained"
                className={`custom-btn ${keywordCount < 4 ? "" : "green-btn"}`}
                size="small"
              >
                Game
              </Button>
            </span>
          </Tooltip>
          <Button
            onClick={handleViewList}
            disableElevation
            variant="contained"
            className="custom-btn "
            size="small"
          >
            View
          </Button>
        </div>
      </div>
      <i className="text-muted from">From</i>
      <div className="title-div mt-2 px-3">
        <div onClick={handleViewUser} className="d-flex gap-2 cursor-pointer">
          <Avatar src={author?.avatarURL} alt={"username"} />
          <div className="d-flex flex-column">
            <span className="fw-500">{author?.username}</span>
            <span className="text-muted" style={{ fontSize: "11px" }}>
              {dayjs(createdAt).fromNow()}
            </span>
          </div>
        </div>
      </div>
      {mainKeywordImg && (
        <div
          onClick={handleViewList}
          style={{
            backgroundImage: `url(${mainKeywordImg || image || DummyImage1})`,
          }}
          className="image-div"
        />
      )}
      <div className="p-3">
        <span className="subject"> Subject: {subject}</span>
      </div>
      <div ref={commentsRef}>
        <ListComments
          listID={uuid}
          commentsInView={commentsInView}
          likes={likes}
          reportedBy={reportedBy}
          dislikes={dislikes}
          refetchListData={handleSearch}
        />
      </div>
      {playGame && (
        <PlayGame open={playGame} close={setPlayGame} listHref={href} />
      )}
    </Paper>
  );
};

export default SearchList;
