import "./styles.scss";

import { Avatar, IconButton } from "@mui/material";
import RelativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useNotify, useReduxUserProfile } from "Utils/hooks";
import { Delete } from "@mui/icons-material";
import { useState } from "react";
import { getToken } from "Shared/baseURL";
import axios from "axios";

dayjs.extend(RelativeTime);

const RenderListComments = ({
  name = "",
  comment = "",
  date = "",
  image = "",
  userID,
  commentID,
  refetch,
}) => {
  // state
  const notify = useNotify();
  const [btnLoading, setBtnLoading] = useState(false);

  // router
  const navigate = useNavigate();

  // redux
  const userProfile = useReduxUserProfile();

  const viewUserProfile = () => {
    if (userID) {
      navigate(`/my-network/${userID}`);
    }
  };

  const handleDeleteComment = async () => {
    try {
      setBtnLoading(true);
      const token = await getToken();
      await axios.delete(`/comments/${commentID}`, {
        headers: { Authorization: token },
      });
      notify("Comment deleted.");
      if (refetch) {
        refetch();
      }
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <div className="render-list-comments-component">
      <Avatar
        src={image}
        alt={name}
        sx={{ mt: "0.4rem" }}
        onClick={viewUserProfile}
        className="cursor-pointer"
      />
      <div className="comment-info">
        <div className="name-div">
          <span onClick={viewUserProfile}>{name}</span>
          <div className="date-div">
            <span>{dayjs(date).fromNow()}</span>
          </div>
        </div>
        <div className="comment">
          <span>{comment}</span>
          {userProfile && userProfile.username === userID && (
            <IconButton
              size="small"
              className="delete-btn"
              color="error"
              onClick={handleDeleteComment}
              disabled={btnLoading}
            >
              <Delete fontSize="small" />
            </IconButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default RenderListComments;
