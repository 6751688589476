import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import "./styles.scss";

// Api
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { getUserByID, UpdatePassword } from "./Api";
import { useNotify } from "Utils/hooks";

// Components
const MotionBox = motion(Box);

const passReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;

const SubmitSchema = Yup.object().shape({
  password: Yup.string()
    .matches(passReg, {
      message:
        "Password must be between 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter",
    })
    .required("Required"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password")],
    "Password does not match"
  ),
});

const ResetPassword = () => {
  // state
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [userData, setUserData] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [alert, setAlert] = useState({ msg: "", type: "success" });

  // router
  const { userID } = useParams();

  // framer motion
  const fadeIn = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0 },
  };

  useEffect(() => {
    if (userID) {
      getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID]);

  const getUser = async () => {
    try {
      const res = await getUserByID(userID);
      switch (res.status) {
        case 200: {
          setStatus(200);
          setUserData(res.data);
          break;
        }
        case 404: {
          setStatus(404);
          break;
        }
        default: {
          break;
        }
      }
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setAlert({ msg: "", type: "error" });
    try {
      setBtnLoading(true);
      let validate = await SubmitSchema.validate({ password, confirmPassword });
      if (validate) {
        const res = await UpdatePassword(userID, password);
        if (res.message) {
          throw new Error(res.message);
        }
        setAlert({ msg: "Password updated.", type: "success" });
        setPassword("");
        setConfirmPassword("");
      }
    } catch (error) {
      setAlert({ msg: error.message, type: "error" });
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <Container className="reset-password-page">
      {isLoading ? (
        <Box className="center">
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {status === 404 ? (
            <Box className="show-error">
              <p>404</p>
              <span>User not found.</span>
            </Box>
          ) : status === 200 ? (
            <MotionBox
              variants={fadeIn}
              initial="hidden"
              animate="visible"
              className="show-form"
            >
              <p className="title">Reset Password</p>
              <p className="sub-title">
                Enter a new password for your{" "}
                <span className="fw-500">{userData.email}</span> account.
              </p>
              <Box>
                {alert.msg && (
                  <Alert severity={alert.type} variant="filled">
                    {alert.msg}
                  </Alert>
                )}
              </Box>
              <Box className="form-box">
                <TextField
                  autoFocus
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  label="New Password"
                  placeholder="Enter new password"
                  variant="filled"
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOffOutlined />
                          ) : (
                            <VisibilityOutlined />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  helperText="Password must be between 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter"
                />
                <TextField
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  label="Confirm Password"
                  placeholder="Enter confirm password"
                  variant="filled"
                  fullWidth
                  type={showConfirmPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOffOutlined />
                          ) : (
                            <VisibilityOutlined />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ mt: 3 }}
                />
              </Box>
              <LoadingButton
                onClick={handleSubmit}
                loading={btnLoading}
                variant="contained"
                sx={{ mt: 3, width: { xs: "100%", md: "auto" } }}
              >
                Submit
              </LoadingButton>
            </MotionBox>
          ) : (
            <></>
          )}
        </Box>
      )}
    </Container>
  );
};

export default ResetPassword;
