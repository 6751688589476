export const getUsernameComponent = (to, related) => {
  let name = "";

  switch (related) {
    case "1st": {
      name = getNameComponent(to, related);
      break;
    }
    case "2nd": {
      name = getNameComponent(to, related);
      break;
    }
    case "3rd": {
      name = getNameComponent(to, related);
      break;
    }
    default: {
      name = getNameComponent("Out of network");
    }
  }

  return name;
};

const getNameComponent = (to, related = "") => {
  return (
    <span style={{ position: "relative" }}>
      {to}
      {related && (
        <small
          style={{ position: "absolute", top: -15, right: -20, color: "gray" }}
        >
          ({related})
        </small>
      )}
    </span>
  );
};
