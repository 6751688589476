import {
  Add,
  AddLinkOutlined,
  DeleteOutline,
  ExtensionOutlined,
  SettingsOutlined,
  Share,
  SportsEsportsOutlined,
} from "@mui/icons-material";
import {
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Menu as MuiMenu,
} from "@mui/material";
import React from "react";

const ListMenu = ({
  menuAnchor,
  handleCloseListMenu,
  handleOpenAddKeyword,
  handleOpenEditModal,
  handleLinkList,
  handleOpenDeleteModal,
  handlePlayGame,
  keywordsCount,
  handleChallengeGame,
  handleShareList,
}) => {
  return (
    <MuiMenu
      anchorEl={menuAnchor}
      open={Boolean(menuAnchor)}
      onClose={handleCloseListMenu}
      PaperProps={{
        sx: {
          borderRadius: "10px",
          px: 2,
        },
      }}
    >
      <MenuList dense>
        <MenuItem disableGutters onClick={handleOpenAddKeyword}>
          <ListItemIcon>
            <Add />
          </ListItemIcon>
          <ListItemText>Add Keyword</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem disableGutters onClick={handleOpenEditModal}>
          <ListItemIcon>
            <SettingsOutlined />
          </ListItemIcon>
          <ListItemText>Edit Kewordal List</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem disableGutters onClick={handleShareList}>
          <ListItemIcon>
            <Share />
          </ListItemIcon>
          <ListItemText>Share</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem disableGutters onClick={handleLinkList}>
          <ListItemIcon>
            <AddLinkOutlined />
          </ListItemIcon>
          <ListItemText>Link List</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          disableGutters
          onClick={handlePlayGame}
          disabled={keywordsCount < 4}
        >
          <ListItemIcon>
            <ExtensionOutlined />
          </ListItemIcon>
          <ListItemText>Test Yourself</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          disableGutters
          onClick={handleChallengeGame}
          disabled={keywordsCount < 4}
        >
          <ListItemIcon>
            <SportsEsportsOutlined />
          </ListItemIcon>
          <ListItemText>Challenge a Friend</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem disableGutters onClick={handleOpenDeleteModal}>
          <ListItemIcon>
            <DeleteOutline />
          </ListItemIcon>
          <ListItemText>Delete Kewordal List</ListItemText>
        </MenuItem>
      </MenuList>
    </MuiMenu>
  );
};

export default ListMenu;
