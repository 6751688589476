import "./styles.scss";

import { Container, Grid } from "@mui/material";
import AccountManagement from "Components/AccountManagement/AccountManagement";
import { Outlet } from "react-router-dom";

const EditProfile = () => {
  return (
    <div className="edit-profile-page">
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <AccountManagement />
          </Grid>
          <Grid item xs={12} md={9}>
            <Outlet />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default EditProfile;
