import { Box, Button, CircularProgress, Grid } from "@mui/material";
import Advertisement from "Components/Advertisement/Advertisement";
import EmptySearch from "Components/EmptySearch/EmptySearch";
import { Search } from "@mui/icons-material";
import SearchPeopleList from "Components/SearchPeopleList/SearchPeopleList";
import React from "react";

const PeoplesTab = ({
  searchQuery,
  setActiveTab,
  postCount,
  peopleCount,
  allPeopleList,
  keywordsCount,
  listCount,
  usersLoading,
  fetchNextUsers,
  hasNextUsers,
  isFetchingNextUsers,
  refetchUsers,
  isFetchingUsers,
  initialRender,
}) => {
  if (initialRender) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ my: 2 }}>
            <h5 className="text-muted">
              <Search /> Type to search...
            </h5>
          </Box>
        </Grid>
      </Grid>
    );
  }

  if (usersLoading || isFetchingNextUsers || isFetchingUsers) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ my: 2 }} className="center">
            <CircularProgress />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <div>
      <span
        style={{
          marginTop: "1rem",
          marginLeft: "5px",
          marginBottom: "1rem",
          display: "inline-block",
          color: "#666666",
        }}
      >
        {searchQuery && `${peopleCount} results found for "${searchQuery}"`}
      </span>
      <Grid key="people-list" container>
        {allPeopleList.length === 0 ? (
          <Grid item xs={12}>
            <EmptySearch
              searchQuery={searchQuery}
              tabName="people"
              posts={postCount}
              keywordsCount={keywordsCount}
              setActiveTab={setActiveTab}
              people={peopleCount}
              lists={listCount}
              kewords={keywordsCount}
            />
          </Grid>
        ) : (
          allPeopleList?.map((item, idx) => (
            <>
              <Grid item xs={12}>
                <SearchPeopleList
                  handleSearch={refetchUsers}
                  user={item}
                  refetch={refetchUsers}
                />
              </Grid>
              {idx % 2 === 0 && (
                <Grid
                  item
                  xs={12}
                  key={`advertisement-` + idx}
                  className="mb-3"
                >
                  <Advertisement />
                </Grid>
              )}
            </>
          ))
        )}
        {hasNextUsers && (
          <Grid item xs={12}>
            <div className="mt-1 center">
              <Button disabled={isFetchingNextUsers} onClick={fetchNextUsers}>
                {isFetchingNextUsers ? "Loading..." : "Load More"}
              </Button>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default PeoplesTab;
