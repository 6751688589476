import "./styles.scss";
import { Container } from "@mui/material";
import BackgroundImage from "./BackgroundImage";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import MainContent from "./MainContent";
import { useNotify, useReduxUserProfile } from "Utils/hooks";
import Endorse from "./Endorse";

const BusinessProfile = () => {
  // state
  const notify = useNotify();
  const [endorse, setEndorse] = useState(false);

  // redux
  const reduxUser = useReduxUserProfile();

  const handleShare = () => {
    notify("Link copied.", { variant: "default" });
  };

  const handleEndorse = () => {
    setEndorse(true);
  };

  return (
    <div className="business-profile-container">
      <BackgroundImage />
      <div className="header">
        <Container className="container">
          {reduxUser ? (
            <>
              <Link to="#" className="links">
                Update Profile
              </Link>
              <Link to="#" className="links">
                Your updates
              </Link>
            </>
          ) : (
            <>
              <Link to="#" className="links">
                Follow
              </Link>
              <Link to="#" className="links" onClick={handleEndorse}>
                Endorse
              </Link>
              <Link to="#" className="links" onClick={handleShare}>
                Share
              </Link>
            </>
          )}
        </Container>
      </div>
      <MainContent />

      {/* Dialogs */}
      <Endorse open={endorse} toggle={setEndorse} />
    </div>
  );
};

export default BusinessProfile;
