import React from "react";
import Spinner from "Components/Spinner/Spinner";
import classes from "./styles.module.scss";

const FullScreenSpinner = ({ size = 40 }) => {
  return (
    <div className={classes.main}>
      <Spinner size={size} />
      <p>Loading</p>
    </div>
  );
};

export default FullScreenSpinner;
