import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Suggestions from "Components/Suggestions/Suggestions";
import { useSelector } from "react-redux";
import { useSpecificUserDataByUsername } from "Utils/queries";

const SuggestionsDialog = ({ open, toggle }) => {
  // redux
  const currentUser = useSelector((state) => state.user.profile);

  // queries
  const { refetch: refetchUserData } = useSpecificUserDataByUsername(
    currentUser.username
  );

  const handleClose = () => {
    toggle(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>Users you might know</DialogTitle>
      <DialogContent>
        <Suggestions refetchUserData={refetchUserData} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="error">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuggestionsDialog;
