import axios from "axios";
import dayjs from "dayjs";

/**
 * @returns {string} Bearer {token}
 */
export const getToken = async () => {
  try {
    const prevLogTime = localStorage.getItem("logTime");
    if (prevLogTime) {
      const timeDiff = dayjs().diff(
        dayjs(prevLogTime).format(),
        "minutes",
        false
      );

      if (timeDiff >= 55) {
        let token = await refreshToken();
        return token;
      }
    }

    const userToken = localStorage.getItem("userToken");
    if (userToken) {
      let token = `Bearer ${userToken}`;
      return token;
    } else {
      throw new Error("Error refreshing token.");
    }
  } catch (error) {
    window.location.href = "/auth/login?token_expired=true";
    return false;
  }
};

/**
 * @returns {string} Bearer {token}
 */
export const refreshToken = async () => {
  try {
    let refreshToken = localStorage.getItem("refreshToken");
    const params = new URLSearchParams();
    params.append("refresh_token", refreshToken);
    const res = await axios.post("/oauth/token", params, {
      params: {
        grant_type: "refresh_token",
      },
      auth: {
        ...basicAuthHeaders,
      },
    });
    if (res.status === 200) {
      const { access_token, refresh_token } = res.data;
      localStorage.setItem("userToken", access_token);
      localStorage.setItem("refreshToken", refresh_token);
      localStorage.setItem("logTime", dayjs().format());
      let token = `Bearer ${access_token}`;
      return token;
    }
    throw new Error("Error refreshing token.");
  } catch (error) {
    window.location.href = "/auth/login?token_expired=true";
    return false;
  }
};

const username = process.env.REACT_APP_USERNAME;
const password = process.env.REACT_APP_PASSWORD;

export const baseURL = process.env.REACT_APP_BASEURL;

export const basicAuthHeaders = {
  username,
  password,
};

export const getClientToken = async () => {
  try {
    const res = await axios.post(
      "/oauth/token",
      {},
      {
        params: {
          grant_type: "client_credentials",
        },
        auth: {
          ...basicAuthHeaders,
        },
      }
    );
    return res.data.access_token;
  } catch (error) {
    return false;
  }
};
