import axios from "axios";
const { useQuery } = require("react-query");
const { getToken } = require("Shared/baseURL");

const fetchDeletedListData = async () => {
  const token = await getToken();
  return axios
    .get("/users/me/deleted-lists.json", {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data);
};

export const useDeletedListsData = () => {
  return useQuery(
    ["get-deleted-lists-of-user"],
    () => fetchDeletedListData(),
    {}
  );
};

const fetchDeletedKeywordsData = async () => {
  const token = await getToken();
  return axios
    .get("/users/me/deleted-keywords.json", {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data);
};

export const useDeletedKeywordsData = () => {
  return useQuery(
    ["get-deleted-keywords-of-user"],
    fetchDeletedKeywordsData,
    {}
  );
};
