import "./styles.scss";
import React, { useState, useEffect } from "react";
import { Box, Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
import { motion } from "framer-motion";
import Logo from "assets/images/logo.png";
import { useNotify, useReduxUserProfile } from "Utils/hooks";
import axios from "axios";
import { getProfile } from "Redux/Actions";
import { useDispatch } from "react-redux";
import { getToken } from "Shared/baseURL";
import ReactPlayer from "react-player";

const TutorialDialog = ({ open, toggleDialog, page, maxPage }) => {
  // state
  const notify = useNotify();
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState(1);

  // redux
  const dispatch = useDispatch();
  const userRedux = useReduxUserProfile();

  useEffect(() => {
    if (page) {
      setActiveTab(page);
    }
  }, [page]);

  useEffect(() => {
    const { showTutorial } = userRedux;
    if (showTutorial) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [userRedux]);

  const handleClose = () => {
    toggleDialog(false);
  };

  const handleNext = () => {
    const newKey = activeTab + 1;
    // if (maxPage) {
    //   if (newKey <= maxPage) {
    //     setActiveTab(newKey);
    //   } else {
    //     handleClose();
    //   }
    // } else {
    if (newKey <= 7) {
      setActiveTab(newKey);
    } else {
      handleClose();
    }
    // }
  };

  const handleDisableTutorial = async () => {
    try {
      handleClose();
      let token = await getToken();
      let data = {
        showTutorial: false,
      };
      const res = await axios.patch("/users/me", data, {
        headers: {
          Authorization: token,
        },
      });
      if (res.status === 200) {
        dispatch(getProfile(token.split(" ")[1]));
      }
    } catch (error) {
      notify(error.message, { variant: "error" });
    }
  };

  return (
    <Box>
      <Dialog
        open={open && show}
        onClose={handleClose}
        aria-labelledby="tutorial-dialog"
        fullWidth
        className="tutorial-dialog"
      >
        <DialogTitle id="tutorial-dialog"></DialogTitle>
        <DialogContent>
          <Box sx={{ pb: 8 }}>
            <TabPanel value={activeTab} index={1}>
              <Box className="img-box">
                <ReactPlayer
                  url="https://kewordal-images.s3.eu-west-1.amazonaws.com/video/kewordal-explainer-video-2-s.mp4"
                  width="100%"
                  controls
                  muted
                  playing
                />
              </Box>
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              <Box className="img-box">
                <p className="title">Welcome to</p>
                <img
                  className="logo-img"
                  src={Logo}
                  alt="logo"
                  loading="lazy"
                />
                <p className="desc">
                  Follow this short tutorial to get started!
                </p>
              </Box>
            </TabPanel>
            <TabPanel value={activeTab} index={3}>
              <Box>
                <p className="title">The Dashboard</p>
                <p className="desc text-center mt-4">
                  Here you can view all of your different subject areas that you
                  have made lists under, as well as all of your individual
                  lists.
                </p>
              </Box>
            </TabPanel>
            <TabPanel value={activeTab} index={4}>
              <Box>
                <p className="title">View Lists</p>
                <p className="desc text-center mt-4">
                  The panel on the left hand side of the page allows you to
                  navigate through all of your lists. Click on a list to
                  continue.
                </p>
              </Box>
            </TabPanel>
            <TabPanel value={activeTab} index={5}>
              <Box>
                <p className="title">Create a new keyword</p>
                <p className="desc text-center mt-4">
                  Here you can see all the keywords within this list, to add a
                  new keyword simply click on the phrase new keyword, to enter
                  your keyword and it’s description
                </p>
              </Box>
            </TabPanel>
            <TabPanel value={activeTab} index={6}>
              <Box>
                <p className="title">Test Yourself</p>
                <p className="desc text-center mt-4">
                  Awesome, now your list has 4 keywords which is the minimum
                  amount needed to test yourself. Click the test yourself button
                  to play a multiple choice game to test your knowledge of your
                  keywords
                </p>
              </Box>
            </TabPanel>
            <TabPanel value={activeTab} index={7}>
              <Box>
                <p className="title">Create your own lists</p>
                <p className="desc text-center mt-4">
                  Now you are a kewordal expert! Have a go at creating your own
                  lists and keywords!
                </p>
              </Box>
            </TabPanel>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Button
                sx={{ py: 2, mr: "6px" }}
                onClick={handleClose}
                className="gray-btn"
              >
                SKIP
              </Button>
              <Button
                sx={{ py: 2 }}
                variant="outlined"
                color="error"
                onClick={handleDisableTutorial}
                disableElevation
                className="gray-btn bg-white text-danger"
              >
                Disable
              </Button>
            </Box>
            <Box className="circle-box">
              <motion.div
                onClick={() => setActiveTab(1)}
                className={`circle ${activeTab === 1 && "active"}`}
              />
              <motion.div
                onClick={() => setActiveTab(2)}
                className={`circle ${activeTab === 2 && "active"}`}
              />
              <motion.div
                onClick={() => setActiveTab(3)}
                className={`circle ${activeTab === 3 && "active"}`}
              />
              <motion.div
                onClick={() => setActiveTab(4)}
                className={`circle ${activeTab === 4 && "active"}`}
              />
              <motion.div
                onClick={() => setActiveTab(5)}
                className={`circle ${activeTab === 5 && "active"}`}
              />
              <motion.div
                onClick={() => setActiveTab(6)}
                className={`circle ${activeTab === 6 && "active"}`}
              />
              <motion.div
                onClick={() => setActiveTab(6)}
                className={`circle ${activeTab === 7 && "active"}`}
              />
            </Box>
            <Button
              sx={{ py: 2 }}
              disableElevation
              onClick={handleNext}
              variant="contained"
            >
              {activeTab === 7 ? "Done" : "Next"}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export default TutorialDialog;
