import "./styles.scss";

import { ArrowBack, ArrowForward, Search } from "@mui/icons-material";
import {
  Container,
  Grid,
  IconButton,
  Pagination,
  PaginationItem,
  Stack,
} from "@mui/material";

import BlogCards from "Components/BlogCards/BlogCards";
import Spinner from "Components/Spinner/Spinner";
import { useHandleGetBlogs } from "./Api";
import { useEffect, useState } from "react";
import NoData from "Components/NoData/NoData";
import Advertisement from "Components/Advertisement/Advertisement";
import NewMyList from "Components/NewMyList/NewMyList";
import DashboardListCard from "Components/DashboardListCard/DashboardListCard";
import { useReduxUser } from "Utils/hooks";
import StickySidebar from "sticky-sidebar-v2";

const Blogs = () => {
  // state
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");

  // redux
  const userProfile = useReduxUser();

  // queries
  const { data, isLoading } = useHandleGetBlogs(page, query);

  useEffect(() => {
    setTimeout(() => {
      new StickySidebar(".sticky-side-grid", {
        topSpacing: 20,
        bottomSpacing: 20,
        containerSelector: ".main-container",
        innerWrapperSelector: ".sidebar__inner",
      });
    }, 200);
  }, []);

  const handleChange = (_event, value) => {
    setPage(value);
  };

  return (
    <div className="blogs-page">
      <div className="hero-container">
        <Container className="hero-wrapper" sx={{ mb: 10 }}>
          <h2 className="heading">Welcome to Our Blog</h2>
          <p className="text">
            Stay updated with the latest posts, stories, case studies and
            insights shared by the Kewordal Team
          </p>
          <div className="input-container">
            <input
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search our blog"
            />
            <IconButton size="small">
              <Search />
            </IconButton>
          </div>
        </Container>
      </div>
      {isLoading ? (
        <div className="my-5">
          <Spinner />
        </div>
      ) : (
        <Container className="main-container">
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className="pagination-container">
                    <h4 className="fw-700 m-0">Featured Articles</h4>
                    <div>
                      <IconButton size="small">
                        <ArrowBack />
                      </IconButton>
                      <IconButton size="small">
                        <ArrowForward />
                      </IconButton>
                    </div>
                  </div>
                </Grid>
                {data?.blogs.length > 0 ? (
                  data?.blogs.map((blog, idx) => (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={idx === 0 ? 12 : 6}
                        key={blog.uuid}
                      >
                        <BlogCards idx={idx} blog={blog} />
                      </Grid>
                      {idx % 2 === 0 && (
                        <Grid item xs={12}>
                          <Advertisement fullView />
                        </Grid>
                      )}
                    </>
                  ))
                ) : (
                  <Grid item sm={12}>
                    <NoData message="No blogs!" />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <div className="center mt-3">
                    <Pagination
                      count={data?.pagination?.totalPages}
                      // page={page}
                      onChange={handleChange}
                      color="primary"
                      renderItem={(item) => (
                        <PaginationItem
                          components={{
                            previous: ArrowBack,
                            next: ArrowForward,
                          }}
                          {...item}
                        />
                      )}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="sticky-side-grid">
                <div className="sidebar__inner">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={12}>
                      <Stack spacing={2}>
                        <Advertisement />
                        {userProfile && (
                          <>
                            <NewMyList />
                            <Advertisement />
                            <DashboardListCard title="Most Viewed" mostViewed />
                            <Advertisement />
                            <DashboardListCard title="Trending" trending />
                            <Advertisement />
                            <DashboardListCard title="Blogs Feed" blogsFeed />
                          </>
                        )}
                      </Stack>
                    </Grid>
                    {/* {["Most Popular"].map((titleItem) => (
                <Grid item xs={12} sm={6} md={12}>
                  <div className="related-blogs">
                    <h5>{titleItem}</h5>
                    <div className="blog-list custom-scroll-thin">
                      {Array(7)
                        .fill("items")
                        .map((item, idx) => (
                          <div
                            key={`blog-item-${idx}`}
                            className="blog-item"
                            title="Title of blog"
                          >
                            <div
                              className="blog-image-div"
                              style={{ backgroundImage: `url(${BlogImage})` }}
                            />
                            <span className="blog-title">
                              {idx % 2
                                ? "How a Simple Nature Journal Practice Can Nurture Student Engagement in Any Subject"
                                : "Four Ways to Help Students Adjust to the Classroom"}
                            </span>
                          </div>
                        ))}
                    </div>
                  </div>
                </Grid>
              ))}*/}
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      )}
    </div>
  );
};

export default Blogs;
