import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const PolicesLinks = () => {
  return (
    <div className="footer-links">
      <div className="link-wrapper">
        <p className="footer-heading">Policies</p>
        <Box
          sx={{
            display: {
              xs: "block",
              sm: "none",
            },
          }}
        >
          <hr />
        </Box>
        <Link className="link" to="/terms-of-service">
          Terms of Service
        </Link>
        <Link className="link" to="/privacy-policy">
          Privacy Policy
        </Link>
        <Link className="link" to="cookie-policy">
          Cookie Policy
        </Link>
        <Link className="link" to="safety-policy">
          Safety Policy
        </Link>
      </div>
    </div>
  );
};

export default PolicesLinks;
