import {
  CircularProgress,
  createFilterOptions,
  LinearProgress,
  Stack,
  Table,
  TableBody,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Autocomplete } from "formik-mui";
import { useState } from "react";
import * as yup from "yup";

// Api
import { useNotify } from "Utils/hooks";
import { PostInvites } from "./Api";
import RenderExistingUsers from "./RenderExistingUsers";

const EmailValidation = yup.string().email("Invalid email address");
const SubmitSchema = yup.object().shape({
  users: yup.array().min(1, "Select at least 1 user.").required("Required"),
});

const filter = createFilterOptions();

const SendInvites = ({ toggleDialog, open: modalOpen }) => {
  // state
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [alreadyExistingUsers, setAlreadyExistingUsers] = useState([]);

  // hooks
  const notify = useNotify();

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setAlreadyExistingUsers([]);
    setOpen(false);
    setLoading(false);
    toggleDialog(false);
  };

  const handleSubmit = async (values, setSubmitting) => {
    const data = values.users.map((item) => item.email);
    try {
      const res = await PostInvites({ email: data });
      if (res.status === 200) {
        const { data } = res;

        let arr = [];
        Object.entries(data).forEach(([key, value]) => {
          if (value !== "INVITED") {
            arr.push({ userID: value, email: key });
          }
        });
        setAlreadyExistingUsers(arr);

        notify("Invites sent.");
        // handleClose();
        return;
      }
      throw new Error("Error sending invites.");
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      open={modalOpen}
      onClose={handleClose}
      fullWidth
      aria-labelledby="send-invites"
    >
      <DialogTitle>Invite Users</DialogTitle>
      <DialogContent>
        <Stack mt={2} spacing={3}>
          {!alreadyExistingUsers.length && (
            <>
              <DialogContentText>
                Type and press <strong>"Enter"</strong> to add emails.
              </DialogContentText>
              <Box>
                <Formik
                  initialValues={{ users: [] }}
                  validationSchema={SubmitSchema}
                  onSubmit={(values, { setSubmitting }) =>
                    handleSubmit(values, setSubmitting)
                  }
                >
                  {({ values, isSubmitting, submitForm, setFieldValue }) => (
                    <Form autoComplete="off">
                      <Box>
                        <Field
                          name="users"
                          multiple
                          component={Autocomplete}
                          options={[]}
                          getOptionLabel={(option) => option.email}
                          filterSelectedOptions
                          loading={loading}
                          open={open}
                          autoHighlight
                          onOpen={() => {
                            setOpen(true);
                          }}
                          onClose={() => {
                            setOpen(false);
                          }}
                          onChange={(e, newVal) => {
                            if (newVal) {
                              const obj = newVal[newVal.length - 1];
                              if (obj) {
                                const isValid = EmailValidation.isValidSync(
                                  obj.email
                                );
                                if (!isValid) {
                                  notify("Invalid email address", {
                                    variant: "error",
                                  });
                                } else {
                                  setFieldValue("users", newVal);
                                }
                              } else {
                                setFieldValue("users", newVal);
                              }
                            }
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const { inputValue } = params;
                            const isExisting = [...values.users].some(
                              (item) => item.email === inputValue
                            );
                            if (inputValue !== "" && !isExisting) {
                              filtered.push({
                                email: inputValue,
                              });
                            }
                            return filtered;
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="users"
                              label="Select Users"
                              variant="outlined"
                              placeholder="Type..."
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {loading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                        <ErrorMessage
                          name="users"
                          render={(msg) => (
                            <small className="text-danger">{msg}</small>
                          )}
                        />
                      </Box>
                      <Box mt={2} className="d-flex justify-content-end">
                        <Button
                          disabled={isSubmitting}
                          onClick={handleClose}
                          color="error"
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={isSubmitting}
                          onClick={submitForm}
                          variant="contained"
                          disableElevation
                          color="primary"
                        >
                          Send
                        </Button>
                      </Box>
                      {isSubmitting && <LinearProgress sx={{ my: 1 }} />}
                    </Form>
                  )}
                </Formik>
              </Box>
            </>
          )}
          {alreadyExistingUsers.length > 0 && (
            <>
              <DialogContentText>
                Some of the users are already Kewordal. Do you want to connect
                with them?
              </DialogContentText>
              <Table size="small">
                <TableBody>
                  {alreadyExistingUsers.map((user) => (
                    <RenderExistingUsers
                      key={user.userID}
                      userID={user.userID}
                      userEmail={user.email}
                    />
                  ))}
                </TableBody>
              </Table>
              <Box className="d-flex justify-content-end">
                <Button onClick={handleClose} color="error">
                  Close
                </Button>
              </Box>
            </>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default SendInvites;
