import { Box, Button, Tooltip } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useReduxUserProfile } from "Utils/hooks";
import "./styles.scss";

const CustomToolTip = ({
  title = "",
  children,
  open,
  toggleTooltip,
  showArrow,
  placement = "right",
}) => {
  // state
  const [show, setShow] = useState(false);

  // redux
  const userRedux = useReduxUserProfile();

  useEffect(() => {
    const { showHints } = userRedux;
    if (showHints) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [userRedux]);

  const handleClose = () => {
    toggleTooltip(false);
  };

  const renderTitle = (
    <div className="tooltip-content-box">
      <span className="tooltip-text">{title}</span>
      <br />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={handleClose} size="small">
          OK
        </Button>
      </Box>
    </div>
  );

  return (
    <Tooltip
      open={open && show}
      title={renderTitle}
      arrow={showArrow}
      placement={placement}
      classes={{
        popper: "custom-tooltip-container",
        tooltip: "custom-tooltip",
        arrow: "custom-tooltip-arrow",
      }}
    >
      {children}
    </Tooltip>
  );
};

export default CustomToolTip;
