import axios from "axios";
import { getClientToken, getToken } from "Shared/baseURL";
import { useInfiniteQuery } from "react-query";

const getListComments = async (listID, page) => {
  const token = await getToken();
  return axios
    .get(`/lists/${listID}/comments.json/`, {
      headers: {
        Authorization: token,
      },
      params: {
        size: 10,
        fields: "comment,uuid,createdAt,user.avatarURL,user.username",
        page: page,
      },
    })
    .then((res) => res.data);
};

/**
 * @description gets all comments related to passed list id
 * @param {string} listID
 * @returns array of comments
 */
export const useListCommentsData = (listID, inView) => {
  return useInfiniteQuery(
    ["get-list-comments", listID],
    ({ pageParam = 1 }) => getListComments(listID, pageParam),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 30 * 1000,
      enabled: !!listID && inView,
      getNextPageParam: (lastPage) => {
        const { pagination } = lastPage;
        if (pagination.page < pagination.totalPages) {
          return pagination.page + 1;
        } else {
          return undefined;
        }
      },
    }
  );
};

const getBlogComments = async (blogID, page) => {
  const token = await getClientToken();
  return axios
    .get(`/blogs/${blogID}/comments.json/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        size: 10,
        fields: "comment,uuid,createdAt,user.avatarURL,user.username",
        page: page,
      },
    })
    .then((res) => res.data);
};

/**
 * @description gets all comments related to passed list id
 * @param {string} listID
 * @returns array of comments
 */
export const useBlogCommentsData = (blogID) => {
  return useInfiniteQuery(
    ["get-blog-comments", blogID],
    ({ pageParam = 1 }) => getBlogComments(blogID, pageParam),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 30 * 1000,
      enabled: !!blogID,
      getNextPageParam: (lastPage) => {
        const { pagination } = lastPage;
        if (pagination.page < pagination.totalPages) {
          return pagination.page + 1;
        } else {
          return undefined;
        }
      },
    }
  );
};
