import { AccessTime, WarningAmber } from "@mui/icons-material";
import { Box, Button, LinearProgress, Stack } from "@mui/material";
import Spinner from "Components/Spinner/Spinner";
import { useNavigateToSubscription, useReduxUserProfile } from "Utils/hooks";
import { useUserHasSubscription } from "Utils/queries";
import React, { useEffect, useState } from "react";
import { ABOUT_REACH_LIMIT_PERCENT, SHOW_SUB_COMPONENT } from "../sub-enums";

const TestYourSelfSubMessage = ({
  bgColor = "#fff",
  isChallenge = false,
  blurBackground = false,
  children,
}) => {
  // state
  const [gameLimit, setGameLimit] = useState(null);
  const [renderSubComponent, setRenderSubComponent] = useState(null);
  const [limitPercent, setLimitPercent] = useState(0);

  // hooks
  const navigateToSub = useNavigateToSubscription();

  // queries
  const { data: userHasSub, isLoading } = useUserHasSubscription();

  // redux
  const reduxUser = useReduxUserProfile();

  const shareContainerStyles = {
    padding: ".5rem",
    backgroundColor: bgColor,
    borderRadius: "10px",
  };

  const sharedButtonProps = {
    size: "small",
    color: isChallenge ? "primary" : "success",
    sx: {
      textTransform: "initial",
    },
  };

  const sharedIconProps = {
    size: "small",
    color: isChallenge ? "primary" : "success",
  };

  useEffect(() => {
    if (reduxUser && !isLoading) {
      const { subscription } = reduxUser;

      const gameLimitData = isChallenge
        ? subscription.challengeGames
        : subscription.games;

      setGameLimit(gameLimitData || null);

      // calculate limit percent
      const _limitPercent =
        (gameLimitData.existing / gameLimitData.limit) * 100;

      setLimitPercent(_limitPercent);

      // no sub
      if (!userHasSub) {
        if (_limitPercent >= 100) {
          setRenderSubComponent(SHOW_SUB_COMPONENT.SHOW_REACH_LIMIT);
        } else if (_limitPercent >= ABOUT_REACH_LIMIT_PERCENT) {
          setRenderSubComponent(SHOW_SUB_COMPONENT.ABOUT_REACH_LIMIT);
        } else {
          setRenderSubComponent(SHOW_SUB_COMPONENT.NO_SUB);
        }
      }
      // user has sub
      else {
        // reach limit check

        if (_limitPercent >= 100) {
          setRenderSubComponent(SHOW_SUB_COMPONENT.SHOW_REACH_LIMIT);
        } else if (_limitPercent >= ABOUT_REACH_LIMIT_PERCENT) {
          setRenderSubComponent(SHOW_SUB_COMPONENT.ABOUT_REACH_LIMIT);
        } else {
          setRenderSubComponent(SHOW_SUB_COMPONENT.SHOW_CHILDREN);
        }
      }
    }
  }, [reduxUser, userHasSub, isLoading, isChallenge]);

  if (isLoading) {
    return <Spinner />;
  }

  if (renderSubComponent === SHOW_SUB_COMPONENT.NO_SUB) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          justifyContent: "space-between",
          flexWrap: "nowrap",
          ...shareContainerStyles,
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          <WarningAmber {...sharedIconProps} />
          <small>
            {isChallenge
              ? "You can play up to 5 multiple-choice challenges every 3 months"
              : "You can play up to 5 'test-yourself' challenges every 3 months"}
          </small>
        </div>
        <Button {...sharedButtonProps} onClick={navigateToSub}>
          Upgrade
        </Button>
      </Box>
    );
  }

  if (renderSubComponent === SHOW_SUB_COMPONENT.ABOUT_REACH_LIMIT) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            justifyContent: "space-between",
            flexWrap: "nowrap",
            ...shareContainerStyles,
          }}
        >
          <WarningAmber {...sharedIconProps} />
          <div style={{ flexGrow: 1 }}>
            <small>You're about to reach your limit</small>
            <div
              style={{ display: "flex", alignItems: "center", gap: "0.2rem" }}
            >
              <div style={{ flexGrow: 1 }}>
                <LinearProgress
                  value={limitPercent}
                  color={sharedIconProps.color}
                  sx={{
                    backgroundColor: "#eee",
                  }}
                  variant="determinate"
                />
              </div>
              <small>
                {gameLimit.existing}/{gameLimit.limit}
              </small>
            </div>
          </div>
          <Button {...sharedButtonProps} onClick={navigateToSub}>
            Upgrade
          </Button>
        </Box>
        <div className="mt-2">{children}</div>
      </>
    );
  }

  if (renderSubComponent === SHOW_SUB_COMPONENT.SHOW_REACH_LIMIT) {
    if (blurBackground) {
      return (
        <>
          <div
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              backdropFilter: "blur(5px)",
              position: "absolute",
              height: "100%",
              width: "100%",
              top: 0,
              left: 0,
              zIndex: 1,
            }}
            className="center"
          >
            <ShowLimitMessage
              isChallenge={isChallenge}
              navigateToSub={navigateToSub}
              shareContainerStyles={shareContainerStyles}
              sharedButtonProps={sharedButtonProps}
              sharedIconProps={sharedIconProps}
              gameLimit={gameLimit}
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <ShowLimitMessage
            isChallenge={isChallenge}
            navigateToSub={navigateToSub}
            shareContainerStyles={shareContainerStyles}
            sharedButtonProps={sharedButtonProps}
            sharedIconProps={sharedIconProps}
            gameLimit={gameLimit}
          />
        </>
      );
    }
  }

  // only return children
  if (renderSubComponent === SHOW_SUB_COMPONENT.SHOW_CHILDREN) {
    return <>{children}</>;
  }
};

const ShowLimitMessage = ({
  shareContainerStyles,
  sharedIconProps,
  sharedButtonProps,
  isChallenge,
  navigateToSub,
  gameLimit,
}) => {
  return (
    <div style={shareContainerStyles}>
      <Stack spacing={2}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            justifyContent: "space-between",
            flexWrap: "nowrap",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              flexGrow: 1,
            }}
          >
            <AccessTime {...sharedIconProps} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.2rem",
                flexGrow: 1,
              }}
            >
              <small>
                {isChallenge
                  ? `You have reached your limit. Upgrade now to play more
          multiple-choice challenges.`
                  : `You have reached your limit. Upgrade now to play more
          'test-yourself' challenges.`}
              </small>
            </div>
            <Box
              sx={{
                display: "flex",
                gap: "0.5rem",
                alignItems: "flex-end",
                color: isChallenge ? "success.main" : "primary.main",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.15rem",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
              >
                <small style={{ fontWeight: 600 }}>
                  {gameLimit.daysRemaining}
                </small>
                <small style={{ fontSize: 10 }}>days</small>
              </div>
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.15rem",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
              >
                <small style={{ fontWeight: 600 }}>23</small>
                <small style={{ fontSize: 10 }}>hrs</small>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.15rem",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
              >
                <small style={{ fontWeight: 600 }}>59</small>
                <small style={{ fontSize: 10 }}>mins</small>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.15rem",
                  alignItems: "center",
                  flexWrap: "nowrap",
                }}
              >
                <small style={{ fontWeight: 600 }}>59</small>
                <small style={{ fontSize: 10 }}>secs</small>
              </div> */}
            </Box>
          </div>
        </Box>
        <Button
          {...sharedButtonProps}
          variant="contained"
          fullWidth
          disableElevation
          size="large"
          onClick={navigateToSub}
        >
          Upgrade
        </Button>
      </Stack>
    </div>
  );
};

export default TestYourSelfSubMessage;
