import "./styles.scss";

import { useEffect, useState } from "react";
// import { Adsense } from "@ctrl/react-adsense";

// images
import LogoSvg from "assets/images/logo.png";
// import img1 from "assets/adv/logo1.png";
// import img2 from "assets/adv/logo2.png";
// import img3 from "assets/adv/logo3.png";
// import img4 from "assets/adv/logo4.png";
// import img5 from "assets/adv/logo5.png";
// import img6 from "assets/adv/logo6.png";
import { Link } from "react-router-dom";

const imgArr = [
  { image: LogoSvg, link: null },
  { image: LogoSvg, link: null },
  { image: LogoSvg, link: null },
  { image: LogoSvg, link: null },
  { image: LogoSvg, link: null },
  { image: LogoSvg, link: null },
  { image: LogoSvg, link: null },
  { image: LogoSvg, link: null },
  { image: LogoSvg, link: null },
  { image: LogoSvg, link: null },
];

const Advertisement = ({
  fullView = false,
  placeStart = false,
  placeEnd = false,
}) => {
  // state
  const [currentImage, setCurrentImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const changeImg = setInterval(() => {
      if (imgArr.length > 0) {
        if (currentIndex === null) {
          setCurrentIndex(0);
        } else {
          let newIndex = currentIndex + 1;
          if (newIndex === imgArr.length) {
            setCurrentIndex(0);
          } else {
            setCurrentIndex(newIndex);
          }
        }
      }
    }, 10 * 1000);
    return () => {
      clearInterval(changeImg);
    };
  }, [currentIndex]);

  useEffect(() => {
    if (Number.isInteger(currentIndex)) {
      setCurrentImage(imgArr[currentIndex]);
    }
  }, [currentIndex]);

  const handleIndexClick = (idx) => {
    setActiveIndex(idx);
  };

  if (fullView) {
    return (
      <div className="advertisement-component-fullview">
        <div className="advertisement-div">
          {/* <Adsense
            client="ca-pub-4720744444473535"
            slot="6730128480"
            style={{
              display: "block",
              height: "100%",
              width: "100%",
              backgroundColor: "whitesmoke",
            }}
            format="fluid"
          /> */}
          <span className="link">
            <Link to="/contact-us/?advertiseWithUs=true">Advertise with</Link>
          </span>
          <img src={LogoSvg} alt="Kewordal" className="logo-icon" />
        </div>
      </div>
    );
  }

  return (
    <div
      className={`advertisement-component ${placeEnd && "end"} ${
        placeStart && "start"
      }`}
    >
      <div>
        <div className="advertisement-div">
          <span className="link">
            <Link to="/contact-us/?advertiseWithUs=true">Advertise with</Link>
          </span>
          <img src={LogoSvg} alt="Kewordal" className="logo-icon" />
        </div>
        <div className="carousel-dots-div">
          {imgArr.map((item, idx) => (
            <div
              className={`carousel-dots ${idx === activeIndex && "active"}`}
              onClick={() => handleIndexClick(idx)}
            />
          ))}
        </div>
      </div>

      {/* {currentImage && (
        <a href={currentImage.link} target="_blank" rel="noreferrer">
          <img src={currentImage.image} alt={currentImage.link} />
        </a>
      )} */}
    </div>
  );
};

export default Advertisement;
