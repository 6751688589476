import { Close } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import {
  sendConnectRequest,
  sendFollowRequest,
} from "Components/PeopleList/Api";
import TestYourSelfSubMessage from "Components/SubscriptionMessages/Game/TestYourSelfSubMessage";
import { getProfile } from "Redux/Actions";
import { useNotify, useReduxUserProfile } from "Utils/hooks";
import { useEffect, useState } from "react";
import {
  FaFacebook,
  FaPinterest,
  FaReddit,
  FaTelegram,
  FaWhatsapp,
} from "react-icons/fa";
import { FaLinkedin, FaXTwitter } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import {
  FacebookMessengerShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const shareIconProps = {
  size: 20,
  color: "#01AAF5",
};

const GameResults = ({
  gameResults,
  handleClose,
  challenge,
  challengeEmail,
  challengeUserID,
  showConnect = false,
  gameID = "",
}) => {
  // state
  const [isLoading, setIsLoading] = useState(false);
  const [isAlreadyFollowed, setIsAlreadyFollowed] = useState(false);
  const [isAlreadyConnected, setIsAlreadyConnected] = useState(false);
  const [shareGameURL, setShareGameURL] = useState("");

  // hooks
  const notify = useNotify();

  // redux
  const reduxUser = useReduxUserProfile();
  const dispatch = useDispatch();

  useEffect(() => {
    if (gameID) {
      setShareGameURL(`${process.env.REACT_APP_BASEURL}/share/game/${gameID}`);
    }
  }, [gameID]);

  useEffect(() => {
    if (reduxUser) {
      const { following, connections, sentConnections } = reduxUser;
      setIsAlreadyFollowed(following.some((i) => i.uuid === challengeUserID));
      setIsAlreadyConnected(
        connections.some((i) => i.uuid === challengeUserID) ||
          sentConnections.some((i) => i.uuid === challengeUserID)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxUser]);

  const handleFollowRequest = async () => {
    try {
      setIsLoading(true);
      const res = await sendFollowRequest(reduxUser.uuid, challengeUserID);
      if (res.message) {
        throw new Error(res.message);
      }
      notify("User followed.");
      //   if (refetch) {
      //     refetch();
      //   }
      let token = localStorage.getItem("userToken");
      dispatch(getProfile(token));
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleConnectRequest = async () => {
    try {
      setIsLoading(true);
      const res = await sendConnectRequest(challengeUserID); // other user id
      if (res.message) {
        throw new Error(res.message);
      }
      notify(
        `Your invitation to connect was sent and you are now following ${challengeEmail}.`
      );
      //   if (refetch) {
      //     refetch();
      //   }
      let token = localStorage.getItem("userToken");
      dispatch(getProfile(token));
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ minHeight: "200px" }}>
      <Box className="game-title-box">
        <span>RESULT</span>
        <Box className="center">
          <IconButton size="large" onClick={handleClose}>
            <Close fontSize="large" sx={{ color: "black" }} />
          </IconButton>
        </Box>
      </Box>
      <div style={{ padding: "10px 16px" }}>
        <TestYourSelfSubMessage
          showReachLimit
          isChallenge={!!challenge}
          bgColor="#eee"
        />
      </div>
      <Box
        sx={{
          p: 4,
          pb: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ mt: 2 }}>
          {parseInt(gameResults.scorePercentage.split("%")[0]) > 45
            ? "Well done"
            : "Sadly"}
          , you answered {gameResults.score.split("/")[0]} out of{" "}
          {gameResults.score.split("/")[1]} question(s) correctly
        </Box>
        <Box sx={{ mt: 2 }}>You took {gameResults.time} seconds</Box>
        <Box sx={{ mt: 2 }}>
          Your score is{" "}
          <Typography
            sx={{ fontStyle: "italic" }}
            fontSize="20px"
            fontWeight={500}
            component="span"
          >
            {gameResults.scorePercentage}
          </Typography>
        </Box>
      </Box>
      {challenge && (
        <>
          <Box sx={{ mb: 4, px: 2 }}>
            <p className="text-center black fs-5">
              We have notified <span className="fw-700">{challengeEmail}</span>{" "}
              about your challenge.
            </p>
          </Box>
        </>
      )}
      {challengeUserID && showConnect && (
        <>
          <div className="my-4">
            <p className="text-center black">
              Would you like to connect or follow the challenger?
            </p>
            <div className="d-flex justify-content-center gap-2">
              <Button
                disabled={isLoading || isAlreadyConnected}
                onClick={handleConnectRequest}
                variant="contained"
                disableElevation
                size="small"
              >
                {isAlreadyConnected ? "Already connected" : "Connect"}
              </Button>
              <Button
                onClick={handleFollowRequest}
                disabled={isLoading || isAlreadyFollowed}
                variant="contained"
                disableElevation
                size="small"
              >
                {isAlreadyFollowed ? "Already followed" : "Follow"}
              </Button>
            </div>
          </div>
        </>
      )}

      {gameID && (
        <div>
          <p className="text-center">
            <strong>Share your results on these socials</strong>
          </p>
          <div className="social-links-game">
            <FacebookMessengerShareButton url={shareGameURL}>
              <FaFacebook {...shareIconProps} />
            </FacebookMessengerShareButton>
            <TwitterShareButton url={shareGameURL}>
              <FaXTwitter {...shareIconProps} />
            </TwitterShareButton>
            <LinkedinShareButton url={shareGameURL}>
              <FaLinkedin {...shareIconProps} />
            </LinkedinShareButton>

            {/* <a
              href="http://www.tiktok.com/@kewordal"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              <FaTiktok />
            </a>
            <a href="#" target="_blank" rel="noreferrer" className="link">
              <FaDiscord />
            </a> */}
            <PinterestShareButton url={shareGameURL} media={shareGameURL}>
              <FaPinterest {...shareIconProps} />
            </PinterestShareButton>
            {/* <a href="#" target="_blank" rel="noreferrer" className="link">
              <FaYoutube />
            </a>
            <a href="#" target="_blank" rel="noreferrer" className="link">
              <FaSnapchat />
            </a> */}
            <RedditShareButton url={shareGameURL}>
              <FaReddit {...shareIconProps} />
            </RedditShareButton>
            <TelegramShareButton url={shareGameURL}>
              <FaTelegram {...shareIconProps} />
            </TelegramShareButton>
            <WhatsappShareButton url={shareGameURL}>
              <FaWhatsapp {...shareIconProps} />
            </WhatsappShareButton>
          </div>
        </div>
      )}
    </Box>
  );
};

export default GameResults;
