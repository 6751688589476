import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Hide login and get started button for these URLS
const paths = ["/auth/login"];

const LoggedOutLinks = () => {
  // state
  const [pathName, setPathName] = useState("");

  // router
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setPathName(location.pathname);
  }, [location]);

  const handleLogin = () => {
    navigate("/auth/login");
  };

  const handleGetStarted = () => {
    navigate("/register");
  };

  const handleBlogs = () => {
    navigate("/blogs");
  };

  const handleAboutUs = () => {
    navigate("/about-us");
  };

  return (
    <div className="text-btn-container">
      <Button
        onClick={handleAboutUs}
        size="small"
        className={`text-btn underline logged-out ${
          location.pathname === "/about-us" && "activeLink"
        }`}
      >
        About us
      </Button>
      <Button
        onClick={handleBlogs}
        size="small"
        className={`text-btn underline logged-out ${
          location.pathname === "/blogs" && "activeLink"
        }`}
      >
        Blogs
      </Button>
      {!paths.includes(pathName) && (
        <>
          <Button
            size="small"
            className="text-btn logged-out login-btn"
            onClick={handleLogin}
            color="primary"
            variant="contained"
            disableElevation
            sx={{ color: "white" }}
          >
            Login
          </Button>
          <Button
            onClick={handleGetStarted}
            size="small"
            variant="contained"
            disableElevation
            color="success"
            sx={{
              display: {
                xs: "none",
                sm: "inline-block",
              },
              color: "white",
              fontSize: "14px",
              letterSpacing: "1px",
            }}
            // className="text-btn"
          >
            Join Us
          </Button>
        </>
      )}
    </div>
  );
};

export default LoggedOutLinks;
