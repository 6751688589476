import { Alert, AlertTitle, Button, Container } from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { getToken } from "Shared/baseURL";
import axios from "axios";
import { useNotify, useReduxUserProfile } from "Utils/hooks";

const ConfirmConnection = () => {
  // state
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({
    color: "info",
    message: "",
    title: "Info",
  });

  // router
  const { userID } = useParams();
  const navigate = useNavigate();

  // redux
  const currentUser = useReduxUserProfile();

  const handleAcceptConnection = async () => {
    try {
      setIsLoading(true);
      const token = await getToken();
      await axios.post(
        `/users/confirm-connection/${userID}`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify("Connection accepted");
      navigateToMyNetwork();
    } catch (error) {
      setAlert({
        title: "Error",
        message:
          error.response.data.message ||
          error.message ||
          "Something went wrong!",
        color: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeclineConnection = async () => {
    try {
      setIsLoading(true);
      const token = await getToken();
      await axios.delete(
        `/users/${currentUser.uuid}/CONNECTED_WITH/users/${userID}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify("Connection rejected");
      navigateToMyNetwork();
    } catch (error) {
      setAlert({
        title: "Error",
        message:
          error.response.data.message ||
          error.message ||
          "Something went wrong!",
        color: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const navigateToMyNetwork = () => {
    navigate("/my-network");
  };

  return (
    <Container sx={{ my: 4 }} maxWidth="sm">
      <h3 className="blue text-center">Confirm your connection?</h3>
      <div className="mt-3 d-flex center gap-3">
        <Button
          variant="contained"
          color="error"
          disabled={isLoading}
          onClick={handleDeclineConnection}
        >
          Decline
        </Button>
        <Button
          variant="contained"
          color="success"
          disabled={isLoading}
          onClick={handleAcceptConnection}
        >
          Accept
        </Button>
      </div>
      <div className="mt-4">
        {alert.message && (
          <Alert
            variant="filled"
            severity={alert.color}
            sx={{ color: "white" }}
          >
            <AlertTitle>{alert.title}</AlertTitle>
            {alert.message}
          </Alert>
        )}
      </div>
    </Container>
  );
};

export default ConfirmConnection;
