import { getClientToken } from "Shared/baseURL";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Spinner from "Components/Spinner/Spinner";
import XMLViewer from "react-xml-viewer";

const PostsXML = () => {
  // state
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchXML();
  }, []);

  const fetchXML = async () => {
    try {
      setIsLoading(true);
      const token = await getClientToken();
      await axios
        .get("/posts-sitemap.xml", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setData(res.data);
        });
    } catch (error) {
      setData(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return <XMLViewer xml={data} collapsible />;
};

export default PostsXML;
