import { Menu, MenuItem } from "@mui/material";
import React from "react";

const ViewMenu = ({ viewMenu, closeViewMenu, handleSelectView, view }) => {
  return (
    <Menu anchorEl={viewMenu} open={Boolean(viewMenu)} onClose={closeViewMenu}>
      <MenuItem
        selected={view === "full"}
        onClick={() => handleSelectView("full")}
        value="full"
      >
        FULL VIEW
      </MenuItem>
      <MenuItem
        selected={view === "small"}
        onClick={() => handleSelectView("small")}
        value="small"
      >
        SMALL VIEW
      </MenuItem>
    </Menu>
  );
};

export default ViewMenu;
