import { GET_PROFILE, LOGIN, LOGOUT, UPDATE_PROFILE } from "./ActionTypes";

import axios from "axios";

export const userLogin = (data) => async (dispatch) => {
  dispatch({
    type: LOGIN,
    payload: data,
  });
};

export const getProfile = (token) => async (dispatch) => {
  if (!token) {
    return false;
  }
  try {
    const res = await axios.get("/users/me.json", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res.status === 200) {
      const { emailValidated } = res.data;
      if (emailValidated) {
        const res1 = await axios
          .get(`/users/me/connections.json`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => res.data);
        dispatch({
          type: GET_PROFILE,
          payload: { ...res.data, ...res1 },
        });
      }
      return res.data;
    }
  } catch (error) {
    console.log(error.message);
    return false;
  }
};

export const updateReduxProfile = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_PROFILE,
    payload: data,
  });
};

export const userLogout = () => (dispatch) => {
  let arr = [
    "userToken",
    "refreshToken",
    "dashboardSelectedSubject",
    "myListSelectedSubject",
    "logTime",
  ];
  arr.forEach((key) => {
    localStorage.removeItem(key);
  });
  dispatch({
    type: LOGOUT,
  });
};
