import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const API_KEY = process.env.REACT_APP_GOOGLE_MAP;

const GooglePlace = ({ location = "", setLocation }) => {
  const handleChange = (val) => {
    setLocation(val);
  };

  return (
    <div>
      <GooglePlacesAutocomplete
        apiKey={API_KEY}
        selectProps={{
          defaultInputValue: location,
          onChange: (val) => handleChange(val),
          placeholder: "Type to search...",
        }}
      />
    </div>
  );
};

export default GooglePlace;
