import axios from "axios";
import { getClientToken } from "Shared/baseURL";
import { useQuery } from "react-query";

const getBlogs = async (page, query, excludeHref) => {
  const token = await getClientToken();
  return axios
    .get("/blogs.json", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        sort: "createdAt.DESC",
        page: page,
        "exclude-href": excludeHref,
        content: query,
      },
    })
    .then((res) => res?.data);
};

export const useHandleGetBlogs = (page, query, excludeHref = "") => {
  return useQuery(["get-all-blogs", page, excludeHref, query], () =>
    getBlogs(page, query, excludeHref)
  );
};

const getBlogsBID = async (ID) => {
  const token = await getClientToken();
  return axios
    .get(`/blogs/[href=${ID}].json`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res?.data);
};

export const useBlogByID = (blogID) => {
  return useQuery(["get-blog-by-id", blogID], () => getBlogsBID(blogID), {
    enabled: !!blogID,
  });
};
