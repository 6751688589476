import "./styles.scss";

import { MenuItem, Paper } from "@mui/material";
import { getToken } from "Shared/baseURL";
import axios from "axios";
import { Mention, MentionsInput } from "react-mentions";

const MentionUsers = ({ value, handleChange, handleKeyPress }) => {
  const handleSuggestion = (entry) => {
    return <MenuItem>{entry.display}</MenuItem>;
  };

  const handleCustomSuggestionContainer = (children) => {
    return (
      <Paper
        elevation={2}
        sx={{
          maxHeight: 200,
          overflow: "auto",
        }}
      >
        {children}
      </Paper>
    );
  };

  const handleDisplayTransform = (id, display) => {
    return `@${display}`;
  };

  const handleSuggestionData = async (query, cb) => {
    try {
      const token = await getToken();
      const res = await axios.get("/search/users", {
        params: {
          q: query,
          page: 1,
          size: 6,
        },
        headers: {
          Authorization: token,
        },
      });
      const users = res.data.users.map((user) => ({
        id: user.username,
        display: user.username,
      }));
      cb(users);
    } catch (error) {
      cb([]);
      console.log(error);
    }
  };

  return (
    <MentionsInput
      value={value}
      onChange={(e) => handleChange(e.target.value)}
      customSuggestionsContainer={handleCustomSuggestionContainer}
      className="suggestion-input"
      placeholder="Type @ for user suggestions..."
      allowSuggestionsAboveCursor
      onKeyUp={handleKeyPress}
    >
      <Mention
        trigger="@"
        data={handleSuggestionData}
        renderSuggestion={handleSuggestion}
        markup={`<a href="/my-network/__display__" target="_blank" class="blue cursor-pointer mention-link">__id__</a>`}
        displayTransform={handleDisplayTransform}
        appendSpaceOnAdd
      />
    </MentionsInput>
  );
};

export default MentionUsers;
