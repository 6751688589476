export const UserTypes = [
  {
    label: "Student (School/College)",
    value: "KW1928",
  },
  {
    label: "Student (University)",
    value: "KW2194",
  },
  {
    label: "Student (Other)",
    value: "KW8794",
  },
  {
    label: "Teacher (School/College)",
    value: "KW5937",
  },
  {
    label: "Lecturer (University)",
    value: "KW5836",
  },
  {
    label: "Lecturer (Other)",
    value: "KW5769",
  },
  {
    label: "Mentor (Corporate Business Professional)",
    value: "KW3532",
  },
  {
    label: "Mentor (Business Professional)",
    value: "KW6452",
  },
  {
    label: "Mentor (Community)",
    value: "KE1701",
  },
  {
    label: "Parent/Guardian",
    value: "KW6743",
  },
  {
    label: "Relative (not a parent or guardian)",
    value: "KW9012",
  },
  {
    label: "Other",
    value: "KW0283",
  },
];

export const RegisterStatus = {
  OK: "OK",
  USER_SIGNED_UP: "USER_SIGNED_UP",
  USER_REGISTERED: "USER_REGISTERED",
  USER_APPROVED: "USER_APPROVED",
};

export const InquiryCategory = [
  { value: "Report offensive content", label: "Report offensive content" },
  {
    value: "Advertise with Us",
    label: "Advertise with Us",
  },
  {
    value: "Business",
    label: "Business",
  },
  {
    value: "Billing",
    label: "Billing",
  },
  {
    value: "GDPR",
    label: "General Data Protection Regulation (GDPR)",
  },
  {
    value: "Privacy",
    label: "Privacy",
  },
  {
    value: "Report bug",
    label: "Report bug",
  },
  {
    value: "Report spam",
    label: "Report spam",
  },
  {
    value: "Technical issue",
    label: "Technical issue",
  },
  {
    value: "Website down",
    label: "Website down",
  },
];

export const Disabilities = [
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4",
    label: "4",
  },
  {
    value: "5",
    label: "5",
  },
];

export const Genders = [
  "Male",
  "Female",
  "Non-binary",
  "Other term preferred",
  "Prefer not to say",
].map((item) => ({
  label: item,
  value: item,
}));

export const Races = [
  "I do not wish to identify at this stage",
  "White British",
  "White Irish",
  "White , any other background",
  "Mixed White and Black Caribbean",
  "Mixed White and Black African",
  "Mixed White and Asian",
  "Mixed , any other mixed background",
  "Asian or Asian British Indian",
  "Asian or Asian British Pakistani",
  "Asian or Asian British Bangladeshi",
  "Asian any other background",
  "Chinese",
  "Black or Black British Caribbean",
  "Black or Black British African",
  "Black or Black British , any other background",
  "Any other background",
].map((item) => ({ value: item, label: item }));

export const RacesWithTextField = [
  "White - any other background",
  "Mixed - any other mixed background",
  "Asian -  any other background",
  "Black or Black British - any other background",
  "Any other background",
];

export const Titles = [
  "--Select--",
  "Mr",
  "Mrs",
  "Ms",
  "Miss",
  "Dr",
  "Professor",
  "Sir",
  "Lord",
];

export const HighestQualifications = [
  "GCSEs (or equivalent)",
  "A' Levels (or equivalent)",
  "Diploma (College)",
  "Diploma (University)",
  "Degree",
  "Post Graduate Diploma",
  "Masters Degree",
  "Doctorate Studies",
  "PhD",
];

export const HEAR_FROM_US_OPTIONS = [
  {
    label: "Search engine",
    freeTextField: true,
  },
  {
    label: "Social media influencer",
    freeTextField: true,
  },
  {
    label: "Social media",
    freeTextField: true,
  },
  {
    label: "Other website",
    freeTextField: true,
  },
  {
    label: "Blogs, videos, pictures",
    freeTextField: true,
  },
  {
    label: "Education institution",
    freeTextField: true,
  },
  {
    label: "Corporate or SME Business",
    freeTextField: true,
  },
  {
    label: "Internet ads",
    freeTextField: false,
  },
  {
    label: "Word of mouth",
    freeTextField: false,
  },
  {
    label: "Referral",
    freeTextField: true,
  },
  {
    label: "Newspaper / Online Newspaper",
    freeTextField: false,
  },
  {
    label: "Customer review sites",
    freeTextField: false,
  },
  {
    label: "Comparison sites",
    freeTextField: false,
  },
  {
    label: "Customer testimonials",
    freeTextField: false,
  },
  {
    label: "Other",
    freeTextField: true,
  },
  {
    label: "Radio",
    freeTextField: false,
  },
  {
    label: "Television",
    freeTextField: false,
  },
  {
    label: "Billboard",
    freeTextField: false,
  },
];
