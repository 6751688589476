import styles from "./styles.module.scss";

import React from "react";

const SponsoredBy = ({
  name,
  image = "",
  bgColor = "#eee",
  smallImage = false,
}) => {
  if (!image) {
    return null;
  }

  return (
    <div className={styles["main"]}>
      <small
        style={{
          fontSize: smallImage ? ".7rem" : "1rem",
        }}
      >
        Sponsored by
      </small>
      {/* <p>{name}</p> */}
      <img
        src={image}
        alt={name}
        style={{ backgroundColor: bgColor }}
        className={`${styles["image"]} ${
          smallImage ? styles["small-image"] : styles["medium-image"]
        }`}
      />
    </div>
  );
};

export default SponsoredBy;
