import { Search } from "@mui/icons-material";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import Advertisement from "Components/Advertisement/Advertisement";
import EmptySearch from "Components/EmptySearch/EmptySearch";
import React from "react";
import KeywordCard from "../../Components/ViewListCards/ViewListCards";

const KeywordsTab = ({
  searchQuery,
  keywordsCount,
  allKeywords,
  setActiveTab,
  postCount,
  listCount,
  peopleCount,
  keywordsLoading,
  refetchKeywords,
  isFetchingNextKeywords,
  hasNextKeywords,
  fetchNextKeywords,
  isFetchingKeywords,
  initialRender,
}) => {
  if (initialRender) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ my: 2 }}>
            <h5 className="text-muted">
              <Search /> Type to search...
            </h5>
          </Box>
        </Grid>
      </Grid>
    );
  }

  if (keywordsLoading || isFetchingNextKeywords || isFetchingKeywords) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ my: 2 }} className="center">
            <CircularProgress />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <div>
      <span
        style={{
          marginTop: "1rem",
          marginLeft: "5px",
          marginBottom: "1rem",
          display: "inline-block",
          color: "#666666",
        }}
      >
        {searchQuery && `${keywordsCount} results found for "${searchQuery}"`}
      </span>
      <Grid key="keywords-list" container spacing={2}>
        {allKeywords.length < 1 ? (
          <Grid item xs={12}>
            <EmptySearch
              searchQuery={searchQuery}
              tabName="keywords"
              posts={postCount}
              people={peopleCount}
              lists={listCount}
              setActiveTab={setActiveTab}
            />
          </Grid>
        ) : (
          allKeywords?.map((item, idx) => (
            <>
              {idx % 2 === 0 && idx !== 0 && (
                <Grid item xs={12} key={`advertisement-` + idx}>
                  <Advertisement />
                </Grid>
              )}
              <Grid item xs={12} sm={6} key={item.uuid + idx}>
                <KeywordCard
                  searchResults
                  searchResultKeyword={item}
                  index={idx}
                  dislikes={item?.dislikes}
                  likes={item?.likes}
                  kewordsId={item?.uuid}
                  refetch={refetchKeywords}
                  idx={idx}
                />
              </Grid>
            </>
          ))
        )}
        {hasNextKeywords && (
          <Grid item xs={12}>
            <div className="mt-1 center">
              <Button
                disabled={isFetchingNextKeywords}
                onClick={fetchNextKeywords}
              >
                {isFetchingNextKeywords ? "Loading..." : "Load More"}
              </Button>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default KeywordsTab;
