import { ControlPoint } from "@mui/icons-material";
import classes from "./styles.module.scss";
import { lazy } from "react";
import { useState } from "react";

const AddSubject = lazy(() => import("Components/AddSubject/AddSubject"));

const AddSubjectCard = ({ children }) => {
  // state
  const [addSubject, setAddSubject] = useState(false);

  if (children) {
    return (
      <>
        <div onClick={() => setAddSubject(true)}>{children}</div>
        {addSubject && <AddSubject open={addSubject} toggle={setAddSubject} />}
      </>
    );
  } else {
    return (
      <>
        <div className={classes.main} onClick={() => setAddSubject(true)}>
          <ControlPoint sx={{ fontSize: "49px" }} />
          <p>Add Subject</p>
        </div>
        {addSubject && <AddSubject open={addSubject} toggle={setAddSubject} />}
      </>
    );
  }
};

export default AddSubjectCard;
