/* eslint-disable jsx-a11y/iframe-has-title */

import "./styles.scss";

import { Avatar, IconButton, Menu, MenuItem, Paper } from "@mui/material";

import { MoreHoriz } from "@mui/icons-material";
import PostComments from "Components/PostComments/PostComments";
import axios from "axios";
import dayjs from "dayjs";
import { getToken } from "Shared/baseURL";
import { red } from "@mui/material/colors";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { useNotify } from "Utils/hooks";
import { useEffect, useState } from "react";
import Advertisement from "Components/Advertisement/Advertisement";
import PostText from "./PostText";
import PostVideo from "./PostVideo";
import RepostUser from "./RepostUser";

const PostCard = ({ post, refetchPosts, myPosts, idx }) => {
  // props
  const {
    uuid,
    content,
    user,
    subject,
    photo,
    createdAt,
    likes,
    list,
    video,
    dislikes,
    repostedBy,
    href,
  } = post;

  // state
  const notify = useNotify();
  const [postMenu, setPostMenu] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [renderAdvertisement, setRenderAdvertisement] = useState(false);
  const [latestRepost, setLatestRepost] = useState(null);

  // observer
  const { ref: commentsRef, inView: commentsInView } = useInView({});

  // router
  const navigate = useNavigate();

  useEffect(() => {
    if (idx && idx % 2 === 0) {
      setRenderAdvertisement(true);
    }
  }, [idx]);

  useEffect(() => {
    if (repostedBy && repostedBy.length > 0) {
      setLatestRepost(repostedBy[0]);
    }
  }, [repostedBy]);

  const handleCloseMenu = () => {
    setPostMenu(null);
  };

  const handleDeletePost = async () => {
    try {
      setIsLoading(true);
      const token = await getToken();
      await axios.delete(`/posts/${uuid}`, {
        headers: {
          Authorization: token,
        },
      });
      handleCloseMenu();
      notify("Post deleted.");
      if (refetchPosts) refetchPosts();
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const viewUserProfile = () => {
    navigate(`/my-network/${user.username}`);
  };

  return (
    <>
      <Paper elevation={0} className={latestRepost ? "repost-post-card" : ""}>
        {latestRepost && <RepostUser user={latestRepost} />}
        <Paper elevation={2} className="posts-card">
          <div className="title-div">
            <div className="d-flex gap-2">
              <Avatar
                src={user?.avatarURL}
                alt={user?.username}
                onClick={viewUserProfile}
                className="cursor-pointer"
              />
              <div className="d-flex flex-column">
                <span
                  className="fw-500 cursor-pointer"
                  onClick={viewUserProfile}
                >
                  {user?.username ?? ""}
                </span>
                {createdAt && (
                  <span className="text-muted" style={{ fontSize: "11px" }}>
                    {dayjs(createdAt).fromNow()}
                  </span>
                )}
              </div>
            </div>
            {myPosts && (
              <IconButton
                onClick={(e) => setPostMenu(e.currentTarget)}
                size="small"
              >
                <MoreHoriz />
              </IconButton>
            )}
          </div>
          <PostText content={content} />
          <p className="subject-name">{subject?.name ?? ""}</p>
          {photo && photo?.url && (
            <div
              style={{ backgroundImage: `url(${photo?.url})` }}
              className={"image-div"}
            />
          )}

          <PostVideo video={video} />

          <div className="footer-div">
            <span className="footer-title">{list?.name ?? ""}</span>
          </div>

          <div ref={commentsRef}>
            <PostComments
              postID={uuid}
              commentsInView={commentsInView}
              likes={likes}
              dislikes={dislikes}
              refetchPosts={refetchPosts}
              //   reportedBy={reportedBy}
              repostedBy={repostedBy}
              user={user}
              postHref={href}
              content={content}
            />
          </div>
          <Menu
            open={Boolean(postMenu)}
            anchorEl={postMenu}
            onClose={handleCloseMenu}
            PaperProps={{
              sx: {
                minWidth: "150px",
                paddingX: 1,
              },
            }}
          >
            <MenuItem
              disabled={isLoading}
              sx={{
                "&:hover": {
                  backgroundColor: red[700],
                  color: "white",
                  borderRadius: 2,
                },
              }}
              onClick={handleDeletePost}
            >
              Delete
            </MenuItem>
          </Menu>
        </Paper>
      </Paper>
      {renderAdvertisement && (
        <div className="mt-3">
          <Advertisement fullView />
        </div>
      )}
    </>
  );
};

export default PostCard;
