import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const FooterLinks = () => {
  return (
    <div className="footer-links">
      <div className="link-wrapper">
        <p className="footer-heading">Quick Links</p>
        <Box
          sx={{
            display: {
              xs: "block",
              sm: "none",
            },
          }}
        >
          <hr />
        </Box>
        <Link className="link" to="/faq">
          FAQs
        </Link>
        <Link className="link" to="/about-us">
          About
        </Link>
        <Link className="link" to="/mission">
          Mission
        </Link>
        <Link className="link" to="/contact-us">
          Contact Us
        </Link>
        <Link className="link" to="/contact-us?advertiseWithUs=true">
          Advertise on Kewordal
        </Link>
      </div>
    </div>
  );
};

export default FooterLinks;
