import { Button, Container } from "@mui/material";
import Spinner from "Components/Spinner/Spinner";
import { getToken } from "Shared/baseURL";
import { useNotify } from "Utils/hooks";
import axios from "axios";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

const fetcher = async (listID) => {
  const token = await getToken();
  return axios
    .get(`/lists/${listID}.json`, {
      headers: {
        Authorization: token,
      },
    })
    .then((res) => res.data);
};

const AcceptShare = () => {
  // state
  const notify = useNotify();
  const [btnLoading, setBtnLoading] = useState(false);

  // router
  const navigate = useNavigate();
  const { listID } = useParams();

  // query
  const { data, isLoading } = useQuery([listID], () => fetcher(listID), {
    enabled: !!listID,
  });

  const handleAccept = async () => {
    try {
      setBtnLoading(true);
      const token = await getToken();
      await axios.patch(
        `/lists/${listID}/shared/accept`,
        {},
        { headers: { Authorization: token } }
      );
      notify("List accepted.");
      navigate(`/lists`);
    } catch (error) {
      if (
        error.response.data.message === "USER_NOT_ALLOWED_TO_CLONE_OWN_LIST"
      ) {
        notify("User not allowed to clone own list", { variant: "error" });
      } else {
        notify(error.message, { variant: "error" });
      }
    } finally {
      setBtnLoading(false);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (data) {
    return (
      <Container className="my-4">
        <h5 className="text-center">
          Accept list <span className="blue">"{data.name}"</span>
        </h5>
        <div className="center mt-4">
          <Button
            variant="contained"
            disableElevation
            onClick={handleAccept}
            disabled={btnLoading}
          >
            Accept
          </Button>
        </div>
      </Container>
    );
  }
};

export default AcceptShare;
