import axios from "axios";
import { getToken } from "../../Shared/baseURL";

export const updateUserProfilePic = async (file) => {
  try {
    let data = new FormData();
    data.append("fileName", "My Photo");
    data.append("profileImage", file);
    const token = await getToken();
    const res = await axios.post("/users/profile-image", data, {
      headers: {
        Authorization: token,
      },
    });
    if (res.status === 200 || res.status === 201) {
      const { data, status } = res;
      return {
        status,
        data,
      };
    }
  } catch (error) {
    if (error.response) {
      const { data, status, statusText } = error.response;
      return {
        status,
        message: data.message || data.error_description || statusText,
      };
    }
  }
};

export const updateUserProfile = async (values, id) => {
  try {
    const token = await getToken();
    const res = await axios.patch(`/users/${id}`, values, {
      headers: {
        Authorization: token,
      },
    });
    if (res.status === 200 || res.status === 201) {
      const { data, status } = res;
      return {
        status,
        data,
      };
    }
  } catch (error) {
    if (error.response) {
      const { data, status, statusText } = error.response;
      return {
        status,
        message: data.message || data.error_description || statusText,
      };
    }
  }
};

export const updateUserPassword = async (values, id) => {
  try {
    const token = await getToken();
    const res = await axios.patch(`/users/${id}/update-password`, values, {
      headers: {
        Authorization: token,
      },
    });
    if (res.status === 200 || res.status === 201) {
      const { data, status } = res;
      return {
        status,
        data,
      };
    }
  } catch (error) {
    if (error.response) {
      const { data, status, statusText } = error.response;
      return {
        status,
        message: data.message || data.error_description || statusText,
      };
    }
  }
};

export const deleteUserAccount = async (id) => {
  try {
    const token = await getToken();
    const res = await axios.delete(`/users/${id}`, {
      headers: {
        Authorization: token,
      },
    });
    if (res.status === 200 || res.status === 201) {
      const { data, status } = res;
      return {
        status,
        data,
      };
    }
  } catch (error) {
    if (error.response) {
      const { data, status, statusText } = error.response;
      return {
        status,
        message: data.message || data.error_description || statusText,
      };
    }
  }
};

export const getSpecificUserProfile = async (id) => {
  try {
    const token = await getToken();
    const res = await axios.get(`/users/${id}.json`, {
      headers: {
        Authorization: token,
      },
    });
    if (res.status === 200 || res.status === 201) {
      const { data, status } = res;
      return {
        status,
        data,
      };
    }
  } catch (error) {
    if (error.response) {
      const { data, status, statusText } = error.response;
      return {
        status,
        message: data.message || data.error_description || statusText,
      };
    }
  }
};
