import { TableRow, TableCell, Button } from "@mui/material";
import { sendConnectRequest } from "Components/PeopleList/Api";
import { getProfile } from "Redux/Actions";
import { useNotify, useReduxUserProfile } from "Utils/hooks";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const RenderExistingUsers = ({ userEmail, userID }) => {
  // state
  const [isAlreadyConnected, setIsAlreadyConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //   hooks
  const notify = useNotify();

  // redux
  const reduxUser = useReduxUserProfile();
  const dispatch = useDispatch();

  useEffect(() => {
    if (reduxUser) {
      const { connections, sentConnections } = reduxUser;
      setIsAlreadyConnected(
        connections.some((i) => i.uuid === userID) ||
          sentConnections.some((i) => i.uuid === userID)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxUser]);

  const handleConnectRequest = async () => {
    try {
      setIsLoading(true);

      const res = await sendConnectRequest(userID); // other user id
      if (res.message) {
        throw new Error(res.message);
      }
      notify(
        `Your invitation to connect was sent and you are now following ${userEmail}.`
      );
      let token = localStorage.getItem("userToken");
      dispatch(getProfile(token));
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <TableRow hover>
      <TableCell>{userEmail}</TableCell>
      <TableCell>
        <Button
          disabled={isLoading || isAlreadyConnected}
          onClick={handleConnectRequest}
          variant="outlined"
          disableElevation
          size="small"
        >
          {isAlreadyConnected ? "Connected" : "Connect"}
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default RenderExistingUsers;
