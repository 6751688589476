import {
  AccountBoxOutlined,
  ArrowDropDown,
  EmojiEvents,
  LogoutOutlined,
  Menu,
  SettingsOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Menu as MuiMenu,
  Typography,
} from "@mui/material";
import React, { lazy, useEffect, useState } from "react";
import {
  useGetNotification,
  useNotify,
  useReduxUser,
  useReduxUserProfile,
} from "Utils/hooks";
import { useLocation, useNavigate } from "react-router-dom";

import Challenges from "Components/Challenges/Challenges";
import CustomToolTip from "../CustomToolTip/CustomToolTip";
import NotificationDialog from "../NotificationDialog/NotificationDialog";
import NotificationsIcon from "@mui/icons-material/Notifications";
import axios from "axios";
import { getToken } from "Shared/baseURL";
import { useDispatch } from "react-redux";
import { userLogout } from "Redux/Actions";

// lazy loading
const SideBar = lazy(() => import("../Sidebar/SideBar"));

const UserHeader = ({ drawerWidth }) => {
  // state
  const notify = useNotify();
  const [anchorEl, setAnchorEl] = useState(null);
  const [allNotifications, setAllNotifications] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [sidebarMenu, setSidebarMenu] = useState(false);
  const [notificationMenu, setNotificationMenu] = useState(null);
  const [viewChallenges, setViewChallenges] = useState(false);
  const [showNotifications, setShowNotifications] = useState(true);

  // redux
  const dispatch = useDispatch();
  const notificationShow = useGetNotification();
  const userRedux = useReduxUser();
  const profile = useReduxUserProfile();

  // router
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (profile && userRedux) {
      // get notifications
      getUserNotifications();

      setTimeout(() => {
        setShowTooltip(true);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, userRedux]);

  const getUserNotifications = async () => {
    try {
      const token = await getToken();
      const res = await axios.get("/users/me/notifications.json", {
        headers: {
          Authorization: token,
        },
      });
      if (res.status === 200) {
        const { notifications } = res.data;
        if (Array.isArray(notifications)) setAllNotifications(notifications);
      }
    } catch (error) {
      notify(error.message, { variant: "error" });
    }
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleNavigateDashboard = () => {
    navigate("/dashboard");
  };

  const handleNavigateSearchResults = () => {
    navigate("/search-results");
  };

  const handleNavigateNetwork = () => {
    navigate("/my-network");
  };

  const handleNavigateLists = () => {
    navigate("/lists");
    handleMenuClose();
  };

  const handleNavigateAllLists = () => {
    navigate("/all-lists");
    handleMenuClose();
  };

  const handleBlogs = () => {
    navigate("/blogs");
  };

  const handleSidebarDrawerOpen = () => {
    setSidebarMenu(true);
  };

  const handleSidebarDrawerClose = () => {
    setSidebarMenu(false);
  };

  const handleNavigateMyLists = () => {
    navigate("/lists?showMyList=1");
    handleMenuClose();
  };

  const handleNavigateProfile = () => {
    navigate("/user-profile");
    handleMenuClose();
  };

  const handleNavigateSettings = () => {
    navigate("/edit-profile/user-details");
    handleMenuClose();
  };

  const handleLogout = async () => {
    handleMenuClose();
    dispatch(userLogout());
    navigate("/");
  };

  const handleViewChallenges = () => {
    setViewChallenges(true);
  };

  const handleNotifications = (e) => {
    // call api
    setNotificationsFalseApi();
    setShowNotifications(false);
    setNotificationMenu(e.currentTarget);
  };

  const setNotificationsFalseApi = async () => {
    try {
      let allIDs = allNotifications.map((item) => item.uuid);
      const token = await getToken();
      await axios.patch(
        "/users/me/notifications/read",
        { uuids: allIDs },
        {
          headers: {
            Authorization: token,
          },
        }
      );
    } catch (error) {
      notify(error.message, { variant: "error" });
    }
  };

  return (
    <>
      <Box
        className="user-links-container"
        sx={{
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        <Button
          size="small"
          className={`text-btn underline ${
            location.pathname === "/dashboard" && "activeLink"
          }`}
          onClick={handleNavigateDashboard}
        >
          Dashboard
        </Button>
        <Button
          size="small"
          className={`text-btn underline ${
            location.pathname === "/my-network" && "activeLink"
          }`}
          onClick={handleNavigateNetwork}
        >
          My Network
        </Button>
        <Button
          size="small"
          onClick={handleNavigateLists}
          className={`text-btn underline ${
            (location.pathname === "/lists" ||
              location.pathname.startsWith("/view-list")) &&
            "activeLink"
          }`}
        >
          Lists
        </Button>
        <Button
          size="small"
          onClick={handleNavigateAllLists}
          className={`text-btn underline ${
            location.pathname === "/all-lists" && "activeLink"
          }`}
        >
          All lists
        </Button>
        <Button
          size="small"
          className={`text-btn underline ${
            location.pathname === "/search-results" && "activeLink"
          }`}
          onClick={handleNavigateSearchResults}
        >
          Search
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: { xs: "0.5rem", sm: "2.2rem", md: "1rem" },
        }}
      >
        <Button
          sx={{ display: { xs: "none", md: "flex" } }}
          onClick={handleBlogs}
          size="small"
          className={`text-btn underline ${
            location.pathname === "/blogs" && "activeLink"
          }`}
        >
          Blogs
        </Button>
        <IconButton
          title="Notifications"
          onClick={handleNotifications}
          sx={{
            display: {
              xs: "none",
              sm: "inline-block",
              md: "none",
              lg: "inline-block",
            },
          }}
          className="blue-btn"
        >
          <Badge
            badgeContent={
              notificationShow &&
              showNotifications &&
              allNotifications.length > 0
                ? allNotifications.length
                : 0
            }
            color="warning"
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <IconButton
          title="Challenges"
          onClick={handleViewChallenges}
          sx={{
            display: {
              xs: "none",
              sm: "inline-block",
              md: "none",
              lg: "inline-block",
            },
          }}
          className="blue-btn"
        >
          <EmojiEvents />
        </IconButton>

        <Box
          onClick={handleOpenMenu}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // flexDirection: "column",
            gap: "0.2rem",
            cursor: "pointer",
          }}
        >
          <Avatar
            src={profile?.avatarURL}
            alt={profile?.username}
            className="avatar-img"
          />
          <CustomToolTip
            title="Click on your profile to amend or update your details."
            showArrow
            placement="bottom"
            open={showTooltip}
            toggleTooltip={setShowTooltip}
          >
            <Typography
              fontSize="13px"
              fontWeight="500"
              sx={{ color: "#8d8d8d" }}
            >
              {profile?.username}
            </Typography>
          </CustomToolTip>
          <ArrowDropDown fontSize="small" />
        </Box>
        <IconButton
          sx={{
            display: {
              xs: "block",
              md: "none",
            },
          }}
          onClick={handleSidebarDrawerOpen}
          className="blue-btn"
        >
          <Menu />
        </IconButton>
      </Box>
      <Drawer
        open={sidebarMenu}
        variant="temporary"
        onClose={handleSidebarDrawerClose}
        anchor="right"
      >
        <SideBar
          handleSidebarDrawerClose={handleSidebarDrawerClose}
          drawerWidth={drawerWidth}
          headerSidebar={true}
        />
      </Drawer>
      {/* dialogs */}
      <NotificationDialog
        notifications={allNotifications}
        anchorEl={notificationMenu}
        toggle={setNotificationMenu}
        setNotificationsFalseApi={setNotificationsFalseApi}
      />
      {viewChallenges && (
        <Challenges open={viewChallenges} toggle={setViewChallenges} />
      )}
      {/* Menus */}
      <MuiMenu
        className="profile-menu"
        id="profile-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            borderRadius: "8px",
            minWidth: 190,
            padding: "0.3rem 0.8rem",
            backgroundColor: "white",
          },
        }}
      >
        <MenuList>
          <Box>
            <span className="fw-300 black text-center d-block">
              Currently in:
            </span>
            <span className="fw-300 black text-center d-block">
              {profile?.email}
            </span>
          </Box>
          <Divider sx={{ my: 1 }} />
          <MenuItem onClick={handleNavigateMyLists}>
            <ListItemIcon>
              <VisibilityOutlined sx={{ color: "#2E3A59" }} fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="View my lists" />
          </MenuItem>
          <MenuItem
            sx={{
              display: {
                xs: "flex",
                sm: "none",
                md: "flex",
                lg: "none",
              },
            }}
            onClick={handleViewChallenges}
          >
            <ListItemIcon>
              <EmojiEvents sx={{ color: "#2E3A59" }} fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Challenges" />
          </MenuItem>
          <MenuItem
            sx={{
              display: {
                xs: "flex",
                sm: "none",
                md: "flex",
                lg: "none",
              },
            }}
            onClick={handleNotifications}
          >
            <ListItemIcon>
              <NotificationsIcon sx={{ color: "#2E3A59" }} fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Notifications" />
          </MenuItem>
          <Divider sx={{ my: 1 }} />
          <MenuItem onClick={handleNavigateProfile}>
            <ListItemIcon>
              <AccountBoxOutlined sx={{ color: "#2E3A59" }} fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </MenuItem>
          <MenuItem onClick={handleNavigateSettings}>
            <ListItemIcon>
              <SettingsOutlined sx={{ color: "#2E3A59" }} fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutOutlined sx={{ color: "#2E3A59" }} fontSize="small" />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </MenuList>
      </MuiMenu>
    </>
  );
};

export default UserHeader;
