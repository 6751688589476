import ReactPlayer from "react-player";
import "./section.styles.scss";

import { Box, Container, Grid, Typography } from "@mui/material";
import { useState } from "react";

const CONNECT =
  "https://kewordal-images-dev.s3-eu-west-1.amazonaws.com/assets/kewordal-media/image/connect.svg";
const LEARN =
  "https://kewordal-images-dev.s3-eu-west-1.amazonaws.com/assets/kewordal-media/image/learn.svg";
const SHARE =
  "https://kewordal-images-dev.s3-eu-west-1.amazonaws.com/assets/kewordal-media/image/share.svg";
const GROW =
  "https://kewordal-images-dev.s3-eu-west-1.amazonaws.com/assets/kewordal-media/image/grow.svg";
const REVISION_VIDEO = "https://youtu.be/_sP55WlEaCg";

const Section6 = () => {
  // state
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <Box className="section-6">
      <div className="top-polygon" />
      <Box sx={{ mt: "-6rem", mb: "-4rem" }}>
        <Container maxWidth="xl">
          <Grid
            container
            columnSpacing={{
              xs: 0,
              md: "4rem",
              // lg: "8rem",
              // xl: "5rem",
            }}
            rowSpacing={10}
          >
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    md: "center",
                  },
                }}
              >
                <Box className="large-circle" />
                <Box
                  className="center"
                  onMouseEnter={() => setIsPlaying(true)}
                  onMouseLeave={() => setIsPlaying(false)}
                  sx={{
                    flexGrow: 1,
                    minHeight: "340px",
                    position: "relative",
                    zIndex: 1,
                    borderRadius: "8px",
                    ml: {
                      xs: 0,
                      sm: "4rem",
                    },
                    maxWidth: {
                      xs: "100%",
                      // sm: "600px",
                      // md: "500px",
                      // lg: "600px",
                    },
                  }}
                >
                  <ReactPlayer
                    style={{
                      borderRadius: "15px",
                      overflow: "hidden",
                    }}
                    width="100%"
                    height="100%"
                    controls={true}
                    url={REVISION_VIDEO}
                    playing={isPlaying}
                    muted
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  position: "relative",
                  zIndex: 1,
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "1rem",
                  height: "100%",
                  paddingRight: {
                    xs: "2rem",
                    md: "7rem",
                  },
                }}
              >
                <Typography
                  component="h2"
                  sx={{
                    color: "#333",
                    fontWeight: 700,
                    fontSize: {
                      xs: "1.5rem",
                      md: "2.8rem",
                    },
                  }}
                >
                  Our Mission
                </Typography>
                <p style={{ fontSize: "1.2rem" }}>
                  Our mission is to enable learners and passive educators to
                  'connect, learn, share and grow'.
                </p>
                <p style={{ fontSize: "1.2rem", marginTop: "-8px" }}>
                  Users visit our site to learn, to share with each other, and
                  by doing this, they grow.
                </p>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "1.8rem",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <img
                      src={CONNECT}
                      alt="personalize"
                      width="60px"
                      height="60px"
                    />
                    <h3 style={{ fontWeight: 600 }}>Connect</h3>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <img src={LEARN} alt="LEARN" width="60px" height="60px" />
                    <h3 style={{ fontWeight: 600 }}>Learn</h3>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <img src={SHARE} alt="Share" width="60px" height="60px" />
                    <h3 style={{ fontWeight: 600 }}>Share</h3>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <img src={GROW} alt="Grow" width="60px" height="60px" />
                    <h3 style={{ fontWeight: 600 }}>Grow</h3>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <div className="bottom-polygon" />
    </Box>
  );
};

export default Section6;
