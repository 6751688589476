import axios from "axios";
import { getToken } from "../../Shared/baseURL";

export const deleteKeyword = async (id) => {
  try {
    const token = await getToken();
    const res = await axios.delete(`/keywords/${id}`, {
      headers: {
        Authorization: token,
      },
    });
    if (res.status >= 200 || res.status < 300) {
      const { data, status } = res;
      return {
        status,
        data,
      };
    }
  } catch (error) {
    if (error.response) {
      const { data, status, statusText } = error.response;
      return {
        status,
        message: data.message || data.error_description || statusText,
      };
    }
  }
};
