import { FormLabel, Grid, MenuItem, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Spinner from "Components/Spinner/Spinner";
import { useNotify, useReduxUserProfile } from "Utils/hooks";
import { useEffect, useState } from "react";
import {
  handleChangeSubscription,
  handleSubscription,
  useSubscriptionProducts,
} from "./Apis";
import ProductCard from "./ProductCard";
import SubscriptionPaymentDrawer from "./SubscriptionPaymentDrawer";
import { HEAR_FROM_US_OPTIONS } from "Shared/Enums";
import { updateUserProfile } from "Pages/Profile/Api";
import { getToken } from "Shared/baseURL";
import { getProfile } from "Redux/Actions";
import { useDispatch } from "react-redux";
import ConfirmPassword from "Components/ConfirmPassword/ConfirmPassword";

const AddSubscription = ({
  open,
  toggle,
  captureHowYouHeard = false,
  alreadySelectedPriceID,
  refetch,
}) => {
  // state
  const [selectedPriceID, setSelectedPriceID] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [howYouHeard, setHowYouHeard] = useState("");
  const [howYouHeardDescription, setHowYouHeardDescription] = useState("");
  const [textFieldHearFromUsOptions] = useState(
    HEAR_FROM_US_OPTIONS.filter((i) => i.freeTextField).map((i) => i.label)
  );

  //   hooks
  const notify = useNotify();

  // queries
  const { isLoading, data } = useSubscriptionProducts();

  // redux
  const dispatch = useDispatch();
  const reduxUser = useReduxUserProfile();

  useEffect(() => {
    if (alreadySelectedPriceID) {
      setSelectedPriceID(alreadySelectedPriceID);
    }
  }, [alreadySelectedPriceID]);

  useEffect(() => {
    if (data) {
      const { products } = data;
      setFilteredProducts(products);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onClose = () => {
    toggle();
  };

  const handleContinue = async (passwordParams = {}) => {
    try {
      setIsBtnLoading(true);

      if (captureHowYouHeard) {
        if (!howYouHeard) {
          throw new Error("Please provide how you heard of subscription.");
        }

        const args = {
          ...passwordParams,
          howYouHeardOfUsSubscription: howYouHeard,
        };
        if (howYouHeardDescription) {
          args.howYouHeardOfUsSubscription = `${howYouHeard} (${howYouHeardDescription})`;
        }
        await handleUpdateProfile(args);
      }

      if (alreadySelectedPriceID) {
        await handleChangeSubscription(selectedPriceID);
        notify("Subscription changed");
        if (refetch) {
          refetch();
        }
        onClose();
      } else {
        const res = await handleSubscription(selectedPriceID);
        setSubscriptionData(res);
      }

      setConfirmPassword(null);
    } catch (error) {
      let errorMessage = "";
      if (error.message.includes("already exists")) {
        errorMessage = error.message;
      } else if (error.message === "INVALID_PASSWORD") {
        errorMessage = "Password is invalid";
      } else {
        errorMessage = error.message;
      }
      notify(errorMessage, { variant: "error" });
    } finally {
      setIsBtnLoading(false);
    }
  };

  const handleConfirmPassword = () => {
    setConfirmPassword(
      <ConfirmPassword
        onCancel={() => {
          setConfirmPassword(null);
        }}
        onConfirm={async (currentPassword) => {
          const data = { currentPassword };
          await handleContinue(data);
        }}
      />
    );
  };

  const handleUpdateProfile = async (values) => {
    const data = { ...values };
    const res = await updateUserProfile(data, reduxUser.uuid);
    if (res.message) {
      throw new Error(res.message);
    }
    let token = await getToken();
    token = token.split(" ")[1];
    dispatch(getProfile(token));
  };

  const handleClosePaymentDrawer = (status) => {
    setSubscriptionData(null);
    if (status === "success" || status === "cancel-payment") {
      onClose();
    }
  };

  const handleSelectPriceID = (priceID) => {
    setSelectedPriceID(priceID);
  };

  return (
    // <Dialog open={open} onClose={onClose} fullScreen>
    //   <DialogTitle>Select a Subscription Plan</DialogTitle>
    //   <DialogContent>
    <div>
      <div className="mt-4">
        {alreadySelectedPriceID && <h5>Select a Subscription Plan</h5>}
        <Grid container spacing={2}>
          {isLoading && <Spinner />}

          {captureHowYouHeard && (
            <Grid item xs={12}>
              <FormLabel>How did you hear of subscription</FormLabel>
              <TextField
                value={howYouHeard}
                required
                type="text"
                label="Select"
                select
                variant="filled"
                fullWidth
                onChange={(e) => {
                  setHowYouHeard(e.target.value);
                }}
              >
                {HEAR_FROM_US_OPTIONS.map((option) => (
                  <MenuItem key={option.label} value={option.label}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              {textFieldHearFromUsOptions.includes(howYouHeard) && (
                <div className="mt-3 mb-3">
                  <TextField
                    value={howYouHeardDescription}
                    fullWidth
                    multiline
                    minRows={2}
                    onChange={(e) => {
                      setHowYouHeardDescription(e.target.value);
                    }}
                    variant="filled"
                    placeholder="Please state"
                  />
                </div>
              )}
            </Grid>
          )}

          {!isLoading &&
            filteredProducts.map((p) => (
              <Grid item xs={12} sm={12} key={p.id}>
                <ProductCard
                  productID={p.id}
                  name={p.name}
                  prices={p.prices}
                  description={p.description}
                  handleSelectPriceID={handleSelectPriceID}
                  selectedPriceID={selectedPriceID}
                />
              </Grid>
            ))}
        </Grid>
      </div>
      <div className="mt-5 d-flex justify-content-center align-items-center gap-3">
        <Button
          disabled={isBtnLoading}
          onClick={onClose}
          variant="outlined"
          color="error"
        >
          Close
        </Button>
        {captureHowYouHeard ? (
          <Button
            disabled={
              !selectedPriceID ||
              isBtnLoading ||
              selectedPriceID === alreadySelectedPriceID ||
              !howYouHeard
            }
            disableElevation
            onClick={handleConfirmPassword}
            variant="contained"
          >
            Continue
          </Button>
        ) : (
          <Button
            disabled={
              !selectedPriceID ||
              isBtnLoading ||
              selectedPriceID === alreadySelectedPriceID
            }
            disableElevation
            onClick={(e) => handleContinue()}
            variant="contained"
          >
            Continue
          </Button>
        )}
      </div>
      {/* Payment drawer */}
      <SubscriptionPaymentDrawer
        open={!!subscriptionData}
        toggle={handleClosePaymentDrawer}
        subscriptionData={subscriptionData}
        refetch={refetch}
      />
      {confirmPassword}
      {/* <Drawer
        open={!!subscriptionData}
        onClose={handleClosePaymentDrawer}
        anchor="right"
        sx={{ zIndex: (theme) => theme.zIndex.modal + 10 }}
      >
        <div className="p-4">
          {subscriptionData && (
            <StripePayment
              clientSecret={subscriptionData.clientSecret}
              closePayment={handleClosePaymentDrawer}
            />
          )}
        </div>
      </Drawer> */}
    </div>
    //   </DialogContent>
    // </Dialog>
  );
};

export default AddSubscription;
