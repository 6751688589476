import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Box, Input, LinearProgress, Paper } from "@mui/material";
import axios from "axios";
import { getToken } from "../../Shared/baseURL";
import { useNotify } from "Utils/hooks";

const ReportList = ({ listID, open, toggle, refetchListData }) => {
  // state
  const [isLoading, setIsLoading] = useState(false);
  const [reason, setReason] = useState("");

  // hooks

  const notify = useNotify();

  const onClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    toggle();
  };

  const handleReportList = async () => {
    try {
      setIsLoading(true);
      const token = await getToken();
      await axios.post(
        `lists/${listID}/report`,
        { content: reason },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify("List reported.");
      setReason("");
      onClose();
      if (refetchListData) {
        refetchListData();
      }
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      aria-labelledby="report-list"
    >
      <DialogTitle id="report-list">Report list</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Tell us what's wrong with this list
        </DialogContentText>
        <Box sx={{ py: 2 }}>
          <Paper sx={{ mb: 2 }} elevation={2}>
            <Input
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              sx={{
                p: 1,
                borderRadius: "10px",
                "&::before": {
                  border: "none",
                },
                "&::after": {
                  border: "none",
                },
                "&:hover:not(.Mui-disabled)::before": {
                  border: "none",
                },
              }}
              fullWidth
              placeholder="Enter reason"
              size="large"
            />
          </Paper>
          {isLoading && (
            <Box mt={2}>
              <LinearProgress />
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          onClick={handleReportList}
          disabled={isLoading}
          color="error"
          variant="contained"
          disableElevation
        >
          Report
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportList;
