import axios from "axios";
import { getToken } from "../../Shared/baseURL";

export const PostGame = async (values) => {
  try {
    const token = await getToken();
    const res = await axios.post("/games", values, {
      headers: {
        Authorization: token,
      },
    });
    return res;
  } catch (error) {
    if (error.response) {
      const { data, status, statusText } = error.response;
      return {
        message: data.message ?? data.error_description ?? statusText,
        status,
      };
    }
  }
};

export const PostChallenge = async (values) => {
  try {
    const token = await getToken();
    const res = await axios.post("/users/challenge", values, {
      headers: {
        Authorization: token,
      },
    });
    return res;
  } catch (error) {
    if (error.response) {
      const { data, status, statusText } = error.response;
      return {
        message: data.message ?? data.error_description ?? statusText,
        status,
      };
    }
  }
};
