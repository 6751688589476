import "./styles.scss";

import { Add, ArrowBack, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
} from "@mui/material";
import { lazy, useEffect, useRef, useState } from "react";
import { useListsGroupedBySubjectsData, useListsOfSubjectsByUser } from "./Api";
import { useNavigate, useParams } from "react-router-dom";

import CustomToolTip from "Components/CustomToolTip/CustomToolTip";
// import GoldLine from "assets/icons/dashed.png";
import NewListCard from "Components/NewDashboard/NewListCard";
import NewMyList from "Components/NewMyList/NewMyList";
import { cloneDeep } from "lodash";
import { debounce } from "lodash";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { useSpecificUserDataByUsername } from "Utils/queries";
import Advertisement from "Components/Advertisement/Advertisement";
import AllSubjectsSelect from "Components/AllSubjectsSelect/AllSubjectsSelect";

// components
const SubjectCards = lazy(() => import("Components/SubjectCard/SubjectCard"));
// const ListCards = lazy(() => import("Components/ListCards/ListCards"));
const AddList = lazy(() => import("Components/AddList/AddList"));
const TutorialDialog = lazy(() =>
  import("Components/TutorialDialog/TutorialDialog")
);

const MotionGrid = motion(Grid);

const fadeVariant = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
  },
};

const MyLists = () => {
  // ref
  let scrollBox = useRef(null);

  // state
  const [showSubjectsLists, setShowSubjectsLists] = useState(false);
  const [allListSubject, setAllListSubject] = useState([]);
  const [filteredAllListSubject, setFilteredAllListSubject] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [addListDialog, setAddListDialog] = useState(false);
  const [otherUser, setOtherUser] = useState(true);
  const [userData, setUserData] = useState(null);
  const [tutorialDialog, setTutorialDialog] = useState(false);
  const [showTooltip, setShowTooltip] = useState(true);

  // router
  const { username } = useParams();
  const navigate = useNavigate();

  // redux
  const userRedux = useSelector((state) => state.user.profile);

  // queries
  const { data: userProfileData } = useSpecificUserDataByUsername(username);
  const {
    data: listBySubjectsData,
    isLoading: subjectsLoading,
    // refetch: refetchListData,
  } = useListsGroupedBySubjectsData(userData?.uuid ?? null);
  const {
    data: listData,
    isLoading: myListLoading,
    refetch: refetchListData,
    fetchNextPage,
    hasNextPage: hasNextListData,
    isFetchingNextPage: isFetchingNextListPage,
  } = useListsOfSubjectsByUser(
    selectedSubject?.subjectHref,
    userData?.uuid,
    showSubjectsLists
  );

  useEffect(() => {
    if (userProfileData) {
      if (username === userRedux.username) {
        setOtherUser(false);
      } else {
        setOtherUser(true);
      }
      setUserData(userProfileData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfileData]);

  useEffect(() => {
    if (listBySubjectsData?.subjects) {
      setAllListSubject(listBySubjectsData?.subjects);
    }
  }, [listBySubjectsData]);

  useEffect(() => {
    if (!username && !otherUser) {
      const renderTutorialDialog = JSON.parse(
        sessionStorage.getItem("tutorialDialog")
      );
      if (!renderTutorialDialog.includes("my-lists")) {
        setTimeout(() => {
          setTutorialDialog(true);
        }, 700);
        let arr = [...renderTutorialDialog, "my-lists"];
        sessionStorage.setItem("tutorialDialog", JSON.stringify(arr));
      } else {
        setTutorialDialog(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  useEffect(() => {
    setFilteredAllListSubject(allListSubject);
    let prevSelectedSubject = localStorage.getItem("myListSelectedSubject");
    let subjects = cloneDeep(allListSubject);
    if (prevSelectedSubject) {
      let obj = subjects.find(
        (item) => item.subjectHref === prevSelectedSubject
      );
      if (obj) {
        handleSelectSubject(obj);
      } else {
        if (subjects.length > 0) {
          handleSelectSubject(subjects[0]);
        }
      }
    } else {
      handleAllSubjectLists();
    }
  }, [allListSubject]);

  const handleSelectSubject = (subject) => {
    localStorage.setItem("myListSelectedSubject", subject?.subjectHref);
    setSelectedSubject(subject);
    setShowSubjectsLists(false);
    // setAllList(subject?.lists ?? []);
  };

  const handleScrollLeft = () => {
    scrollBox.current.scrollBy({
      top: 0,
      left: -400,
      behavior: "smooth",
    });
  };

  const handleScrollRight = () => {
    scrollBox.current.scrollBy({
      top: 0,
      left: 400,
      behavior: "smooth",
    });
  };

  const handleAddList = () => {
    setAddListDialog(true);
  };

  const handleSubjectSearch = debounce((value) => {
    value = value.trim().toLowerCase();
    let arr = cloneDeep(allListSubject);
    arr = arr.filter((item) => item.subject.toLowerCase().includes(value));
    setFilteredAllListSubject(arr);
    if (arr.length > 0) {
      handleSelectSubject(arr[0]);
    }
  }, 600);

  const handleAllSubjectLists = () => {
    setShowSubjectsLists(true);
    setSelectedSubject(null);
    localStorage.removeItem("myListSelectedSubject");
  };

  const handleViewDeletedLists = () => {
    navigate("/deleted-history");
  };

  return (
    <Box className="other-lists-page">
      <Container>
        <Box className="title-box">
          <Box>
            <Box sx={{ display: "flex" }}>
              <span className="fw-500 black text-center size-subTitle">
                {otherUser
                  ? userData &&
                    userData.firstName + " " + userData.lastName + " Lists"
                  : "MY LISTS"}
              </span>
            </Box>
          </Box>
        </Box>
        <Box mt={2}>
          <div className="search-div">
            <MuiTextField
              className="input"
              size="small"
              fullWidth
              variant="filled"
              hiddenLabel
              placeholder="Search subject"
              onChange={(e) => handleSubjectSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Box>
        {subjectsLoading ? (
          <Box sx={{ my: 2 }} className="center">
            <CircularProgress />
          </Box>
        ) : (
          <CustomToolTip
            title="You can find specific kewordal lists using the search bar above as well as pin keywords in different lists using the function buttons within each Kewordal list. Give it a go!"
            open={showTooltip}
            toggleTooltip={setShowTooltip}
            placement="top"
            showArrow
          >
            <div ref={scrollBox} className="subject-list-div custom-scroll">
              <div className="icon-div div-1">
                <IconButton
                  onClick={handleScrollLeft}
                  edge="start"
                  className="icon-btn btn-1"
                >
                  <ArrowBack fontSize="inherit" />
                </IconButton>
              </div>
              <AllSubjectsSelect
                title="All Subjects"
                isSelected={showSubjectsLists}
                onClick={handleAllSubjectLists}
              />
              <div className="subjects-card-container">
                {filteredAllListSubject.map((subject, idx) => (
                  <SubjectCards
                    key={idx}
                    handleSelectSubject={handleSelectSubject}
                    selectedSubject={selectedSubject}
                    subject={subject}
                    newMyLists
                  />
                ))}
              </div>
              <div className="icon-div div-2">
                <IconButton
                  onClick={handleScrollRight}
                  edge="end"
                  className="icon-btn btn-2"
                >
                  <ArrowBack fontSize="inherit" />
                </IconButton>
              </div>
            </div>
          </CustomToolTip>
        )}
        {!otherUser && (
          <Box className="center gap-2" sx={{ my: 3 }}>
            <Button
              onClick={handleAddList}
              size="small"
              variant="contained"
              startIcon={<Add />}
            >
              Add New List
            </Button>
            <Button
              onClick={handleViewDeletedLists}
              size="small"
              color="secondary"
              variant="contained"
            >
              Deleted History
            </Button>
          </Box>
        )}
        <Grid container columns={24} spacing={2} mt={1}>
          <Grid item xs={24} md={16}>
            <Grid container spacing={2}>
              {myListLoading ? (
                <Grid item xs={12}>
                  <Box sx={{ my: 2 }} className="center">
                    <CircularProgress />
                  </Box>
                </Grid>
              ) : (
                listData?.pages.map((page) =>
                  page.lists.map((list, idx) => (
                    <MotionGrid
                      item
                      xs={12}
                      key={list.uuid + idx}
                      variants={fadeVariant}
                      initial="hidden"
                      animate="show"
                    >
                      <NewListCard
                        list={list}
                        selectedSubject={selectedSubject}
                        refetchListData={refetchListData}
                      />
                    </MotionGrid>
                  ))
                )
              )}
              <Grid item xs={12}>
                {hasNextListData && (
                  <div className="center">
                    <Button
                      onClick={fetchNextPage}
                      disabled={isFetchingNextListPage}
                    >
                      {isFetchingNextListPage ? "Loading" : "Load More"}
                    </Button>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={24} md={8}>
            <Grid
              container
              spacing={2}
              direction={{ xs: "row-reverse", md: "row" }}
            >
              <Grid item xs={12} sm={6} md={12}>
                <Advertisement />
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <NewMyList />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {/* Dialogs */}
      <AddList
        open={addListDialog}
        close={setAddListDialog}
        refresh={() => refetchListData()}
        selectedSubject={
          selectedSubject
            ? {
                ...selectedSubject,
                name: selectedSubject.subject,
                href: selectedSubject.subjectHref,
              }
            : null
        }
      />
      {tutorialDialog && (
        <TutorialDialog
          open={tutorialDialog}
          toggleDialog={setTutorialDialog}
          page={4}
          maxPage={3}
        />
      )}
    </Box>
  );
};

export default MyLists;
