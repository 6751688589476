import {
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useDisclosure, useNotify, useReduxUserProfile } from "Utils/hooks";
import dayjs from "dayjs";
import CancelSubscription from "./CancelSubscription";
import { useEffect, useState } from "react";
import { cancelCancellation, handleCompleteSubscription } from "./Apis";
import SubscriptionPaymentDrawer from "./SubscriptionPaymentDrawer";
import AddSubscription from "./AddSubscription";

const DATE_FORMAT = "DD MMM YYYY";

const ActiveSubscriptionCard = ({
  subscriptionID,
  name,
  description,
  price,
  currency = "",
  startDate,
  endDate,
  interval,
  intervalCount,
  status,
  cancelAt,
  selectedPriceID,
  refetch,
}) => {
  // state
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [sponsorData, setSponsorData] = useState(null);

  // redux
  const reduxUser = useReduxUserProfile();

  // hooks
  const notify = useNotify();
  const { isOpen, onToggle } = useDisclosure();
  const {
    isOpen: isOpenChangeSubscription,
    onToggle: onToggleChangeSubscription,
  } = useDisclosure();

  useEffect(() => {
    if (reduxUser && reduxUser.sponsoredBy) {
      setSponsorData(reduxUser.sponsoredBy);
    }
  }, [reduxUser]);

  const handleComplete = async () => {
    try {
      setIsBtnLoading(true);
      const res = await handleCompleteSubscription(selectedPriceID);
      if (res.subscriptions) {
        const { subscriptions } = res;
        const data = subscriptions[0];
        const obj = {
          clientSecret: data.clientSecret,
        };
        setSubscriptionData(obj);
      }
    } catch (error) {
      if (error.response.data.message === "SUBSCRIPTION_EXISTS") {
        notify("Subscription exists", { variant: "error" });
      } else {
        notify(error.response.data.message || error.message, {
          variant: "error",
        });
      }
    } finally {
      setIsBtnLoading(false);
    }
  };

  const handleCancelCancellation = async () => {
    try {
      setIsBtnLoading(true);
      await cancelCancellation(subscriptionID);
      if (refetch) {
        refetch();
      }
    } catch (error) {
      notify(error.response.data.message || error.message, {
        variant: "error",
      });
    } finally {
      setIsBtnLoading(false);
    }
  };

  const handleClosePaymentDrawer = () => {
    setSubscriptionData(null);
    if (refetch) refetch();
  };

  return (
    <div>
      <TableContainer>
        <Table>
          <TableBody>
            {sponsorData ? (
              <TableRow>
                <TableCell component="th">Sponsored By</TableCell>
                <TableCell>
                  <div className="d-flex align-items-center gap-2">
                    <img
                      src={sponsorData?.logo}
                      height={50}
                      width={100}
                      style={{ objectFit: "contain", backgroundColor: "#eee" }}
                      alt={sponsorData?.name}
                    />
                    {sponsorData.name || "---"}
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              <TableRow>
                <TableCell component="th">Description</TableCell>
                <TableCell>{description || "---"}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell component="th">Start Date</TableCell>
              <TableCell>{dayjs.unix(startDate).format(DATE_FORMAT)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th">End Date</TableCell>
              <TableCell>{dayjs.unix(endDate).format(DATE_FORMAT)}</TableCell>
            </TableRow>
            {!sponsorData && (
              <TableRow>
                <TableCell component="th">Price</TableCell>
                <TableCell>
                  £ {price / 100}{" "}
                  <span className="text-capitalize">
                    {intervalCount === 3 ? "quarterly" : `${interval}ly`}
                  </span>
                </TableCell>
              </TableRow>
            )}
            {cancelAt && (
              <TableRow>
                <TableCell component="th">
                  <span className="text-danger fw-500">Cancel At</span>
                </TableCell>
                <TableCell>
                  {dayjs.unix(cancelAt).format(DATE_FORMAT)}
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell component="th">Status</TableCell>
              <TableCell>
                {status === "active" ? (
                  <Chip
                    label={status}
                    color="success"
                    sx={{ color: "white" }}
                  />
                ) : (
                  <Chip
                    label={status}
                    color="warning"
                    sx={{ color: "white" }}
                  />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {!isOpenChangeSubscription && (
        <div className="d-flex gap-3 justify-content-end mt-4">
          {status !== "active" && (
            <Button
              onClick={handleComplete}
              variant="contained"
              color="info"
              disabled={isBtnLoading}
              disableElevation
              size="small"
            >
              Complete
            </Button>
          )}
          {cancelAt ? (
            <Button
              onClick={handleCancelCancellation}
              variant="contained"
              color="info"
              disabled={isBtnLoading}
              disableElevation
              size="small"
            >
              Continue Subscription
            </Button>
          ) : (
            <>
              <Button
                onClick={onToggle}
                variant="contained"
                color="error"
                disabled={isBtnLoading}
                disableElevation
                size="small"
              >
                Cancel
              </Button>
            </>
          )}
          {!cancelAt && status === "active" && !sponsorData && (
            <Button
              onClick={onToggleChangeSubscription}
              variant="contained"
              color="secondary"
              disabled={isBtnLoading}
              disableElevation
              size="small"
            >
              Change Subscription
            </Button>
          )}
        </div>
      )}

      {isOpen && (
        <CancelSubscription
          key="cancel-subscription"
          open={isOpen}
          toggle={onToggle}
          subscriptionID={subscriptionID}
          status={status}
          refetch={refetch}
        />
      )}
      {/* Payment drawer to change */}
      {isOpenChangeSubscription && (
        <AddSubscription
          alreadySelectedPriceID={selectedPriceID}
          open={isOpenChangeSubscription}
          toggle={onToggleChangeSubscription}
          refetch={refetch}
        />
      )}
      {/* Payment drawer to complete */}
      <SubscriptionPaymentDrawer
        open={!!subscriptionData}
        toggle={handleClosePaymentDrawer}
        subscriptionData={subscriptionData}
        refetch={refetch}
      />
    </div>
  );
};

export default ActiveSubscriptionCard;
