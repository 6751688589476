import {
  Avatar,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const GENERIC_AVATAR =
  "https://icon-library.com/images/generic-user-icon/generic-user-icon-10.jpg";

const RenderNotificationList = ({ item }) => {
  // router
  const navigate = useNavigate();

  const handleViewProfile = (username) => {
    if (!username) return;
    navigate(`/my-network/${username}`);
  };

  return (
    <>
      <ListItem
        sx={{
          "&:hover": {
            backgroundColor: "#F2F2F2",
          },
        }}
        className="p-1 justify-content-between"
      >
        {item.username ? (
          <ListItemIcon>
            <Avatar
              src={item?.avatarURL || GENERIC_AVATAR}
              alt={item?.username}
              onClick={() => handleViewProfile(item.username)}
              className={item.username ? "cursor-pointer" : ""}
            />
          </ListItemIcon>
        ) : (
          <ListItemIcon>
            <Avatar
              sx={{ bgcolor: "#22AAF5" }}
              onClick={() => handleViewProfile(item.username)}
            >
              <NotificationsNoneIcon
                sx={{ color: "white" }}
                fontSize="medium"
              />
            </Avatar>
          </ListItemIcon>
        )}
        <ListItemText
          className="me-2"
          primary={
            <div
              dangerouslySetInnerHTML={{
                __html: item.content,
              }}
            />
          }
          classes={{ secondary: "text-end" }}
        />
        <span className="time">{`${dayjs(item.createdAt).fromNow()}`}</span>
      </ListItem>
      <Divider sx={{ my: 1 }} />
    </>
  );
};

export default RenderNotificationList;
