import React from "react";
import { Button, Dialog, DialogContent } from "@mui/material";
import {
  FacebookIcon,
  WhatsappIcon,
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { CopyAll } from "@mui/icons-material";
import { useNotify } from "Utils/hooks";

const deployLink = `${process.env.REACT_APP_DEPLOY_LINK}/posts`;

const SharePostDialog = ({ open, toggle, postId, postHref }) => {
  // state
  const notify = useNotify();

  const handleClose = () => {
    toggle(false);
  };

  const handleCopyClipboard = () => {
    const text = `${deployLink}/${postHref}`;
    navigator.clipboard.writeText(text);
    notify("Link copied.", { variant: "default" });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <h4>Share Post</h4>
        <div
          className="pe-5 pt-2"
          style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          <div>
            <FacebookShareButton
              url={`${deployLink}/${postHref}`}
              hashtag={"#post"}
            >
              <FacebookIcon size={32} round /> Facebook
            </FacebookShareButton>
          </div>
          <div>
            <WhatsappShareButton
              url={`${deployLink}/${postHref}`}
              hashtag={"#post"}
            >
              <WhatsappIcon size={32} round /> Whatsapp
            </WhatsappShareButton>
          </div>
          <div>
            <TwitterShareButton
              url={`${deployLink}/${postHref}`}
              hashtag={"#post"}
            >
              <TwitterIcon size={32} round /> Twitter
            </TwitterShareButton>
          </div>
          <div>
            <LinkedinShareButton
              url={`${deployLink}/${postHref}`}
              hashtag={"#post"}
            >
              <LinkedinIcon size={32} round /> LinkedIn
            </LinkedinShareButton>
          </div>
          <div>
            <Button
              sx={{ color: "#333" }}
              size="large"
              startIcon={<CopyAll />}
              onClick={handleCopyClipboard}
            >
              Copy URL
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SharePostDialog;
