import { Box, Container, Typography } from "@mui/material";
import HomeBlogCards from "Components/BlogCards/HomeBlogCards";
import Spinner from "Components/Spinner/Spinner";
import { useHandleGetBlogs } from "Pages/Blogs/Api";
import { useEffect, useState } from "react";
import Slider from "react-slick";

const Section5 = () => {
  // state
  const [allBlogs, setAllBlogs] = useState([]);

  // queries
  const { data, isLoading } = useHandleGetBlogs(1, "");

  useEffect(() => {
    if (data) {
      const { blogs } = data;
      if (blogs.length > 0 && blogs.length <= 1) {
        setAllBlogs(Array(5).fill(blogs[0]));
      } else {
        setAllBlogs(blogs);
      }
    }
  }, [data]);

  const handleScrollToBottom = () => {
    const elem = document.getElementById("newsletter-subscribe-email");
    if (elem) {
      elem.focus();
    } else {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  return (
    <Container>
      <Box sx={{ mt: "4rem" }}>
        <Typography
          component="h2"
          sx={{
            fontWeight: 600,
            color: "#333",
            fontSize: { xs: "1.5rem", md: "2.8rem" },
          }}
          className="text-center"
        >
          Start Your Learning Journey
        </Typography>
        <Box
          sx={{
            padding: {
              xs: "0 2rem",
              md: "0 10rem",
            },
          }}
          className="center mb-3"
        >
          <p
            onClick={handleScrollToBottom}
            className="black text-center fw-400"
            style={{
              fontSize: "0.95rem",
              textDecoration: "underline",
              cursor: "pointer",
              display: "inline-block",
            }}
          >
            Subscribe to our newsletter to get regular updates.
          </p>
        </Box>
        {/* <Grid container spacing={3} sx={{ mt: "5px" }}>
          <Grid item xs={12} md={6}>
            <div className="d-flex justify-content-center">
              <Box maxWidth={300}>
                <Subscribe />
              </Box>
            </div>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid> */}
      </Box>
      {isLoading && (
        <div className="my-5">
          <Spinner />
        </div>
      )}

      <Slider
        dots={true}
        slidesToShow={3}
        slidesToScroll={2}
        infinite={true}
        autoplay={true}
        variableWidth={false}
        responsive={[
          {
            breakpoint: "600px",
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: "900px",
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: "1200px",
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
            },
          },
        ]}
      >
        {!isLoading &&
          allBlogs.map((blog) => (
            <Box
              sx={{
                pr: "1rem",
                maxWidth: {
                  xs: "100%",
                  // sm: "350px",
                },
              }}
              key={blog.uuid}
            >
              <HomeBlogCards blog={blog} />
            </Box>
          ))}
      </Slider>
    </Container>
  );
};

export default Section5;
