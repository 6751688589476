import { useEffect } from "react";

const SplitContent = ({ content = "", splitAt = 500 }) => {
  useEffect(() => {
    if (content) {
      //   handleSplit();
    }
  }, [content]);

  const handleSplit = () => {
    const contentLength = content.length;

    if (contentLength > splitAt) {
      const text = content.substring(0, splitAt);
      console.log(text);
    }

    console.log(contentLength);
  };

  return (
    <div
      className="description"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default SplitContent;
