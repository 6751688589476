import { useNavigate } from "react-router-dom";

const RenderList = ({ list, idx }) => {
  // router
  const navigate = useNavigate();

  const handleClickMyList = () => {
    if (!list.subjectHref) return;
    navigate(`/view-list/${list.subjectHref}/${list.href}`);
  };

  return (
    <>
      <div
        className="list-item"
        key={list.uuid}
        onClick={() => handleClickMyList(list.uuid)}
      >
        <span>{++idx}.</span>
        <span className="list-name">{list.name}</span>
      </div>
    </>
  );
};

export default RenderList;
