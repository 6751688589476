import "./styles.scss";

const SubjectCard = ({
  handleSelectSubject,
  selectedSubject,
  subject,
  newMyLists,
}) => {
  // renders subject cards in `my-lists` page
  if (newMyLists) {
    const { subject: name, subjectHref, subjectImage } = subject;
    return (
      <div
        className={`subject-card ${
          subjectHref === selectedSubject?.subjectHref && "selected"
        }`}
        style={
          {
            // backgroundImage: `url(${subjectImage})`,
          }
        }
        // onMouseOver={handleMouseOver}
        // onMouseLeave={handleMouseLeave}
        onClick={() => handleSelectSubject(subject)}
      >
        <img
          src={subjectImage}
          alt={name}
          height="100%"
          width="100%"
          style={{ objectFit: "cover" }}
        />
        <p
          className={`name-div ${
            subjectHref === selectedSubject?.subjectHref && "selected"
          }`}
        >
          {name}
        </p>
      </div>
    );
  } else {
    const { name, image, uuid } = subject;
    return (
      <div
        id={`subject-card-${uuid}`}
        className={`subject-card ${
          uuid === selectedSubject?.uuid && "selected"
        }`}
        style={
          {
            // backgroundImage: `url(${image})`,
          }
        }
        // onMouseOver={handleMouseOver}
        // onMouseLeave={handleMouseLeave}
        onClick={() => handleSelectSubject(subject)}
      >
        <img
          src={image}
          alt={name}
          height="100%"
          width="100%"
          style={{ objectFit: "cover" }}
        />
        <p
          className={`name-div ${uuid === selectedSubject?.uuid && "selected"}`}
        >
          {name}
        </p>
      </div>
    );
  }
};

export default SubjectCard;
