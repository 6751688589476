import { Avatar, Box, Button, Grid, MenuItem, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { Field } from "formik";
import { TextField } from "formik-mui";
import { Titles } from "Shared/Enums";
import dayjs from "dayjs";
import { InfoOutlined } from "@mui/icons-material";

/**
 * @description contains text fields for edit profile page
 * @returns TextFields => About Me, First Name, Last Name, Username, Department
 */

const TextFieldsContainer = ({
  userProfile,
  handleProfilePic,
  profilePicRef,
  profileImagePreview,
}) => {
  // state
  const [renderTitle, setRenderTitle] = useState(true);
  const [renderUserPicture, setRenderUserPicture] = useState(false);

  useEffect(() => {
    const { dateOfBirth } = userProfile;
    if (dateOfBirth) {
      let userAge = dayjs().diff(dateOfBirth, "years");
      if (userAge >= 21) {
        setRenderTitle(false);
      } else {
        setRenderTitle(false);
      }
      if (userAge >= 16) {
        setRenderUserPicture(true);
      } else {
        setRenderUserPicture(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} className="align-items-start">
            <p className="text-style mb-3 mb-lg-0">
              Username{" "}
              <Tooltip title="Usernames cannot change once registered. A username SHOULD NOT be the same as your real name.">
                <InfoOutlined fontSize="small" color="info" />
              </Tooltip>
            </p>
          </Grid>
          <Grid item xs={12} md={5}>
            <Field
              component={TextField}
              name="username"
              label="Username"
              fullWidth
              size="small"
              disabled
            />
          </Grid>
        </Grid>
        <hr />
      </Grid>
      {renderUserPicture && (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} className="align-items-start">
              <p className="text-style m-0">Profile picture</p>
              <span className="small-text">
                This will be displayed on your profile
              </span>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              className="d-flex align-items-center gap-1"
            >
              <Avatar
                sx={{ width: 70, height: 70 }}
                src={profileImagePreview || userProfile?.avatarURL}
              />
              <Button
                component="label"
                className="text-capitalize"
                size="small"
                color="secondary"
                // onClick={() => profilePicRef.current.click()}
              >
                Upload
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={(e) => handleProfilePic(e.target.files[0])}
                  ref={profilePicRef}
                />
              </Button>
              {/* <Button size="small" color="error" className="text-capitalize">
              Delete
            </Button> */}
            </Grid>
          </Grid>
          <hr />
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} className="align-items-start">
            <p className="text-style mb-3 mb-lg-0">
              Bio{" "}
              <Tooltip title="Please do not include financial information or personal data in this section.">
                <InfoOutlined fontSize="small" color="info" />
              </Tooltip>
            </p>
            <span className="small-text">Tell us something about yourself</span>
          </Grid>
          <Grid item xs={12} md={8}>
            <Field
              component={TextField}
              name="aboutMe"
              multiline
              minRows={4}
              fullWidth
              placeholder="“Use this section to tell us something about yourself in relation to your achievements (if applicable or adapt where necessary), e.g., 
              “My achievements in education so far are …”
              “I aspire to achieve in education a …
              “My favorite subject is (was) …
              “The subject I am most successful in is (was) …
              “The subject I require the most support in is (was) …
              “The subject I do well enough in to be able to help others is (was) …”"
            />
          </Grid>
        </Grid>
        <hr />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} className="align-items-start">
            <p className="text-style mb-3 mb-lg-0">Name</p>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              {renderTitle && (
                <Grid item xs={12} sm="auto">
                  <Box
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "100px",
                      },
                    }}
                  >
                    <Field
                      component={TextField}
                      select
                      size="small"
                      name="title"
                      label="Title"
                      fullWidth
                    >
                      {Titles.map((title) => (
                        <MenuItem key={title} value={title}>
                          {title}
                        </MenuItem>
                      ))}
                    </Field>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <Field
                  component={TextField}
                  name="firstName"
                  label="First Name"
                  fullWidth
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm>
                <Field
                  component={TextField}
                  fullWidth
                  size="small"
                  name="lastName"
                  label="Last Name"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <hr />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} className="align-items-start">
            <p className="text-style mb-3 mb-lg-0">
              Department{" "}
              <Tooltip
                title="Complete this section by either adding the:
name of your department or the subject you teach if you are a teacher or lecturer
name of the department if you are studying a single topic at university level
name of the subject or department you would provide support in if you were able to volunteer and utilize your knowledge and skills."
              >
                <InfoOutlined fontSize="small" color="info" />
              </Tooltip>
            </p>
          </Grid>
          <Grid item xs={12} md={5}>
            <Field
              component={TextField}
              name="department"
              fullWidth
              size="small"
              label="Department"
            />
          </Grid>
        </Grid>
        <hr />
      </Grid>
    </>
  );
};

export default TextFieldsContainer;
