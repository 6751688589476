import React from "react";
import { red, grey } from "@mui/material/colors";

const RenderError = ({
  message = "Something went wrong!",
  renderBlackText,
}) => {
  const redStyles = {
    color: red["400"],
  };

  const blackStyles = {
    color: grey["800"],
  };

  return (
    <div>
      <h4
        style={renderBlackText ? blackStyles : redStyles}
        className="mt-4 text-center"
      >
        Error: {message}.
      </h4>
    </div>
  );
};

export default RenderError;
