import styles from "./profile-info.module.scss";
import { TableCell, TableRow } from "@mui/material";
import React from "react";

const CustomTableRow = ({ rowName, rowValue }) => {
  return (
    <TableRow>
      <TableCell>
        <span className={styles["title"]}>{rowName}:</span>
      </TableCell>
      <TableCell align="left">
        <span className={styles["info"]}>{rowValue || "---"}</span>
      </TableCell>
    </TableRow>
  );
};

export default CustomTableRow;
