import { Navigate, useSearchParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { userLogout } from "Redux/Actions";

const OnlyForLoggedOut = ({ user, element }) => {
  // redux
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  let redirectURL = searchParams.get("redirectURL");

  useEffect(() => {
    const tokenExpired = searchParams.get("token_expired");
    if (tokenExpired === "true") {
      dispatch(userLogout());
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  if (user) {
    if (redirectURL) {
      return <Navigate to={redirectURL} replace />;
    }
    return <Navigate to="/dashboard" replace />;
  }
  return element;
};

export default OnlyForLoggedOut;
