import axios from "axios";
import { getToken } from "../../Shared/baseURL";

export const fetchSubject = async (listHref) => {
  try {
    const token = await getToken();
    const res = await axios.get(`/query/lists/${listHref}`, {
      headers: {
        Authorization: token,
      },
    });
    if (res.status === 200) {
      const { data, status } = res;
      return {
        status,
        data,
      };
    }
  } catch (error) {
    if (error.response) {
      const { data, status, statusText } = error.response;
      return {
        status,
        message: data.message || data.error_description || statusText,
      };
    }
  }
};

export const fetchSubjectObject = async (listHref) => {
  try {
    const token = await getToken();
    const data = await axios
      .get(`/lists/[href=${listHref}]`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => res.data);
    return data;
  } catch (error) {
    if (error.response) {
      const { data, status, statusText } = error.response;
      return {
        status,
        message: data.message || data.error_description || statusText,
      };
    }
  }
};

export const fetchKewordsByListID = async (
  listHref,
  page = 1,
  sort = "",
  viewSize = 30
) => {
  try {
    const token = await getToken();
    const res = await axios.get(`lists/[href=${listHref}]/keywords.json`, {
      headers: {
        Authorization: token,
      },
      params: {
        page,
        sort: `pinnedTime.DESC,position,${sort}`,
        size: viewSize,
      },
    });
    if (res.status === 200) {
      const { data, status } = res;
      return {
        status,
        data,
      };
    }
  } catch (error) {
    if (error.response) {
      const { data, status, statusText } = error.response;
      return {
        status,
        message: data.message || data.error_description || statusText,
      };
    }
  }
};
