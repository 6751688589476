import "./styles.scss";

import * as Yup from "yup";

import {
  Box,
  Button,
  Grid,
  Paper,
  Tooltip,
  Typography,
  TextField as MuiTextField,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { updateUserProfile, updateUserProfilePic } from "./Api";
import { useRef, useState } from "react";
import { useNotify, useReduxUserProfile } from "Utils/hooks";
import GooglePlace from "Components/GooglePlace/GooglePlace";
import SelectContainer from "Components/EditProfileForm/SelectContainer";
import Spinner from "Components/Spinner/Spinner";
import TextFieldsContainer from "Components/EditProfileForm/TextFieldsContainer";
import { getProfile } from "Redux/Actions";
import { getToken } from "Shared/baseURL";
import { useDispatch } from "react-redux";
import { InfoOutlined } from "@mui/icons-material";
import ConfirmPassword from "Components/ConfirmPassword/ConfirmPassword";
import { COUNTRIES } from "Utils/countries";
import { Autocomplete } from "formik-mui";
import ImageToBase64 from "Utils/imageToBase64";

const SubmitSchema = Yup.object().shape({
  aboutMe: Yup.string(),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  username: Yup.string().required("Required"),
  department: Yup.string(),
  degree: Yup.string().required("Required"),
  highestQualification: Yup.string(),
  studiesAt: Yup.string().required("Required"),
  cityFrom: Yup.string(),
  countryFrom: Yup.object().nullable().required("Required"),
  gender: Yup.string().required("Required"),
  race: Yup.string(),
  title: Yup.string(),
});

const EditUserDetails = () => {
  // ref
  const profilePicRef = useRef(null);

  // state
  const notify = useNotify();
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [profileImagePreview, setProfileImagePreview] = useState("");

  // redux
  const dispatch = useDispatch();
  const user = useReduxUserProfile();

  const handleProfileImageSelect = async (image) => {
    if (!image) return;
    const base64URL = await ImageToBase64(image);
    setProfileImage(image);
    setProfileImagePreview(base64URL);
    notify("Image selected");
  };

  const handleProfilePic = async (image) => {
    if (image) {
      try {
        notify("Uploading profile image...", {
          variant: "info",
          key: "profile-image-status",
        });
        const res = await updateUserProfilePic(image);
        if (res.message === "MODERATION_UNSUCCESSFUL") {
          notify("The content was not uploaded because it was inappropriate.", {
            variant: "error",
            key: "profile-image-status",
          });
          return;
        }
        notify("Profile image updated.", {
          variant: "success",
          key: "profile-image-status",
        });
        // let token = localStorage.getItem("userToken");
        // setTimeout(() => {
        //   dispatch(getProfile(token));
        // }, 3000);
      } catch (err) {
        notify(err.message, {
          variant: "error",
          key: "profile-image-status",
        });
      }
    }
  };

  const handleSubmitForm = async (values, setSubmitting) => {
    try {
      // handle profile image upload
      if (profileImage) {
        await handleProfilePic(profileImage);
      }

      const data = { ...values, countryFrom: values.countryFrom.name };
      const res = await updateUserProfile(data, user.uuid);
      if (res.message) {
        throw new Error(res.message);
      }
      let token = await getToken();
      token = token.split(" ")[1];
      setTimeout(() => {
        dispatch(getProfile(token));
        notify("Profile updated.");
      }, 3000);
      setConfirmPassword(null);
      setProfileImage(null);
    } catch (error) {
      return error;
    } finally {
      setSubmitting(false);
    }
  };

  const handleConfirmPassword = (values, setSubmitting) => {
    setConfirmPassword(
      <ConfirmPassword
        onCancel={() => {
          setSubmitting(false);
          setConfirmPassword(null);
        }}
        onConfirm={async (currentPassword) => {
          const data = { ...values, currentPassword };
          return await handleSubmitForm(data, setSubmitting);
        }}
      />
    );
  };

  if (!user) {
    return <Spinner />;
  }

  return (
    <Paper className="p-4 rounded-3 paper">
      <div>
        <h4>Personal Details</h4>
        <hr />
      </div>
      <Formik
        initialValues={
          user
            ? {
                aboutMe: user?.aboutMe ?? "",
                firstName: user.firstName ?? "",
                lastName: user.lastName ?? "",
                username: user.username ?? "",
                avatarURL: user.avatarURL ?? "",
                degree: user.degree ?? "",
                studiesAt: user.studiesAt ?? "",
                department: user.department ?? "",
                cityFrom: user.cityFrom ?? "",
                countryFrom: user.countryFrom
                  ? { name: user.countryFrom, code: "" }
                  : {
                      name: "",
                      code: "",
                    },
                disabilities: user.disabilities ?? "",
                gender: user.gender ?? "Male",
                race: user.race ?? "",
                highestQualification: user.highestQualification ?? "",
                title: user.title ?? "",
              }
            : {
                aboutMe: "",
                firstName: "",
                lastName: "",
                username: "",
                avatarURL: "",
                studiesAt: "",
                degree: "",
                department: "",
                cityFrom: "",
                countryFrom: {
                  name: "",
                  code: "",
                },
                disabilities: "",
                gender: "Male",
                race: "",
                highestQualification: "",
                title: "",
              }
        }
        validationSchema={SubmitSchema}
        onSubmit={(values, { setSubmitting }) =>
          handleConfirmPassword(values, setSubmitting)
        }
      >
        {({ values, isSubmitting, submitForm, setFieldValue }) => (
          <Form autoComplete="off">
            <div>
              <span className="text-style">Profile</span>
              <br />
              <span className="small-text">
                Update your picture and personal details here.
              </span>
              <hr />
            </div>
            <Grid container spacing={2}>
              <TextFieldsContainer
                userProfile={user}
                profileImagePreview={profileImagePreview}
                profilePicRef={profilePicRef}
                handleProfilePic={handleProfileImageSelect}
              />
              <SelectContainer
                formValues={values}
                setFieldValue={setFieldValue}
              />
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} className="align-items-start">
                    <p className="text-style mb-3 mb-lg-0">
                      Current Place of Study
                      <Tooltip title="Please type in the name of the place you currently study at. If you are not currently studying please use the dropdown menu to which says 'not currently studying'.">
                        <InfoOutlined
                          fontSize="small"
                          color="info"
                          className="ms-1"
                        />
                      </Tooltip>
                    </p>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <GooglePlace
                      location={user.studiesAt}
                      setLocation={(val) => {
                        setFieldValue("studiesAt", val.value.description);
                      }}
                    />
                    <ErrorMessage
                      name="studiesAt"
                      render={(msg) => (
                        <Typography
                          sx={{ color: "red", fontSize: "12px" }}
                          component="small"
                        >
                          {msg}
                        </Typography>
                      )}
                    />
                  </Grid>
                </Grid>
                <hr />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} className="align-items-start">
                    <p className="text-style mb-3 mb-lg-0">
                      Country of birth{" "}
                      <Tooltip title="We monitor the country of birth of our users to improve the delivery of our services. On occasions, and where appropriate, we may use this to strengthen a match that might be more effective in helping to improve outcomes.">
                        <InfoOutlined
                          fontSize="small"
                          color="info"
                          className="ms-1"
                        />
                      </Tooltip>
                    </p>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Field
                      name="countryFrom"
                      component={Autocomplete}
                      options={COUNTRIES}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <MuiTextField
                          {...params}
                          name="countryFrom"
                          placeholder="Type to search..."
                          variant="outlined"
                          fullWidth
                          hiddenLabel
                          size="small"
                        />
                      )}
                    />
                    <ErrorMessage
                      name="countryFrom"
                      render={(msg) => (
                        <Typography
                          sx={{ color: "red", fontSize: "12px" }}
                          component="small"
                        >
                          {msg}
                        </Typography>
                      )}
                    />
                  </Grid>
                </Grid>
                <hr />
              </Grid>
              <Grid item xs={12}>
                <Box className="display-end">
                  <Button
                    onClick={submitForm}
                    disabled={isSubmitting}
                    variant="contained"
                    type="submit"
                  >
                    {isSubmitting ? "Submitting..." : "Save Changes"}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      {confirmPassword}
    </Paper>
  );
};

export default EditUserDetails;
