import "./styles.scss";

import {
  Avatar,
  AvatarGroup,
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
} from "@mui/material";
import {
  ChatBubbleOutline,
  IosShareOutlined,
  Send,
  ThumbDown,
  ThumbUp,
  ThumbUpOutlined,
  ThumbDownOutlined,
} from "@mui/icons-material";
import { useNotify, useReduxUserProfile } from "Utils/hooks";
import { useEffect, useRef, useState } from "react";

import RenderListComments from "Components/RenderListComments/RenderListComments";
import Spinner from "Components/Spinner/Spinner";
import axios from "axios";
import { getToken } from "Shared/baseURL";
import { useBlogCommentsData } from "Utils/comments";

const BlogComments = ({ blogId, likes, dislikes, refetchBlogData }) => {
  // ref
  const commentInputRef = useRef();

  // state
  const notify = useNotify();
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [textForLikes, setTextForLikes] = useState("A person liked this blog");
  const [isLikedByMe, setIsLikedByMe] = useState(false);
  const [isDislikedByMe, setDislikedByMe] = useState(false);
  const [moderationErrors, setModerationErrors] = useState("");

  // redux
  const reduxUser = useReduxUserProfile();

  // queries
  const {
    isLoading: commentsLoading,
    data: commentsData,
    refetch,
    hasNextPage: hasNextComments,
    fetchNextPage: fetchNextComments,
    isFetchingNextPage: isFetchingNextComments,
  } = useBlogCommentsData(blogId);

  useEffect(() => {
    let likesLength = likes.length;
    let text = "";
    switch (likes.length) {
      case 0: {
        text = "0 liked this.";
        break;
      }
      case 1: {
        let firstUser = likes[0];
        text = `${firstUser.username} liked this.`;
        break;
      }
      default: {
        let firstUser = likes[0];
        text = `${firstUser.username} and ${likesLength - 1} other liked this.`;
        break;
      }
    }
    setTextForLikes(text);
  }, [likes]);

  useEffect(() => {
    setIsLikedByMe(likes?.some((item) => item.uuid === reduxUser?.uuid));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [likes]);

  useEffect(() => {
    setDislikedByMe(dislikes?.some((item) => item.uuid === reduxUser?.uuid));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dislikes]);

  const handlePostComment = async () => {
    if (!comment) {
      return;
    }
    try {
      setIsLoading(true);
      const token = await getToken();
      await axios.post(
        `blogs/${blogId}/comments`,
        { comment },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify("Comment posted.");
      setComment("");
      refetch();
    } catch (error) {
      if (error?.response?.data?.message === "MODERATION_UNSUCCESSFUL") {
        if (error?.response?.data?.errors) {
          let errors = [];
          error?.response?.data?.errors?.forEach((err) => {
            const { kewordalModerationLabels } = err;
            kewordalModerationLabels.forEach((item) => {
              errors.push(item.name);
            });
          });
          setModerationErrors(errors.join(", "));
        }
        notify("The content was not uploaded because it was inappropriate.", {
          variant: "error",
        });
      } else {
        notify(error.message, { variant: "error" });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeComment = (e) => {
    setComment(e.target.value);
  };

  const handleClickCommentBtn = () => {
    commentInputRef.current.scrollIntoView({ block: "center" });
    commentInputRef.current.focus();
  };

  const handleDislike = async () => {
    try {
      const token = await getToken();
      await axios.post(
        `blogs/${blogId}/dislikes`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify("Blog disliked.");
      refetchBlogData();
    } catch (error) {
      notify(error.message, { variant: "error" });
    }
  };

  const handleUndoDislike = async () => {
    try {
      const token = await getToken();
      await axios.delete(`blogs/${blogId}/dislikes`, {
        headers: {
          Authorization: token,
        },
      });
      notify("Undo disliked.");
      refetchBlogData();
    } catch (error) {
      notify(error.message, { variant: "error" });
    }
  };

  const handleLike = async () => {
    try {
      const token = await getToken();
      await axios.post(
        `blogs/${blogId}/likes`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify("Blog liked.");
      refetchBlogData();
    } catch (error) {
      notify(error.message, { variant: "error" });
    }
  };

  const handleUnlike = async () => {
    try {
      const token = await getToken();
      await axios.delete(`blogs/${blogId}/likes`, {
        headers: {
          Authorization: token,
        },
      });
      notify("Blog unliked.");
      refetchBlogData();
    } catch (error) {
      notify(error.message, { variant: "error" });
    }
  };

  if (commentsLoading) {
    return <Spinner size={25} />;
  }

  return (
    <div className="list-comments-component">
      <Divider />
      <div className="info-div">
        <div className="avatar-div">
          <AvatarGroup max={4}>
            {likes.map((item) => (
              <Avatar alt={item.username} src={item.avatarURL} />
            ))}
          </AvatarGroup>
          <span>{textForLikes}</span>
        </div>
        <div className="comments-reposts">
          <span>{commentsData?.pages[0].pagination.total} comments</span>
        </div>
      </div>
      <Divider />
      {reduxUser && (
        <div className="btn-div">
          {isLikedByMe ? (
            <Button
              title="Dislike"
              startIcon={<ThumbUp />}
              className="liked-btn"
              onClick={handleUnlike}
            >
              <span className="btn-text">Liked</span>
            </Button>
          ) : (
            <Button
              title="Like"
              startIcon={<ThumbUpOutlined />}
              onClick={handleLike}
            >
              <span className="btn-text">Like</span>
            </Button>
          )}
          {isDislikedByMe ? (
            <Button
              title="Undo Dislike"
              startIcon={<ThumbDown />}
              className="liked-btn"
              onClick={handleUndoDislike}
            >
              <span className="btn-text">Disliked</span>
            </Button>
          ) : (
            <Button
              title="Dislike"
              startIcon={<ThumbDownOutlined />}
              onClick={handleDislike}
            >
              <span className="btn-text">Dislike</span>
            </Button>
          )}
          <Button
            startIcon={<ChatBubbleOutline />}
            onClick={handleClickCommentBtn}
          >
            <span className="btn-text">Comment</span>
          </Button>
          <Button startIcon={<IosShareOutlined />}>
            <span className="btn-text">Repost</span>
          </Button>
        </div>
      )}
      <Divider />
      <div className="comments-list custom-scroll-thin">
        {commentsData?.pages.map((page) =>
          page?.comments.map((item) => (
            <>
              <RenderListComments
                comment={item?.comment}
                name={item?.user?.username}
                date={item?.createdAt}
                image={item?.user?.avatarURL}
              />
              <Divider className="comment-divider" />
            </>
          ))
        )}
      </div>
      {hasNextComments && !isFetchingNextComments && (
        <div className="center">
          <Button size="small" color="secondary" onClick={fetchNextComments}>
            Load More
          </Button>
        </div>
      )}
      {isFetchingNextComments && <Spinner size={20} />}
      {reduxUser && (
        <div className="post-comment-div">
          <Input
            ref={commentInputRef}
            value={comment}
            onChange={handleChangeComment}
            placeholder="Write a comment"
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  disabled={!comment}
                  color="primary"
                  size="small"
                  aria-label="toggle password visibility"
                  onClick={handlePostComment}
                  edge="end"
                >
                  {isLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <Send sx={{ rotate: "-45deg" }} />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          {moderationErrors && (
            <FormHelperText error>
              This field contains inappropriate words "{moderationErrors}"
            </FormHelperText>
          )}
        </div>
      )}
    </div>
  );
};

export default BlogComments;
