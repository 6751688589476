import * as Yup from "yup";

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Container,
  FormLabel,
  Grid,
  LinearProgress,
  MenuItem,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Link, useParams } from "react-router-dom";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "formik-mui-x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField } from "formik-mui";
import { UserTypes } from "../../Shared/Enums";
import axios from "axios";
import dayjs from "dayjs";
import { getClientToken } from "../../Shared/baseURL";
import { useQuery } from "react-query";
import { useState } from "react";

const RegisterSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  username: Yup.string().required("Required"),
  invitationCode: Yup.string().required("Required"),
  dateOfBirth: Yup.date()
    .max(dayjs().subtract(13, "year"), "Must be at least 13 years old.")
    .required("Required"),
});

const fetchUser = async (userID) => {
  const token = await getClientToken();
  return await axios
    .get(`/users/${userID}.json`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      if (error.response) {
        return error.response.data.message;
      }
      return error;
    });
};

const Activate = () => {
  // state
  const [alert, setAlert] = useState({
    msg: "",
    type: "info",
    title: "Success",
  });
  const [showActivationPage, setShowActivationPage] = useState(false);
  const [otherSelected, setOtherSelected] = useState(false);

  // router
  const { userID } = useParams();

  // query
  const { isLoading, error, data, isFetching } = useQuery(
    ["get-user-profile", userID],
    () => fetchUser(userID),
    {
      onSuccess,
      onError,
      refetchOnWindowFocus: false,
    }
  );

  function onSuccess(data) {
    if (data === "Resource not found") {
      setAlert({
        title: "Error",
        type: "error",
        msg: "Invalid request.",
      });
    } else if (data.emailValidated) {
      setAlert({
        title: "Success",
        type: "success",
        msg: (
          <span>
            Your account is already active. Please proceed to{" "}
            <Link className="text-white" to="/auth/login">
              login.
            </Link>
          </span>
        ),
      });
    } else if (!data.emailValidated && data.facebookUserId) {
      setShowActivationPage(true);
      setAlert({
        title: "Success",
        type: "success",
        msg: "",
      });
    } else if (!data.emailValidated && !data.facebookUserId) {
      setAlert({
        title: "Activating...",
        type: "info",
        msg: "Activating your account.",
      });
      handleActivateUser();
    }
  }

  function onError(error) {
    setAlert({
      title: "Error",
      type: "error",
      msg: `${error.message}.`,
    });
  }

  const handleSubmit = async (values, setSubmitting) => {
    if (!data.emailValidated && data.facebookUserId) {
      handleActivateFbUser(values, setSubmitting);
    }
  };

  // fb user
  const handleActivateFbUser = async (values, setSubmitting) => {
    try {
      let data = {
        ...values,
        dateOfBirth: dayjs(values.dateOfBirth).format("YYYY-MM-DD"),
      };
      const token = await getClientToken();
      const res = await axios.post(`/users/${userID}/activate-facebook`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        setShowActivationPage(false);
        setAlert({
          title: "Activated",
          type: "success",
          msg: (
            <span>
              Your account has been activated. Please proceed to{" "}
              <Link className="text-white" to="/auth/login">
                login.
              </Link>
            </span>
          ),
        });
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data.message.includes("username")) {
          setAlert({
            title: "Error",
            type: "error",
            msg: "User with this username already exists.",
          });
        } else {
          setAlert({
            title: "Error",
            type: "error",
            msg: error.response.data.message,
          });
        }
      } else {
        setAlert({
          title: "Error",
          type: "error",
          msg: error.message,
        });
      }
    } finally {
      setSubmitting(false);
    }
  };

  // simple user
  const handleActivateUser = async () => {
    try {
      const token = await getClientToken();
      const res = await axios.post(
        `/users/${userID}/activate`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setAlert({
          title: "Activated",
          type: "success",
          msg: (
            <span>
              Your account has been activated.. Please proceed to{" "}
              <Link className="text-white" to="/auth/login">
                login.
              </Link>
            </span>
          ),
        });
      }
    } catch (error) {
      if (error.response) {
        setAlert({
          title: "Error",
          type: "error",
          msg: error.response.data.message,
        });
      } else {
        setAlert({
          title: "Error",
          type: "error",
          msg: error.message,
        });
      }
    } finally {
      setShowActivationPage(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 5 }}>
      {isLoading || isFetching ? (
        <Box className="center">
          <CircularProgress />
        </Box>
      ) : error ? (
        <p className="text-danger">{error.message}</p>
      ) : (
        <Box>
          {alert.msg && (
            <Box sx={{ mb: 5 }}>
              <Alert severity={alert.type} variant="filled">
                <AlertTitle>{alert.title}</AlertTitle>
                {alert.msg}
              </Alert>
            </Box>
          )}
          {showActivationPage && (
            <Box>
              <Formik
                initialValues={{
                  firstName: data.firstName,
                  lastName: data.lastName,
                  username: "",
                  dateOfBirth: dayjs().subtract(13, "year").subtract(1, "day"),
                  invitationCode: "",
                }}
                validationSchema={RegisterSchema}
                onSubmit={(values, { setSubmitting }) => {
                  handleSubmit(values, setSubmitting);
                }}
              >
                {({ isSubmitting, submitForm, setFieldValue }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Form autoComplete="off">
                      <Grid
                        container
                        columnSpacing={2}
                        rowSpacing={3}
                        sx={{ mb: 2 }}
                        className="form-box"
                      >
                        <Grid item xs={12} md={6}>
                          <Field
                            component={TextField}
                            name="firstName"
                            label="First Name"
                            fullWidth
                            variant="filled"
                            size="small"
                            autoFocus
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={TextField}
                            name="lastName"
                            label="Last Name"
                            fullWidth
                            variant="filled"
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={TextField}
                            name="username"
                            label="Username"
                            fullWidth
                            variant="filled"
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Field
                            component={DatePicker}
                            name="dateOfBirth"
                            label="Date of Birth"
                            maxDate={dayjs()
                              .subtract(13, "year")
                              .subtract(1, "day")}
                            renderInput={(params) => (
                              <MuiTextField
                                {...params}
                                name="dateOfBirth"
                                label="Date of Birth"
                                variant="filled"
                                fullWidth
                                size="small"
                              />
                            )}
                          />
                          <ErrorMessage
                            name="dateOfBirth"
                            render={(msg) => (
                              <Typography color="error">{msg}</Typography>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormLabel>Tell us who you are</FormLabel>
                          {otherSelected ? (
                            <Field
                              component={TextField}
                              type="text"
                              name="invitationCode"
                              label="Add text here"
                              variant="filled"
                              fullWidth
                              autoFocus
                            />
                          ) : (
                            <Field
                              id="userType"
                              component={TextField}
                              type="text"
                              name="invitationCode"
                              label="Select"
                              select
                              variant="filled"
                              fullWidth
                              onChange={(e) => {
                                if (e.target.value === "KW0283") {
                                  setFieldValue("invitationCode", "");
                                  setOtherSelected(true);
                                } else {
                                  setFieldValue(
                                    "invitationCode",
                                    e.target.value
                                  );
                                }
                              }}
                            >
                              {UserTypes.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Field>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <p className="text-center black">
                              By clicking{" "}
                              <span className="fw-700">'activate'</span>
                              , you are agreeing to our
                              <br />
                              Terms of Service, SafeGuarding Policy, Privacy
                              Policy, and Data Protection Policy.
                            </p>
                          </Box>
                          <Box className="center">
                            <Button
                              size="large"
                              onClick={submitForm}
                              variant="contained"
                              disabled={isSubmitting}
                              sx={{
                                letterSpacing: "3px",
                                fontSize: "1.3rem",
                                fontWeight: 400,
                              }}
                            >
                              Activate
                            </Button>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          {isSubmitting && <LinearProgress />}
                        </Grid>
                      </Grid>
                    </Form>
                  </LocalizationProvider>
                )}
              </Formik>
            </Box>
          )}
        </Box>
      )}
    </Container>
  );
};

export default Activate;
