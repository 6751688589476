import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Tooltip,
  TextField as MuiTextField,
} from "@mui/material";
import {
  Genders,
  HEAR_FROM_US_OPTIONS,
  Races,
  RacesWithTextField,
  UserTypes,
} from "Shared/Enums";
import React, { useEffect, useState } from "react";
import { ErrorMessage, Field } from "formik";
import { InfoOutlined, MoreHoriz } from "@mui/icons-material";
import { TextField, Autocomplete } from "formik-mui";
import { COUNTRIES } from "Utils/countries";

const CompleteProfileSelectField = ({
  formValues,
  setFieldValue,
  reduxUser,
  shouldRenderRaceTextfield,
}) => {
  // state
  const [selectedDisabilityChoice, setSelectedDisabilityChoice] =
    useState("no");
  const [otherSelected, setOtherSelected] = useState(false);
  const [renderRaceTextField, setRenderRaceTextField] = useState(
    shouldRenderRaceTextfield
  );
  const [renderDisabilityTextField, setRenderDisabilityTextField] =
    useState(false);
  const [textFieldHearFromUsOptions] = useState(
    HEAR_FROM_US_OPTIONS.filter((i) => i.freeTextField).map((i) => i.label)
  );

  useEffect(() => {
    if (formValues) {
      if (formValues.invitationCode === "KW0283") {
        setFieldValue("invitationCode", reduxUser?.whoYouAre ?? "");
        setOtherSelected(true);
      }

      if (reduxUser?.disabilities) {
        handleDisabilityChange(reduxUser?.disabilities);
      }
    }
  }, []);

  const handleChangeRace = (event) => {
    const selectedVal = event.target.value;
    const check = RacesWithTextField.includes(selectedVal);
    setRenderRaceTextField(check);
    if (check) {
      let newVal = selectedVal.split("-")[0];
      if (newVal === "Any other background") {
        newVal = "";
      } else {
        newVal = `${newVal} - `;
      }
      setFieldValue("race", newVal);
    } else {
      setFieldValue("race", selectedVal);
    }
  };

  const handleSelectRaceFromList = () => {
    setFieldValue("race", "");
    setRenderRaceTextField(false);
  };

  const handleDisabilityChange = (selectedVal) => {
    setSelectedDisabilityChoice(selectedVal);
    setFieldValue("disabilities", selectedVal);

    switch (selectedVal) {
      case "yes": {
        setRenderDisabilityTextField(true);
        break;
      }
      case "no": {
        setRenderDisabilityTextField(false);
        setFieldValue("disabilitiesDescription", "");
        break;
      }
      case "no-disclose": {
        setRenderDisabilityTextField(false);
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <>
      <Grid item xs={12} md={6}>
        <Field
          select
          fullWidth
          component={TextField}
          type="text"
          name="gender"
          label="Select Gender"
          variant="filled"
          size="small"
        >
          {Genders.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Field>
      </Grid>
      {formValues?.gender === "Other term preferred" && (
        <Grid item xs={12} md={6}>
          <Field
            fullWidth
            component={TextField}
            type="text"
            name="otherTerm"
            label="Other Term"
            variant="filled"
          />
        </Grid>
      )}
      <Grid item xs={12} md={6}>
        {renderRaceTextField ? (
          <Field
            component={TextField}
            name="race"
            label="Type Ethnicity"
            fullWidth
            variant="filled"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip
                    arrow
                    title="We monitor the ethnic group of our users to ensure we reach all groups in society and to take the appropriate steps to address this where we don’t. These categories are recommended by the Equality and Human Rights Commission and are used in the population census."
                  >
                    <InfoOutlined fontSize="small" />
                  </Tooltip>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ cursor: "pointer" }}
                  onClick={handleSelectRaceFromList}
                >
                  <Tooltip arrow title="Select from list">
                    <MoreHoriz fontSize="small" />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        ) : (
          <Field
            select
            fullWidth
            component={TextField}
            // type="text"
            name="race"
            label="Select Ethnicity"
            variant="filled"
            size="small"
            onChange={handleChangeRace}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip
                    arrow
                    title="We monitor the ethnic group of our users to ensure we reach all groups in society and to take the appropriate steps to address this where we don’t. These categories are recommended by the Equality and Human Rights Commission and are used in the population census."
                  >
                    <InfoOutlined fontSize="small" />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          >
            {Races.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        {otherSelected ? (
          <Field
            component={TextField}
            type="text"
            name="invitationCode"
            label="Please tell us who you are"
            placeholder="Add text here"
            variant="filled"
            fullWidth
            autoFocus
            size="small"
          />
        ) : (
          <Field
            id="userType"
            component={TextField}
            type="text"
            name="invitationCode"
            label="Please tell us who you are"
            select
            variant="filled"
            fullWidth
            size="small"
            onChange={(e) => {
              if (e.target.value === "KW0283") {
                setFieldValue("invitationCode", "");
                setOtherSelected(true);
              } else {
                setFieldValue("invitationCode", e.target.value);
              }
            }}
          >
            {UserTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Field>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <FormLabel>How did you hear of us</FormLabel>
        <Field
          id="userType"
          component={TextField}
          type="text"
          name="howYouHeardOfUs"
          label="Select"
          select
          variant="filled"
          fullWidth
          onChange={(e) => {
            setFieldValue("howYouHeardOfUs", e.target.value);
          }}
        >
          {HEAR_FROM_US_OPTIONS.map((option) => (
            <MenuItem key={option.label} value={option.label}>
              {option.label}
            </MenuItem>
          ))}
        </Field>
        {textFieldHearFromUsOptions.includes(formValues?.howYouHeardOfUs) && (
          <div className="mt-3">
            <Field
              fullWidth
              multiline
              minRows={2}
              component={TextField}
              name="hearFromUsDescription"
              variant="filled"
              placeholder="Please state"
              size="small"
            />
          </div>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <FormLabel className="d-flex align-items-center">
          Country of birth{" "}
          <Tooltip
            arrow
            title="We monitor the country of birth of our users to improve the delivery of our services. On occasions, and where appropriate, we may use this to strengthen a match that might be more effective in helping to improve outcomes."
          >
            <InfoOutlined fontSize="small" sx={{ ml: "5px" }} />
          </Tooltip>
        </FormLabel>
        <Field
          name="countryFrom"
          component={Autocomplete}
          options={COUNTRIES}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              name="countryFrom"
              placeholder="Type to search..."
              variant="filled"
              fullWidth
              hiddenLabel
            />
          )}
        />
        <ErrorMessage
          name="countryFrom"
          render={(msg) => <small className="text-danger">{msg}</small>}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl size="small">
          <FormLabel>
            Do you have a disability?{" "}
            <Tooltip title="We monitor the disability of our users to ensure we reach all groups in society and to take the appropriate steps to address this where we don’t.  The definition of disability under the Equality Act 2010 is ‘A physical or mental impairment which has a substantial and long-term adverse effect on a person’s ability to carry out normal day to day activities and has lasted, or is expected to last, 12 months or more.">
              <InfoOutlined fontSize="small" />
            </Tooltip>
          </FormLabel>
          <RadioGroup
            row
            value={selectedDisabilityChoice}
            onChange={(e) => handleDisabilityChange(e.target.value)}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
            <FormControlLabel
              value="no-disclose"
              control={<Radio />}
              label="I do not wish to disclose"
            />
          </RadioGroup>
        </FormControl>
        <br />
        {renderDisabilityTextField && (
          <Field
            fullWidth
            multiline
            minRows={4}
            component={TextField}
            name="disabilitiesDescription"
            variant="filled"
            placeholder="If you wish to disclose the type of disability to help us shape our services, please type in this box."
            helperText="Optional"
          />
        )}
      </Grid>
    </>
  );
};

export default CompleteProfileSelectField;
