import {
  Genders,
  HighestQualifications,
  Races,
  RacesWithTextField,
} from "Shared/Enums";
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";

import { Field } from "formik";
import { Info, InfoOutlined, MoreHoriz } from "@mui/icons-material";
import { TextField } from "formik-mui";

/**
 * @description contains text fields for edit profile page
 * @returns Select => Qualifications, Gender, Race, Disabilities
 */

const SelectContainer = ({ formValues, setFieldValue }) => {
  // state
  const [renderRaceTextField, setRenderRaceTextField] = useState(false);

  useEffect(() => {
    const { race } = formValues;
    handleInitialChangeRace(race);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInitialChangeRace = (initialVal) => {
    const check = !!Races.find((item) => item.value === initialVal);
    if (check) {
      setRenderRaceTextField(false);
    } else {
      setRenderRaceTextField(true);
    }
  };

  const handleSelectRaceFromList = () => {
    setFieldValue("race", "");
    setRenderRaceTextField(false);
  };

  const handleChangeRace = (selectedVal) => {
    const check = RacesWithTextField.includes(selectedVal);
    setRenderRaceTextField(check);
    if (check) {
      let newVal = selectedVal.split("-")[0];
      if (newVal === "Any other background") {
        newVal = "";
      } else {
        newVal = `${newVal} - `;
      }
      setFieldValue("race", newVal);
    } else {
      setFieldValue("race", selectedVal);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} className="align-items-start">
            <p className="text-style mb-3 mb-lg-0">
              Qualifications{" "}
              <Tooltip title="Please use this section to let us know what qualifications you are studying or have achieved. Alternatively, you can use this field to indicate what you think you will achieve.We will evolve this section and its functionality to support your engagement on our platform in the further update releases that follow.">
                <InfoOutlined fontSize="small" color="info" />
              </Tooltip>
            </p>
          </Grid>
          <Grid item xs={12} md={5}>
            <Field
              // select
              fullWidth
              component={TextField}
              name="degree"
              // variant="filled"
              hiddenLabel
              size="small"
            />
            {/* <MenuItem value="phd">PH.D</MenuItem>
              <MenuItem value="masters">Masters</MenuItem>
              <MenuItem value="bachelors">Bachelors</MenuItem>
              <MenuItem value="other">Other</MenuItem> */}
            {/* </Field> */}
          </Grid>
        </Grid>
        <hr />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} className="align-items-start">
            <p className="text-style mb-3 mb-lg-0">
              Highest Qualification Achieved
            </p>
          </Grid>
          <Grid item xs={12} md={5}>
            <Field
              select
              fullWidth
              component={TextField}
              name="highestQualification"
              size="small"
              hiddenLabel
            >
              <MenuItem value="--">--Select--</MenuItem>
              {HighestQualifications.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Field>
          </Grid>
        </Grid>
        <hr />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} className="align-items-start">
            <p className="text-style mb-3 mb-lg-0">Gender</p>
          </Grid>
          <Grid item xs={12} md={5}>
            <Field
              select
              fullWidth
              component={TextField}
              type="text"
              name="gender"
              hiddenLabel
              size="small"
            >
              {Genders.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Field>
          </Grid>
        </Grid>
        <hr />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} className="align-items-start">
            <p className="text-style mb-3 mb-lg-0">Ethnicity</p>
          </Grid>
          <Grid item xs={12} md={5}>
            {renderRaceTextField ? (
              <Field
                placeholder="Type ethnicity"
                hiddenLabel
                fullWidth
                component={TextField}
                name="race"
                size="small"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Tooltip
                        arrow
                        title="We monitor the ethnic group of our users to ensure we reach all groups in society and to take the appropriate steps to address this where we don’t. These categories are recommended by the Equality and Human Rights Commission and are used in the population census."
                      >
                        <Info fontSize="14px" />
                      </Tooltip>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      style={{ cursor: "pointer" }}
                      onClick={handleSelectRaceFromList}
                    >
                      <Tooltip arrow title="Select from list">
                        <MoreHoriz fontSize="small" />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Field
                placeholder="Select ethnicity"
                select
                fullWidth
                component={TextField}
                type="text"
                name="race"
                hiddenLabel
                variant="outlined"
                size="small"
                onChange={(e) => handleChangeRace(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Tooltip
                        arrow
                        title="We monitor the ethnic group of our users to ensure we reach all groups in society and to take the appropriate steps to address this where we don’t. These categories are recommended by the Equality and Human Rights Commission and are used in the population census."
                      >
                        <Info fontSize="14x" />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value="--">--Select--</MenuItem>
                {Races.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Field>
            )}
          </Grid>
        </Grid>
        <hr />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} className="align-items-start">
            <p className="text-style mb-3 mb-lg-0">
              Do you have a disability?{" "}
              <Tooltip title="We monitor the disability of our users to ensure we reach all groups in society and to take the appropriate steps to address this where we don’t.  The definition of disability under the Equality Act 2010 is ‘A physical or mental impairment which has a substantial and long-term adverse effect on a person’s ability to carry out normal day to day activities and has lasted, or is expected to last, 12 months or more.">
                <InfoOutlined fontSize="small" color="info" />
              </Tooltip>
            </p>
            <span className="small-text">
              If you wish to disclose the type of disability to help us shape
              our services, please type in this box.
            </span>
          </Grid>
          <Grid item xs={12} md={8}>
            <FormControl fullWidth>
              <RadioGroup
                row
                value={formValues.disabilities}
                onChange={(e, val) => {
                  setFieldValue("disabilities", val);
                }}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
                <FormControlLabel
                  value="no-disclose"
                  control={<Radio />}
                  label="I do not wish to disclose"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <hr />
      </Grid>
    </>
  );
};

export default SelectContainer;
