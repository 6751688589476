import "./styles.scss";

import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import {
  DeleteOutline,
  Flag,
  LocalOfferOutlined,
  PushPinOutlined,
  SettingsOutlined,
  ThumbDown,
  ThumbDownOutlined,
  ThumbUp,
  ThumbUpOutlined,
  Visibility,
} from "@mui/icons-material";
import { lazy, useEffect, useState } from "react";

import FallbackImage1 from "assets/images/logo.svg";
import FallbackImage2 from "assets/images/logo.svg";
import axios from "axios";
import { getToken } from "Shared/baseURL";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import ViewListSearchResults from "./ViewListSearchResults";
import Reminder from "Components/Reminder/Reminder";

// import FallbackImage2 from "assets/images/placeholder2.svg";
// import FallbackImage1 from "assets/images/logo.svg";

// components
const DeleteKeywordModal = lazy(() => import("../DeleteKeyword/DeleteKeyword"));
const EditKeyword = lazy(() => import("../EditKeyword/EditKeyword"));
const ReportKeyword = lazy(() => import("../ReportKeyword/ReportKeyword"));
const ViewKeyword = lazy(() => import("Components/ViewKeyword/ViewKeyword"));

const ViewListCards = ({
  keyword,
  view,
  refresh,
  searchResults,
  searchResultKeyword,
  index,
  getAllKewordsByList,
  dislikes,
  likes,
  kewordsId,
  refetch,
  keywordPosition,
  subjectHref,
  listHref,
}) => {
  // state
  const [btnLoading, setBtnLoading] = useState(false);
  const { enqueueSnackbar: notify } = useSnackbar();
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [pinLoading, setPinLoading] = useState(false);
  const [reportKeywordDialog, setReportKeywordDialog] = useState(false);
  const [viewKeyword, setViewKeyword] = useState(false);
  const [isLikedByMe, setIsLikedByMe] = useState(false);
  const [isDislikedByMe, setIsDislikedByMe] = useState(false);

  // redux
  const userRedux = useSelector((state) => state.user.profile);

  useEffect(() => {
    if (Array.isArray(likes)) {
      setIsLikedByMe(likes?.some((item) => item.uuid === userRedux.uuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [likes]);

  useEffect(() => {
    if (Array.isArray(dislikes)) {
      setIsDislikedByMe(dislikes?.some((item) => item.uuid === userRedux.uuid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dislikes]);

  const handleOpenDeleteModal = () => {
    setDeleteModal(true);
  };

  const handleEditModal = () => {
    setEditModal(true);
  };

  const handlePinKeyword = async (id) => {
    try {
      setPinLoading(true);
      const token = await getToken();
      const res = await axios.post(
        `/keywords/${id}/pin`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        notify("Keyword pinned.");
        if (refresh) {
          refresh(1, false, true);
        }
      } else {
        throw new Error("Error pinning the keyword.");
      }
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setPinLoading(false);
    }
  };

  const handleUnpinKeyword = async (id) => {
    try {
      setPinLoading(true);
      const token = await getToken();
      const res = await axios.post(
        `/keywords/${id}/unpin`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (res.status >= 200 && res.status < 300) {
        notify("Keyword un-pinned.");
        if (refresh) {
          refresh(1, false, true);
        }
      } else {
        throw new Error("Error un-pinning the keyword.");
      }
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setPinLoading(false);
    }
  };

  const handleReportKeyword = () => {
    setReportKeywordDialog(true);
  };

  const handleViewKeyword = () => {
    setViewKeyword(true);
  };

  const handleLikeKeyword = async () => {
    try {
      setBtnLoading(true);
      const token = await getToken();
      await axios.post(
        `keywords/${kewordsId}/likes`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify("Keyword liked.");
      refetch();
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setBtnLoading(false);
    }
  };

  const handleUnlikeKeword = async () => {
    try {
      setBtnLoading(true);
      const token = await getToken();
      await axios.delete(`keywords/${kewordsId}/likes`, {
        headers: {
          Authorization: token,
        },
      });
      notify("Keword Unliked.");
      refetch();
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setBtnLoading(false);
    }
  };

  const handleDislikeKeyword = async () => {
    try {
      setBtnLoading(true);
      const token = await getToken();
      await axios.post(
        `keywords/${kewordsId}/dislikes`,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      );
      notify("Keyword Disliked.");
      refetch();
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setBtnLoading(false);
    }
  };

  const handleUndoDislikeKeword = async () => {
    try {
      setBtnLoading(true);
      const token = await getToken();
      await axios.delete(`keywords/${kewordsId}/dislikes`, {
        headers: {
          Authorization: token,
        },
      });
      notify("Undo disliked.");
      refetch();
    } catch (error) {
      notify(error.message, { variant: "error" });
    } finally {
      setBtnLoading(false);
    }
  };

  if (searchResults) {
    return (
      <ViewListSearchResults
        index={index}
        searchResultKeyword={searchResultKeyword}
        dislikes={dislikes}
        likes={likes}
        kewordsId={kewordsId}
        refetch={refresh || refetch}
      />
    );
  } else {
    // props
    const {
      uuid,
      term,
      videoURL,
      question,
      userUuid,
      newImgURL,
      reportedBy,
      pinnedTime,
    } = keyword;

    let isExternal = false;

    if (newImgURL) {
      const baseURL = process.env.REACT_APP_BASEURL;
      isExternal = !newImgURL.includes(`${baseURL}/img`);
    }

    return (
      <Box className="view-list-cards">
        <Box className="title-box-card"></Box>
        {Boolean(newImgURL) ? (
          <Box
            onClick={handleViewKeyword}
            className={`center img-box ${view === "small" && "small-img-box"} ${
              isExternal ? "external-image" : ""
            }`}
            sx={{
              backgroundImage: `url(${newImgURL})`,
              cursor: "pointer",
            }}
          ></Box>
        ) : (
          <Box
            onClick={handleViewKeyword}
            className={`center img-box ${view === "small" && "small-img-box"}`}
            sx={{ cursor: "pointer" }}
          >
            <img
              src={index % 2 === 0 ? FallbackImage1 : FallbackImage2}
              alt={term}
              loading="lazy"
              className={`card-image px-3 ${view === "small" && "small-img"}`}
            />
          </Box>
        )}
        <Box className="title-box-card p-2">
          <div>
            <span onClick={handleViewKeyword} title={term ?? ""}>
              {term && term.toString().substr(0, 15)}
              {term.length > 15 ? "..." : ""}
            </span>
          </div>
        </Box>
        <Box sx={{ px: "1rem", py: "0.6rem" }}>
          <Box className="description">
            {question ? (
              <Tooltip title={question}>
                <span className="black">{question}</span>
              </Tooltip>
            ) : (
              <span className="indicate-empty">(Empty)</span>
            )}
          </Box>
        </Box>
        <Box className="d-flex  px-1 pb-2 bottom-box ">
          <div className="icon-div d-flex">
            <IconButton
              onClick={handleViewKeyword}
              size="small"
              title="View keyword"
            >
              <Visibility fontSize="inherit" />
            </IconButton>
            {isLikedByMe ? (
              <IconButton
                disabled={btnLoading}
                onClick={handleUnlikeKeword}
                size="small"
                className="liked-btn "
                title="You liked it - click to unlike"
              >
                <ThumbUp fontSize="inherit" />
              </IconButton>
            ) : (
              <IconButton
                disabled={btnLoading}
                onClick={handleLikeKeyword}
                size="small"
                title="Like keyword"
              >
                <ThumbUpOutlined fontSize="inherit" />
              </IconButton>
            )}
            {isDislikedByMe ? (
              <IconButton
                disabled={btnLoading}
                onClick={handleUndoDislikeKeword}
                size="small"
                title="Disliked keyword"
                className="disliked-btn"
              >
                <ThumbDown fontSize="inherit" />
              </IconButton>
            ) : (
              <IconButton
                disabled={btnLoading}
                onClick={handleDislikeKeyword}
                size="small"
                title="Dislike keyword"
              >
                <ThumbDownOutlined fontSize="inherit" />
              </IconButton>
            )}
            {pinnedTime && (
              <IconButton
                disabled={pinLoading}
                onClick={() => handleUnpinKeyword(uuid)}
                className="pin-btn"
                size="small"
                title="Unpin keyword"
              >
                {pinLoading ? (
                  <CircularProgress size={15} />
                ) : (
                  <PushPinOutlined fontSize="inherit" />
                )}
              </IconButton>
            )}
            {!pinnedTime && (
              <IconButton
                size="small"
                disabled={pinLoading}
                onClick={() => handlePinKeyword(uuid)}
                title="Pin keyword"
              >
                {pinLoading ? (
                  <CircularProgress size={15} />
                ) : (
                  <LocalOfferOutlined fontSize="inherit" />
                )}
              </IconButton>
            )}
            {userRedux.uuid === userUuid && (
              <>
                <IconButton
                  disabled={btnLoading}
                  onClick={handleEditModal}
                  size="small"
                  title="Update keyword"
                >
                  <SettingsOutlined fontSize="inherit" />
                </IconButton>
                <Reminder keyword keywordID={keyword.uuid} size="small" />
                <IconButton
                  disabled={btnLoading}
                  onClick={handleOpenDeleteModal}
                  disableRipple
                  size="small"
                  color="error"
                  title="Delete Keyword"
                >
                  <DeleteOutline fontSize="inherit" />
                </IconButton>
              </>
            )}
            {userRedux && userRedux.uuid !== userUuid ? (
              Array.isArray(reportedBy) &&
              reportedBy.includes(userRedux.uuid) ? (
                <IconButton
                  sx={{ cursor: "not-allowed" }}
                  disabled={btnLoading}
                  disableRipple
                  size="small"
                  color="error"
                  title="Already reported"
                >
                  <Flag fontSize="inherit" sx={{ cursor: "not-allowed" }} />
                </IconButton>
              ) : (
                <IconButton
                  disabled={btnLoading}
                  onClick={() => handleReportKeyword(uuid)}
                  size="small"
                  title="Report keyword"
                >
                  <Flag fontSize="inherit" />
                </IconButton>
              )
            ) : (
              <></>
            )}
          </div>
        </Box>
        {view === "full" && videoURL && (
          <Box className="link-box">
            <a
              href={videoURL}
              target="_blank"
              className="link blue"
              rel="noreferrer"
            >
              {videoURL}
            </a>
          </Box>
        )}
        {deleteModal && (
          <DeleteKeywordModal
            open={deleteModal}
            close={setDeleteModal}
            id={uuid}
            refresh={refresh}
          />
        )}
        {editModal && (
          <EditKeyword
            open={editModal}
            keyword={keyword}
            close={setEditModal}
            refresh={refresh}
          />
        )}
        {reportKeywordDialog && (
          <ReportKeyword
            open={reportKeywordDialog}
            toggleDialog={setReportKeywordDialog}
            uuid={uuid}
            name={term}
            getAllKewordsByList={getAllKewordsByList}
          />
        )}
        {viewKeyword && (
          <ViewKeyword
            open={viewKeyword}
            toggle={setViewKeyword}
            keyword={keyword}
            refetch={refresh || refetch}
            fallBackImage={index % 2 === 0 ? FallbackImage1 : FallbackImage2}
            subjectHref={subjectHref}
            listHref={listHref}
          />
        )}
      </Box>
    );
  }
};

export default ViewListCards;
