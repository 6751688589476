import { Box, Tab, Tabs } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import ReceivedChallenges from "./ReceivedChallenges";
import SentChallenges from "./SentChallenges";

const Challenges = ({ open, toggle }) => {
  // state
  const [tabKey, setTabKey] = useState(0);

  const onClose = () => {
    toggle(false);
  };

  const handleChangeTabKey = (event, newKey) => {
    setTabKey(newKey);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onclose}
      aria-labelledby="challenges"
    >
      <DialogTitle>Challenges</DialogTitle>
      <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            centered
            value={tabKey}
            onChange={handleChangeTabKey}
            aria-label="challenges-tab"
          >
            <Tab label="Received" />
            <Tab label="Sent" />
          </Tabs>
        </Box>
        <TabPanel index={0} value={tabKey}>
          <ReceivedChallenges closeChallengesDialog={onClose} />
        </TabPanel>
        <TabPanel index={1} value={tabKey}>
          <SentChallenges closeChallengesDialog={onClose} />
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function TabPanel({ value, index, children }) {
  return (
    <div hidden={value !== index}>
      {value === index && (
        <Box sx={{ p: 2 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

export default Challenges;
