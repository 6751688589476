import "./styles.scss";

import * as Yup from "yup";

import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
} from "@mui/material";
import { Field, Form, Formik } from "formik";

import React from "react";
import { RadioGroup, TextField } from "formik-mui";
import { deleteUserAccount } from "Pages/Profile/Api";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useNotify } from "Utils/hooks";
import { useReduxUserProfile } from "Utils/hooks";
import { userLogout } from "Redux/Actions";

const SubmitSchema = Yup.object().shape({
  reason: Yup.string().required("Required"),
  feedback: Yup.string().required("Required"),
});

const DELETE_REASON = [
  "I have a duplicate account",
  "I'm getting too many emails",
  "I'm not getting any value from my membership",
  "I have a privacy concent",
  "I'm receiving unwanted contact",
  "Other",
];

const DeleteUserAccount = () => {
  // state
  const notify = useNotify();

  // redux
  const userRedux = useReduxUserProfile();
  const dispatch = useDispatch();

  // navigation
  const navigate = useNavigate();

  const handleDeleteAccount = async (values, setSubmitting) => {
    try {
      const res = await deleteUserAccount(userRedux.uuid);
      if (res.message) {
        throw new Error(res.message);
      }
      notify("Account deleted.");
      handleLogout();
    } catch (error) {
      notify(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleLogout = () => {
    dispatch(userLogout());
    navigate("/");
  };
  return (
    <Paper className="p-4 rounded-3 paper">
      <div>
        <h4>Delete Account</h4>
        <hr />
      </div>
      <Box sx={{ my: 2 }}>
        {alert.msg && (
          <Alert severity={alert.type} variant="filled">
            {alert.msg}
          </Alert>
        )}
      </Box>
      <Formik
        initialValues={{
          reason: DELETE_REASON[0],
          feedback: "",
        }}
        validationSchema={SubmitSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          handleDeleteAccount(values, setSubmitting, resetForm);
        }}
      >
        {({ values, isSubmitting, submitForm }) => (
          <Form autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <p style={{ color: "#666" }}>
                  Tell us the reason of deleting your account. This action can't
                  be undone.
                </p>
                <Field component={RadioGroup} name="reason">
                  {DELETE_REASON.map((item) => (
                    <FormControlLabel
                      value={item}
                      control={<Radio />}
                      label={item}
                    />
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  name="feedback"
                  label="Feedback"
                  fullWidth
                  autoComplete="off"
                  size="small"
                  multiline
                  minRows={3}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                  onClick={submitForm}
                  color="error"
                  disableElevation
                  className="px-3 rounded-3 mt-4"
                >
                  {isSubmitting ? "Deleting Account" : "Delete Account"}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};

export default DeleteUserAccount;
